"use client";

import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter, usePathname } from "next/navigation";
import classNames from "classnames";
import {
  LuMenu as Menu,
  LuChevronRight as ChevronRight,
  LuInfo as Info,
  LuLogOut as LogOut,
} from "react-icons/lu";
import { useGlobalStore } from "@/providers/global-provider";
import {
  Button,
  Sheet,
  SheetContent,
  SheetTrigger,
  ScrollArea,
  Separator,
  Badge,
} from "@/components/atoms";
import { NavItemWithIcon, MobileLinkProps } from "@/types/nav";
import { createNavigation } from "@/utils/permissions";

export function MobileNav() {
  const router = useRouter();
  const pathName = usePathname();
  const [open, setOpen] = React.useState(false);
  const {
    user: { name, plan_version, is_owner, is_supervisor },
    permissions,
  } = useGlobalStore();

  const mainNav = createNavigation({
    permissions,
    plan: plan_version,
    isSupervisor: is_supervisor,
    isOwner: is_owner,
  });

  const titleSection = mainNav.find((obj) => obj.url === pathName)?.title || "";

  const badgeVariant = [
    "full",
    "standard",
    "small",
    "smallpre",
    "premium",
  ].includes(plan_version)
    ? "premium"
    : "secondary";

  const secNav = [
    {
      title: "Acerca de",
      url: "/dashboard/about",
      icon: Info,
      styles: "px-4 flex justify-between text-slate-500",
    },
    {
      title: "Salir",
      url: "/",
      icon: LogOut,
      onClick: () => {
        router.push("/?flush=true");
      },
      styles: "px-4 flex justify-between text-red-500",
    },
  ];

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger
        asChild
        className="bg-blue-500 py-6 lg:hidden fixed w-full top-0 flex justify-start"
      >
        <Button className="flex !justify-start w-full px-0 text-base rounded-none space-x-4">
          <Menu className="h-6 w-6 text-white" />
          <span className="sr-only">Toggle Menu</span>
          <div>{titleSection}</div>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="!p-0 !text-slate-500 !border-r-0">
        <div className="bg-blue-500 pt-10 pb-4 px-4">
          <Image
            src="/logo-bilog-white.svg"
            alt="Logo"
            width={100}
            height={100}
            className="mb-4"
          />
          <h3 className="text-2xl text-white font-semibold">Hola {name}</h3>
          <Badge className="capitalize w-full !text-sm" variant={badgeVariant}>
            ¡Eres {plan_version}!
          </Badge>
        </div>
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10">
          <div className="flex flex-col space-y-4">
            {mainNav.map(
              (item: NavItemWithIcon) =>
                item.url && (
                  <MobileLink
                    key={item.url}
                    href={item.url}
                    onOpenChange={setOpen}
                    className={item.styles}
                  >
                    <div className="flex gap-x-2">
                      <item.icon className="h-5 w-5" />
                      {item.title}
                    </div>
                    <ChevronRight className="h-5 w-5" />
                  </MobileLink>
                )
            )}
            <Separator className="!bg-slate-500/20" />
            {secNav.map(
              (item: NavItemWithIcon) =>
                item.url && (
                  <MobileLink
                    key={item.url}
                    href={item.url}
                    onClick={item.onClick}
                    onOpenChange={setOpen}
                    className={item.styles}
                  >
                    <div className="flex gap-x-2">
                      <item.icon className="h-5 w-5" />
                      {item.title}
                    </div>
                    <ChevronRight className="h-5 w-5" />
                  </MobileLink>
                )
            )}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}

function MobileLink({
  href,
  onOpenChange,
  className,
  children,
  onClick,
  ...props
}: MobileLinkProps) {
  const router = useRouter();

  return (
    <Link
      href={href}
      onClick={() => {
        onClick?.();
        router.push(href.toString());
        onOpenChange?.(false);
      }}
      className={classNames(className)}
      {...props}
    >
      {children}
    </Link>
  );
}
