import React from "react";
import { Form } from "@/types/general";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Checkbox,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
} from "@/components/atoms";
import { AMOUNTS_PAYMENTS } from "@/libs/patients";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { formSchema } from "./schema";

export const ValuesToPay = ({ form }: { form: Form<typeof formSchema> }) => {
  const [typeAmount, setTypeAmount] = React.useState<string>("percentage");

  return (
    <Card className="col-span-6 p-5">
      <CardHeader>
        <CardTitle>Valores del pago</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-x-6 ">
        <FormField
          control={form.control}
          name="change_amount_professional"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-full">
              <FormControl>
                <>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      if (!checked) {
                        form.setValue("amount_to_pay_professional", 0);
                        form.setValue(
                          "amount_to_pay_professional_percentage",
                          0
                        );
                      }
                      field.onChange(checked);
                    }}
                  />
                  <div className="leading-none flex flex-col mx-2 w-full">
                    <Label>Modificar valores a pagar al profesional</Label>
                    <p className="text-sm font-regular text-gray-400">
                      Estos son los valores que se usaran para la liquidación
                      del profesional
                    </p>
                  </div>
                </>
              </FormControl>
            </FormItem>
          )}
        />
        <RadioGroup
          disabled={!form.watch("change_amount_professional")}
          onValueChange={setTypeAmount}
          defaultValue={typeAmount}
          className="col-span-full gap-x-5 mt-2 !flex flex-row items-center "
        >
          {AMOUNTS_PAYMENTS.map((option: any, index: number) => (
            <FormField
              key={index}
              control={form.control}
              name={option.name}
              disabled={
                typeAmount !== option.disableCondition ||
                !form.watch("change_amount_professional")
              }
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="flex items-center gap-x-2">
                    <RadioGroupItem value={option.disableCondition} />
                    {option.label}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      value={
                        typeAmount !== option.disableCondition
                          ? ""
                          : field.value || ""
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}{" "}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};
