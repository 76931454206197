import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  specialty_code: z.string().trim().min(1, "Código requerido"),
  description: z.string().trim().min(1, "Nombre de especialidad requerida"),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
