"use client";

import React from "react";
import { Button, Dialog } from "@/components/atoms";
import { MedicalHistoryTable } from "@/components/organisms/tables";
import { Actions } from "@/components/organisms/forms";
import { useGlobalStore } from "@/providers/global-provider";
import { EmptyState } from "@/components/molecules";
import { getPatientHc } from "@/actions/patients";
import { toast } from "@/hooks";
import { MedicalHistory } from "@/types/patients";

export const MedicalHistoryView = () => {
  const {
    patient,
    setPatientHistory,
    setHistorySelected,
    updatePatient,
    searchBarRef,
    general_params: { professionals, offices },
  } = useGlobalStore();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [formState, setFormState] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleFormState = (hc: { [key: string]: any } | null, type: string) => {
    setHistorySelected(hc);
    setFormState(type);
    setOpenModal(true);
  };
  const refreshData = async () => {
    try {
      const medical_history: MedicalHistory[] = await getPatientHc(
        patient.id_patient
      );
      setPatientHistory({ history: medical_history });
      updatePatient("medical_history", medical_history);
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    }
  };

  return (
    <div className="!w-full hidden lg:flex lg:flex-col">
      {patient.id_patient ? (
        <>
          {patient?.medical_history?.length ? (
            <>
              <MedicalHistoryTable
                history={patient.medical_history}
                action={handleFormState}
              />
              {patient.id_patient && (
                <div className="flex mt-4 space-x-5 justify-end">
                  <Button
                    type="button"
                    onClick={() => handleFormState(null, "create_edit")}
                  >
                    Nueva historia clínica
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
              <EmptyState
                title="El paciente no tiene historia clínica para mostrar."
                copy="Puedes cargar una historia clínica"
                className="col-span-full !justify-start"
              />
              <Button
                type="button"
                className="col-span-4 col-start-6 mt-4"
                onClick={() => handleFormState(null, "create_edit")}
              >
                Nueva historia clínica
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="grid grid-cols-12 gap-x-10">
          <EmptyState
            title="No existe paciente seleccionado"
            copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
            className="col-span-6 !justify-start flex col-start-4"
          />
          <Button
            className="col-span-3 col-start-6"
            onClick={() => searchBarRef?.current?.focus()}
          >
            Buscar Paciente
          </Button>
        </div>
      )}
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <Actions
          professionals={professionals}
          offices={offices}
          formState={formState}
          closeModal={setOpenModal}
          loading={loading}
          setLoading={setLoading}
          refreshData={refreshData}
        />
      </Dialog>
    </div>
  );
};
