import { INITAL_PARAMS } from "@/libs/statistics";

type StatisticsData = {
  params: any;
  data: any;
  initialized_data: boolean;
};

type State = {
  statistics: {
    [key: string]: StatisticsData;
  };
};

type Actions = {
  setDataStatistics: (payload: {
    data: any;
    params: any;
    section: string;
  }) => void;
  setInitializedDataStatistics: (payload: {
    data: any;
    section: string;
  }) => void;
};

type StatisticsStore = State & Actions;

export const initState: State = {
  statistics: {
    finances: { params: INITAL_PARAMS, data: [], initialized_data: false },
    control: {
      params: INITAL_PARAMS,
      data: {},
      initialized_data: false,
    },
    "benefits-made": {
      params: INITAL_PARAMS,
      data: [],
      initialized_data: false,
    },
    budgets: {
      params: INITAL_PARAMS,
      data: [],
      initialized_data: false,
    },
  },
};

export const StatisticsSlice = (
  set: (partial: Partial<StatisticsStore>) => void,
  get: () => StatisticsStore
): StatisticsStore => ({
  ...initState,
  setInitializedDataStatistics: ({
    data,
    section,
  }: {
    data: any;
    section: string;
  }) => {
    const state = get();

    if (!state.statistics[section]?.initialized_data) {
      set({
        statistics: {
          ...state.statistics,
          [section]: {
            ...state.statistics[section],
            data,
            initialized_data: true,
          },
        },
      });
    }
  },
  setDataStatistics: ({
    data,
    params,
    section,
  }: {
    data: any;
    params: any;
    section: string;
  }) => {
    set({
      statistics: {
        ...get().statistics,
        [section]: {
          ...get().statistics[section],
          params,
          data,
        },
      },
    });
  },
});
