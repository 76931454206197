import React from "react";
import classNames from "classnames";
import { HiOutlineIdentification } from "react-icons/hi2";
import { TooltipComponent } from "@/components/molecules/tooltip";
import { FormControl, FormField, FormItem, FormMessage } from "./form";
import { Button, Input } from "../atoms";

export function InputCarnetVerification({
  form,
  onClick,
}: {
  className?: string;
  form?: any;
  onClick: () => void;
}) {
  const DISABLED_ACTION =
    form.formState.isDirty || !form.getValues("id_patient");

  return (
    <FormField
      control={form.control}
      name="affiliate_number"
      render={({ field }) => (
        <FormItem className="flex items-center w-full mt-1 shadow-sm rounded-md pr-4 focus-within:ring-1 ring-1 ring-gray-300 h-10">
          <FormControl>
            <Input
              type="text"
              {...field}
              className="w-full border-none"
              maxLength={30}
            />
          </FormControl>
          <TooltipComponent
            trigger={
              <Button
                type="button"
                variant="link"
                size="icon"
                onClick={onClick}
                disabled={DISABLED_ACTION}
              >
                <HiOutlineIdentification
                  className={classNames(
                    "text-xl ml-2 flex",
                    DISABLED_ACTION
                      ? "flex-shrink-0 hover:cursor-not-allowed text-slate-300"
                      : "flex-shrink-0 text-blue-500 hover:cursor-pointer hover:transform hover:rotate-[10deg] transform-gpu hover:transition-all hover:duration-200"
                  )}
                />
              </Button>
            }
            content={<span>Verificar carnet</span>}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
