"use client";

import React from "react";
import { DailiesDataPatient } from "@/types/statistics";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data: DailiesDataPatient[];
}> = ({ data }) => <Data columns={columns} data={data} />;
