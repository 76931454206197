import React from "react";
import classNames from "classnames";
import { Badge, CardTitle } from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";

export const Header = ({
  data,
}: {
  data: {
    header: {
      professional_name: { first_name: string; last_name: string };
      total_amount_order_list: number;
      date_received: Date;
      paid: string;
      amount_laboratory: number;
      description: string;
    };
  };
}) => {
  const {
    professional_name,
    total_amount_order_list,
    date_received,
    paid,
    amount_laboratory,
    description,
  } = data["header"];
  const MOVEMENTS_TYPE =
    date_received && paid === "si"
      ? "Pagado"
      : date_received
      ? "Recibido"
      : "Emitido";
  const STYLES_MOVEMENTS = {
    Emitido: "bg-slate-100 !text-slate-500",
    Pagado: "bg-green-100 !text-green-500",
    Recibido: "bg-yellow-100 !text-yellow-500",
  };
  const STYLES_SECTIONS =
    "font-bold tracking-wide leading-5 text-xl rounded p-5";
  const sections: {
    title: string;
    value: string | React.ReactElement;
    styles: string;
  }[] = [
    {
      title: "Profesional",
      value: professional_name.first_name,
      styles: STYLES_SECTIONS,
    },
    {
      title: "Valor total",
      value: numberToCurrency(total_amount_order_list) || "-",
      styles: classNames(STYLES_SECTIONS, "!text-green-500"),
    },
    {
      title: "Valor laboratorio",
      value: numberToCurrency(amount_laboratory) || "-",
      styles: STYLES_SECTIONS,
    },
    {
      title: "Estado",
      value: (
        <Badge className={STYLES_MOVEMENTS[MOVEMENTS_TYPE]}>
          {MOVEMENTS_TYPE}
        </Badge>
      ),
      styles: "mt-4",
    },
  ];

  return (
    <div className="grid grid-cols-12 print:hidden rounded-md bg-white mt-2 mb-5">
      <CardTitle className="col-span-12 border-b border-b-slate-300 p-5 text-xl rounded-t-md bg-white truncate">
        {description}
      </CardTitle>
      {sections.map((s, i) => {
        return (
          <div key={i} className="col-span-3 p-4 py-8 border border-slate-200">
            <h3 className="text-slate-500 text-base">{s.title}</h3>
            <CardTitle className={s.styles}>{s.value}</CardTitle>
          </div>
        );
      })}
    </div>
  );
};
