import { Checkbox } from "@/components/atoms";
import { ListSendWhatsapp, WhatsappMessage } from "@/types/reminders";

export const columns = (
  listSendReminders: WhatsappMessage[],
  setListReminders: (value: WhatsappMessage[]) => void,
  data: WhatsappMessage[]
) => {
  const onChangeHandler = (row: any) => {
    if (
      listSendReminders.some(
        (t: { [key: string]: any }) => t.id_schedule === row.id_schedule
      )
    ) {
      const selected = listSendReminders?.filter(
        (t: any) => t.id_schedule !== row.id_schedule
      );
      setListReminders(selected);
    } else {
      setListReminders([...listSendReminders, row]);
    }
  };

  return [
    {
      id: "select",
      header: ({ table }: any) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value: any) => {
            table.toggleAllPageRowsSelected(!!value);
            setListReminders(value ? data : []);
          }}
          aria-label="Seleccionar todo"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }: any) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
            onChangeHandler(row.original);
          }}
          aria-label="Seleccionar fila"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "hour",
      header: "Hora",
      cell: ({ row }: { row: any }) => {
        const { hour } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {hour}
          </p>
        );
      },
    },
    {
      accessorKey: "paciente",
      header: "Paciente",
      cell: ({ row }: { row: any }) => {
        const { patient } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {patient}
          </p>
        );
      },
    },
    {
      accessorKey: "professional",
      header: "Profesional",
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return <p className="leading-6 text-slate-800 my-1">{professional}</p>;
      },
    },
    {
      accessorKey: "phone",
      header: "Teléfono",
      cell: ({ row }: { row: any }) => {
        const { phone } = row.original;

        return <p className="leading-6 text-slate-800 my-1">{phone}</p>;
      },
    },
  ];
};
