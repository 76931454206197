import { Skeleton } from "@/components/atoms";

export const Loading = ({ heigth }: { heigth: string }) => {
  return (
    <div className="max-w-screen-2xl mx-auto mt-6">
      <Skeleton className="h-14 w-full rounded-xl" />
      <Skeleton className="mt-2 w-full rounded-xl" style={{ height: heigth }} />
    </div>
  );
};
