import React from "react";
import {
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Form, Specialty } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/configuration/new-medical-insurance/benefit/schema";

export const BenefitsSpecialies = ({
  form,
  specialties,
}: {
  form: Form<typeof formSchema>;
  specialties: Specialty[];
}) => {
  React.useEffect(() => {
    if (!form.getValues("disabled_specialties") && specialties.length > 0) {
      form.setValue("id_specialty", specialties[0]?.id_specialty || null);
    } else {
      form.setValue("id_specialty", null);
    }
  }, [form.getValues("disabled_specialties")]);

  return (
    <>
      <FormField
        control={form.control}
        name="id_specialty"
        render={({ field }) => (
          <FormItem className="col-span-6">
            <FormLabel>Especialidad</FormLabel>
            <FormControl>
              <Select
                key="id_specialty"
                disabled={
                  form.getValues("disabled_specialties") ||
                  specialties?.length === 0
                }
                value={field.value}
                onValueChange={(value: number) => {
                  form.setValue("id_specialty", value);
                }}
              >
                <SelectTrigger className="w-full !h-10 !text-sm">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {specialties.map((specialty: Specialty) => (
                    <SelectItem
                      key={specialty.id_specialty}
                      value={"" + specialty.id_specialty}
                    >
                      <p>{specialty.description}</p>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="disabled_specialties"
        render={({ field }) => (
          <FormItem className="col-span-6 gap-x-1 flex items-center">
            <FormControl>
              <Checkbox
                disabled={specialties?.length === 0}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel>No tiene especialidad</FormLabel>
          </FormItem>
        )}
      />
    </>
  );
};
