import React from "react";
import classNames from "classnames";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";
import { usePagination } from "@/hooks/use-pagination";

export const Pagination: React.FC<{
  onPageChange: (value: any) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  totalPages: number;
}> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  totalPages,
}) => {
  const DOTS = "...";
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    totalPages,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () =>
    currentPage !== totalPages && onPageChange(currentPage + 1);
  const onPrevious = () => currentPage > 1 && onPageChange(currentPage - 1);

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 items-center justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span
              onClick={onPrevious}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 hover:cursor-pointer"
            >
              <span className="sr-only">Previous</span>
              <LuArrowLeft className="size-4" />
            </span>
            {paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={index}
                    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                  >
                    &#8230;
                  </span>
                );
              }
              const pillCss = classNames(
                currentPage === lastPage &&
                  "relative z-10 inline-flex items-center border border-slate-100 bg-slate-50 px-4 py-2 text-sm font-medium text-slate-100 focus:z-20 hover:cursor-pointer hover:bg-gray-50",
                currentPage === pageNumber
                  ? "relative z-10 inline-flex items-center border border-blue-500 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-500 focus:z-20 hover:cursor-pointer hover:bg-gray-50"
                  : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm text-slate-500 font-medium hover:cursor-pointer hover:bg-gray-50"
              );

              return (
                <span
                  key={index}
                  onClick={() => onPageChange(pageNumber)}
                  aria-current="page"
                  className={pillCss}
                >
                  {pageNumber}
                </span>
              );
            })}
            <span
              onClick={onNext}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 hover:cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <LuArrowRight className="size-4" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};
