import { Prescription } from "@/types/patients";

type State = {
  prescription_selected: Prescription | null;
};

type Actions = {
  setPrescriptionsSelected: (prescription: Prescription | null) => void;
};

type PatientPrescriptionStore = State & Actions;

export const INIT_STATE: State = {
  prescription_selected: null,
};

export const PrescriptionsSlice = (
  set: (partial: Partial<PatientPrescriptionStore>) => void,
  get: () => PatientPrescriptionStore
): PatientPrescriptionStore => ({
  ...INIT_STATE,
  setPrescriptionsSelected: (prescription: Prescription | null) => {
    const state = get();

    set({
      ...state,
      prescription_selected: prescription,
    });
  },
});
