"use client";

import React from "react";
import { columns } from "./columns";
import { Data } from "./data";
import { Service } from "@/types/reports";

export const Table: React.FC<{ data: Service }> = ({ data }) => (
  <Data columns={columns} data={data} />
);
