import React from "react";
import classNames from "classnames";
import { LuSlidersHorizontal, LuTrash2, LuUsers } from "react-icons/lu";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Card,
  CardDescription,
  CardTitle,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Form, Process, User } from "@/types/general";
import { SelectPermissions, CopyPermissions } from "./permissions";
import { formSchema } from "./schema";

export const ActionsPermissions: React.FC<{
  permissions: Process[];
  form: Form<typeof formSchema>;
  users: User[];
}> = ({ permissions, form, users }) => {
  const { is_supervisor, id_user_to_copy, id_user } = form.watch();
  const { permissions: PREV_PERMISSIONS } = form.formState.defaultValues || {};
  const USER_TO_COPY = users?.find((u: User) => u.id_user === id_user_to_copy);
  const { openDialog } = useDialog();

  return (
    <div
      className={classNames("grid grid-cols-2 gap-10 pb-8 col-span-full", {
        "opacity-50 cursor-not-allowed pointer-events-none": is_supervisor,
      })}
    >
      <div className="item">
        <Card>
          <div className="text-lg mb-1 !font-bold">
            <LuUsers className="size-8 text-blue-500 mb-2" />
            <CardTitle className="text-lg mb-1 !font-bold">
              Copia automática
            </CardTitle>
          </div>
          <CardDescription className="mb-6">
            Copia los permisos de otro usuario predefinido para asignarlos a
            este.
          </CardDescription>
          {id_user_to_copy && (
            <Alert variant="warning" className="flex flex-col p-2 mb-6">
              <AlertTitle className="flex justify-between">
                <span className="text-base">
                  Profesional seleccionado:{" "}
                  <strong>{USER_TO_COPY?.name}</strong>
                </span>
                <LuTrash2
                  className="size-5 text-red-500 cursor-pointer hover:text-red-400"
                  onClick={() => {
                    form.setValue("id_user_to_copy", null);
                    form.setValue("permissions", PREV_PERMISSIONS || []);
                  }}
                />
              </AlertTitle>
              <AlertDescription>
                <p>
                  Se copiarán los permisos del profesional seleccionado, en caso
                  de no quererlo, podés eliminar la selección.
                </p>
              </AlertDescription>
            </Alert>
          )}
          <Button
            variant="ghost"
            type="button"
            className="border border-blue-500 w-full"
            onClick={() =>
              openDialog({
                content: <CopyPermissions users={users} form={form} />,
              })
            }
            disabled={users.length <= 1 && !!id_user}
          >
            Ver más
          </Button>
        </Card>
      </div>
      <div>
        <Card>
          <div>
            <LuSlidersHorizontal className="size-8 text-blue-500 mb-2" />
            <CardTitle className="text-lg mb-1 !font-bold">
              Permisos personalizados
            </CardTitle>
          </div>
          <CardDescription className="mb-6">
            Selecciona los permisos de acceso según las funciones del usuario.
          </CardDescription>
          <Button
            variant="ghost"
            type="button"
            className="border border-blue-500 w-full"
            onClick={() =>
              openDialog({
                content: (
                  <SelectPermissions permissions={permissions} form={form} />
                ),
              })
            }
          >
            Ver más
          </Button>
        </Card>
      </div>
    </div>
  );
};
