"use client";

import React from "react";
import classNames from "classnames";
import { getDefaultStats } from "@/libs/reports";
import { Button, Card } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { ReportsTable } from "@/components/organisms/tables";
import { ReportList } from "@/components/organisms/reports/reports-list";
import { GenerateFile } from "@/components/organisms/reports/generate-file";
import {
  closeReport,
  createAuditorReport,
  uploadReportFile,
} from "@/actions/reports";
import { headerReportsMapper } from "@/utils/tranformers";
import { toast } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { Loading } from "./loading";
import { Pagination } from "./pagination";

export const SummaryReports = ({
  device,
  section,
}: {
  device: string;
  section: "auditor";
}) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const {
    reports,
    general_params: {
      user_params: { name, address, phone, email, lender_code_bilog },
    },
    setDataReports,
    startLoading,
    stopLoading,
    loading,
  } = useGlobalStore();
  const { services, total_amount, total_services, total_pages } =
    reports[section].data;
  const IS_DESKTOP = ["desktop"].includes(device);
  const STATS_DATA = getDefaultStats({
    totalAmount: total_amount,
    totalServices: total_services,
  });
  const onSubmitCurrentPage = async (page: number) => {
    startLoading();
    const { params } = reports[section];
    setCurrentPage(page);
    const response = (await createAuditorReport({
      ...params,
      page_number: currentPage,
    })) as any;

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const { services, total_amount, total_services, total_pages } = response;
      setDataReports({
        data: { services, total_amount, total_services, total_pages },
        params,
        section: "auditor",
      });
      toast({
        variant: "successful",
        title: "Reporte generado correctamente",
        description: "Ahora puede ver los datos del reporte generado.",
      });
    }

    stopLoading();
  };

  const HandleUpReport = async () => {
    try {
      await uploadReportFile(reports.params);
      return toast({
        variant: "successful",
        title: "Archivo subido correctamente",
        description: "El reporte ahora se enuentra cargado en la nube.",
      });
    } catch (e: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: e.message,
      });
    }
  };
  const handleCloseReport = async () => {
    try {
      await closeReport(reports.params);

      return toast({
        variant: "successful",
        title: "Liquidacion cerrada correctamente",
        description: "La liquidacion del periodo ha sido cerrada.",
      });
    } catch (e: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: e.message,
      });
    }
  };

  return reports[section].data.services.length > 0 ? (
    <>
      <Card className="gap-5 flex-col w-auto h-auto">
        <h4 className="mb-4 text-lg">{name}</h4>
        <div className="flex-auto text-sm text-gray-600 lg:grid grid-cols-4 gap-x-6 gap-y-6">
          {headerReportsMapper({
            address,
            phone,
            email,
            lender: lender_code_bilog,
          }).map((item, index) => (
            <div
              key={index}
              className="flex items-center space-x-4 lg:space-x-0 justify-between md:block"
            >
              <p className="font-medium text-gray-900">{item.title}</p>
              <p className="lg:mt-1">{item.data}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-12 border-t border-t-slate-300 lg:pt-5 mt-5">
          <div
            className={classNames(
              "col-span-8 lg:grid divide-y divide-gray-200 overflow-hidden bg-white md:divide-y-0 md:divide-x",
              `grid-cols-${STATS_DATA.length}`
            )}
          >
            {STATS_DATA.map((item, index) => {
              return (
                <div
                  key={item.name}
                  className={classNames("py-4 lg:py-0", {
                    "lg:pl-4": index > 0,
                  })}
                >
                  <dt className="text-base font-normal text-gray-900">
                    {item.name}
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-xl font-semibold text-blue-600">
                      {item.stat}
                    </div>
                  </dd>
                </div>
              );
            })}
          </div>
          {IS_DESKTOP && reports[section].data?.total_pages > 0 && (
            <div className="flex space-x-5 mt-5 justify-end col-span-4">
              <GenerateFile onClick={HandleUpReport} />
              <Button
                className="w-full lg:w-36"
                onClick={handleCloseReport}
                disabled={reports[section].params.id_benefits_state === 2}
              >
                Cerrar liquidación
              </Button>
            </div>
          )}
        </div>
      </Card>
      {loading ? (
        <Loading heigth="300px" />
      ) : IS_DESKTOP ? (
        <ReportsTable data={services} />
      ) : (
        <ReportList data={services} />
      )}
      <Pagination
        currentPage={currentPage}
        totalCount={services.length || 0}
        totalPages={total_pages}
        onPageChange={(page: number) => onSubmitCurrentPage(page)}
      />
      {!IS_DESKTOP && reports[section].data?.total_pages > 0 && (
        <div className="flex gap-5 mt-5 mx-5 justify-center">
          <GenerateFile onClick={HandleUpReport} />
          <Button
            className="w-full lg:w-36"
            onClick={handleCloseReport}
            disabled={reports[section].params.id_benefits_state === 2}
          >
            Cerrar liquidación
          </Button>
        </div>
      )}
    </>
  ) : (
    <EmptyState
      title="No se generó ningún reporte"
      copy="Primero debes especificar los parámetros con los cuales se debe generar el reporte."
    />
  );
};
