import { format } from "date-fns";
import {
  ServicesToAudit,
  ServicesToAuditDB,
  Service,
  ServiceDB,
} from "@/types/reports";

export const headerReportsMapper = (data: {
  address: string;
  phone: string;
  email: string;
  lender: string;
}): {
  title: string;
  data: string;
}[] => {
  return [
    {
      title: "Dirección",
      data: data.address,
    },
    {
      title: "Teléfono",
      data: data.phone,
    },
    {
      title: "Email",
      data: data.email,
    },

    {
      title: "Prestador",
      data: data.lender,
    },
  ];
};

export const servicesToAudit = (data: ServicesToAuditDB): ServicesToAudit => {
  const { servicesToAudit } = data;

  return {
    professional: servicesToAudit.professional,
    clinic: servicesToAudit.clinic,
    address: servicesToAudit.address,
    phone: servicesToAudit.phone,
    mail: servicesToAudit.mail,
    med_ins_code: servicesToAudit.medicalInsuranceCode,
    medical_insurance: servicesToAudit.medicalInsurance,
    lender: servicesToAudit.lender,
    period: servicesToAudit.period,
    rebilling: servicesToAudit.rebilling,
    group_code: servicesToAudit.groupCode,
    group: servicesToAudit.group,
    total_amount: servicesToAudit.totalAmount,
    total_services: servicesToAudit.totalServices,
    total_pages: servicesToAudit.totalPages,
    services: servicesToAudit?.services
      ? services(servicesToAudit.services)
      : [],
  };
};

const services = (data: ServiceDB[]): Service[] =>
  data.map((service) => {
    return {
      id_service: service.serviceId,
      patient: service.patient,
      affiliate_number: service.affiliateNumber,
      document_type: service.documentType,
      patient_document: service.patientDocument,
      plan_code: service.planCode,
      date: format(service.date, "dd/MM/yyyy"),
      professional_code: service.professionalCode,
      liquidation_code: service.liquidationCode,
      is_liquidated: service.isLiquidated,
      tooth_sector: service.toothSector,
      faces: service.faces,
      condition: service.condition,
      amount: service.amount,
      description: service.description,
    };
  });
