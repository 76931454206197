import { Card, Skeleton } from "@/components/atoms";

export const LoadingForm = () => {
  return (
    <div className="w-full grid grid-cols-12 gap-10 mt-5">
      <Card className="col-span-12 2xl:col-span-7 p-5">
        <div className="px-10 max-w-screen-2xl mx-auto">
          <Skeleton className="h-10 w-[500px] rounded-xl mb-20 mt-20" />
          <div className="grid grid-cols-3 gap-x-10 mb-20">
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="flex flex-col space-y-3">
                <Skeleton className="h-32 w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-52" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <Card className="col-span-12 2xl:col-span-5 p-5">
        <div className="px-10 max-w-screen-2xl mx-auto">
          <Skeleton className="h-10 w-[500px] rounded-xl mb-20 mt-20" />
          <div className="grid grid-cols-3 gap-x-10 mb-20">
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="flex flex-col space-y-3">
                <Skeleton className="h-32 w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-52" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <Card className="col-span-12 2xl:col-span-6 p-10">
        <div className="px-10 max-w-screen-2xl mx-auto">
          <Skeleton className="h-10 w-[500px] rounded-xl mb-20 mt-20" />
          <div className="grid grid-cols-3 gap-x-10 mb-20">
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="flex flex-col space-y-3">
                <Skeleton className="h-32 w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-52" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <Card className="col-span-12 2xl:col-span-6 p-10">
        <div className="px-10 max-w-screen-2xl mx-auto">
          <Skeleton className="h-10 w-[500px] rounded-xl mb-20 mt-20" />
          <div className="grid grid-cols-3 gap-x-10 mb-20">
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="flex flex-col space-y-3">
                <Skeleton className="h-32 w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-52" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};
