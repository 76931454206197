import { translations } from "@/libs/translate";

export const getTranslations = (region: string) => {
  return translations[region] || translations["default"];
};

export const PAYMENT_METHODS: { [key: string]: string } = {
  TarjetaD: "Tarjeta de débito",
  Tarjeta: "Tarjeta de crédito",
  Efectivo: "Efectivo",
  Cheque: "Cheque",
  Otro: "Otro",
  "Transf.": "Transferencia",
};
