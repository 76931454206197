import React from "react";
import { Combobox, Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { INSTALLMENTS } from "@/libs/patients";
import { roundNumber } from "@/utils/helpers";

export const Quota: React.FC<{ form: any }> = ({ form }) => {
  const { id_installment, initial_payment, total_amount_with_discount } =
    form.watch();

  React.useEffect(() => {
    const INSTALLMENT =
      INSTALLMENTS.find(
        (installment) => installment.id_installment === id_installment
      )?.value || 0;
    const INSTALLMENT_VALUE = roundNumber(
      (total_amount_with_discount - initial_payment?.amount) / INSTALLMENT
    );
    form.setValue("installment_value", INSTALLMENT_VALUE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_installment, total_amount_with_discount, initial_payment?.amount]);

  return (
    <>
      <Combobox
        form={form}
        data={INSTALLMENTS}
        dataKey="id_installment"
        value="text"
        title="cuota"
        classContent="!w-[300px]"
        className="col-span-3"
        label="Cuotas"
        showInputSearch={false}
      />
      <FormField
        control={form.control}
        name="installment_value"
        render={({ field }) => (
          <FormItem className="col-span-3">
            <FormLabel>Valor de cada cuota</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                max={99}
                placeholder="0.0%"
                disabled
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
