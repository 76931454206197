import React from "react";

export const useSetHeight = (minus: number) => {
  const [height, setHeight] = React.useState<number>(0);

  React.useEffect(() => {
    const updateHeight = () => {
      if (window?.innerHeight < 750) {
        setHeight(514);
      } else {
        setHeight(window?.innerHeight - minus);
      }
    };

    updateHeight();

    window?.addEventListener("resize", updateHeight);

    return () => {
      window?.removeEventListener("resize", updateHeight);
    };
  }, [minus]);

  return height;
};
