"use client";

import React, { createContext, ReactNode, useContext } from "react";
import { useBoundStore } from "@/hooks/use-bound-store";

const GlobalStore = createContext<any | null>(null);

interface StoreProviderProps {
  children: ReactNode;
}

const GlobalStoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const storeValue = useBoundStore() as any;

  return (
    <GlobalStore.Provider value={storeValue}>{children}</GlobalStore.Provider>
  );
};

const useGlobalStore = () => {
  const context = useContext(GlobalStore);

  //This is to delete the old redux storage
  if (typeof window !== "undefined") {
    localStorage.removeItem("persist:root");
  }

  if (!context) {
    throw new Error("useGlobalStore must be used within a GlobalStoreProvider");
  }
  return context;
};

export { GlobalStoreProvider, useGlobalStore };
