import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { BenefitBudget } from "@/types/patients";

export const ServicePerformed = ({
  hiddenView,
  benefit,
  setActiveTab,
}: {
  hiddenView: (value: boolean) => void;
  benefit: BenefitBudget;
  setActiveTab: (value: string) => void;
}) => {
  const { setServicePerformed } = useGlobalStore();
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);
  const handlerClick = () => {
    hiddenView(false);
    setActiveTab("services");
    return setServicePerformed({
      ...benefit,
      selected_quantity: selectedQuantity,
      performance_indicator: true,
    });
  };
  const QUANTITY_DATA = Array.from(
    { length: benefit.quantity },
    (_, index) => ({
      id: index + 1,
    })
  );

  return (
    <DialogContent className="grid grid-cols-12 grid-rows-2 pt-3">
      <DialogHeader className="col-span-full mt-5 w-[400px]">
        ¿Cuantas prestaciones realizaste?
      </DialogHeader>
      <Select onValueChange={setSelectedQuantity} defaultValue={1}>
        <SelectTrigger className="col-span-full">
          <SelectValue placeholder="selecciona una cantidad" />
        </SelectTrigger>
        <SelectContent>
          {QUANTITY_DATA.map((q: any, index: number) => (
            <SelectItem key={index} value={q.id}>
              {q.id}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button className="mt-6 col-span-12 row-span-1" onClick={handlerClick}>
        siguiente
      </Button>
    </DialogContent>
  );
};
