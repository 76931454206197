import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { Badge } from "@/components/atoms";
import { appointmensOptions } from "@/utils/helpers";

export const Columns = [
  {
    accessorKey: "date",
    header: "Fecha",
    cell: ({ row }: { row: any }) => {
      const { date } = row.original;

      return (
        date && (
          <p className="lg:text-sm text-slate-600 my-1">
            {format(date, "dd/MM/yyyy")}
          </p>
        )
      );
    },
  },
  {
    accessorKey: "time",
    header: "Hora",
    cell: ({ row }: { row: any }) => {
      const { time } = row.original;

      return <p className="lg:text-sm text-slate-800 my-1">{time}</p>;
    },
  },
  {
    accessorKey: "name",
    header: "Turno dado por",
    cell: ({ row }: { row: any }) => {
      const {
        professional_navigation: { id_professional, name },
      } = row.original;

      return (
        <p
          id={id_professional}
          className="lg:text-sm font-bold text-slate-600 my-1"
        >
          {name}
        </p>
      );
    },
  },
  {
    accessorKey: "assistance",
    header: "Asistencia",
    cell: ({ row }: { row: any }) => {
      const { assistance } = row.original;
      const attendance = appointmensOptions(assistance);

      return (
        <Badge className={classNames("w-auto mt-2 mb-1", attendance?.styles)}>
          {attendance?.text}
        </Badge>
      );
    },
  },
  {
    accessorKey: "observation",
    header: "Observaciones",
    cell: ({ row }: { row: any }) => {
      const { observation } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{observation}</p>;
    },
  },
  {
    accessorKey: "date_give",
    header: "Turno dado el",
    cell: ({ row }: { row: any }) => {
      const { date_give } = row.original;

      return (
        date_give && (
          <p className="lg:text-sm text-slate-600 my-1">
            {format(date_give, "dd/MM/yyyy")}
          </p>
        )
      );
    },
  },
];
