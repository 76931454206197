export { LoginForm } from "./login/form";
export { MyAccountForm } from "./my-account/form";
export { ReportsForm } from "./reports/form";
export { StatisticsForm } from "./statistics/form";
export {
  PatientForm,
  DeletePatient,
  BenefitsPatientForm,
  Actions,
  GalleryActions,
  PaymentsForm,
  PrescriptionsForm,
  BudgetsForm,
} from "./patients";
export {
  BanksForm,
  NewBankForm,
  DeleteBankForm,
  NewMovementForm,
  DeleteMovementForm,
  DailybalanceForm,
  CashReconciliationForm,
  InstallmentsForm,
  NewCardForm,
  DeleteCardForm,
  SuppliersForm,
  NewIncomeForm,
  NewCashExpenseForm,
  NewPaymentExpenseForm,
  NewInvoiceExpenseForm,
  DeleteExpenseForm,
  DeleteIncomeForm,
} from "./administration";
export {
  FirstTimeForm,
  NewAppointmentPatientExist,
  DeleteAppointment,
  EditObservation,
  NewOverturn,
  DisabledAppointment,
  SearchForm,
} from "./schedule";
export {
  LaboratoriesForm,
  ListPriceFrom,
  ListCopyForm,
  DeleteLaboratory,
  DeleteWork,
} from "./laboratories";
export { WhatsappForm } from "./whatsapp-data";
export { SmsForm } from "./sms-data";
export { GeneralDataForm } from "./general-data";
export { SpecialtyForm } from "./specialty";
export { HolydayForm } from "./holyday";
export { OfficesForm } from "./offices";
export { UsersForm } from "./users";
export {
  TimesTableForm,
  AbscencesForm,
  DeleteAbscence,
  DeleteProfessional,
  DeleteTime,
  ProfesionalsForm,
} from "./professionals";
export {
  NewMedicalInsuranceDataForm,
  NewMedicalInsurancePlansForm,
  NewMedicalInsuranceBenefitForm,
  NewMedicalInsuranceGroupsForm,
  DeleteMedicalInsuranceForm,
  DeleteBenefitForm,
  DeleteGroupForm,
  DeletePlanForm,
  DeleteSupplierForm,
  NewSupplierForm,
} from "./configuration";
export { DeleteSpecialty } from "./specialty/delete";
export { DeleteHolyday } from "./holyday/delete";
export { DeleteOffice } from "./offices/delete";
export { Lenders } from "./lenders";
export { Various } from "./various-data";
export { MedInsCopy } from "./medical-insurances-copy";
