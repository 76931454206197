"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { LuPen, LuTrash2, LuCircleCheck } from "react-icons/lu";
import { HiDotsVertical, HiOutlineBan } from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { User } from "@/types/general";
import { DeleteUser } from "@/components/organisms/forms/users/delete";

type RowDataT = {
  original: any;
};

const Dropdown = ({ user }: { user?: User }) => {
  const { openDialog } = useDialog();
  const router = useRouter();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() =>
              router.push(
                `/dashboard/configuration/sistem/users/new?id=${user?.id_user}`
              )
            }
            className="hover:cursor-pointer"
          >
            <LuPen className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteUser user={user} />,
              });
            }}
          >
            <LuTrash2 className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      return <Dropdown user={row.original} />;
    },
  },
  {
    accessorKey: "name",
    header: "Nombre",
    cell: ({ row }: { row: RowDataT }) => {
      const { name } = row.original;
      return <p className="font-bold text-sm text-gray-500">{name}</p>;
    },
  },
  {
    accessorKey: "user",
    header: "Usuario",
    cell: ({ row }: { row: RowDataT }) => {
      const { user } = row.original;
      return <p className="font-bold text-sm text-gray-500">{user}</p>;
    },
  },
  {
    accessorKey: "is_supervisor",
    header: "Es supervisor",
    cell: ({ row }: { row: RowDataT }) => {
      const { is_supervisor } = row.original;
      return (
        <p>
          {is_supervisor ? (
            <LuCircleCheck className="size-5 text-green-500" />
          ) : (
            <HiOutlineBan className="size-5 text-red-500" />
          )}
        </p>
      );
    },
  },
  {
    accessorKey: "is_dentist",
    header: "Es odontologo",
    cell: ({ row }: { row: RowDataT }) => {
      const { is_dentist } = row.original;
      return (
        <p>
          {is_dentist ? (
            <LuCircleCheck className="size-5 text-green-500" />
          ) : (
            <HiOutlineBan className="size-5 text-red-500" />
          )}
        </p>
      );
    },
  },
  {
    accessorKey: "enabled",
    header: "Habilitado",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { enabled } = row.original;

      return (
        <p>
          {enabled ? (
            <LuCircleCheck className="size-5 text-green-500" />
          ) : (
            <HiOutlineBan className="size-5 text-red-500" />
          )}
        </p>
      );
    },
    enableGrouping: false,
  },
];
