import React from "react";
import classNames from "classnames";
import { useGlobalStore } from "@/providers/global-provider";
import { useStateAcordeon } from "@/hooks";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
  Combobox,
  Input,
} from "@/components/atoms";
import {
  Form,
  MedicalInsurance,
  Offices,
  Professional,
  Translations,
} from "@/types/general";
import { Patient } from "@/types/patients";
import { Medications } from "@/types/recipes";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { BenefitsTable } from "@/components/organisms/tables";
import { BenefitLoad } from "../benefit-load";
import { formSchema } from "../schema";

export const Second: React.FC<{
  data: {
    offices: Offices[];
    professionals: Professional[];
    patient: Patient;
    translations: Translations;
    medicalInsurances: MedicalInsurance[];
  };
  viewLoadBenefit: {
    hiddenLoadBenefit: boolean;
    setHiddenLoadBenefit: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar?: (value: number) => void;
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({
  data,
  form,
  setStep,
  viewLoadBenefit: { hiddenLoadBenefit, setHiddenLoadBenefit },
}) => {
  const { ref_header } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const { list_benefits, id_med_ins } = form.watch();
  const { selected_benefit } = form.getValues();
  const TABLE_VALIDATION = list_benefits && list_benefits.length >= 1;
  const plans = React.useMemo(() => {
    return (
      data.medicalInsurances?.find(
        (m: MedicalInsurance) => m.id_med_ins === id_med_ins
      )?.plans || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_med_ins]);

  const onLoadBenefit = () => {
    form.setValue("id_benefit", null);
    setHiddenLoadBenefit(!hiddenLoadBenefit);
    form.setValue("selected_benefit", {
      amount_patient: 0,
      benefit_code: null,
      description: "",
      id_benefit: null,
      id_budget: 0,
      id_budget_benefit: Math.random(),
      is_manual: false,
      needs_thoot: false,
      quantity: 1,
      thoot_face: "NIN",
      tooth_section: "",
      total_amount: 0,
      age: "0",
      amount_med_ins: 0,
      code_benefit_med_ins_liq: "",
      disabled: false,
      drawing: "",
      id_benefit_to_be_performed: null,
      id_group: null,
      id_plan: 0,
      id_specialty: null,
      id_specialty_navigation: null,
      internal: false,
      is_odontogram: false,
      is_usd: false,
      per_patient: 0,
      per_piece_sector: 0,
      requires_face: false,
      rowversion: "",
      unit: "",
    });
  };
  const actions: { [key: string]: (value: { [key: string]: any }) => void } = {
    edit: (selectedRow: { [key: string]: any }) => {
      const BENEFIT_SELECTED = list_benefits?.find(
        (benefit: { [key: string]: any }) =>
          benefit.id_budget_benefit === selectedRow.id_budget_benefit
      );
      form.setValue(
        "is_manual_benefit",
        selectedRow.benefit_code ? false : true
      );

      form.setValue("selected_benefit", {
        ...selected_benefit,
        ...BENEFIT_SELECTED,
      });
      setHiddenLoadBenefit(!hiddenLoadBenefit);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const BENEFIT_SELECTED = list_benefits?.filter(
        (benefit: { [key: string]: any }) =>
          benefit.id_budget_benefit !== selectedRow.id_budget_benefit
      );
      form.setValue("list_benefits", BENEFIT_SELECTED);
    },
  };
  const action = (Benefit: Medications, action: string) => {
    actions[action](Benefit);
  };

  React.useEffect(() => {
    const total_amount = list_benefits?.reduce(
      (sum: number, benefit: any) => sum + (benefit.total_amount || 0),
      0
    );
    const total_quantity = list_benefits?.reduce(
      (sum: number, benefit: any) => sum + (Number(benefit.quantity) || 0),
      0
    );
    form.setValue("total_amount_benefit_list", total_amount);
    form.setValue("total_quantity_benefit_list", total_quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list_benefits]);

  return (
    <Card
      className={classNames(
        "!rounded-t-none overflow-y-auto !w-full h-[calc(100vh-258px)]",
        {
          "h-[calc(100vh-315px)]":
            STATE_ACCORDION && STATE_ACCORDION !== "closed",
        }
      )}
    >
      {!hiddenLoadBenefit && (
        <>
          <CardHeader>
            <CardTitle>Datos de obra social</CardTitle>
            <CardDescription>
              Selecciona los datos de la obra social e incluye las prestaciones
              que formarán parte de tu presupuesto.
            </CardDescription>
          </CardHeader>
          <CardContent className="grid grid-cols-3 gap-5 border-b pb-10">
            <Combobox
              form={form}
              data={data.medicalInsurances}
              dataKey="id_med_ins"
              value="medical_insurance"
              placeholder={`Busca un/a ${data.translations.medical_insurance}`}
              title={data.translations.medical_insurance}
              classContent="!w-[300px]"
              label={data.translations.medical_insurance}
              disabled={list_benefits?.length > 0}
            />
            <Combobox
              form={form}
              data={plans}
              dataKey="id_plan"
              value="name"
              placeholder="Busca un plan"
              title="plan"
              classContent="!w-[300px]"
              label="Plan"
              disabled={list_benefits?.length > 0}
            />
            <FormField
              control={form.control}
              name="affiliate_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nro de afiliado</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </>
      )}
      <CardHeader>
        <CardTitle>Selección de prestación</CardTitle>
        <CardDescription>
          Cargá todos los datos referidos a la prestación.
        </CardDescription>
      </CardHeader>
      {!hiddenLoadBenefit ? (
        <div className="justify-between flex flex-col">
          <CardContent>
            <>
              {TABLE_VALIDATION && (
                <BenefitsTable
                  data={list_benefits}
                  action={action}
                  form={form}
                />
              )}
              <div className="flex gap-5 mt-5 w-full justify-end">
                <Button variant="ghost" onClick={onLoadBenefit}>
                  Cargar prestación
                </Button>
                <Button
                  type="button"
                  disabled={!TABLE_VALIDATION}
                  onClick={() => setStep(2)}
                >
                  Siguiente
                </Button>
              </div>
            </>
          </CardContent>
          <CardDescription className="flex justify-center">
            {!TABLE_VALIDATION && "Todavía no posee ninguna pretación cargada"}
          </CardDescription>
        </div>
      ) : (
        <BenefitLoad setHiddenLoadBenefit={setHiddenLoadBenefit} form={form} />
      )}
    </Card>
  );
};
