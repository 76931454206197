import React from "react";
import classNames from "classnames";
import { LuChevronDown } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { SelectedBadgeT } from "@/types/components/badge-selected";

export const BadgeSelect: React.FC<SelectedBadgeT> = ({
  text,
  options,
  onSelect,
  className,
  disabled,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <div
          className={classNames(
            "inline-flex items-center w-44 h-7 rounded-md bg-blue-50 px-2 py-1 text-sm font-bold text-blue-600 ring-1 ring-inset ring-blue-600/10 hover:cursor-pointer",
            className
          )}
          id="attendance"
        >
          <p>{text}</p>
          {!disabled && <LuChevronDown className="ml-2 size-4" />}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options?.map((option) => (
          <DropdownMenuItem
            key={option.id}
            id={option.id}
            className="hover:cursor-pointer"
            onSelect={onSelect}
          >
            {option.text}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
