"use client";

import React from "react";
import classNames from "classnames";
import { HiOutlineCheckCircle, HiOutlineBan } from "react-icons/hi";
import { ColumnDef } from "@tanstack/react-table";
import { Badge, Checkbox } from "@/components/atoms";

type RowDataT = {
  original: any;
};

export const Columns = ({
  showAction,
}: {
  showAction?: boolean;
}): ColumnDef<any>[] => {
  const cols = [
    {
      id: "actions",
      header: ({ table }: { table: any }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => {
            table.toggleAllPageRowsSelected(!!value);
          }}
          aria-label="Seleccionar todo"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }: { row: any }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Seleccionar fila"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "description",
      header: "Prestación",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { description, benefit_code, code_benefit_med_ins_liq } = row.original;

        return (
          <>
            <div className="flex gap-x-2">
              <Badge className="w-auto 2xl:ml-3">{`Código OS ${benefit_code}`}</Badge>
              <Badge
                variant="secondary"
                className="w-auto 2xl:ml-3"
              >{`Cód. OS Liq. ${code_benefit_med_ins_liq}`}</Badge>
            </div>
            <p className="font-semibold text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
              {description}
            </p>
          </>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En USD",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_usd } = row.original;

        return (
          <p
            className={classNames(
              "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
              {
                "text-slate-500": !is_usd,
                "text-green-500": is_usd,
              }
            )}
          >
            {is_usd ? (
              <HiOutlineCheckCircle className="size-5" />
            ) : (
              <HiOutlineBan className="size-5" />
            )}
          </p>
        );
      },
    },
    {
      accessorKey: "amount_mi",
      header: "Importe OS",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { amount_mi } = row.original;

        return (
          <p className="font-semibold text-sm text-slate-500/80">
            $ {amount_mi}
          </p>
        );
      },
    },
    {
      accessorKey: "amount_patient",
      header: "Importe paciente",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { amount_patient } = row.original;

        return (
          <p className="font-semibold text-sm text-slate-500/80">
            $ {amount_patient}
          </p>
        );
      },
    },
  ];

  if (!showAction) return cols.slice(1);

  return cols;
};
