import React from "react";
import classNames from "classnames";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
  Input,
  Combobox,
} from "@/components/atoms";
import { Form, Laboratory, Offices, Professional } from "@/types/general";
import { Budget } from "@/types/patients";
import {
  CheckboxForm,
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useStateAcordeon } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema } from "../schema";

export const First: React.FC<{
  data: {
    offices: Offices[];
    professionals: Professional[];
    laboratories: Laboratory[];
  };
  onCancel: () => void;
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({ data, form, setStep, onCancel }) => {
  const {
    general_params: { user_params },
    user,
    patient,
    ref_header,
    order_selected,
  } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const {
    id_office,
    description,
    link_budget,
    id_budget,
    link_office,
    is_usd,
  } = form.watch();
  const budgets = React.useMemo(() => {
    return user_params.use_usd && !user.use_usd
      ? patient?.budgets?.filter((budget: Budget) => !budget.is_usd)
      : patient?.budgets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const BUDGET_SELECTED = patient?.budgets?.find(
    (budget: Budget) => budget.id_budget === id_budget
  );
  const STATE_BUDGET =
    BUDGET_SELECTED &&
    BUDGET_SELECTED?.balance < BUDGET_SELECTED?.total_amount &&
    BUDGET_SELECTED?.balance !== 0
      ? "Con Mov."
      : BUDGET_SELECTED?.total_amount !== 0 && BUDGET_SELECTED?.balance === 0
      ? "Saldado"
      : "Sin Mov.";

  return (
    <>
      <Card
        className={classNames(
          "!rounded-t-none overflow-y-auto",
          STATE_ACCORDION !== "open"
            ? "!h-[calc(100vh-305px)]"
            : "h-[calc(100vh-365px)]"
        )}
      >
        <CardHeader className="!p-0 !pb-4">
          <CardTitle>Datos del pedido</CardTitle>
          <CardDescription>
            Carga los datos referidos a la identificación del pedido del
            laboratorio.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid grid-cols-12 gap-5 !p-0">
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Descripción</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="col-span-3 self-end">
            <DatePicker
              form={form}
              name="date_order"
              label="Fecha de pedido"
              dateformat="dd/MM/yyyy"
            />
          </div>
          <div className="col-span-3 self-end">
            <DatePicker
              form={form}
              name="date_estimated_received"
              label="Fecha estimada de entrega"
              dateformat="dd/MM/yyyy"
            />
          </div>
          <Combobox
            form={form}
            data={data.laboratories}
            dataKey="id_laboratory"
            value="name"
            placeholder="Busca un laboratorio"
            title="laboratorio"
            className="col-span-3"
            classContent="!w-[300px]"
            label="Laboratorio"
          />
          <CheckboxForm
            classname="col-span-3"
            label="Presupuestos"
            subLabel="Se vinculará la información a un presupuesto."
            onCheckedChange={(check: boolean) => {
              form.setValue("link_budget", check);
              if (!check) {
                form.setValue("id_budget", -1);
              }
            }}
            checked={link_budget}
          />
          <Combobox
            form={form}
            data={budgets}
            dataKey="id_budget"
            value="description"
            placeholder="Busca un presupuesto"
            title="presupuesto"
            className="col-span-3"
            classContent="!w-[300px]"
            label="Presupuesto"
            disabled={!link_budget}
          />
          <div className="col-span-3 mt-3">
            <span className="mb-1.5 block text-sm font-medium ">
              Estado del presupuesto
            </span>
            <span className="font-bold">{STATE_BUDGET}</span>
          </div>
          <CheckboxForm
            classname="col-span-3"
            label="Es USD"
            disabled={order_selected?.id_order}
            subLabel="Los importes se cargan en USD."
            onCheckedChange={(check: boolean) => {
              form.setValue("is_usd", check);
            }}
            checked={is_usd}
          />
          {id_office && (
            <>
              <Combobox
                form={form}
                data={data.offices}
                dataKey="id_office"
                value="name"
                placeholder="Busca una sucursal"
                title="sucursal"
                classContent="!w-[300px]"
                label="Sucursal"
                className="col-span-3"
                disabled={link_office}
              />
              <CheckboxForm
                classname="col-span-3"
                label="No incluye sucursal"
                subLabel="El registro no posee una sucursal."
                onCheckedChange={(check: boolean) => {
                  form.setValue("link_office", check);
                  if (check) {
                    form.setValue("id_office", -1);
                  }
                }}
                checked={link_office}
              />
            </>
          )}
          <Combobox
            form={form}
            data={data.professionals}
            dataKey="id_professional"
            value="name"
            placeholder="Busca un profesional"
            title="profesional"
            classContent="!w-[300px]"
            className="col-span-3"
            label="Profesional"
          />
          <FormField
            control={form.control}
            name="observation"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel>Observación</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </CardContent>
      </Card>
      <div className="space-x-4 flex justify-end mt-4">
        <Button type="button" variant="destructive" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          type="button"
          disabled={!description}
          onClick={() => setStep(1)}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
};
