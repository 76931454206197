"use client";

import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { Skeleton } from "@/components/atoms";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: any[];
}> = ({ data }) => {
  const { loading } = useGlobalStore();

  return loading ? (
    <div className="grid grid-cols-4 gap-4 mx-auto my-4">
      <Skeleton className="col-span-5 h-[calc(100vh-153px)] rounded-xl" />
    </div>
  ) : (
    <Data columns={columns} data={data} />
  );
};
