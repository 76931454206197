"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  Card,
  CardDescription,
  CardTitle,
  Separator,
} from "@/components/atoms";
import { ButtonLoading, Form } from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { Templates } from "@/types/whatsapp-templates";
import { Offices } from "@/types/general";
import { userParamsToDB } from "@/utils/tranformers/params";
import { updateWhatsapp } from "@/actions/configs";
import { toast } from "@/hooks";
import { Carousel } from "./carousel";
import { formSchema, resolver } from "./schema";

export const WhatsappForm = ({
  templates,
  offices,
}: {
  templates: Templates;
  offices: Offices[];
}) => {
  const { general_params, startLoading, updateParams, loading, stopLoading } =
    useGlobalStore();
  const { user_params } = general_params;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      what_template_appointment: user_params?.what_template_appointment || "",
      what_template_birthday: user_params?.what_template_birthday || "",
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const params = userParamsToDB(user_params);
    const response = await updateWhatsapp({
      ...params,
      ...values,
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Cambios realizados correctamente",
        description: "Los datos han sido actualizados.",
      });
      updateParams("general_params", {
        ...general_params,
        user_params: { ...response },
      });
    }

    stopLoading();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-end"
      >
        <Card className="w-full bg-white h-[calc(100vh-150px)] overflow-y-scroll">
          <CardTitle>Plantillas de turnos</CardTitle>
          <CardDescription className="mb-4 !text-base">
            Estas son todas las plantillas para el envío de recordatorio de
            turnos.
          </CardDescription>
          <Carousel
            data={templates.appointments}
            name="what_template_appointment"
            form={form}
          />
          <Separator />
          {offices?.length > 0 && templates.appointments_office && (
            <>
              <div className="mt-4">
                <CardTitle>Plantillas con sucursales</CardTitle>
                <CardDescription className="mb-4 !text-base">
                  Estas son todas las plantillas para el envío de recordatorio
                  de turnos con sucursales.
                </CardDescription>
                <Carousel
                  data={templates.appointments_office}
                  name="what_template_appointment"
                  form={form}
                />
              </div>
              <Separator />
            </>
          )}
          <div className="grid gap-2 mt-4">
            <CardTitle>Plantillas de cumpleaños</CardTitle>
            <CardDescription className="mb-4 !text-base">
              Estas son todas las plantillas para el envío de felicitaciones de
              cumpleaños.
            </CardDescription>
            {templates.birthday && (
              <Carousel
                data={templates.birthday}
                name="what_template_birthday"
                form={form}
              />
            )}
          </div>
        </Card>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            className="mt-4"
            type="submit"
            disabled={form.formState.isSubmitting}
          >
            Guardar
          </Button>
        )}
      </form>
    </Form>
  );
};
