import React from "react";
import { Filter, Visibility } from "./filter";

export const TableToolbar: React.FC<{ table: any }> = ({ table }) => {
  return (
    <div className="flex items-center justify-between mb-2">
      <Filter table={table} name="cli_hist" />
      <div className="space-x-2">
        <Visibility table={table} />
      </div>
    </div>
  );
};
