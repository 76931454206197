"use client";

import { EmptyState } from "@/components/molecules";
import { Supplier } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { Default as GeistTable } from "@/components/molecules/geist";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{ data: Supplier[] }> = ({ data }) => {
  const { loading } = useGlobalStore();

  if (loading) return <GeistTable />;

  return !data || !data.length ? (
    <div className="grid grid-cols-12 gap-x-10">
      <EmptyState
        title="¡Ups!"
        copy="Parece que no proveedores definidos."
        className="col-span-6 !justify-start flex col-start-4"
      />
    </div>
  ) : (
    <Data data={data} columns={columns} />
  );
};
