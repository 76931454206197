import * as React from "react";
import { Combobox } from "@/components/atoms";
import { Benefit, Form, Professional } from "@/types/general";
import { benefitsMapper } from "@/utils/mappers";

export function BenefitsSelect({
  benefits,
  form,
  classname,
  professionals,
  useUsd,
}: {
  benefits: Benefit[];
  classname?: string;
  form: Form<any>;
  professionals?: Professional[];
  useUsd: { params: boolean; user: boolean };
}) {
  const { id_professional, id_benefit } = form.watch();
  const FILTERED_BENEFITS = React.useMemo(() => {
    const { params, user } = useUsd || {};
    const SELECTED_PROFESSIONAL = professionals?.find(
      (p: any) => p.id_professional === id_professional
    );

    if (!SELECTED_PROFESSIONAL) return [];

    let specialties: number[] = [];
    if (SELECTED_PROFESSIONAL.only) {
      SELECTED_PROFESSIONAL.id_speciality_1 &&
        specialties.push(SELECTED_PROFESSIONAL.id_speciality_1);
      SELECTED_PROFESSIONAL.id_speciality_2 &&
        specialties.push(SELECTED_PROFESSIONAL.id_speciality_2);
    }

    const FILTER_BY_SPECIALTIES = benefits?.filter(
      (b: Benefit) =>
        (!SELECTED_PROFESSIONAL.only ||
          specialties.includes(b.id_specialty || 0)) &&
        !b.disabled
    );
    const RESULT =
      params && !user
        ? FILTER_BY_SPECIALTIES.filter((b: Benefit) => !b.is_usd)
        : FILTER_BY_SPECIALTIES;
    return benefitsMapper(RESULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_professional]);

  const onChangeHandler = (id: string) => {
    const BENEFIT = FILTERED_BENEFITS?.find(
      (b: Benefit) => b.id_benefit === Number(id)
    );
    form.setValue("is_usd", !!BENEFIT?.is_usd);
    form.setValue("amount_med_ins", BENEFIT?.amount_mi || 0);
    form.setValue("amount_patient", BENEFIT?.amount_patient || 0);
  };

  return (
    <Combobox
      form={form}
      data={FILTERED_BENEFITS}
      dataKey="id_benefit"
      value="description"
      placeholder="Busca una prestación"
      title="Prestación"
      className={classname}
      classContent="!w-[500px]"
      label="Prestación"
      onChange={onChangeHandler}
    />
  );
}
