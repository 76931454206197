import * as React from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { HiDotsVertical } from "react-icons/hi";
import { LuCircleCheck, LuPen, LuTrash2 } from "react-icons/lu";
import { FcCancel } from "react-icons/fc";
import {
  Badge,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { STATUS_OPTIONS } from "@/libs/benefits";
import { toast } from "@/hooks";
import { useRestrictedPatientAction } from "@/hooks/use-patient-restricted-action";
import { PaymentsPatient } from "@/types/patients";

const Dropdown = ({
  payment,
  action,
  config: { permissions, is_supervisor },
}: {
  payment: PaymentsPatient;
  action: (payment: PaymentsPatient, type: string) => void;
  config: { permissions: Record<string, boolean>; is_supervisor: boolean };
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(payment, type),
          id: payment.id_professional,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          {(is_supervisor || permissions.hasOwnProperty("Modificar Pago")) && (
            <Action type="edit">
              <LuPen className="mr-2 size-4" />
              <span>Modificar pago</span>
            </Action>
          )}
          {(is_supervisor || permissions.hasOwnProperty("Anular Pago")) && (
            <Action type="cancel">
              {payment.canceled ? (
                <LuCircleCheck className="mr-2 size-4" />
              ) : (
                <FcCancel className="mr-2 size-4" />
              )}
              <span>{`${payment.canceled ? "Habilitar" : "Anular"}`} pago</span>
            </Action>
          )}
          {(is_supervisor || permissions.hasOwnProperty("Eliminar Pago")) && (
            <Action type="delete">
              <LuTrash2 className="mr-2 size-4" />
              <span>Eliminar</span>
            </Action>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export const Columns = ({ action, config }: any) => {
  const { useUsd } = config;

  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const handleAction = (payment: PaymentsPatient, type: string) => {
          if (["Cheque"].includes(row.original.method)) {
            return toast({
              variant: "warning",
              title: "Acción no permitida.",
              description:
                "El pago no se puede modificar ya que tiene como forma de pago CHEQUE y esta se encuentra en desuso.",
            });
          }

          action(payment, type);
        };

        return (
          <Dropdown
            payment={row.original}
            action={handleAction}
            config={config}
          />
        );
      },
    },
    {
      accessorKey: "date_es",
      header: "Fecha",
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="lg:text-sm text-slate-600 my-1">
            {format(date, "dd/MM/yyyy")}
          </p>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Estado",
      cell: ({ row }: { row: any }) => {
        const { id_all, canceled } = row.original;
        const STATUS = id_all
          ? STATUS_OPTIONS["has_benefit"]
          : canceled
          ? STATUS_OPTIONS["canceled"]
          : STATUS_OPTIONS["payment"];

        return (
          <Badge
            className={classNames(
              "w-auto mt-2 mb-1 whitespace-nowrap",
              STATUS.style
            )}
          >
            {STATUS?.text}
          </Badge>
        );
      },
    },
    {
      accessorKey: "professional",
      header: "Profesional",
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="lg:text-sm text-slate-800 font-bold text-nowrap my-1">
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: "concept",
      header: "Concepto",
      cell: ({ row }: { row: any }) => {
        const { concept } = row.original;

        return (
          <Tooltip delayDuration={500}>
            <TooltipTrigger asChild>
              <p className="lg:text-sm text-slate-600 my-1 max-w-40 truncate text-ellipsis">
                {concept}
              </p>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              <p>{concept}</p>
            </TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "receipt",
      header: "Comprobante",
      cell: ({ row }: { row: any }) => {
        const { receipt } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{receipt}</p>;
      },
    },
    {
      accessorKey: "type",
      header: "Tipo",
      cell: ({ row }: { row: any }) => {
        const { type } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{type}</p>;
      },
    },
    {
      accessorKey: "number",
      header: "Número",
      cell: ({ row }: { row: any }) => {
        const { number } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{number}</p>;
      },
    },
    {
      accessorKey: "method",
      header: "Forma",
      cell: ({ row }: { row: any }) => {
        const { method } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{method}</p>;
      },
    },
    {
      accessorKey: "debit",
      header: "Debe",
      cell: ({ row }: { row: any }) => {
        const { debit } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-green-500": debit < 0,
              "text-red-500": debit > 0,
              "text-slate-600": debit === 0,
            })}
          >
            {numberToCurrency(debit)}
          </p>
        );
      },
    },
    {
      accessorKey: "credit",
      header: "Haber",
      cell: ({ row }: { row: any }) => {
        const { credit } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-green-500": credit > 0,
              "text-red-500": credit < 0,
              "text-slate-600": credit === 0,
            })}
          >
            {numberToCurrency(credit)}
          </p>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "Saldo",
      cell: ({ row }: { row: any }) => {
        const { balance } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-green-500": balance < 0,
              "text-red-500": balance > 0,
              "text-slate-600": balance === 0,
            })}
          >
            {numberToCurrency(balance)}
          </p>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En USD",
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return is_usd ? (
          <LuCircleCheck className="size-5 mx-auto text-green-500" />
        ) : (
          <FcCancel className="size-6 mx-auto" />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "user",
      header: "Modificado por",
      cell: ({ row }: { row: any }) => {
        const { user } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{user}</p>;
      },
    },
  ];

  !useUsd.params && columns.splice(12, 1);

  return columns;
};
