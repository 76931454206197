"use client";

import React from "react";
import { format } from "date-fns";
import classNames from "classnames";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Bars,
} from "@/components/atoms";
import { DatePickerWithRange } from "@/components/molecules";
import { getWeekliesData } from "@/actions/statistics";
import { getDailyStatisticsDefaultPeriod } from "@/utils/date";
import { WeekliesData } from "@/types/statistics";

export function WeekliesStatistics({
  weeklies,
  totals,
}: {
  weeklies: WeekliesData[];
  totals: number[];
}) {
  const [state, setState] = React.useState<{
    data: WeekliesData[];
    totals: number[];
  }>({ data: weeklies, totals });
  const { firstday, lastday } = getDailyStatisticsDefaultPeriod();

  const changeDate = async (dates: any) => {
    if (dates && dates.from && dates.to) {
      const { from, to } = dates;
      const { weeklies, totals } = await getWeekliesData({
        from: format(from, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      });
      return setState({ data: weeklies, totals });
    }
  };

  return (
    <div className="my-10 flex flex-col gap-y-4">
      <div className="flex flex-col items-center lg:flex-row lg:justify-between lg:items-end">
        <h3 className="font-semibold text-slate-900 text-2xl mb-2 lg:mb-0">
          Estadísticas semanales
        </h3>
        <DatePickerWithRange
          className="w-full lg:w-80"
          onSelect={changeDate}
          from={new Date(firstday)}
          to={new Date(lastday)}
          disabledPastDates
        />
      </div>
      <div
        className={classNames(
          "flex flex-col gap-y-4 lg:grid lg:gap-x-4 lg:gap-y-0",
          state?.data.length > 0
            ? `grid-cols-${state?.data.length}`
            : "grid-cols-3"
        )}
      >
        {state?.data.length > 0 &&
          state.data.map((s: WeekliesData, index: number) => (
            <Card key={index} className="flex flex-col p-4">
              <CardHeader>
                <CardTitle className="text-slate-900">{s.title}</CardTitle>
                <CardDescription>
                  Estos son tus {s.title.toLowerCase()} de la semana pasada.
                </CardDescription>
                <p className="text-blue-500 text-2xl">
                  {state?.totals[index]} totales
                </p>
              </CardHeader>
              <CardContent>
                <Bars
                  data={s.data}
                  color={s.color}
                  height={200}
                  Xkey="period"
                  BarKey="quantity"
                />
              </CardContent>
            </Card>
          ))}
      </div>
    </div>
  );
}
