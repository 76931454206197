import React from "react";
import classNames from "classnames";
import { LuPen } from "react-icons/lu";
import { MedicinesPatientTable } from "@/components/organisms/tables";
import { Button, CardTitle } from "@/components/atoms";
import { Prescription } from "@/types/patients";

export const SummarySteps = ({
  data,
  setStep,
  form,
}: {
  data: {
    [key: string]: {
      viewLoadMedicine: {
        hiddenLoadMedicine: boolean;
        setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
      };
      list_medications: any[];
      setListMedicineValue: (value: any) => void;
      setMedicine: (value: any) => void;
      general_diagnosis: { description: string; code: string };
      user_name: string;
      prescription_selected: Prescription;
    };
  };
  form: any;
  setStep: (value: number) => void;
}) => {
  const {
    viewLoadMedicine: { hiddenLoadMedicine, setHiddenLoadMedicine },
    list_medications,
    general_diagnosis,
    user_name,
    prescription_selected,
  } = data["summarySteps"];
  const actions: {
    [key: string]: (selectedRow: { [key: string]: any }) => void;
  } = {
    edit: (selectedRow: { [key: string]: any }) => {
      const MEDICINE_SELECTED = list_medications?.find(
        (medicine: any) =>
          medicine.id_medicine_recipe === selectedRow.id_medicine_recipe
      );
      setStep(1);
      form.setValue("selected_medication", MEDICINE_SELECTED);
      setHiddenLoadMedicine(true);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const FILTERED_MEDICINES = list_medications?.filter(
        (medicine: any) =>
          medicine.id_medicine_recipe !== selectedRow.id_medicine_recipe
      );

      form.setValue("list_medications", FILTERED_MEDICINES);
    },
  };
  const action = (medicine: { [key: string]: string }, action: string) => {
    actions[action](medicine);
  };
  const CARD_DATA = [
    {
      title: "Lista de medicamentos",
      isTable: true,
      className: "text-2xl truncate mb-5 print:mt-5",
      Content: () => (
        <MedicinesPatientTable
          data={list_medications}
          action={action}
          prescription_selected={prescription_selected}
        />
      ),
      tabIndex: 1,
      stylesContainer:
        "flex flex-col justify-between mb-5 print:shadow-md print:rounded-md",
      stylesHeader: "flex justify-between print:ml-4",
    },
    {
      title: "Datos adicionales",
      className:
        "border-b border-b-slate-300 p-5 text-2xl rounded-t-md bg-white truncate",
      content: [
        {
          label: "Diagnostico general:",
          value: general_diagnosis?.description || "-",
        },
        {
          label: "Emitido por:",
          value: user_name,
        },
      ],
      tabIndex: 2,
      stylesContainer: "bg-white flex flex-col justify-between rounded-md mb-5",
    },
  ];

  return (
    <div className="mt-6 mb-5">
      {CARD_DATA.map((Item: any, index: number) => (
        <div key={index} className={Item.stylesContainer}>
          <div className={Item.stylesHeader || ""}>
            <CardTitle className={Item.className}>{Item.title}</CardTitle>
            {Item.isTable && (
              <Button
                variant="ghost"
                onClick={() => {
                  setStep(Item.tabIndex);
                  setHiddenLoadMedicine(false);
                }}
                disabled={
                  !!prescription_selected || list_medications?.length === 3
                }
              >
                Cargar medicamento
              </Button>
            )}
          </div>
          <div className="flex">
            {!Item.isTable ? (
              Item.content.map((content: any, i: number) => (
                <div key={i} className="flex px-5 my-2">
                  <span>{content.label}</span>
                  <span className="ml-2 !text-slate-900">{content.value}</span>
                </div>
              ))
            ) : (
              <Item.Content />
            )}
          </div>
          {!Item.isTable && (
            <p
              className={classNames(
                "group flex text-base print:hidden text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer",
                {
                  "text-gray-400 cursor-not-allowed pointer-events-none":
                    prescription_selected,
                }
              )}
              onClick={() => setStep(Item.tabIndex)}
            >
              Modificar
              <LuPen
                className={classNames(
                  "h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3",
                  {
                    "text-gray-400 cursor-not-allowed": prescription_selected,
                  }
                )}
              />
            </p>
          )}
        </div>
      ))}
    </div>
  );
};
