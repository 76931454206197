"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import classNames from "classnames";
import { LuChevronLeft } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  buttonVariants,
  CardTitle,
  Tabs,
  TabsContent,
} from "@/components/atoms";
import { Form, ProgressBar } from "@/components/molecules";
import { toast, useStepBar } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { MedicalInsurance, Offices, Professional } from "@/types/general";
import { enabledProfessionals } from "@/utils/mappers";
import { INITIAL_STEPS } from "@/libs/budgets";
import { INSTALLMENTS, PAYMENT_METHOD, TAXES } from "@/libs/patients";
import { addOrUpdateBudget } from "@/actions/budgets";
import {
  filterEnabledOffices,
  getPaymentMethod,
  roundNumber,
} from "@/utils/helpers";
import { BenefitBudget } from "@/types/patients";
import { formSchema, resolver } from "./schema";
import { First, Fourth, Second, Third } from "./steps";

export const BudgetsForm = ({
  onCancel,
  offices,
  professionals,
  medicalInsurances,
  refresh,
  setActiveTab,
}: {
  onCancel: () => void;
  professionals: Professional[];
  offices: Offices[];
  medicalInsurances: MedicalInsurance[];
  refresh: () => void;
  setActiveTab: (value: string) => void;
}) => {
  const {
    patient,
    general_params: { translations },
    permissions,
    user,
    startLoading,
    stopLoading,
    budget_selected,
  } = useGlobalStore();
  const [hiddenLoadBenefit, setHiddenLoadBenefit] =
    React.useState<boolean>(false);
  const { steps, currentPosition, setStep, resetSteps } = useStepBar({
    initialSteps: INITIAL_STEPS,
    completeSteps: !!budget_selected,
  });
  const ENABLED_OFFICES = filterEnabledOffices(offices);
  const filterProfessionals = (id_office?: number | null) => {
    const ENABLED_PROFESSIONALS = enabledProfessionals(professionals);
    return ENABLED_PROFESSIONALS.filter((p: Professional) => {
      if (id_office !== -1) {
        const professionals_office = p.professional_timetable.some(
          (prof: { [key: string]: any }) =>
            prof.office_navigation?.id_office === id_office
        );

        return p.enabled && professionals_office;
      }

      return p.enabled;
    });
  };
  const INSTALLMENT =
    budget_selected &&
    INSTALLMENTS.find((i: any) =>
      [budget_selected.installments].includes(i.text)
    );

  const TAX = budget_selected
    ? TAXES.find((i: any) => [budget_selected.vat_percentage].includes(i.value))
        ?.id_tax
    : 1;
  const INITIAL_ID_OFFICE = ENABLED_OFFICES?.[0]?.id_office;
  const INITIAL_PROFESSIONALS = filterProfessionals(INITIAL_ID_OFFICE);
  const INITAL_PAYMENT = roundNumber(
    (budget_selected?.total_amount || 1) *
      ((budget_selected?.initial_payment || 0) / 100) || 0
  );
  const MED_INS = medicalInsurances.find((m: MedicalInsurance) =>
    m?.plans?.find((plan) => plan.id_plan === patient?.id_plan)
  );
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      date:
        (budget_selected &&
          new TZDate(
            new Date(budget_selected.date).toISOString(),
            TIMEZONES.AR
          )) ||
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
      affiliate_number: budget_selected?.affiliation_number || "",
      is_usd: budget_selected?.is_usd || false,
      id_installment: INSTALLMENT?.id_installment || 1,
      estimated_time: budget_selected?.duration || "",
      observations: budget_selected?.observation || "",
      description: budget_selected?.description || "",
      sessions: budget_selected?.sessions || "0",
      id_payment_method: getPaymentMethod(budget_selected?.payment_method),
      is_manual_benefit: false,
      installment_value: roundNumber(
        (budget_selected?.total_amount - INITAL_PAYMENT) / INSTALLMENT?.value
      ),
      id_office: budget_selected?.id_office || INITIAL_ID_OFFICE,
      id_budget: budget_selected?.id_budget || 0,
      id_tax: TAX,
      balance: budget_selected?.balance || 0,
      id_professional:
        budget_selected?.id_professional ||
        INITIAL_PROFESSIONALS?.[0]?.id_professional,
      id_med_ins:
        budget_selected?.id_plan_navigation?.id_med_ins || MED_INS?.id_med_ins,
      id_plan: budget_selected?.id_plan_navigation?.id_plan || patient?.id_plan,
      id_benefit: null,
      list_benefits:
        budget_selected?.budget_items?.map((benefit: BenefitBudget) => {
          return {
            amount_patient: benefit.imp_benefit,
            amount_med_ins: benefit.benefit_navigation.amount_medical_insurance,
            benefit_code: benefit.benefit_navigation.code_benefit,
            description: benefit.benefit_navigation.description,
            id_benefit: benefit.benefit_navigation.id_benefit,
            id_budget: benefit.id_budget,
            id_budget_benefit: benefit.id_benefit_budget || Math.random(),
            is_manual: benefit.benefit_navigation.id_benefit ? false : true,
            needs_thoot: benefit.benefit_navigation.requires_face,
            quantity: benefit.quantity || 1,
            thoot_face: benefit.benefit_navigation.tooth,
            tooth_section: benefit.section,
            total_amount: (benefit.imp_benefit || 0) * benefit.quantity,
            services_performed: benefit.services_performed || 0,
          };
        }) || [],
      discount: {
        type_discount: budget_selected?.discount_percentage
          ? "percentage"
          : "amount",
        amount: budget_selected?.discount_value || 0,
        percentage: budget_selected?.discount_percentage || 0,
      },
      initial_payment: {
        amount: INITAL_PAYMENT,
        percentage: budget_selected?.initial_payment || 0,
      },
      total_amount_with_discount: budget_selected?.total_amount || 0,
      interest_rate: budget_selected?.interest_percentage || 0,
      selected_benefit: {
        amount_patient: 0,
        benefit_code: null,
        description: "",
        id_benefit: null,
        id_budget: 0,
        id_budget_benefit: Math.random(),
        is_manual: false,
        needs_thoot: false,
        quantity: 1,
        thoot_face: "",
        tooth_section: "",
        total_amount: 0,
        is_usd: false,
      },
    },
  });
  const STEPS = [
    { component: First, position: "first" },
    { component: Second, position: "second" },
    { component: Third, position: "third" },
    { component: Fourth, position: "fourth" },
  ];
  const VALUE = STEPS.find(
    (_step, index) => index === currentPosition
  )?.position;
  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const benefits = values.list_benefits?.map(
      (b: z.infer<typeof formSchema>["list_benefits"][number]) => {
        let benefit: { [key: string]: any } = {
          id_prest_presu: b.id_budget ? b.id_budget_benefit : 0,
          id_presup: b.id_budget || 0,
          imp_prest: b.amount_patient,
          id_prestacion: b.id_benefit,
          cant: b.quantity || 1,
          pieza: b.tooth_section,
        };

        if (!b.id_benefit) {
          benefit = {
            ...benefit,
            descripcion: b.description,
          };
        }

        return benefit;
      }
    );
    const TAX = TAXES.find(
      (tax: { [key: string]: any }) => tax.id_tax === values.id_tax
    );
    const INSTALLMENT = INSTALLMENTS?.find(
      (q: { [key: string]: any }) => q.id_installment === values.id_installment
    );
    const METHOD = PAYMENT_METHOD?.find(
      (q: { [key: string]: any }) =>
        q.id_payment_method === values.id_payment_method
    );

    const response = await addOrUpdateBudget({
      ...values,
      tax: TAX?.text,
      installment: INSTALLMENT?.text,
      benefits,
      id_user: user.id_user,
      id_patient: patient.id_patient,
      payment_method: METHOD?.value || "Efectivo",
      rowversion: budget_selected?.rowversion,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      refresh();
      onCancel();
      toast({
        variant: "successful",
        title: "Presupuesto creado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
    }

    stopLoading();
  }
  const { id_office } = form.getValues();

  return (
    <Form {...form}>
      <CardTitle className="flex items-center gap-x-2 col-span-4 mb-2">
        <span
          onClick={onCancel}
          className={classNames(
            "cursor-pointer",
            buttonVariants({ variant: "outline", size: "icon" })
          )}
        >
          <LuChevronLeft className="size-4" />
          <span className="sr-only">Back</span>
        </span>
        {budget_selected ? "Detalle del presupuesto" : "Nuevo presupuesto"}
      </CardTitle>
      <form className="w-full grid grid-cols-12 gap-10 mt-0 overflow-hidden">
        <Tabs
          defaultValue="first"
          value={VALUE}
          className="!w-full hidden lg:flex lg:flex-col col-span-full"
        >
          <ProgressBar
            length={4}
            stepsBar={steps}
            onClick={setStep}
            form={form}
            disabled={
              !user.is_supervisor &&
              !permissions.hasOwnProperty("Modificar Presupuesto")
            }
          />
          {STEPS.map((Step: any, index: number) => (
            <TabsContent
              key={index}
              value={Step.position}
              className="!w-full !mt-0"
            >
              <Step.component
                form={form}
                data={{
                  professionals: filterProfessionals(id_office || -1),
                  offices: ENABLED_OFFICES,
                  translations,
                  medicalInsurances,
                }}
                setStep={setStep}
                resetSteps={resetSteps}
                onSubmit={form.handleSubmit(onSubmit)}
                viewLoadBenefit={{
                  hiddenLoadBenefit,
                  setHiddenLoadBenefit,
                }}
                onCancel={onCancel}
                setActiveTab={setActiveTab}
              />
            </TabsContent>
          ))}
        </Tabs>
      </form>
    </Form>
  );
};
