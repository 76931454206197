"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Form,
  FormMessage,
} from "@/components/molecules";
import { Button, DialogClose, Input } from "@/components/atoms";
import { toast } from "@/hooks";
import { saveTurn } from "@/actions/schedule";
import { formatTime } from "@/utils/helpers";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema, resolver } from "./schema";

export function NewOverturn({
  refresh,
  setChangeDataTable,
}: {
  refresh: () => void;
  setChangeDataTable: (value: string) => void;
}) {
  const {
    schedule: { current_appointment },
    user: { id_user: id_user },
    startLoading,
    stopLoading,
  } = useGlobalStore();
  const [hour, minutes] = current_appointment?.time.split(":");
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      hour: Number(hour),
      minutes: Number(minutes) + 1,
    },
  });

  async function onSubmit({ values }: { values: z.infer<typeof formSchema> }) {
    startLoading();
    setChangeDataTable(current_appointment.time);

    const response = await saveTurn({
      appointment: {
        ...current_appointment,
        id_patient: null,
        id_office: null,
        dont_give: false,
        id_appointment: 0,
        observation: null,
        patient_name: null,
        patient_phone: null,
        attendance: null,
        medical_insurance: {
          name: null,
          plan: null,
          affiliate_number: null,
        },
        time: `${formatTime(values.hour)}:${formatTime(values.minutes)}`,
      },
      attendance: current_appointment.attendance,
      id_user,
      option: "agregarSobreTurno",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      await refresh();
      toast({
        variant: "successful",
        title: "Sobreturno creado con éxito",
        description: "Puedes visualizar los cambios en la agenda.",
      });
    }

    setChangeDataTable("");
    stopLoading();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit((values: any) => onSubmit({ values }))}>
        <div className="grid grid-cols-2 gap-5 mt-4 w-[600px]">
          <FormField
            control={form.control}
            name="hour"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Hora</FormLabel>
                <FormControl>
                  <Input type="number" {...field} min={0} max={23} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="minutes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Minutos</FormLabel>
                <FormControl>
                  <Input type="number" {...field} min={0} max={59} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-4 mt-5 gap-x-5">
          <DialogClose>
            <Button
              type="button"
              className="w-full"
              onClick={() => {
                form.reset();
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
          </DialogClose>
          <Button type="submit" className="w-full">
            Guardar
          </Button>
        </div>
      </form>
    </Form>
  );
}
