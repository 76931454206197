type State = {
  patient_gallery: {
    gallery: any[];
    archives_selected: any[];
    archive: any[];
  };
};

type Actions = {
  setPatientGallery: (payload: { gallery: any[] }) => void;
  setArchivesSelected: (archives: any) => void;
  resetArchiveSelected: () => void;
  setArchiveEdit: (archive: any) => void;
};

type GalleryStore = State & Actions;

export const INIT_STATE: State = {
  patient_gallery: {
    gallery: [],
    archives_selected: [],
    archive: [],
  },
};

export const GallerySlice = (
  set: (partial: Partial<GalleryStore>) => void,
  get: () => GalleryStore
): GalleryStore => ({
  ...INIT_STATE,
  setPatientGallery: ({ gallery }: { gallery: any[] }) => {
    const state = get();
    set({
      ...state,
      patient_gallery: {
        ...state.patient_gallery,
        gallery,
      },
    });
  },
  setArchivesSelected: (archives: any) => {
    const state = get();
    const {
      patient_gallery: { archives_selected },
    } = state;
    if (archives_selected.includes(archives)) {
      const filtered =
        archives_selected.filter((e: any) => e.id !== archives.id) || [];
      return set({
        ...state,
        patient_gallery: {
          ...state.patient_gallery,
          archives_selected: filtered,
        },
      });
    }
    set({
      ...state,
      patient_gallery: {
        ...state.patient_gallery,
        archives_selected: [
          ...state.patient_gallery.archives_selected,
          archives,
        ],
      },
    });
  },
  resetArchiveSelected: () => {
    const state = get();
    set({
      ...state,
      patient_gallery: {
        ...state.patient_gallery,
        archives_selected: [],
      },
    });
  },
  setArchiveEdit: (archives: any) => {
    const state = get();
    set({
      ...state,
      patient_gallery: {
        ...state.patient_gallery,
        archive: archives,
      },
    });
  },
});
