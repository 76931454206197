import * as React from "react";
import { Laboratory } from "@/types/general";
import { Combobox } from "@/components/atoms";

export const LaboratoriesSelected = ({
  form,
  laboratories,
  fieldName,
  className,
  label
}: {
  form: any;
  laboratories: Laboratory[];
  fieldName: string;
  className?: string;
  label:string
}) => {
  return (
    <Combobox
      form={form}
      data={laboratories}
      dataKey="id_laboratory"
      fieldName={fieldName}
      value="name"
      placeholder="Busca un laboratorio"
      label={label}
      className={className}
    />
  );
};
