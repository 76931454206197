"use client";

import * as React from "react";
import Link from "next/link";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import { LuUserCog } from "react-icons/lu";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/atoms";
import { SIDEBAR_MY_ACCOUNT } from "@/libs/sidebars";

export function MyAccountSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar>) {
  const pathname = usePathname();
  const isActive = (url: string) => pathname.includes(url);

  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild className="menu-side-bar">
              <div>
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-slate-700 text-white">
                  <LuUserCog className="size-4" />
                </div>
                <h3 className="font-semibold">Mi cuenta</h3>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu className="gap-2">
            {SIDEBAR_MY_ACCOUNT?.navMain?.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild>
                  <Link
                    shallow
                    href={item.url}
                    className={classNames("font-medium", {
                      "bg-slate-100": isActive(item.url),
                    })}
                  >
                    {item.title}
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
