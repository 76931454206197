export const INITIAL_STEPS: {
  id: number;
  name: string;
  status: "complete" | "current" | "upcoming";
  position: string;
  description: string | null;
  key: string | null;
}[] = [
  {
    id: 1,
    name: "Datos del presupuesto",
    status: "current",
    position: "first",
    description: "Profesional: ",
    key: "id_professional",
  },
  {
    id: 2,
    name: "Datos afiliatorios",
    status: "upcoming",
    position: "second",
    description: "",
    key: "id_med_ins",
  },
  {
    id: 3,
    name: "Datos de pago",
    status: "upcoming",
    position: "third",
    description: null,
    key: null,
  },
  {
    id: 4,
    name: "Resumen presupuesto",
    status: "upcoming",
    position: "fourth",
    description: null,
    key: null,
  },
];
