"use client";

import { LuArrowRight } from "react-icons/lu";
import Link from "next/link";
import classNames from "classnames";
import {
  Badge,
  Card,
  CardDescription,
  buttonVariants,
  Separator,
} from "@/components/atoms";
import { ProductCardProps } from "@/types/my-account";
import { products, renderParams } from "@/libs/my-account";

function checkIsSuscribed({
  account,
  product,
}: {
  account: any;
  product: string;
}) {
  if (!product) return;

  switch (product) {
    case "whatsapp":
      return !!account.whatsapp_pack;
    case "recepies":
      return !!account.recipe_pack;
    case "cloud":
      return !!account.storage_pack;
  }
}

export const ProductCard = ({
  account,
  user: { plan_version, web_user },
  product = "",
  plan,
}: ProductCardProps) => {
  const isSuscribed =
    checkIsSuscribed({
      account,
      product,
    }) || false;
  const plansColors: { [key: string]: string } = {
    lender: "text-slate-600",
    freemium: "text-blue-500",
    premium: "text-amber-500",
    whatsapp: "text-green-500",
    cloud: "text-violet-500",
  };
  const price = account?.user_pack
    ? account?.user_pack * (products[plan || plan_version]?.price?.money || 0)
    : 0;
  const params = renderParams({
    account,
    web_user,
    product,
    plan,
    isSuscribed,
  });
  const userProduct = products[plan || product || plan_version];

  return (
    <Card className="flex flex-col justify-between">
      <div
        className={classNames(
          plansColors[plan || plan_version],
          product && plansColors[product]
        )}
      >
        <div className="flex justify-between items-center">
          {userProduct && (
            <div className="flex items-center">
              {userProduct?.icon}
              <h2 className="text-2xl">{userProduct?.title}</h2>
            </div>
          )}
          {product && isSuscribed && <Badge>Contratado</Badge>}
        </div>
        <CardDescription className="mt-2">
          {userProduct && <p>{userProduct?.description}</p>}
        </CardDescription>
      </div>
      <div>
        {products[plan || plan_version]?.price && !product && (
          <div className="flex items-end mt-4">
            <p className="text-2xl mr-2">{`${
              products[plan || plan_version].price?.currency
            } ${price}`}</p>
            <span> / mes</span>
          </div>
        )}
        <>
          <Separator className="my-4" />
          <Link
            target="_blank"
            className={classNames(buttonVariants({ variant: "default" }), {
              "bg-slate-400 !text-slate-200 pointer-events-none":
                params.disabled,
            })}
            href={params.href}
            rel="noreferrer"
          >
            {params.text}
            <LuArrowRight className="ml-2 size-4" />
          </Link>
        </>
      </div>
    </Card>
  );
};
