import React, { useState, useRef } from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { getDiagnosis } from "@/actions/recipes";
import { Button, Input, Label } from "@/components/atoms";
import { DiagnosisTable } from "@/components/organisms/tables";
import { toast, useOnClickOutside } from "@/hooks";
import { Diagnosis } from "@/types/recipes";
import { ButtonLoading } from "@/components/molecules";

export const SearchDiagnosis: React.FC<{
  placeholder: string;
  label: string;
  disabled?: boolean;
  form: any;
  name: string;
}> = ({ placeholder, label, disabled, form, name }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Diagnosis[]>([]);
  const [sorting, setSorting] = useState([]);
  const refWrapper = useRef<any>(null);

  const SHOW_RESULTS = isOpen && searchResults && searchResults.length >= 1;
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(false);
    setSearchInput(e.target.value);
    form.setValue(name, {
      description: "",
      code: "",
      id_diagnosis: -1,
      is_manual: false,
    });
    setHasSearched(false);
  };
  const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingButton(true);
    try {
      if (searchInput?.trim() !== "" && !hasSearched) {
        const diagnosis = (await getDiagnosis(searchInput)) as Diagnosis[];
        setSearchResults(diagnosis);
        setIsOpen(true);
        setHasSearched(true);
      }
    } catch (error: any) {
      toast({
        variant: ["00001", "00002"].some((code) => error.message.includes(code))
          ? "warning"
          : "destructive",
        title: "Ups! No se encontraron coincidencias.",
        description: error.message,
      });
    } finally {
      setLoadingButton(false);
    }
  };
  const onClickHandler = async (selected_diagonosis: Diagnosis) => {
    form.setValue(name, { ...selected_diagonosis, is_manual: false });
    setIsOpen(false);
    setSearchInput("");
  };

  useOnClickOutside({
    ref: refWrapper,
    handler: () => {
      setIsOpen(false);
      setSearchInput("");
    },
  });

  return (
    <form
      ref={refWrapper}
      className={classNames(
        "rounded-md will-change-[left] transition-all duration-100 ease-out w-full group col-span-6",
        isOpen && "searchbar-container--is_open relative z-10"
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 rounded-md">
        <Label className="mb-2 block !text-sm col-span-full">{label}</Label>
        <div className="col-span-10">
          <Input
            type="search"
            className="text-slate-900 !border-1 shadow-sm !border-gray-300 pl-5 h-10 w-full relative rounded-md text-base col-span-10"
            placeholder={placeholder}
            onChange={(e: any) => onChangeHandler(e)}
            value={searchInput || form.watch(name).description}
            autoFocus
            maxLength={120}
            autoCapitalize="off"
            disabled={disabled}
          />
        </div>
        {loadingButton ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button
            disabled={!searchInput}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onSubmitHandler(e)
            }
            type="submit"
            className="w-full col-span-2 bg-transparent !text-gray-800 hover:bg-slate-200/50 border border-slate-200"
          >
            Buscar
          </Button>
        )}
      </div>
      <Transition
        show={SHOW_RESULTS}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <DiagnosisTable
          data={searchResults}
          sorting={sorting}
          setSorting={setSorting}
          onClick={onClickHandler}
          height="250px"
        />
      </Transition>
    </form>
  );
};
