import React from "react";
import { Button } from "@/components/atoms";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const ButtonsBenefit: React.FC<{
  setHiddenLoadBenefit: (value: boolean) => void;
  form: Form<typeof formSchema>;
}> = ({ setHiddenLoadBenefit, form }) => {
  const { selected_benefit, list_benefits } = form.getValues();
  const updateAndSaveOrder = () => {
    let benefits_filter = list_benefits || [];
    const index_concept = benefits_filter.findIndex(
      (b: { [key: string]: any }) =>
        b.id_budget_benefit === selected_benefit.id_budget_benefit
    );
    index_concept !== -1
      ? (benefits_filter[index_concept] = selected_benefit)
      : benefits_filter.push(selected_benefit);
    form.setValue("list_benefits", benefits_filter);
    setHiddenLoadBenefit(false);
  };
  const cancelButton = () => {
    form.setValue("is_manual_benefit", false);
    setHiddenLoadBenefit(false);
  };

  return (
    <div className="flex w-full justify-end space-x-4 col-span-full">
      <Button variant="destructive" type="button" onClick={cancelButton}>
        Cancelar
      </Button>
      <Button
        onClick={updateAndSaveOrder}
        type="button"
        disabled={!selected_benefit.description}
      >
        Guardar prestación
      </Button>
    </div>
  );
};
