"use client";

import React from "react";
import { Checkbox, Combobox, Separator } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Offices as OfficesT } from "@/types/general";

export const Offices = ({
  form,
  offices,
  show = {
    disabled: true,
    allOffices: true,
  },
}: {
  form: any;
  offices: OfficesT[];
  show?: {
    disabled: boolean;
    allOffices: boolean;
  };
}) => {
  const { all_offices } = form.watch();

  React.useEffect(() => {
    if (!offices?.length) return;

    form.setValue("id_office", all_offices ? null : offices?.[0]?.id_office);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_offices]);
  // Make the validation inside the component,
  // if there are no offices, return null.
  if (!offices || !offices?.length) return;

  const defaultValues = form.getValues();
  const enabledOffices = offices?.filter((item) => item.enabled);

  return (
    <>
      <Separator className="bg-slate-200 h-px" />
      <p className="text-slate-800 text-lg font-bold">Sucursales</p>
      {show.allOffices && (
        <FormField
          control={form.control}
          name="all_offices"
          render={({ field }) => (
            <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                    checked
                      ? form.setValue("id_office", null)
                      : form.setValue(
                          "id_office",
                          enabledOffices?.[0]?.id_office
                        );
                  }}
                />
              </FormControl>
              <div className="leading-none flex flex-col">
                <FormLabel className="!m-0">Todas las sucursales</FormLabel>
                <p className="text-sm font-regular text-gray-400">
                  Se incluirán todas las sucursales al reporte
                </p>
              </div>
            </FormItem>
          )}
        />
      )}
      <FormItem>
        <FormControl>
          <Combobox
            form={form}
            data={enabledOffices}
            dataKey="id_office"
            value="name"
            placeholder="Busca una sucursal"
            title="Sucursal"
            disabled={defaultValues.all_offices}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
      {show.disabled && (
        <FormField
          control={form.control}
          name="disabled_offices"
          render={({ field }) => (
            <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="leading-none flex flex-col">
                <FormLabel className="!m-0">
                  Incluír deshabilitados/as
                </FormLabel>
                <p className="text-sm font-regular text-gray-400">
                  Se incluirán todos las las sucursales deshabilitados/as
                </p>
              </div>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
