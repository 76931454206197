"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  Input,
  Checkbox,
} from "@/components/atoms";
import { toast, useDialog, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { MedicalInsurance, Plan } from "@/types/general";
import { updatePlansConfig } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function NewMedicalInsurancePlansForm({
  selected,
  medicalInsurance,
}: {
  selected?: Plan;
  medicalInsurance: MedicalInsurance;
}) {
  const { loading, startLoading, stopLoading } = useGlobalStore();
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_plan: selected?.id_plan || 0,
      code_plan: selected?.code_plan || "",
      name: selected?.name || "",
      id_med_ins: selected?.id_med_ins || medicalInsurance.id_med_ins,
      disabled: !selected?.disabled || false,
      rowversion: selected?.rowversion || null,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await updatePlansConfig({
      medicalInsurance,
      plan: values as Plan,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El plan se guardó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {selected?.id_plan ? "Modificar plan" : "Nuevo plan"}
        </DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form className="flex items-end gap-x-4 gap-y-6">
          <FormField
            control={form.control}
            name="code_plan"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Código</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nombre</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="disabled"
            render={({ field }) => (
              <FormItem className="flex items-center gap-x-2">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!mb-0">Plan habilitado</FormLabel>
                  <p className="mt-0 text-sm font-regular text-gray-400">
                    Habilita o deshabilita el plan.
                  </p>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            type="button"
            onClick={() => form.reset()}
            variant="destructive"
          >
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={form.handleSubmit(onSubmit)}>Guardar</Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
