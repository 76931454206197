import { Offices } from "@/types/general";
import { EmptyState } from "@/components/molecules";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: Offices[];
}> = ({ data }) => {
  return !data?.length ? (
    <EmptyState
      title="Ups! no tienes cargada ninguna sucursal."
      copy="Puedes cargar una sucursal para empezar."
    />
  ) : (
    <Data columns={columns} data={data} />
  );
};
