import { INITIAL_PARAMS_SHEDULE, INITIAL_PARAMS_CONFIG } from "@/libs/schedule";
import { ScheduleList } from "@/types/schedule";

type State = {
  schedule: {
    data: {
      [key: string]: any;
    };
    config: typeof INITIAL_PARAMS_CONFIG;
    params: typeof INITIAL_PARAMS_SHEDULE;
    current_appointment: any;
    initialized_data: boolean;
  };
};

type Actions = {
  setScheduleParams: (payload: {
    params: typeof INITIAL_PARAMS_SHEDULE;
  }) => void;
  setScheduleConfig: (payload: {
    config: typeof INITIAL_PARAMS_CONFIG;
  }) => void;
  setDataSchedule: (payload: { data: ScheduleList[] }) => void;
  setCurrentAppointment: (current_appointment: any) => void;
};

type ScheduleStore = State & Actions;

export const INIT_STATE: State = {
  schedule: {
    data: [],
    config: INITIAL_PARAMS_CONFIG,
    params: INITIAL_PARAMS_SHEDULE,
    current_appointment: null,
    initialized_data: false,
  },
};

export const ScheduleSlice = (
  set: (partial: Partial<ScheduleStore>) => void,
  get: () => ScheduleStore
): ScheduleStore => ({
  ...INIT_STATE,
  setDataSchedule: ({
    data,
  }: {
    data: ScheduleList[];
    params?: typeof INITIAL_PARAMS_SHEDULE;
    set_new_params?: Boolean;
  }) => {
    set({
      schedule: {
        ...get().schedule,
        data,
      },
    });
  },
  setScheduleParams: ({
    params,
  }: {
    params: typeof INITIAL_PARAMS_SHEDULE;
  }) => {
    set({
      schedule: {
        ...get().schedule,
        params,
      },
    });
  },
  setScheduleConfig: ({ config }: { config: typeof INITIAL_PARAMS_CONFIG }) => {
    set({
      schedule: {
        ...get().schedule,
        config,
      },
    });
  },
  setCurrentAppointment: ({ current_appointment }: any) => {
    set({
      schedule: {
        ...get().schedule,
        current_appointment,
      },
    });
  },
});
