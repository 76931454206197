"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { LuChevronLeft, LuGlobe } from "react-icons/lu";
import { HiOutlineQueueList } from "react-icons/hi2";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { EmptyState, Form } from "@/components/molecules";
import { MembershipHistory } from "@/components/organisms/tables";
import { toast, useOnClickOutside } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { validateMembership } from "@/actions/patients";
import { formSchema } from "./schema";

export const MemberVerificacion = ({
  history,
  lenderCode,
  getHistory,
}: {
  history: any;
  lenderCode: string | null;
  getHistory: () => void;
}) => {
  const {
    patient: {
      plan_navigation,
      id_patient,
      document_type,
      document_number,
      affiliate_number,
    },
  } = useGlobalStore();
  const [openTab, setOpenTab] = React.useState(false);
  const REF_WRAPPER = React.useRef(null);
  const FORM = useForm({
    defaultValues: {
      customer_code: plan_navigation?.medical_insurance_navigation?.client_ftp,
      lender_code: lenderCode,
      medical_insutance_code:
        plan_navigation?.medical_insurance_navigation?.code_med_ins,
      document_type: document_type,
      id_medical_insurance: plan_navigation?.id_med_ins,
      document_number: document_number,
      member_number: affiliate_number,
      id_patient: id_patient,
    },
  });
  const RENDER_CARDS = [
    {
      name: "Verificación Online",
      description: "Verificamos si el paciente cuenta con su membresía al día.",
      icon: <LuGlobe type="globe" className="size-6" aria-hidden="true" />,
      action: "Realizar verificación",
      onClick: FORM.handleSubmit(onSubmit),
    },
    {
      name: "Ver historial de chequeos",
      description: "Te listamos el historial de los chequeos de este paciente.",
      icon: (
        <HiOutlineQueueList
          type="queue"
          className="size-6"
          aria-hidden="true"
        />
      ),
      action: "Ver historial",
      onClick: () => {
        getHistory();
        setOpenTab(true);
      },
    },
  ];

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const { member_ship_status } = await validateMembership({
        codCliente: values.customer_code,
        codPrestador: values.lender_code,
        codObraSocial: values.medical_insutance_code,
        tipoDocumento: values.document_type,
        nroDocumento: values.document_number,
        nroAfiliado: values.member_number,
        id_os: values.id_medical_insurance,
        id_paciente: values.id_patient,
      });
      toast({
        variant: member_ship_status.includes("NO HABILITADO")
          ? "warning"
          : "successful",
        title: "Verificacion realizada correctamente",
        description: `Usuario: ${member_ship_status}`,
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    }
  }
  useOnClickOutside({
    ref: REF_WRAPPER,
    handler: () => {
      if (openTab) {
        setOpenTab(false);
      }
    },
  });

  return (
    <DialogContent ref={REF_WRAPPER} className="w-3/5">
      <Form {...FORM}>
        <form onSubmit={FORM.handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle>Verificación de carnet</DialogTitle>
          </DialogHeader>
          <DialogClose
            onClick={() => {
              if (openTab) {
                setOpenTab(false);
              }
            }}
          />
          {openTab ? (
            <div>
              <Button
                type="button"
                variant="link"
                className="cursor-pointer flex items-center !text-blue-500 !no-underline transition-transform hover:translate-x-[-10px] mr-2"
                onClick={() => setOpenTab(false)}
              >
                <LuChevronLeft className="size-4" />
                Volver
              </Button>
              {history.length ? (
                <MembershipHistory data={history} />
              ) : (
                <EmptyState
                  title="!Ups"
                  copy="No hay historial de chequeos para mostrar."
                />
              )}
            </div>
          ) : (
            <div className="flex flex-row space-x-4">
              {RENDER_CARDS.map((card, index) => (
                <Card
                  key={index}
                  className="flex flex-col border-none shadow-none justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                >
                  <CardHeader className="flex md:h-full lg:flex-col">
                    <CardTitle className="flex-shrink-0">
                      <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-slate-700 text-white sm:h-12 sm:w-12">
                        {card.icon}
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p className="text-base font-medium text-gray-900">
                          {card.name}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {card.description}
                        </p>
                      </div>
                      <Button
                        type="button"
                        variant="link"
                        className="!text-blue-600 group lg:mt-4 hover:cursor-pointer !no-underline"
                        onClick={card.onClick}
                      >
                        {card.action}
                        <span
                          aria-hidden="true"
                          className="ml-2 inline-block group-hover:translate-x-1 group-hover:transition-all group-hover:duration-200 group-hover:ease-in-out"
                        >
                          &rarr;
                        </span>
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </form>
      </Form>
    </DialogContent>
  );
};
