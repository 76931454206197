import * as React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { LuCircleCheck, LuPen, LuTrash2 } from "react-icons/lu";
import { HiDotsVertical } from "react-icons/hi";
import { FcCancel } from "react-icons/fc";
import {
  Badge,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { useGlobalStore } from "@/providers/global-provider";
import { toast } from "@/hooks";
import { useRestrictedPatientAction } from "@/hooks/use-patient-restricted-action";
import { Alls } from "@/types/patients";

const Dropdown = ({
  benefit,
  action,
}: {
  benefit: Alls;
  action: (benefit: Alls, type: string) => void;
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(benefit, type),
          id: benefit.id_professional,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <Action type="edit">
            <LuPen className="mr-2 size-4" />
            <span>Ver detalles</span>
          </Action>
          <Action type="delete">
            <LuTrash2 className="mr-2 size-4" />
            <span>Eliminar</span>
          </Action>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export const Columns = ({ translations, action, config }: any) => {
  const { permissions, USE_USD, is_supervisor } = config;
  const {
    patient: {
      plan_navigation: { medical_insurance_navigation },
    },
  } = useGlobalStore();

  const rows = () => {
    const rowsArray: any = [
      {
        id: "actions",
        enableHiding: false,
        cell: ({ row: { original } }: { row: any }) => {
          const handleAction = (benefit: Alls, type: string) => {
            if (
              medical_insurance_navigation.medical_insurance !==
              original.plan_navigation?.medical_insurance_navigation
                .medical_insurance
            ) {
              return toast({
                variant: "destructive",
                title: "Ups! Parece que hubo un error",
                description: "No se puede editar la prestación (plan distinto)",
              });
            } else {
              action[type](benefit);
            }
          };

          return <Dropdown benefit={original} action={handleAction} />;
        },
      },
      {
        accessorKey: "date",
        header: "Fecha",
        cell: ({ row }: { row: any }) => {
          const { date } = row.original;

          return (
            <p className="lg:text-sm text-slate-600 my-1">
              {format(date, "dd/MM/yyyy")}
            </p>
          );
        },
      },
      {
        accessorKey: "professional",
        header: "Profesional",
        cell: ({ row }: { row: any }) => {
          const {
            id_professional_navigation: { name },
          } = row.original;

          return (
            <p className="lg:text-sm font-bold text-slate-800 my-1 text-nowrap">
              {name}
            </p>
          );
        },
      },
      {
        accessorKey: "benefit_code",
        header: "Código prestación",
        cell: ({ row }: { row: any }) => {
          const {
            id_benefit_navigation: { benefit_code, code_benefit_med_ins_liq },
          } = row.original;

          return (
            <div className="w-40">
              <p className="lg:text-sm text-slate-600 my-1">
                Cod. Interno:{" "}
                <span className="lg:text-sm text-slate-600 my-1">
                  {benefit_code}
                </span>
              </p>
              <p className="lg:text-sm text-slate-600 my-1">
                {translations?.cod_mi}{" "}
                <span className="lg:text-sm text-slate-800 my-1">
                  {code_benefit_med_ins_liq}
                </span>
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Descripción",
        cell: ({ row }: { row: any }) => {
          const {
            id_benefit_navigation: { description },
            color,
          } = row.original;
          const STATUS: { [key: string]: { [key: string]: string } } = {
            ROJO: {
              text: "Prestación anterior",
              style: "bg-red-50 text-red-600 border-red-600/10",
            },
            AZUL: {
              text: "Prestación a realizar",
              style: "bg-blue-50 text-blue-600 border-blue-600/10",
            },
            VERDE: {
              text: "Prestación realizada",
              style: "bg-green-50 text-green-600 border-green-600/10",
            },
          };
          const STATE = STATUS[color];

          return (
            <div>
              {color && (
                <Badge className={classNames("w-auto mt-2 mb-1", STATE.style)}>
                  {STATE.text}
                </Badge>
              )}
              <p className="lg:text-sm text-slate-600 my-1 text-nowrap">
                {description}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "medical_insurance",
        header: translations?.medical_insurances,
        cell: ({ row }: { row: any }) => {
          const { plan_navigation } = row.original || {};

          return (
            <div className="text-nowrap">
              <p className="lg:text-sm font-bold text-slate-800 my-1">
                {
                  plan_navigation?.medical_insurance_navigation
                    .medical_insurance
                }
              </p>
              <p className="lg:text-sm text-slate-600 my-1">
                Plan: {plan_navigation?.name}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "thoot",
        header: "Diente",
        cell: ({ row }: { row: any }) => {
          const { tooth_sector, face } = row.original;

          return (
            <div className="w-24">
              {tooth_sector && (
                <p className="lg:text-sm text-slate-600 my-1">
                  Pieza:{" "}
                  <span className="font-bold text-slate-800">
                    {tooth_sector}
                  </span>
                </p>
              )}
              {face && (
                <p className="lg:text-sm text-slate-600 my-1">
                  Cara: <span className="font-bold text-slate-800">{face}</span>
                </p>
              )}
            </div>
          );
        },
      },
    ];
    if (permissions.hasOwnProperty("Ver Importes Prest.") || is_supervisor) {
      rowsArray.splice(8, 0, {
        accessorKey: "amouht_medical_insurance",
        header: `Importe ${translations?.medical_insurance_abbreviation}`,
        cell: ({ row }: { row: any }) => {
          const { amount_os } = row.original;

          return (
            <p
              className={classNames("flex justify-end lg:text-sm my-1", {
                "text-green-500": amount_os > 0,
                "text-red-500": amount_os < 0,
                "text-slate-600": amount_os === 0,
              })}
            >
              {numberToCurrency(amount_os)}
            </p>
          );
        },
      });
    }
    if (permissions.hasOwnProperty("Ver Importes Prest.") || is_supervisor) {
      rowsArray.splice(9, 0, {
        accessorKey: "amount_patient",
        header: "Importe paciente",
        cell: ({ row }: { row: any }) => {
          const { amount_patient } = row.original;

          return (
            <p
              className={classNames("flex justify-end lg:text-sm my-1", {
                "text-green-500": amount_patient > 0,
                "text-red-500": amount_patient < 0,
                "text-slate-600": amount_patient === 0,
              })}
            >
              {numberToCurrency(amount_patient)}
            </p>
          );
        },
      });
    }
    if (USE_USD.params) {
      rowsArray.splice(7, 0, {
        accessorKey: "is_usd",
        header: "En USD",
        cell: ({ row }: { row: any }) => {
          const { is_usd } = row.original;

          return is_usd ? (
            <LuCircleCheck className="size-5 mx-auto text-green-500" />
          ) : (
            <FcCancel className="size-6 mx-auto" />
          );
        },
        filterFn: (row: any, id: string, value: any) =>
          value.includes(row.getValue(id)),
      });
    }
    return rowsArray;
  };
  return rows();
};
