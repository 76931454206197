import React from "react";
import { Combobox, Input, Label } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/molecules";
import { Cards } from "@/types/payments";
import { Form } from "@/types/general";

const DebitCardMethod: React.FC<{ cards: Cards[]; form: Form<any> }> = ({
  cards,
  form,
}) => {
  return (
    <>
      <Combobox
        form={form}
        data={cards}
        dataKey="id_card"
        value="card_name"
        showInputSearch={false}
        title="tarjeta de debito"
        className="col-span-3"
        label="Tarjetas de débito"
      />
      <FormField
        control={form.control}
        name="card_number"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0">
            <Label>Nro. Tarjeta</Label>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="card_cvv"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0">
            <Label className="!mt-24 !mb-4">Nro. Autorización</Label>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

export default DebitCardMethod;
