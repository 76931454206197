"use client";

import { useEffect } from "react";
import { useOnClickOutsideT } from "@/types/hooks";

export function useOnClickOutside({ ref, handler }: useOnClickOutsideT) {
  useEffect(() => {
    const LISTENER = (e: MouseEvent | TouchEvent) => {
      if (!ref.current || ref?.current.contains(e.target)) {
        return;
      }
      handler(e);
    };
    document.addEventListener("mousedown", LISTENER);
    document.addEventListener("touchstart", LISTENER);
    return () => {
      document.removeEventListener("mousedown", LISTENER);
      document.removeEventListener("touchstart", LISTENER);
    };
  }),
    [ref, handler];
}
