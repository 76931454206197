"use client";

import Link from "next/link";
import classNames from "classnames";
import {
  buttonVariants,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Button,
} from "@/components/atoms";
import { NavProps } from "@/types/nav";

export function Nav({ links }: NavProps) {
  return (
    <div className="flex flex-col gap-4 py-2 data-[collapsed=true]:py-2">
      <nav className="grid gap-2 px-2">
        {links.map((link, index) => (
          <Tooltip key={index} delayDuration={0}>
            <TooltipTrigger asChild>
              {link.external ? (
                <Button
                  className={classNames(
                    buttonVariants({ size: "icon" }),
                    "h-10 w-full !px-0",
                    link.styles
                  )}
                  onClick={link.onClick}
                >
                  <link.icon className="h-5 w-5" />
                  <span className="sr-only">{link.title}</span>
                </Button>
              ) : (
                <Link
                  href={link.url || ""}
                  className={classNames(
                    buttonVariants({ size: "icon" }),
                    "h-10 w-full",
                    link.styles
                  )}
                >
                  <link.icon className="h-5 w-5" />
                  <span className="sr-only">{link.title}</span>
                </Link>
              )}
            </TooltipTrigger>
            <TooltipContent side="right" className="flex items-center gap-4">
              {link.title}
            </TooltipContent>
          </Tooltip>
        ))}
      </nav>
    </div>
  );
}
