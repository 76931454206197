"use client";

import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "@/components/atoms";

export const columns: ColumnDef<any>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => {
          table.toggleAllPageRowsSelected(!!value);
        }}
        aria-label="Seleccionar todo"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Seleccionar fila"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "patient_name",
    accessorKey: "patient",
    header: "Paciente",
    cell: ({ row }: { row: any }) => {
      const { patient } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {patient}
        </p>
      );
    },
  },
  {
    accessorKey: "phone",
    header: "Teléfono",
    cell: ({ row }: { row: any }) => {
      const { phone } = row.original;

      return <p className="leading-6 text-slate-800 my-1">{phone}</p>;
    },
  },
];
