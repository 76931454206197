import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_med_ins_source: z.string(),
  id_med_ins_destination: z.string(),
  id_plan_source: z.string(),
  id_plan_destination: z.string(),
  benefits_to_copy: z.array(z.number()),
  replace_all: z.boolean(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
