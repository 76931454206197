import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  laboratory_origin: z.number(),
  laboratory_destination: z.number(), 
  selected_articles: z.number().array(),
  replace_destination: z.boolean(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
