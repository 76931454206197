import { Checkbox, Combobox, Separator } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/molecules";
import { MedicalInsurance, Translations } from "@/types/general";

export const MedicalInsurances = ({
  form,
  medicalInsurances,
  translations,
}: {
  form: any;
  medicalInsurances: MedicalInsurance[];
  translations: Translations;
}) => {
  const medicalInsuranceName = translations?.medical_insurance;
  const medicalInsurancesName = translations?.medical_insurances;
  const allMedicalInsurances = translations?.all_medical_insurances;
  const defaultValues = form.getValues();
  const filteredMedicalInsurances = () =>
    defaultValues?.disabled_med_ins
      ? medicalInsurances
      : medicalInsurances?.filter((item) => !item.disabled);

  return (
    <>
      <Separator className="bg-slate-200 h-px" />
      <p className="text-slate-800 text-lg font-bold">{medicalInsuranceName}</p>
      <FormField
        control={form.control}
        name="all_med_ins"
        render={({ field }) => (
          <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                  if (checked) {
                    return form.setValue("id_med_ins", null);
                  }
                  return form.setValue(
                    "id_med_ins",
                    filteredMedicalInsurances()?.[0]?.id_med_ins
                  );
                }}
              />
            </FormControl>
            <div className="leading-none flex flex-col">
              <FormLabel className="!m-0">{medicalInsurancesName}</FormLabel>
              <p className="text-sm font-regular text-gray-400">
                {`Se incluirán ${allMedicalInsurances?.toLowerCase()} al reporte`}
              </p>
            </div>
          </FormItem>
        )}
      />
      <Combobox
        form={form}
        data={filteredMedicalInsurances()}
        dataKey="id_med_ins"
        value="medical_insurance"
        placeholder={`Busca un/a ${medicalInsuranceName}`}
        title={medicalInsuranceName}
        disabled={defaultValues.all_med_ins}
      />
      <FormField
        control={form.control}
        name="disabled_med_ins"
        render={({ field }) => (
          <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="leading-none flex flex-col">
              <FormLabel className="!m-0">Incluír deshabilitados/as</FormLabel>
              <p className="text-sm font-regular text-gray-400">
                {`Se incluirán ${allMedicalInsurances?.toLocaleLowerCase()} deshabilitados/as`}
              </p>
            </div>
          </FormItem>
        )}
      />
    </>
  );
};
