import { LuPlus, LuEye } from "react-icons/lu";
import { Button } from "@/components/atoms";
import {
  NewBankForm,
  NewMovementForm,
  InstallmentsForm,
  NewCardForm,
} from "@/components/organisms/forms/administration";
import { useDialog } from "@/hooks";

export const NewCardDialog = ({ disabled }: { disabled: boolean }) => {
  const { openDialog } = useDialog();

  return (
    <Button
      onClick={() =>
        openDialog({
          content: <NewCardForm />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
      disabled={disabled}
    >
      <LuPlus className="size-4" />
      <p>Nueva tarjeta</p>
    </Button>
  );
};

export const InstallmentsDialog = ({ card }: { card: any }) => {
  const { openDialog } = useDialog();

  return (
    <Button
      onClick={() =>
        openDialog({
          content: <InstallmentsForm card={card} />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
    >
      <LuEye className="size-4" />
      <p>Ver cuotas</p>
    </Button>
  );
};

export const NewMovementDialog = ({
  form,
  disabled,
}: {
  form: any;
  disabled: boolean;
}) => {
  const { openDialog } = useDialog();
  const { id_bank } = form.getValues();

  return (
    <Button
      disabled={disabled || !id_bank}
      onClick={() =>
        openDialog({
          content: <NewMovementForm idBank={id_bank} />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
    >
      <LuPlus className="size-4" />
      <p>Nuevo movimiento</p>
    </Button>
  );
};

export const NewBankDialog = ({ disabled }: { disabled: boolean }) => {
  const { openDialog } = useDialog();

  return (
    <Button
      onClick={() =>
        openDialog({
          content: <NewBankForm />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
      disabled={disabled}
    >
      <LuPlus className="size-4" />
      <p>Nuevo banco</p>
    </Button>
  );
};

export const NewIncomeDialog = ({ disabled }: { disabled: boolean }) => {
  const { openDialog } = useDialog();

  return (
    <Button
      onClick={() =>
        openDialog({
          content: <NewBankForm />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
      disabled={disabled}
    >
      <LuPlus className="size-4" />
      <p>Nuevo ingreso</p>
    </Button>
  );
};

export const NewExpenseDialog = ({ disabled }: { disabled: boolean }) => {
  const { openDialog } = useDialog();

  return (
    <Button
      onClick={() =>
        openDialog({
          content: <NewBankForm />,
        })
      }
      variant="ghost"
      className="flex gap-x-2"
      disabled={disabled}
    >
      <LuPlus className="size-4" />
      <p>Nuevo egreso</p>
    </Button>
  );
};
