"use client";

import React from "react";
import { LuPen, LuTrash2 } from "react-icons/lu";
import {
  HiDotsVertical,
  HiOutlineBan,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { ListPriceFrom, DeleteWork } from "@/components/organisms/forms";
import { numberToCurrency } from "@/utils/currency";
import { useDialog } from "@/hooks";
import { Laboratory, LaboratoryConcept } from "@/types/general";

const Dropdown = ({
  labConcept,
  laboratory,
}: {
  labConcept: LaboratoryConcept;
  laboratory?: Laboratory;
}) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() => {
              openDialog({
                content: (
                  <ListPriceFrom
                    labConcept={labConcept}
                    laboratory={laboratory}
                  />
                ),
              });
            }}
          >
            <LuPen className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() => {
              openDialog({
                content: <DeleteWork labConcept={labConcept} />,
              });
            }}
          >
            <LuTrash2 className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = ({ laboratory }: { laboratory?: Laboratory }) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => (
        <Dropdown laboratory={laboratory} labConcept={row.original} />
      ),
    },
    {
      id: "Codigo",
      accessorKey: "code",
      header: "Codigo",
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-600 my-1">
            {code}
          </p>
        );
      },
    },
    {
      id: "Concepto",
      accessorKey: "description",
      header: "Concepto",
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-600 my-1">
            {description}
          </p>
        );
      },
    },
    {
      id: "Importe",
      accessorKey: "amount",
      header: "Importe",
      cell: ({ row }: { row: any }) => {
        const { amount, is_usd } = row.original;

        return is_usd ? (
          <p className="font-bold text-sm leading-6 text-gray-600 my-1">{`$${amount}`}</p>
        ) : (
          <p className="font-bold text-sm leading-6 text-gray-600 my-1">
            {numberToCurrency(amount)}
          </p>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En U$D",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <p>
            {is_usd ? (
              <HiOutlineCheckCircle className="size-5 text-green-500" />
            ) : (
              <HiOutlineBan className="size-5 text-red-500" />
            )}
          </p>
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "enabled",
      header: "Habilitado",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: any }) => {
        const { enabled } = row.original;

        return (
          <p>
            {enabled ? (
              <HiOutlineCheckCircle className="size-5 text-green-500" />
            ) : (
              <HiOutlineBan className="size-5 text-red-500" />
            )}
          </p>
        );
      },
    },
  ];
};
