import { Professional } from "@/types/general";
import { compareDatesRanges } from "@/utils/helpers";
import { enabledProfessionals } from "@/utils/mappers";

export const filterProfByHoursSchedules = ({
  professionals,
  office,
  dayOfWeekToFilter,
  id_specialty,
  ignoreDateRange,
  date,
  filter_professional_by_day,
}: {
  professionals: Professional[];
  office: number | null;
  dayOfWeekToFilter: any;
  id_specialty: number | null;
  ignoreDateRange: boolean;
  date: Date;
  filter_professional_by_day?: boolean;
}) => {
  return professionals?.filter((el) =>
    el.enabled && !el.has_no_schedule && filter_professional_by_day
      ? el.professional_timetable.find(
          (s: any) =>
            s.id_office === office &&
            (!dayOfWeekToFilter || s.day === dayOfWeekToFilter) &&
            (id_specialty === -1 ||
              s.id_specialty === id_specialty ||
              !s.id_specialty) &&
            (ignoreDateRange ||
              compareDatesRanges({
                from: s.date_from,
                to: s.date_to,
                date,
              }))
        )
      : el.enabled && !el.has_no_schedule
  );
};

export const professionalsByOffice = ({
  id_office,
  professionals,
}: {
  id_office?: number;
  professionals: Professional[];
}) => {
  const ENABLED_PROFESSIONALS = enabledProfessionals(professionals);

  return ENABLED_PROFESSIONALS?.filter((p: Professional) => {
    // Agrego la validacion para los profs que son guardia,
    // solamente para salir del paso, revisemos si esta ok!
    if (id_office && id_office !== -1) {
      const professionals_office = p.professional_timetable.some(
        (prof: { [key: string]: any }) =>
          prof.office_navigation?.id_office === id_office
      );

      return p.enabled && professionals_office;
    }

    return p.enabled;
  });
};
export const filterDaysProfessional = ({
  currentProfessional,
  date,
  id_specialty,
  id_office,
}: {
  currentProfessional: Professional | null;
  date: Date;
  id_specialty: number | null;
  id_office: number | null;
}) => {
  if (!currentProfessional?.professional_timetable?.length) {
    return [];
  }

  const filterByDateRange = (s: any) =>
    !s.date_from ||
    !s.date_to ||
    compareDatesRanges({
      from: s.date_from,
      to: s.date_to,
      date,
    });

  const filterBySpecialty = (s: any) =>
    id_specialty === -1 || s.id_specialty === id_specialty || !s.id_specialty;

  const filterByOffice = (s: any) => !id_office || s.id_office === id_office;

  const professionalDays = currentProfessional.professional_timetable.filter(
    (s: any) =>
      filterByDateRange(s) && filterBySpecialty(s) && filterByOffice(s)
  );

  return professionalDays.map((s: any) => s.day);
};
