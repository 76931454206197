import { EmptyState } from "@/components/molecules";
import { Laboratory } from "@/types/general";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{ data: Laboratory[] }> = ({ data }) => {
  return !data || !data.length ? (
    <div className="grid grid-cols-12 gap-x-10">
      <EmptyState
        title="¡Ups!"
        copy="Parece que no hay laboratorios"
        className="col-span-6 !justify-start flex col-start-4"
      />
    </div>
  ) : (
    <Data data={data} columns={columns} />
  );
};
