import React from "react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Input,
} from "@/components/atoms";
import { LuSettings2 } from "react-icons/lu";

export const Filter: React.FC<{ table: any; name: string }> = ({
  table,
  name,
}) => {
  const column = React.useMemo(() => table.getColumn(name), [table, name]);
  return (
    <div className="realtive">
      <Input
        className="h-10"
        placeholder="Buscar historia clínica..."
        value={column?.getFilterValue() ?? ""}
        onChange={(e) => column?.setFilterValue(e.target.value)}
      />
    </div>
  );
};

export const Visibility: React.FC<{ table: any }> = ({ table }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <p
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-visibility"
        >
          <LuSettings2 className="w-5 h-5" />
          Columnas
        </p>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table
          .getAllColumns()
          .filter((column: any) => column.getCanHide())
          .map((column: any) => (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize"
              checked={column.getIsVisible()}
              onCheckedChange={(value) => column.toggleVisibility(!!value)}
            >
              {column.columnDef.header}
            </DropdownMenuCheckboxItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
