import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_benefit: z.coerce.number(),
  id_plan: z.coerce.number(),
  id_group: z.coerce.number().nullable(),
  id_benefit_to_be_performed: z.null(),
  id_specialty: z.coerce.number().nullable(),
  benefit_code: z
    .string()
    .min(1, {
      message: "El código es requerido",
    })
    .trim()
    .transform((val) => val.replace(/\s+/g, "")),
  code_benefit_med_ins_liq: z
    .string()
    .min(1, {
      message: "El código es requerido",
    })
    .trim()
    .transform((val) => val.replace(/\s+/g, "")),
  description: z
    .string()
    .min(1, {
      message: "La descripción es requerida",
    })
    .max(50, {
      message: "No puede contener más de 50 caracteres",
    }),
  amount_mi: z.coerce.number(),
  amount_patient: z.coerce.number(),
  drawing: z.string(),
  tooth: z.string(),
  requires_face: z.boolean(),
  internal: z.boolean(),
  disabled: z.boolean(),
  disabled_group_belongs: z.boolean(),
  disabled_drawing: z.boolean(),
  disabled_specialties: z.boolean(),
  unit: z.string(),
  quantity: z.coerce.number().optional(),
  is_odontogram: z.boolean(),
  age_text: z.string(),
  age_number: z.coerce.number().optional(),
  per_patient: z.coerce.number(),
  per_piece_sector: z.coerce.number(),
  rowversion: z.string().nullable(),
  is_usd: z.boolean(),
  id_specialty_navigation: z.null(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
