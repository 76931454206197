import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
  Input,
  Combobox,
} from "@/components/atoms";
import { Form, Offices, Professional } from "@/types/general";
import { Patient, Prescription } from "@/types/patients";
import {
  CheckboxForm,
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Financier } from "@/types/recipes";
import { formSchema } from "../schema";

export const First: React.FC<{
  data: {
    financiers: Financier[];
    offices: Offices[];
    professionals: Professional[];
    patient: Patient;
    recipe: Prescription;
  };
  onCancel: () => void;
  resetProgressBar?: () => void;
  viewLoadMedicine?: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar?: (value: number) => void;
  onSubmit?: (data: FormData) => void;
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({ data, form, setStep, onCancel }) => {
  const { id_professional } = form.watch();
  const { is_usd, description } = form.getValues();

  return (
    <Card className="!rounded-t-none">
      <CardHeader className="!px-0 pt-2">
        <CardTitle>Datos del presupuesto</CardTitle>
        <CardDescription>
          Carga los datos referidos a la identificación del presupuesto.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5 !p-0">
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Descripción</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-3 self-end">
          <DatePicker
            form={form}
            name="date"
            label="Fecha"
            dateformat="dd/MM/yyyy"
          />
        </div>
        <Combobox
          form={form}
          data={data.professionals}
          dataKey="id_professional"
          value="name"
          placeholder="Busca un profesional"
          title="profesional"
          className="col-span-3 !h-10"
          classContent="!w-[300px]"
          label="Profesional"
        />
        <CheckboxForm
          classname="col-span-3"
          label="Es USD"
          subLabel="Los importes se cargan en USD"
          onCheckedChange={(check: boolean) => {
            form.setValue("is_usd", check);
          }}
          checked={is_usd}
        />
        {data.offices?.length > 0 && (
          <Combobox
            form={form}
            data={data.offices}
            dataKey="id_office"
            value="name"
            placeholder="Busca una sucursal"
            title="sucursal"
            className="col-span-3"
            classContent="!w-[300px]"
            label="Sucursal"
          />
        )}
        <div className="col-span-full space-x-4 flex justify-end">
          <Button type="button" variant="destructive" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={() => setStep(1)}
            disabled={!description || !id_professional}
          >
            Siguiente
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
