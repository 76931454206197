import React from "react";
import { z } from "zod";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { Form } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table = <TData,>({
  data,
  action,
  form,
}: {
  data: TData[];
  action: (
    row: z.infer<typeof formSchema>["order_from_list"],
    key: string
  ) => void;
  form: Form<typeof formSchema>;
}) => (
  <div className="mx-auto w-full">
    <Data columns={Columns({ action })} data={data} form={form} />
  </div>
);
