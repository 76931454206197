import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  add_diagnosis: z.boolean(),
  add_general_diagnosis: z.boolean(),
  is_manual_diagnosis: z.boolean(),
  full_name: z.string(),
  id_financier: z.number(),
  plan: z.string(),
  document: z.coerce.number().min(6, {
    message: "El documento debe tener al menos 6 números.",
  }),
  id_professional: z.number(),
  date_of_recipe: z.date(),
  id_office: z.number().optional().nullable(),
  affiliate_number: z.string(),
  general_diagnosis: z.object({
    description: z.string(),
    code: z.string(),
    id_diagnosis: z.number(),
    is_manual: z.boolean(),
  }),
  list_medications: z.array(
    z.object({
      code: z.string().nullable(),
      product_name: z.string(),
      presentation: z.string(),
      id_medicine_recipe: z.number(),
      quantity: z.number().int().min(1),
      only_generic: z.boolean(),
      not_replace: z.boolean(),
      diagnosis: z.object({
        description: z.string(),
        code: z.string(),
        id_diagnosis: z.number(),
      }),
      observations: z.string(),
      prolonged_treatment: z.boolean(),
      dosage: z.string(),
      has_coverage: z.boolean(),
      requires_approval: z.boolean(),
      discount: z.number(),
      is_psychopharmaceutical: z.boolean(),
      is_narcotic: z.boolean(),
      is_controlled_sale: z.boolean(),
      is_for_hiv: z.boolean(),
      requires_duplicate: z.boolean(),
      drug_name: z.string(),
      register_number: z.string(),
    })
  ),
  selected_medication: z.object({
    code: z.string().nullable(),
    product_name: z.string(),
    presentation: z.string(),
    id_medicine_recipe: z.number(),
    quantity: z.number().int().min(1),
    only_generic: z.boolean(),
    not_replace: z.boolean(),
    diagnosis: z.object({
      description: z.string(),
      code: z.string(),
      id_diagnosis: z.number(),
    }),
    observations: z.string(),
    prolonged_treatment: z.boolean(),
    dosage: z.string(),
    has_coverage: z.boolean(),
    requires_approval: z.boolean(),
    discount: z.number(),
    is_psychopharmaceutical: z.boolean(),
    is_narcotic: z.boolean(),
    is_controlled_sale: z.boolean(),
    is_for_hiv: z.boolean(),
    requires_duplicate: z.boolean(),
    drug_name: z.string(),
    register_number: z.string(),
  }),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
