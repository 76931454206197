import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_expense: z.number(),
  id_office: z.number().nullable().optional(),
  id_bank: z.number().nullable().optional(),
  id_supplier: z.number().nullable(),
  id_user: z.number().optional(),
  id_professional_paying: z.number().nullable().optional(),
  id_laboratory_paying: z.number().nullable().optional(),
  id_supplier_navigation: z.null().optional(),
  date: z.date(),
  voucher: z.string(),
  voucher_type: z.string(),
  voucher_number: z.string().optional(),
  payment_method: z.string().nullable().optional(),
  show_in_cashflow: z.boolean(),
  disabled: z.boolean(),
  is_supplier_payment: z.boolean(),
  total: z.coerce
    .number({
      required_error:
        "La factura a proveedor debe tener por lo menos un importe.",
    })
    .min(1, "La factura a proveedor debe tener por lo menos un importe."),
  concept: z.string().optional(),
  details: z.string().optional(),
  net_taxed: z.coerce.number().optional(),
  net_other: z.coerce.number().optional(),
  iva_21: z.coerce.number().optional(),
  iva_other: z.coerce.number().optional(),
  percep: z.coerce.number().optional(),
  exempt: z.coerce.number().optional(),
  others: z.coerce.number().optional(),
  is_usd: z.boolean().optional(),
  type: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
