"use client";

import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { EmptyState } from "@/components/molecules";
import { Default as GeistTable } from "@/components/molecules/geist";
import { MedicalInsurance } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  form: any;
  data: MedicalInsurance[];
}> = ({ form, data }) => {
  const {
    loading,
    general_params: { translations },
  } = useGlobalStore();

  return loading ? (
    <div className="mx-auto mt-4">
      <GeistTable />
    </div>
  ) : data?.length > 0 ? (
    <Data columns={Columns()} data={data} form={form} />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy={`No se han encontrado ${translations?.medical_insurances}.`}
    />
  );
};
