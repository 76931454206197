"use client";

import React from "react";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
} from "react-icons/hi";
import { useDialog } from "@/hooks";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
} from "@/components/atoms";
import {
  NewMedicalInsuranceGroupsForm,
  DeleteGroupForm,
} from "@/components/organisms/forms";
import { MedicalInsurance, Group } from "@/types/general";

type RowDataT = {
  original: any;
};

const GroupsDropdown = ({
  selected,
  medicalInsurance,
}: {
  selected: Group;
  medicalInsurance: MedicalInsurance;
}) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() =>
              openDialog({
                content: (
                  <NewMedicalInsuranceGroupsForm
                    selected={selected}
                    medicalInsurance={medicalInsurance}
                  />
                ),
              })
            }
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() =>
              openDialog({
                content: (
                  <DeleteGroupForm
                    selected={selected}
                    medicalInsurance={medicalInsurance}
                  />
                ),
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = ({ selected }: { selected: MedicalInsurance }) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <GroupsDropdown selected={row.original} medicalInsurance={selected} />
      ),
    },
    {
      accessorKey: "code_group",
      header: "Código",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { code_group } = row.original;

        return <Badge className="w-auto 2xl:ml-3">{code_group}</Badge>;
      },
    },
    {
      accessorKey: "description",
      header: "Descripción",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { description } = row.original;

        return (
          <p className="font-semibold max-w-48 text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
            {description}
          </p>
        );
      },
    },
  ];
};
