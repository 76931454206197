"use client";

import React from "react";
import { EmptyState } from "@/components/molecules";
import { Offices } from "@/types/general";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data: any;
  section: "patient_payments" | "incomes" | "expenses";
  offices: Offices[];
}> = ({ data, section, offices }) => {
  return data?.data?.length > 0 ? (
    <Data
      section={section}
      columns={columns({
        column: section,
        config: {
          offices,
        },
      })}
      data={{
        data: data?.data,
        totals: {
          total_amount: data.totals.total_amount,
          owed: data.totals.owed,
          paid: data.totals.paid,
        },
      }}
    />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
