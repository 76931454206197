import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  webuser: z
    .string()
    .min(1, {
      message: "El usuario Bilog es requerido.",
    })
    .max(225),
  user: z
    .string()
    .min(1, {
      message: "El nombre de usuario es requerido.",
    })
    .max(225),
  password: z
    .string()
    .min(1, {
      message: "La contraseña es requerida.",
    })
    .max(225),
  keep_logged: z.boolean().default(false),
};

export const formSchema = z.object(schema);

export const resolver = {
  resolver: zodResolver(formSchema),
  defaultValues: {
    webuser: "",
    user: "",
    password: "",
    keep_logged: false,
  },
};
