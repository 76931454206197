import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  Input,
  Button,
  CardContent,
} from "@/components/atoms";
import {
  CheckboxForm,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { SearchDiagnosis } from "../load-medicine/search-diagnosis";

export const Third: React.FC<{
  setStep: (value: number) => void;
  form: any;
}> = ({ setStep, form }) => {
  const { add_general_diagnosis, general_diagnosis } = form.watch();

  return (
    <Card className="rounded-t-none">
      <CardHeader>
        <CardTitle>Datos adicionales</CardTitle>
        <CardDescription>
          Podrá incluir un diagnóstico general para el tratamiento del paciente.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <CheckboxForm
          classname="col-span-3"
          label="Agregar diagnóstico"
          subLabel="Podrá incluir un diagnóstico para este medicamento."
          onCheckedChange={(check: boolean) => {
            form.setValue("add_general_diagnosis", check);
            if (!add_general_diagnosis) {
              form.setValue("general_diagnosis", {
                description: "",
                code: "",
                id_diagnosis: -1,
                is_manual: false,
              });
            }
          }}
          checked={add_general_diagnosis}
        />
        <CheckboxForm
          classname="col-span-3"
          label="Diagnóstico manual"
          subLabel="Cargar la información de forma manual."
          onCheckedChange={(check: boolean) => {
            form.setValue("general_diagnosis", {
              description: "",
              code: "",
              id_diagnosis: -1,
              is_manual: false,
            });
            form.setValue("general_diagnosis.is_manual", check);
          }}
          checked={general_diagnosis.is_manual}
          disabled={!add_general_diagnosis}
        />
        {general_diagnosis.is_manual ? (
          <FormField
            control={form.control}
            name="general_diagnosis.description"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Diagnóstico</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    disabled={!add_general_diagnosis}
                    placeholder="Escribe aquí el diagnóstico"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <SearchDiagnosis
            form={form}
            name="general_diagnosis"
            label="Diagnóstico"
            placeholder="Escribe aquí el diagnóstico"
            disabled={!add_general_diagnosis}
          />
        )}
        <div className="col-span-full flex justify-end">
          <Button
            onClick={() => {
              setStep(3);
            }}
          >
            Siguiente
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
