import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  date_from: z.date(),
  date_last_close: z.date().nullable(),
  balance_local_currency: z.coerce.number(),
  balance_usd_currency: z.coerce.number(),
  id_office: z.number().nullable(),
  id_user: z.number().nullable(),
  edit_date: z.date().nullable(),
  edit_balance_local_currency: z.coerce.number(),
  edit_balance_usd_currency: z.coerce.number(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
