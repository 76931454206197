"use client";

import { Dialog } from "@/components/atoms";
import { useDialog } from "@/hooks";

export const DialogMultiuse = () => {
  const { open, content, closeDialog } = useDialog();

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      {content}
    </Dialog>
  );
};
