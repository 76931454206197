import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  total_amount: z.number(),
  amount: z.number(),
  tax: z.number(),
  discount: z.number(),
  interest: z.number(),
  id_benefit: z.number(),
  todo_list: z.array(
    z.object({
      period: z.string().nullable(),
      date: z.string(),
      id_professional: z.number(),
      id_patient: z.number(),
      id_benefit: z.number(),
      id_user: z.number(),
      id_office: z.number().optional().nullable(),
      tooth_faces: z.object({
        v: z.boolean(),
        p: z.boolean(),
        o: z.boolean(),
        d: z.boolean(),
        m: z.boolean(),
        l: z.boolean(),
        i: z.boolean(),
        g: z.boolean(),
      }),
      tooth_section: z.string(),
      is_usd: z.boolean(),
      values_to_charge: z.object({
        from_medical_insurance: z.number().optional(),
        from_patient: z.number().optional(),
      }),
    })
  ),
  id_budget_benefit: z.number(),
};
export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
