import { z } from "zod";

export const createFormSchema = (whatRegion: string) => {
  return z
    .object({
      id_professional: z.number(),
      name: z.string().trim().min(1, "Campo requerido"),
      address: z.string().trim(),
      city: z.string().trim(),
      state: z.string().trim(),
      phone: z.string().trim(),
      mail: z
        .string()
        .trim()
        .optional()
        .refine((val) => !val || z.string().email().safeParse(val).success, {
          message: "Debe ser un correo electrónico válido",
        }),
      licence: z
        .string()
        .trim()
        .refine((val) => (whatRegion === "arg" ? val.length > 0 : true), {
          message: "El campo es obligatorio",
        }),
      comission: z.number(),
      comission_patient: z.number(),
      tax_number: z
        .string()
        .trim()
        .refine((val) => (whatRegion === "arg" ? val.length >= 11 : true), {
          message: "El campo debe contener al menos 11 caracteres",
        }),
      comission_payments: z.number(),
      comission_laboratory: z.number(),
      max_solp: z.number(),
      id_prefix: z.number(),
      professional_code: z.string().trim().min(1, "Campo requerido"),
      observations: z.string().trim(),
      enabled: z.boolean(),
      id_speciality_1: z.number().nullable(),
      id_speciality_2: z.number().nullable(),
      only_specialty: z.boolean(),
      abbreviation: z.string().trim(),
      liquidate_all: z.boolean(),
      shcedule_color: z.string().trim(),
      liquidate_code: z.string().trim(),
      id_tax_condition: z.number(),
      has_no_schedule: z.boolean(),
      is_guard: z.boolean(),
      rowversion: z.string().trim(),
      profession: z
        .string()
        .trim()
        .refine((val) => (whatRegion === "arg" ? val.length >= 5 : true), {
          message: "El campo es obligatorio",
        }),
      id_licence_type: z
        .number()
        .refine((val) => (whatRegion === "arg" ? val > 0 : true), {
          message: "El campo es obligatorio",
        }),
      id_province: z.number().nullable(),
      signature: z.any().nullable().optional(),
      option: z.string().trim(),
      professional_timetable: z.array(z.any()),
      professional_values: z.array(z.any()),
      professional_absences: z.array(z.any()),
      appointments: z.any(),
    })
    .superRefine((data, ctx) => {
      if (
        whatRegion === "arg" &&
        data.id_licence_type === 2 &&
        !data.id_province
      ) {
        ctx.addIssue({
          path: ["id_province"],
          code: z.ZodIssueCode.custom,
          message: "El campo es obligatorio",
        });
      }
    });
};
