import { Translations } from "@/types/general";

const defaultTranslations = {
  medical_insurances: "Seguros médicos",
  medical_insurance: "Seguro médico",
  medical_insurance_abbreviation: "S.M.",
  all_medical_insurance_abbreviation: "Todos los S.M.",
  medical_insurance_data: "Datos del seguro médico",
  all_medical_insurances: "Todos los seguros médicos",
  select_medical_insurance: "El seguro médico",
  see_only: "Ver solo habilitados",
  cod_mi: "Cod. S.M.",
  amount_mi: "Imp SM",
  taxes: "RUC",
  money: "$",
  doc_types: [
    { id: "CI", text: "Cédula de identidad" },
    { id: "LC", text: "Libreta Cívica" },
    { id: "LE", text: "Libreta Enrolamiento" },
    { id: "PAS", text: "Pasaporte" },
    { id: "OTR", text: "Otro" },
  ],
  config: {
    med_ins_and_benefits: "Seguros médicos y prestaciones",
    med_ben_text:
      "Dar de alta un nuevo seguro médico, modificar sus planes, grupos y prestaciones.",
    new_med_ins: "Nuevo seguro médico",
    edit_med_ins: "Modificar seguro médico",
  },
};

export const translations: {
  [key: string]: Translations;
} = {
  pry: {
    ...defaultTranslations,
    money: "₲",
  },
  arg: {
    medical_insurances: "Obras sociales",
    medical_insurance: "Obra social",
    medical_insurance_abbreviation: "O.S.",
    medical_insurance_data: "Datos de la obra social",
    all_medical_insurances: "Todas las obras sociales",
    all_medical_insurance_abbreviation: "Todas las O.S.",
    select_medical_insurance: "La obra social",
    see_only: "Ver solo habilitadas",
    cod_mi: "Cod. O.S.",
    amount_mi: "Imp OS",
    taxes: "CUIT",
    money: "$",
    doc_types: [
      { id: "DNI", text: "DNI" },
      { id: "LC", text: "LC" },
      { id: "LE", text: "LE" },
      { id: "PAS", text: "PAS" },
      { id: "OTR", text: "Otro" },
    ],
    config: {
      med_ins_and_benefits: "Obras sociales y prestaciones",
      med_ben_text:
        "Dar de alta una nueva obra social, modificar sus planes, grupos y prestaciones.",
      new_med_ins: "Nueva obra social",
      edit_med_ins: "Modificar obra social",
    },
  },
  default: defaultTranslations,
};
