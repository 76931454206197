import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  name: z
    .string()
    .max(40, { message: "El nombre no puede superar los 40 caracteres" })
    .min(1, { message: "El nombre no puede quedar vacio." }),
  is_manual: z.boolean(),
  affiliate_number: z.string(),
  phone: z.string().optional(),
  observation: z
    .string()
    .max(150, { message: "La observación no puede superar los 150 caracteres" })
    .optional(),
  medical_insurance: z.string().optional(),
  medical_insurance_plan: z.string().optional(),
  id_professional: z.number().optional(),
  id_med_ins: z.number().nullable().optional(),
  id_plan: z.number().nullable().optional(),
  attendance: z.string().nullable().optional(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
