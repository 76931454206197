"use client";

import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { toast } from "@/hooks";
import { deleteBudget } from "@/actions/budgets";

export const Delete = ({ onChange }: { onChange: () => void }) => {
  const { budget_selected } = useGlobalStore();
  const handleSubmit = async () => {
    try {
      await deleteBudget(budget_selected);
      toast({
        variant: "successful",
        title: "Presupuesto eliminada con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      onChange();
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    }
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Aviso</DialogTitle>
        <DialogDescription>
          Estás por eliminar un presupuesto y esta acción es irreversible, el
          presupuesto se eliminará definitivamente.
        </DialogDescription>
      </DialogHeader>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button variant="destructive">Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSubmit}>Eliminar presupuesto</Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
