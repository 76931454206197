import React from "react";
import { Button, Dialog, DialogClose, DialogContent } from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";

export const HirePackage = () => {
  const {
    user: { web_user },
  } = useGlobalStore();

  return (
    <DialogContent>
      <span>
        <strong className="text-slate-900">Contratar Pack de Recetas</strong>
        <br />
        <br /> Adquiriendo el pack de recetas tendrás accesos a: <br />
        <strong className="text-slate-900">
          • Realizar recetas sin límites.
          <br />• Enviar a tus pacientes sus recetas.
        </strong>
      </span>
      <span>
        💊 Realiza recetas farmacológicas con facilidad.
        <br /> 📩 Envía las recetas a tus pacientes.
        <br /> 👌🏼 Configura tus permisos y datos en la sección de configuración.
      </span>
      <span>Disfruta de todas las funcionalidades de Recetas sin límites.</span>
      <div className="flex items-center gap-x-4">
        <Button
          onClick={() => {
            window.open(
              `https://wa.me/+5491128504255?text=Hola!%20quisiera%20contratar%20el%20servicio%20de%20recetas.%20Mi%20usuario%20Bilog%20es:%20${web_user}`,
              "_blank"
            );
          }}
        >
          Contratar ahora
        </Button>
        <DialogClose>
          <Button variant="destructive">Cancelar</Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
