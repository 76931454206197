"use client";

import { usePathname } from "next/navigation";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/atoms";
import { getBreadcrumb } from "@/utils/breadcrumbs";

export const PathBreadcrumb = () => {
  const pathname = usePathname();
  const breadcrumb = getBreadcrumb(pathname);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumb.map((item, index) => (
          <BreadcrumbItem key={index}>
            {item.isPage ? (
              <BreadcrumbPage className="max-w-20 truncate md:max-w-none">
                {item.title}
              </BreadcrumbPage>
            ) : (
              <>
                <BreadcrumbLink className="max-w-20 truncate md:max-w-none">
                  {item.title}
                </BreadcrumbLink>
                {index < breadcrumb.length - 1 && <BreadcrumbSeparator />}
              </>
            )}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
