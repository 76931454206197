"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import classNames from "classnames";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  Checkbox,
  Input,
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  DialogClose,
  Label,
  Badge,
} from "@/components/atoms";
import {
  Form,
  FormControl,
  DatePicker,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ProfessionalsSelect,
  BenefitsSelect,
  ButtonLoading,
  TooltipComponent,
} from "@/components/molecules";
import { toast, useKeypress, useStateAcordeon } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { Benefit, Offices } from "@/types/general";
import { enabledOffices } from "@/utils/mappers";
import { getSelectedFaces } from "@/utils/helpers";
import { createBenefit, updateBenefit } from "@/actions/patient-benefits";
import { validationToothSection } from "@/utils/benefits";
import { professionalsByOffice } from "@/utils/filters";
import { ValuesToPay } from "./values-to-pay";
import { formSchema, resolver } from "./schema";
import { ToothFaces } from "./tooth-faces";

export const BenefitsPatientForm = ({
  onCancel,
  offices,
  benefits,
  onChange,
  compact,
  tooth,
}: {
  onCancel: (value: string) => void;
  offices: Offices[];
  benefits: Benefit[];
  onChange: () => void;
  compact?: boolean;
  tooth?: string;
}) => {
  const {
    general_params: { user_params, translations, professionals },
    user,
    patient,
    permissions,
    patient_benefits: { benefit_selected },
    ref_header,
    loading,
    startLoading,
    stopLoading,
    updatePatient,
  } = useGlobalStore();
  const textField = React.useRef<HTMLInputElement | null>(null);
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const ENABLED_OFFICES = enabledOffices(offices);
  const ENABLED_PROFESSIONALS = professionalsByOffice({
    id_office: ENABLED_OFFICES?.[0]?.id_office || -1,
    professionals,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_benefit: benefit_selected?.id_benefit,
      date: benefit_selected?.date
        ? new Date(benefit_selected?.date)
        : new Date(),
      period: benefit_selected?.period || "",
      observations: benefit_selected?.observation || "",
      tooth_section: benefit_selected?.tooth_sector || tooth || "",
      tooth_face: (benefit_selected?.face ?? "").split("").reduce(
        (acc: any, face: string) => ({
          ...acc,
          [face.toLowerCase()]: true,
        }),
        {}
      ),
      amount_med_ins: benefit_selected?.id_benefit_navigation?.amount_mi || 0,
      amount_patient:
        benefit_selected?.amount_patient ||
        benefit_selected?.id_benefit_navigation?.amount_patient ||
        0,
      change_amount_professional: false,
      is_usd: !!benefit_selected?.is_usd || false,
      id_patient: patient.id_patient,
      id_office: benefit_selected?.id_office || offices?.[0]?.id_office || null,
      id_user: user.id_user,
      id_professional:
        benefit_selected?.id_professional ||
        ENABLED_PROFESSIONALS?.[0]?.id_professional,
    },
  });

  const { id_benefit } = form.watch();
  const SHOW_CARD_VALUES_BENEFITS =
    user?.is_supervisor || permissions.hasOwnProperty("Ver Importes Prest.");
  const BENEFIT_STATE = React.useMemo(() => {
    const FILTER_BENEFIT =
      benefits?.find((b: Benefit) => b.id_benefit === id_benefit) || null;

    return FILTER_BENEFIT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_benefit]);

  const DISABLED = React.useMemo(() => {
    return (
      !user.is_supervisor &&
      (!form.watch("id_professional") ||
        !BENEFIT_STATE ||
        !permissions["Modificar Prestación"])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("id_professional"), BENEFIT_STATE]);
  const MONEY_TYPE = form.watch("is_usd") ? "USD" : "Moneda local";

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();

    if (benefit_selected) {
      const response = await updateBenefit({
        ...values,
        tooth_face: getSelectedFaces(values?.tooth_face || null),
        rowversion: benefit_selected?.rowversion,
        id_all: benefit_selected.id_all,
      });

      if (response?.error) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response?.error,
        });
      } else {
        onChange();
        toast({
          variant: "successful",
          title: "Prestacion actualizada con exito",
          description: "Los datos se veran reflejados en la tabla.",
        });
        if (response?.account) {
          updatePatient("balance_usd", response?.account?.balance_usd);
          updatePatient("balance", response?.account?.balance);
        }
        onCancel("tables");
      }
    } else {
      const response = await createBenefit({
        values: {
          ...values,
          tooth_face: getSelectedFaces(values?.tooth_face || null),
        },
      });

      if (response?.error) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response?.error,
        });
        stopLoading();
        return;
      } else {
        toast({
          variant: "successful",
          title: "Prestacion creada con exito",
          description: "Los datos se veran reflejados en la tabla.",
        });
        if (response?.account) {
          updatePatient("balance_usd", response?.account?.balance_usd);
          updatePatient("balance", response?.account?.balance);
        }
        onCancel("tables");
      }
    }

    onChange();
    stopLoading();
  }

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form
        className={classNames(
          "w-full grid grid-cols-12 gap-5 overflow-y-auto",
          STATE_ACCORDION !== "open"
            ? "h-[calc(100vh-205px)]"
            : "h-[calc(100vh-268px)]"
        )}
      >
        <Card
          className={classNames("col-span-6", {
            "col-span-full border-none !p-0": compact,
          })}
        >
          <CardHeader>
            <CardTitle>
              {benefit_selected ? "Modificar prestación" : "Nueva prestación"}
            </CardTitle>
            <CardDescription>
              Cargá todos los datos referidos a la prestación.
            </CardDescription>
          </CardHeader>
          <CardContent className="col-span-full grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <DatePicker
                name="date"
                form={form}
                label="Fecha"
                dateformat="P"
              />
            </div>
            <ProfessionalsSelect
              offices={ENABLED_OFFICES}
              professionals={professionals}
              form={form}
              classname="col-span-4"
            />
            {patient?.plan_navigation?.medical_insurance_navigation
              ?.load_for_period && (
              <FormField
                control={form.control}
                name="period"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <Label>Período</Label>
                    <FormControl>
                      <Input type="text" {...field} ref={textField} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <BenefitsSelect
              benefits={benefits}
              form={form}
              classname="!w-full lg:!max-w-screen-lg col-span-full"
              professionals={professionalsByOffice({
                id_office: form.watch("id_office") || -1,
                professionals,
              })}
              useUsd={{
                params: user_params?.use_usd,
                user: user_params?.use_usd,
              }}
            />
            <FormField
              control={form.control}
              name="observations"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>Observaciones</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} ref={textField} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tooth_section"
              disabled={validationToothSection(BENEFIT_STATE)}
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Diente/Sector</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} ref={textField} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ToothFaces
              checksCss="col-span-8"
              form={form}
              name="tooth_face"
              benefit={BENEFIT_STATE}
            />
          </CardContent>
        </Card>
        {SHOW_CARD_VALUES_BENEFITS && !compact && (
          <Card className="col-span-6 2xl:col-span-6 p-5">
            <CardHeader>
              <CardTitle>Valores a cobrar de la prestación</CardTitle>
              <CardDescription>
                Definí los valores a cobrar de la prestación
              </CardDescription>
            </CardHeader>
            <CardContent className="grid grid-cols-12 gap-6">
              <FormField
                control={form.control}
                name="amount_med_ins"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>
                      Importe a cargo del/la{" "}
                      {translations.medical_insurance_abbreviation}
                    </FormLabel>
                    <FormControl>
                      <Input disabled type="text" {...field} ref={textField} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="amount_patient"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>Importe a cargo del paciente. </FormLabel>
                    <FormControl>
                      <Input type="text" {...field} ref={textField} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {user_params.use_usd && user.use_usd && (
                <TooltipComponent
                  classname="col-span-4 h-5"
                  trigger={
                    <Badge className="!rounded-md h-5">{MONEY_TYPE}</Badge>
                  }
                  content={
                    <span className="!font-bold !text-slate-900">
                      {`Los importes se cargarán en ${MONEY_TYPE}.`}
                    </span>
                  }
                />
              )}
            </CardContent>
            <CardHeader>
              <CardTitle>Valores a pagar de la prestación</CardTitle>
              <CardDescription>
                Definí los valores a pagar de la prestación
              </CardDescription>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="change_amount_professional"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-full">
                    <FormControl>
                      <Checkbox
                        disabled={DISABLED}
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          if (!checked) {
                            form.setValue("amount_to_pay_med_ins", 0);
                            form.setValue("amount_to_pay_patient", 0);
                            form.setValue(
                              "amount_to_pay_med_ins_percentage",
                              0
                            );
                            form.setValue(
                              "amount_to_pay_patient_percentage",
                              0
                            );
                          }
                          field.onChange(checked);
                        }}
                      />
                    </FormControl>
                    <div className="leading-none flex flex-col">
                      <FormLabel>
                        Modificar valores a pagar al profesional
                      </FormLabel>
                      <p className="text-sm font-regular text-gray-400">
                        Estos son los valores que se usaran para la liquidación
                        del profesional
                      </p>
                    </div>
                  </FormItem>
                )}
              />
              <ValuesToPay form={form} />
            </CardContent>
          </Card>
        )}
      </form>
      <div className="flex mt-4 space-x-5 justify-end w-full">
        {compact ? (
          <DialogClose>
            <Button
              className="col-start-9 col-span-2 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={() => onCancel("tables")}
            >
              Cancelar
            </Button>
          </DialogClose>
        ) : (
          <Button
            className="col-start-9 col-span-2 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
            onClick={() => onCancel("tables")}
          >
            Cancelar
          </Button>
        )}
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            onClick={form.handleSubmit(onSubmit)}
            className="h-10 hover:bg-blue-600 col-span-2"
            disabled={!id_benefit}
          >
            Guardar prestación
          </Button>
        )}
      </div>
    </Form>
  );
};
