import React from "react";
import classNames from "classnames";
import { MedicalInsurance, Plan, Professional } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { Button, CardTitle } from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { TAXES } from "@/libs/patients";
import { useStateAcordeon } from "@/hooks";
import { roundNumber } from "@/utils/helpers";
import { Header } from "./card-header";
import { SummarySteps } from "./card-summary";

export const Fourth: React.FC<{
  data: {
    medicalInsurances: MedicalInsurance[];
    professionals: Professional[];
  };
  onClick: () => void;
  viewLoadBenefit: {
    hiddenLoadBenefit: boolean;
    setHiddenLoadBenefit: React.Dispatch<React.SetStateAction<boolean>>;
  };
  setStep: (value: number) => void;
  onSubmit: () => void;
  form: any;
  resetSteps: () => void;
  onCancel: () => void;
  setActiveTab: (value: string) => void;
}> = ({
  setStep,
  data: { professionals, medicalInsurances },
  viewLoadBenefit,
  onSubmit,
  form,
  resetSteps,
  onCancel,
  setActiveTab,
}) => {
  const {
    id_professional,
    description,
    date,
    id_plan,
    id_med_ins,
    id_budget,
    discount,
    id_tax,
  } = form.getValues();
  const {
    list_benefits,
    total_amount_with_discount,
    interest_rate,
    balance,
    total_amount_benefit_list,
  } = form.watch();
  const {
    loading,
    budget_selected,
    ref_header,
    permissions,
    user: { is_supervisor },
  } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const professional_name: string =
    id_professional && professionals.length > 0
      ? professionals?.find((p: any) => p.id_professional === id_professional)
          ?.name || ""
      : "-";
  const medical_insurance = medicalInsurances?.find(
    (m: MedicalInsurance) => m.id_med_ins === id_med_ins
  );
  const plan = medical_insurance?.plans?.find(
    (m: Plan) => m.id_plan === id_plan
  )?.name;
  const SUMMARY_DATA = {
    header: {
      professional_name,
      id_budget,
      total_amount_with_discount,
      budget_balance: balance,
      description,
      viewLoadBenefit,
    },
    summary_steps: {
      professional_name,
      medical_insurance: medical_insurance?.medical_insurance || "-",
      plan,
      description,
      date,
    },
  };
  React.useEffect(() => {
    if (list_benefits?.length <= 0) {
      resetSteps();
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list_benefits]);

  React.useEffect(() => {
    const total_amount = list_benefits.reduce(
      (sum: number, benefit: any) => sum + (benefit.total_amount || 0),
      0
    );
    form.setValue("total_amount_benefit_list", total_amount);
    const TAX =
      TAXES.find(
        (tax: { id_tax: number; value: number; text: string }) =>
          tax.id_tax === id_tax
      )?.value || 0;
    const AMOUNT_WHITH_IVA = roundNumber(total_amount * (TAX / 100 + 1));
    const AMOUNT_WHITH_DISCOUNTS =
      discount?.type_discount === "percentage"
        ? AMOUNT_WHITH_IVA - AMOUNT_WHITH_IVA * (discount?.percentage / 100)
        : AMOUNT_WHITH_IVA - discount?.amount;
    const AMOUNT_WHITH_INTEREST = roundNumber(
      AMOUNT_WHITH_DISCOUNTS * (interest_rate / 100 + 1)
    );
    const { balance: initial_balance, total_amount_with_discount } =
      form?.formState.defaultValues;
    const PAID_AMOUNT = total_amount_with_discount - initial_balance;

    balance !== 0 &&
      form.setValue("balance", AMOUNT_WHITH_INTEREST - PAID_AMOUNT);
    form.setValue("total_amount_with_discount", AMOUNT_WHITH_INTEREST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list_benefits, total_amount_benefit_list]);

  return (
    <>
      <div
        className={classNames(
          "!rounded-t-none overflow-y-auto !w-full h-[calc(100vh-310px)]",
          {
            "h-[calc(100vh-375px)]":
              STATE_ACCORDION && STATE_ACCORDION !== "closed",
          }
        )}
      >
        <CardTitle
          className={classNames("text-lg mt-6 mb-2", {
            hidden: budget_selected,
          })}
        >
          Resumen del presupuesto cargado
        </CardTitle>
        <span>Revisa y edita la información del presupuesto.</span>
        {[Header, SummarySteps].map(
          (Component: React.ElementType, index: number) => (
            <div key={index} className="grid grid-cols-12 gap-4">
              <Component
                key={index}
                data={SUMMARY_DATA}
                setStep={setStep}
                setActiveTab={setActiveTab}
                form={form}
              />
            </div>
          )
        )}
      </div>
      <div className="mt-5 gap-5 flex justify-end">
        <Button onClick={onCancel} variant="destructive" type="button">
          Cancelar
        </Button>
        {budget_selected && (
          <Button
            disabled={
              id_budget &&
              !is_supervisor &&
              !permissions.hasOwnProperty("Imprimir Presupuesto")
            }
            onClick={() =>
              window.open(`/api/download-pdf?idBudget=${id_budget}`, "_blank")
            }
            variant="ghost"
            type="button"
          >
            Descargar PDF
          </Button>
        )}
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            type="button"
            onClick={onSubmit}
            disabled={
              !is_supervisor &&
              ((id_budget &&
                !permissions.hasOwnProperty("Modificar Presupuesto")) ||
                (!id_budget &&
                  !permissions.hasOwnProperty("Nuevo Presupuesto")))
            }
          >
            Guardar presupuesto
          </Button>
        )}
      </div>
    </>
  );
};
