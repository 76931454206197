import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_supplier: z.number(),
  id_professional: z.number(),
  id_laboratory: z.number(),
  name: z.string().min(1, {
    message: "El nombre es requerido",
  }),
  address: z.string().max(80, {
    message: "La dirección no puede exceder los 80 caracteres",
  }),
  city: z.string().max(80, {
    message: "La Localidad no puede exceder los 80 caracteres",
  }),
  phone: z.string(),
  fax: z.string(),
  email: z.string().max(50, {
    message: "El mail no puede exceder los 50 caracteres",
  }),
  contact: z.string(),
  balance: z.coerce.number(),
  tax_number: z.string(),
  observation: z.string(),
  enabled: z.boolean(),
  rowversion: z.string().nullable(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
