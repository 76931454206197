"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
  Checkbox,
} from "@/components/atoms";
import { toast, useKeypress, useDialog } from "@/hooks";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { setBanks } from "@/actions/administration";
import { getTableParams } from "@/actions/configs";
import { useGlobalStore } from "@/providers/global-provider";
import { BankDB } from "@/types/general";
import { resolver, formSchema } from "./schema";

export function NewBankForm({ bank }: { bank?: z.infer<typeof formSchema> }) {
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_bank: bank?.id_bank || 0,
      name: bank?.name || "",
      account_number: bank?.account_number || "",
      description: bank?.description || "",
      initial_balance: bank?.initial_balance || 0,
      enabled: bank?.enabled || true,
      rowversion: bank?.rowversion || null,
    },
  });
  const { startLoading, stopLoading, setDataAdministration } = useGlobalStore();
  const BANK_EXIST = !!bank?.id_bank;

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setBanks(values);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const { banks }: { banks: BankDB } = await getTableParams(["BANCOS"]);

      setDataAdministration({
        data: { data: banks },
        section: "accounts",
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El banco se ha guardado correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent className="w-1/2">
      <DialogHeader>
        <DialogTitle>
          {BANK_EXIST ? "Modificar banco" : "Nuevo banco"}
        </DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-12 gap-x-4 gap-y-6"
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Nombre del banco</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="account_number"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Cuenta N°</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Descripción</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="initial_balance"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Saldo inicial</FormLabel>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enabled"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-12 self-start">
                <FormControl>
                  <Checkbox
                    checked={field.value as boolean}
                    onCheckedChange={(checked) => field.onChange(checked)}
                  />
                </FormControl>
                <div className="leading-none mt-0 flex flex-col">
                  <FormLabel className="m-0">Banco habilitado</FormLabel>
                  <FormDescription className="text-slate-500">
                    Si el banco se encuenta inhabilitado, no se podrá usar.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button
            type="button"
            onClick={() => form.reset()}
            variant="destructive"
          >
            Cancelar
          </Button>
        </DialogClose>
        <Button className="col-span-2" onClick={form.handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
