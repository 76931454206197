import { Columns } from "./columns";
import { Data } from "./data";
import { Patient } from "@/types/patients";

export const Table: React.FC<{
  data: Patient[];
  onSubmit: (value: any) => void;
  floating: boolean;
  sorting?: any;
  setSorting?: any;
}> = ({ data, onSubmit, floating, sorting, setSorting }) => {
  return (
    <Data
      columns={Columns({ floating })}
      data={data}
      onSubmit={onSubmit}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};
