"use client";

import React from "react";
import { ChartPie, Card, LineCharts } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { BudgetIssued } from "@/types/statistics";

export const BudgetsIssuedGraphics = ({
  data,
  device,
}: {
  data: BudgetIssued;
  device: string;
}) => {
  const {
    statistics: {
      budgets: { data: values, params },
    },
    setInitializedDataStatistics,
    general_params: { translations },
  } = useGlobalStore();

  React.useMemo(() => {
    setInitializedDataStatistics({ data, section: "budgets" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PIES_VALUES = React.useMemo(
    () => [
      {
        title: "Presupuestos aceptados y no aceptados",
        data: values?.percentage_by_use,
        data_key: "quantity",
        total_amount: values?.percentage_by_use?.reduce(
          (sum: number, item: any) => sum + (item.quantity || 0),
          0
        ),
      },
      {
        title: "Presupuestos por estado",
        data: values?.percentage_by_status,
        data_key: "quantity",
        total_amount: values?.percentage_by_status?.reduce(
          (sum: number, item: any) => sum + (item.quantity || 0),
          0
        ),
      },
      {
        title: `Presupuestos por ${
          params.group_by_med_ins
            ? translations.medical_insurance
            : "profesional"
        }`,
        data: values?.grouped?.[0]?.budgets_amount_data_group,
        data_key: "quantity",
        total_amount: values?.grouped?.[0]?.quantity,
      },
    ],
    [values?.percentage_by_use, values?.percentage_by_status, values?.grouped]
  );

  const total = values?.groupedByTime?.reduce(
    (sum: number, item: any) => sum + (item.quantity || 0),
    0
  );

  return (
    <div className="lg:grid grid-cols-12 gap-5 pb-10">
      <Card className="col-span-6 flex flex-col p-8 px-6">
        <h3 className="text-slate-800 text-lg font-bold">
          Presupuestos emitidos por cantidad y fecha
        </h3>
        {total && <p className="text-blue-500 text-lg">{`${total} Totales`}</p>}
        {values?.grouped_by_time ? (
          <LineCharts
            values={values?.grouped_by_time}
            height={300}
            Xkey="period"
            BarKey="quantity"
            legend="Presupuestos emitidos"
          />
        ) : (
          <EmptyState title="Ups!" copy="No hay datos para mostrar" />
        )}
      </Card>
      {PIES_VALUES.map((item: any, index: number) => (
        <Card className="col-span-6 flex flex-col p-8 px-6" key={index}>
          <h3 className="text-slate-800 text-lg font-bold">{item.title}</h3>
          {item.data ? (
            <>
              <p className="!text-blue-500 text-lg">
                {item.total_amount} Totales
              </p>
              <ChartPie
                values={item.data}
                dataKey={item.data_key}
                device={device}
              />
            </>
          ) : (
            <EmptyState title="Ups!" copy="No hay datos para mostrar" />
          )}
        </Card>
      ))}
    </div>
  );
};
