import React from "react";
import { Columns } from "./columns";
import { Data } from "./data";
import { Diagnosis } from "@/types/recipes";

export const Table: React.FC<{
  data: any[];
  onClick: (value: Diagnosis) => void;
  sorting: any;
  setSorting: any;
  height: string;
}> = ({ data, onClick, sorting, setSorting, height }) => {
  return (
    <div className="mx-auto w-full bg-white">
      <Data
        onClick={onClick}
        columns={Columns()}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        height={height}
      />
    </div>
  );
};
