"use client";

import React from "react";
import classNames from "classnames";
import { parseISO, getDay, addDays, isEqual } from "date-fns";
import { Button } from "@/components/atoms";
import { DAYS } from "@/libs/schedule";
import { Form, Professional } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/schedule/search/schema";
import { useDaysAvailability } from "@/hooks";
import { FormControl, FormField } from "@/components/molecules/form";

export const DaysOfWeek: React.FC<{
  professionals: Professional[];
  form: Form<typeof formSchema>;
  className?: string;
  extendSchedule: boolean;
}> = ({ professionals, form, className, extendSchedule }) => {
  const { date, day_of_week, option, id_layout } = form.getValues();
  const { AVAILABLE_OFFICE_PROF_DAYS, WORK_DAYS } = useDaysAvailability({
    professionals,
    form,
  });
  const DISABLED_BUTTONS = ["buscarsemana"].includes(option) || id_layout === 3;

  React.useEffect(() => {
    const dayOfWeek = getDay(date) === 0 ? 7 : getDay(date);
    if (dayOfWeek !== day_of_week) {
      form.setValue("day_of_week", dayOfWeek);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch(["date"])]);

  return (
    <FormField
      control={form.control}
      name="date"
      render={({ field }) => (
        <FormControl>
          <div
            className={classNames(
              "grid grid-cols-8 items-center border border-slate-200 bg-slate-200/40 rounded-md gap-x-1 p-1 h-9",
              className
            )}
          >
            <Button
              disabled={DISABLED_BUTTONS}
              className="h-6 text-xs hover:bg-slate-500 hover:text-white"
              onClick={() => field.onChange(new Date())}
            >
              Hoy
            </Button>
            {DAYS.map((day, index) => {
              const buttonCss = classNames(
                "h-6 text-xs hover:bg-slate-400 hover:text-slate-600",
                day_of_week === index + 1
                  ? "bg-slate-600 text-white"
                  : id_layout !== 2 &&
                    !AVAILABLE_OFFICE_PROF_DAYS.includes(index + 1) &&
                    !WORK_DAYS?.includes(index + 1)
                  ? "bg-slate-200 text-slate-400"
                  : "bg-green-200 text-green-800"
              );

              return (
                <Button
                  disabled={DISABLED_BUTTONS}
                  key={index}
                  variant="ghost"
                  className={buttonCss}
                  type="button"
                  onClick={() => {
                    const DATE_OF_WEEK = index + 1;
                    const DIFF_IN_DAYS = DATE_OF_WEEK - day_of_week;
                    const NEW_DATE = addDays(date, DIFF_IN_DAYS);

                    !isEqual(date, NEW_DATE) && field.onChange(NEW_DATE);
                    form.setValue("day_of_week", DATE_OF_WEEK);
                  }}
                >
                  {extendSchedule ? day : day[0]}
                </Button>
              );
            })}
          </div>
        </FormControl>
      )}
    />
  );
};
