import React from "react";
import { CardContent, Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  CheckboxForm,
} from "@/components/molecules";
import { Benefit, Form } from "@/types/general";
import { getMedicalInsuranceBenefits } from "@/actions/configs";
import { toast } from "@/hooks";
import { ButtonsBenefit } from "./save";
import { SearchBenefit } from "./search-benefit";
import { formSchema } from "../schema";

export const BenefitLoad: React.FC<{
  form: Form<typeof formSchema>;
  setHiddenLoadBenefit: (value: boolean) => void;
}> = ({ setHiddenLoadBenefit, form }) => {
  const { id_plan, selected_benefit, is_manual_benefit } = form.watch();
  const [benefits, setBenefits] = React.useState<any>([]);

  React.useEffect(() => {
    const fetchMedicalBenefits = async () => {
      const response = await getMedicalInsuranceBenefits("OSPRE", id_plan);

      if (response?.error) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response?.error,
        });
      } else {
        const mapped = response.benefits?.map((b: Benefit) => ({
          ...b,
          description: `${b.benefit_code} - ${b.description}`,
        }));

        setBenefits(mapped);
      }
    };

    void fetchMedicalBenefits();
  }, [id_plan]);

  return (
    <CardContent className="grid grid-cols-4 gap-5">
      <CheckboxForm
        label="Ingreso manual"
        subLabel="Cargar la información de forma manual."
        onCheckedChange={(check: boolean) => {
          form.setValue("is_manual_benefit", check);
          const { list_benefits, selected_benefit } = form.getValues();
          if (selected_benefit) {
            const DEFAULT_BENEFIT = list_benefits?.find(
              (e) => e?.id_budget_benefit === selected_benefit.id_budget_benefit
            );
            DEFAULT_BENEFIT &&
              form.setValue("selected_benefit", {
                ...DEFAULT_BENEFIT,
                amount_patient: DEFAULT_BENEFIT.amount_patient ?? 0,
                quantity: DEFAULT_BENEFIT.quantity ?? 1,
              });
          }

          if (check) {
            return form.setValue("selected_benefit", {
              ...selected_benefit,
              id_benefit: null,
              benefit_code: null,
              code_benefit_med_ins_liq: null,
              description: "",
              amount_patient: 0,
              total_amount: 0,
              tooth_section: "",
              quantity: 1,
            });
          }
        }}
        checked={is_manual_benefit}
      />
      <SearchBenefit form={form} benefits={benefits} />
      <FormField
        control={form.control}
        name="selected_benefit.quantity"
        render={({ field }) => (
          <FormItem className="col-start-1">
            <FormLabel>Cantidad</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                onChange={(e) => {
                  form.setValue(
                    "selected_benefit.quantity",
                    Number(e.target.value),
                    { shouldDirty: true }
                  );
                  form.setValue(
                    "selected_benefit.total_amount",
                    selected_benefit.quantity * selected_benefit.amount_patient
                  );
                }}
                min={1}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="selected_benefit.tooth_section"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Pieza/s</FormLabel>
            <FormControl>
              <Input type="text" {...field} value={field.value || ""} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="selected_benefit.amount_patient"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Importe unitario</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                onChange={(e) => {
                  form.setValue(
                    "selected_benefit.amount_patient",
                    Number(e.target.value)
                  );
                  form.setValue(
                    "selected_benefit.total_amount",
                    selected_benefit.quantity * selected_benefit.amount_patient
                  );
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="selected_benefit.total_amount"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Importe total</FormLabel>
            <FormControl>
              <Input type="number" {...field} disabled />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <ButtonsBenefit setHiddenLoadBenefit={setHiddenLoadBenefit} form={form} />
    </CardContent>
  );
};
