import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { Checkbox, Input } from "@/components/atoms";
import { Form, MedicalInsurance, Translations } from "@/types/general";
import { formSchema } from "./schema";
import { MedInsSelect } from "@/components/molecules/med-ins-select";
import { PlansSelect } from "@/components/molecules/plans-select";

export const ControllerMedicalInsurance: React.FC<{
  translations: Translations;
  medicalInsurances: MedicalInsurance[];
  form: Form<typeof formSchema>;
}> = ({ translations, medicalInsurances, form }) => {
  const is_manual = form.watch("is_manual");
  const isInitialMount = React.useRef(true);
  const medicalInsuranceValue = form.watch("id_med_ins");
  const plans = React.useMemo(() => {
    const medicalInsurance = medicalInsurances?.find(
      (m: MedicalInsurance) => m.id_med_ins === medicalInsuranceValue
    );

    return medicalInsurance?.plans || [];
  }, [medicalInsuranceValue, medicalInsurances]);
  const medicalInsurancesEnabled = medicalInsurances?.filter(
    (m: MedicalInsurance) => !m.disabled
  );
  React.useMemo(() => {
    isInitialMount.current
      ? (isInitialMount.current = false)
      : form.setValue("id_plan", plans?.[0]?.id_plan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalInsuranceValue]);

  return (
    <>
      <FormField
        control={form.control}
        name="is_manual"
        render={({ field }) => (
          <FormItem className="col-span-2 flex flex-row items-center space-x-3 justify-start">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                  if (checked) {
                    form.setValue("id_med_ins", null);
                    form.setValue("id_plan", null);
                    return;
                  }
                  form.setValue(
                    "id_med_ins",
                    medicalInsurances?.[0]?.id_med_ins
                  );
                }}
              />
            </FormControl>
            <div className="!my-0 flex flex-col">
              <FormLabel className="!font-bold !mb-0">
                {`Ingresar ${translations?.medical_insurance.toLowerCase()} manualmente`}
              </FormLabel>
              <FormDescription className="truncate">
                Escribe manualmente la cobertura del paciente.
              </FormDescription>
            </div>
          </FormItem>
        )}
      />
      {is_manual ? (
        <>
          <FormField
            control={form.control}
            name="medical_insurance"
            render={({ field }) => (
              <FormItem className="col-span-1">
                <FormLabel>{translations?.medical_insurance}</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="medical_insurance_plan"
            render={({ field }) => (
              <FormItem className="col-span-1">
                <FormLabel>Plan</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      ) : (
        <>
          <MedInsSelect
            label={translations?.medical_insurance}
            form={form}
            medicalInsurance={medicalInsurancesEnabled}
            translations={translations}
          />
          <PlansSelect form={form} plans={plans} />
        </>
      )}
    </>
  );
};
