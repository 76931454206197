"use client";

import React from "react";
import { EmptyState } from "@/components/molecules";
import { Card } from "@/types/general";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  cards: Card[];
}> = ({ cards }) => {
  return cards?.length > 0 ? (
    <Data
      columns={columns()}
      data={{
        data: cards,
      }}
    />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
