import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { deletePatient } from "@/actions/patients";
import { useGlobalStore } from "@/providers/global-provider";
import { ButtonLoading, FreemiumForm } from "@/components/molecules";
import { INITIAL_PATIENT_DATA } from "@/libs/patients";

export function DeletePatient() {
  const {
    resetPatienData,
    patient: { id_patient, rowversion },
  } = useGlobalStore();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { openDialog, closeDialog } = useDialog();
  const onSubmit = async () => {
    try {
      setIsDeleting(true);
      const DELETE_PATIENT = {
        id_patient: id_patient,
        rowversion: rowversion,
        option: "eliminar",
      };
      await deletePatient(DELETE_PATIENT);
      resetPatienData(INITIAL_PATIENT_DATA);
      toast({
        variant: "successful",
        title: "El paciente se elimino con éxito",
      });
      closeDialog();
    } catch (error: any) {
      error.message.includes("40000")
        ? openDialog({
            content: <FreemiumForm />,
          })
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: error.message,
          });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar paciente</DialogTitle>
      </DialogHeader>
      <span>
        Estás por eliminar un paciente y esta acción es irreversible, el
        paciente se eliminará definitivamente.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {isDeleting ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
}
