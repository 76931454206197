import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_card: z.number(),
  card: z.string(),
  enabled: z.boolean().nullable(),
  is_credit: z.string(),
  card_installments: z.array(
    z.object({
      id_card: z.number(),
      id_card_installment: z.number(),
      id_card_navigation: z.number().nullable().optional(),
      installment_number: z.coerce.number(),
      interest: z.coerce
        .number()
        .max(100, "El máximo para el interés es del 100%")
        .min(0, "El mínimo para el interés es del 0%"),
    })
  ),
  rowversion: z.string().nullable(),
  tax_id_number: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
