"use client";

import React from "react";
import { useRouter } from "next/navigation";
import {
  HiDotsVertical,
  HiOutlineBan,
  HiOutlineCheckCircle,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { DeleteLaboratory } from "@/components/organisms/forms";
import { useDialog } from "@/hooks";
import { numberToCurrency } from "@/utils/currency";
import { Laboratory } from "@/types/general";

const Dropdown = ({ laboratory }: { laboratory: Laboratory }) => {
  const { openDialog } = useDialog();
  const router = useRouter();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() => {
              router.push(
                `/dashboard/configuration/tables/laboratories/new-laboratory?id=${laboratory.id_laboratory}`
              );
            }}
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() => {
              openDialog({
                content: <DeleteLaboratory laboratory={laboratory} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: any }) => {
      return <Dropdown laboratory={row.original} />;
    },
  },
  {
    accessorKey: "name",
    header: "Laboratorio",
    cell: ({ row }: { row: any }) => {
      const { name } = row.original;
      return <p className="font-bold text-sm text-gray-500">{name}</p>;
    },
  },
  {
    accessorKey: "enabled",
    header: "Habilitado",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: any }) => {
      const { enabled } = row.original;

      return (
        <p className="">
          {enabled ? (
            <HiOutlineCheckCircle className="size-5 text-green-500" />
          ) : (
            <HiOutlineBan className="size-5 text-red-500" />
          )}
        </p>
      );
    },
  },
  {
    accessorKey: "balance",
    header: "Saldo actual",
    cell: ({ row }: { row: any }) => {
      const { balance } = row.original;

      return (
        <p className="font-bold text-sm text-gray-500">
          {numberToCurrency(balance)}
        </p>
      );
    },
  },
  {
    accessorKey: "balance_usd",
    header: "Saldo actual U$D",
    cell: ({ row }: { row: any }) => {
      const { balance_usd } = row.original;

      return <p className="font-bold text-sm text-gray-500">{balance_usd}</p>;
    },
  },
];
