import React from "react";
import {
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Group, Form } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/configuration/new-medical-insurance/benefit/schema";

export const BenefitsGroups = ({
  form,
  groups,
}: {
  form: Form<typeof formSchema>;
  groups: Group[];
}) => {
  React.useEffect(() => {
    if (!form.getValues("disabled_group_belongs") && groups.length > 0) {
      form.setValue("id_group", groups[0].id_group);
    } else {
      form.setValue("id_group", null);
    }
  }, [form.getValues("disabled_group_belongs")]);

  return (
    <>
      <FormField
        control={form.control}
        name="id_group"
        render={({ field }) => (
          <FormItem className="col-span-6">
            <FormLabel>Grupo</FormLabel>
            <FormControl>
              <Select
                disabled={
                  form.getValues("disabled_group_belongs") ||
                  groups.length === 0
                }
                key="id_group"
                value={field.value}
                onValueChange={(value: number) => {
                  form.setValue("id_group", value);
                }}
              >
                <SelectTrigger className="w-full !h-10 !text-sm">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {groups.map((group: Group) => (
                    <SelectItem
                      key={group.id_group}
                      value={"" + group.id_group}
                    >
                      <p>{group.description}</p>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="disabled_group_belongs"
        render={({ field }) => (
          <FormItem className="col-span-6 gap-x-1 flex items-center">
            <FormControl>
              <Checkbox
                disabled={groups.length === 0}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel>No tiene grupo</FormLabel>
          </FormItem>
        )}
      />
    </>
  );
};
