import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms";
import { useSetHeight } from "@/hooks";

export function Data<TData, TValue>({ columns, data }: any) {
  const [globalFilter, setGlobalFilters] = React.useState("");
  let HEIGHT = useSetHeight(350);
  const getHeigth = () => {
    const calculatedHeight = data.length * 85 + 103;

    if (HEIGHT > calculatedHeight) {
      HEIGHT = calculatedHeight;
    }
    return `${HEIGHT}px`;
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilters,
    state: {
      globalFilter,
    },
  });

  return (
    <Table
      className="rounded-md border h-full max-h-[600px] w-full bg-white shadow-md"
      height={getHeigth()}
    >
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow
            key={headerGroup.id}
            className="shadow sticky top-0 bg-white z-10"
          >
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row: any) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
              className="hover:cursor-pointer h-[85px]"
            >
              {row.getVisibleCells().map((cell: any) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              sin resultados
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <TableRow className="sticky bottom-0 bg-red-500 z-10">
          <TableCell colSpan={5} className="bg-slate-100 text-sm font-normal">
            Total de {data.length} mensajes
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
