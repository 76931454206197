import {
  LuCalendarDays as CalendarDays,
  LuUserPlus as UserPlus,
  LuSearch as Search,
  LuCog as Cog,
} from "react-icons/lu";

export const shortcutsDesktop: {
  title: string;
  description: string;
  route: string | "";
  text: string;
  icon: any;
  type: "route" | "external";
  elementStep: string;
}[] = [
  {
    title: "Ver agenda",
    description: "Accede a la agenda de manera directa y rápida.",
    route: "/dashboard/schedule",
    text: "Ir a mi agenda",
    icon: CalendarDays,
    type: "route",
    elementStep: "shortcut-agenda",
  },
  {
    title: "Nuevo paciente",
    description: "Da de alta un nuevo paciente de forma rápida y sencilla.",
    route: "/dashboard/patients/personal-data",
    text: "Nuevo paciente",
    icon: UserPlus,
    type: "route",
    elementStep: "shortcut-new-patient",
  },
  {
    title: "Buscar paciente",
    description: "Utiliza la búsqueda rápida para encontrar a tus pacientes.",
    route: "",
    text: "Buscar un paciente",
    icon: Search,
    type: "external",
    elementStep: "shortcut-search-patient",
  },
];

export const shortcutsMobile: {
  title: string;
  route: string;
  icon: any;
}[] = [
  {
    title: "Ver agenda",
    route: "/dashboard/schedule",
    icon: CalendarDays,
  },
  {
    title: "Nuevo paciente",
    route: "/dashboard/patients/personal-data",
    icon: UserPlus,
  },
  {
    title: "Buscar paciente",
    route: "/dashboard/patients/personal-data",
    icon: Search,
  },
  {
    title: "Config.",
    route: "/dashboard/settings",
    icon: Cog,
  },
];
