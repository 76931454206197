import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  DatePicker,
} from "@/components/molecules";
import { Form } from "@/types/general";
import { beforeDays } from "@/utils/date";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema as formSchema } from "../schema";

export function Personal({ form }: { form: Form<typeof formSchema> }) {
  const refTarget = React.useRef<any>(null);
  const {
    patient: { prescription_date },
    general_params: { translations },
  } = useGlobalStore();
  const { prescription, document_type, id_patient } = form.watch();

  React.useEffect(() => {
    refTarget?.current?.focus();
  }, [id_patient]);

  return (
    <Card className="col-span-6 h-auto">
      <CardHeader>
        <CardTitle>Datos del paciente</CardTitle>
        <CardDescription>
          Esta es la información básica acerca de tu paciente.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem className="col-span-12">
              <FormLabel> Apellido y Nombre</FormLabel>
              <FormControl>
                <Input type="text" {...field} ref={refTarget} maxLength={40} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-4 2xl:col-span-3">
          <Select
            key="document_type"
            value={document_type || translations?.doc_types?.[0]?.id}
            aria-label="Documento"
            onValueChange={(value: string) => {
              form.setValue("document_type", value);
            }}
          >
            <Label>Documento</Label>
            <SelectTrigger className="w-full !h-10 !text-sm select-trigger">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {translations?.doc_types?.map(
                (type: { id: string; text: string }) => (
                  <SelectItem
                    key={type.id}
                    value={type.id}
                    className="select-item"
                  >
                    {type.text}
                  </SelectItem>
                )
              )}
            </SelectContent>
          </Select>
        </div>
        <FormField
          control={form.control}
          name="document_number"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Nº de Documento</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={20} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="clinical_history_number"
          render={({ field }) => {
            return (
              <FormItem className="col-span-4">
                <FormLabel>Nº Historia clínica</FormLabel>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <div className="col-start-1 col-span-4">
          <DatePicker
            form={form}
            name="date_of_birth"
            label="Fecha de nac."
            className="date-picker-birth"
            dateformat="dd/MM/yyyy"
            disabledDates={(date: Date) => date > new Date()}
            captionLayout="dropdown"
          />
        </div>
        <div className="col-span-4">
          <DatePicker
            form={form}
            name="registration_date"
            label="Fecha de alta"
            className="date-picker-registration"
            dateformat="dd/MM/yyyy"
            disabledDates={(date: Date) => date > new Date()}
          />
        </div>
        <div className="col-span-4">
          <DatePicker
            form={form}
            name="last_visit_date"
            label="Última atención"
            className="date-picker-last-visit"
            dateformat="dd/MM/yyyy"
            disabledDates={(date: Date) => date > new Date()}
          />
        </div>
        <div className="col-span-6 flex flex-row">
          <div className="date-picker-prescription">
            <Checkbox
              className="check-prescription mr-4"
              checked={prescription}
              onCheckedChange={(check: boolean) => {
                form.setValue("prescription", check);
                if (check && prescription_date) {
                  form.setValue("prescription_date", prescription_date);
                } else {
                  form.setValue("prescription_date", null);
                  form.setValue("reasons", "");
                }
              }}
            />
          </div>
          <DatePicker
            form={form}
            name="prescription_date"
            label="Recitación"
            disabled={!prescription}
            dateformat="dd/MM/yyyy"
            className="!col-span-4"
            disabledDates={beforeDays}
            startMonth={new Date()}
            endMonth={
              new Date(new Date().getFullYear() + 5, new Date().getMonth())
            }
            captionLayout="dropdown"
          />
        </div>
        <FormField
          control={form.control}
          name="reasons"
          render={({ field }) => (
            <FormItem className="col-span-full">
              <FormLabel className="text-sm">Motivo</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  {...field}
                  disabled={!prescription}
                  maxLength={50}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
