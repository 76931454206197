import React from "react";
import {
  Button,
  Combobox,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
} from "@/components/atoms";
import { Form, Permissions, User } from "@/types/general";
import { objectChangeKey } from "@/utils/helpers";
import { formSchema } from "../schema";

export const CopyPermissions = ({
  form,
  users,
}: {
  form: Form<typeof formSchema>;
  users: User[];
}) => {
  const FILTER_USERS = users?.filter(
    (u: User) => u.id_user !== form.getValues("id_user")
  );
  const USERS = objectChangeKey({
    originalArray: FILTER_USERS,
    oldKey: "id_user",
    newKey: "id_user_before_copy",
  });
  const onClickSave = () => {
    const { id_user_before_copy } = form.watch();
    const USER_COPY = USERS?.find(
      (u: User & { id_user_before_copy: number }) =>
        u.id_user_before_copy === id_user_before_copy
    );
    const PERMISSIONS_COPY =
      USER_COPY?.permission?.map((p: Permissions) => p.id_process_navigation) ||
      [];
    form.setValue("id_user_to_copy", id_user_before_copy);
    form.setValue("permissions", PERMISSIONS_COPY);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Copia automática</DialogTitle>
        <DialogDescription>
          Seleccioná el profesional de quien querés copiar los permisos a este
          nuevo usuario.
        </DialogDescription>
      </DialogHeader>
      <Combobox
        data={USERS}
        dataKey="id_user_before_copy"
        form={form}
        value="name"
        title="usuario"
        placeholder="Selecciona un professional"
        showInputSearch={false}
        className="min-w-full"
        classContent="!w-[500px]"
        label="Usuario"
      />
      <DialogClose className="flex justify-end gap-x-4 z-10">
        <Button variant="destructive" type="button">
          Cancelar
        </Button>
        <Button type="button" onClick={onClickSave}>
          Guardar selección
        </Button>
      </DialogClose>
    </DialogContent>
  );
};
