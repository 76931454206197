"use client";

import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { toast } from "@/hooks";
import { cancelPayment } from "@/actions/payments";

export const Cancel = ({
  onChange,
  setLoading,
}: {
  onChange: () => void;
  setLoading: (value: boolean) => void;
  loading: boolean;
}) => {
  const {
    patient_payments: { payment_selected },
    updatePatient,
  } = useGlobalStore();

  const handleSubmit = async () => {
    setLoading(true);
    const PAYMENT = {
      id_pago: payment_selected.id_payment,
      id_paciente: payment_selected.id_patient,
      id_prof: payment_selected.id_professional,
      fecha: payment_selected.date,
      concepto: payment_selected.concept,
      comprobante: payment_selected.receipt,
      tipo: payment_selected.type,
      numero: payment_selected.number,
      forma: payment_selected.payment_method,
      debe: payment_selected.debit,
      haber: payment_selected.credit,
      es_usd: payment_selected.is_usd,
      comi_pago: payment_selected.payment_commission,
      comi_pago_imp: payment_selected.payment_commission_amount,
      iva: payment_selected.vat,
      id_presup: payment_selected.id_budget,
      anulado: !payment_selected.canceled,
      id_usuario: payment_selected.id_user,
      id_sucursal: payment_selected.id_office,
      id_os: payment_selected.id_health_insurance,
      obs: payment_selected.observation,
      interes: payment_selected.interest,
      interes_porc: payment_selected.interest_percentage,
      cant_cuotas: payment_selected.installment_count,
      id_banco: payment_selected.id_bank,
      id_liq_prof: payment_selected.id_liquidation_professional,
      importe_liq_prof: payment_selected.professional_liquidation_amount,
      id_fact_electronica: payment_selected.id_electronic_invoice,
      urlpdf: payment_selected.pdf_url,
      estado_comprob: payment_selected.receipt_status,
      mensaje_AFIP: payment_selected.afip_message,
      guardar_caja: payment_selected.save_to_cash_register,
      caja: payment_selected.cash_register,
      tarjetas_mov: payment_selected.card_movements,
      cheques: payment_selected.checks,
      bancos_mov: payment_selected.bank_movements,
      rowversion: payment_selected.rowversion,
    };

    const response = await cancelPayment(PAYMENT);
    if (response?.error) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Pago anulado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      if (response?.account) {
        payment_selected.is_usd
          ? updatePatient("balance_usd", response?.account?.balance_usd)
          : updatePatient("balance", response?.account?.balance);
      }
      onChange();
    }

    setLoading(false);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Aviso</DialogTitle>
        <DialogDescription>
          Esta acción {!payment_selected?.canceled ? "anulará" : "habilitará "}{" "}
          el pago seleccionado.
        </DialogDescription>
      </DialogHeader>
      <div className="grid grid-cols-3 gap-x-5">
        <DialogClose>
          <Button className="w-full" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose>
          <Button className="w-full" onClick={handleSubmit}>
            {!payment_selected?.canceled ? "Anular pago" : "Habilitar pago"}
          </Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
