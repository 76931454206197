"use client";

import React from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { Command, CommandInput, CommandList } from "@/components/atoms/command";
import { SearchPatientTable } from "@/components/organisms/tables";
import { useGlobalStore } from "@/providers/global-provider";
import { Patient } from "@/types/patients";
import { Checkbox, Skeleton } from "@/components/atoms";

export const SearchPatient: React.FC<{
  onSubmit: (value: Patient) => void;
  className?: string;
  classNameTable?: string;
  floating: boolean;
  onChange: (value: string) => void;
  showTable: boolean;
  query: string;
  search: boolean;
  focus?: React.RefObject<HTMLInputElement>;
  showInput?: boolean;
  setContained: (value: boolean) => void;
  contained?: boolean;
  onSearch?: () => void;
}> = ({
  onSubmit,
  className,
  classNameTable,
  floating,
  onChange,
  showTable,
  query,
  focus,
  showInput = true,
  setContained,
  contained,
  onSearch,
}) => {
  const { patient_list, loading } = useGlobalStore();
  const [sorting, setSorting] = React.useState([]);

  return (
    <Command className={classNames(className ? className : "!w-full")}>
      <CommandInput
        className="h-[38px]"
        name="searchPatient"
        ref={focus}
        autoFocus
        placeholder="Buscar paciente..."
        onValueChange={onChange}
        value={query}
        onClick={onSearch}
      />
      <CommandList
        className={classNames(
          "!overflow-hidden shadow-md rounded-lg",
          classNameTable
        )}
      >
        <Transition
          show={showTable}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {showTable && (
            <div className="w-full">
              {showInput && (
                <div className="grid grid-cols-12 space-x-2 bg-white w-full">
                  <CommandInput
                    className="bg-white px-2 col-span-10"
                    name="searchPatient"
                    placeholder="Buscar paciente..."
                    onValueChange={onChange}
                    value={query}
                    onClick={onSearch}
                  />
                  <div className="flex gap-x-4 items-center">
                    <Checkbox
                      id="contained"
                      checked={contained}
                      onClick={() => setContained(!contained)}
                    />
                    <p className="text-sm w-full font-medium leading-none text-slate-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Que esté contenido
                    </p>
                  </div>
                </div>
              )}
              {loading ? (
                <div className="bg-white space-y-2 p-4 w-full">
                  <Skeleton className="h-10 w-auto " />
                  <Skeleton className="h-10 w-auto" />
                </div>
              ) : patient_list?.length > 0 ? (
                <SearchPatientTable
                  data={patient_list}
                  onSubmit={onSubmit}
                  floating={floating}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              ) : (
                <p className="p-4 text-sm text-gray-500 bg-white rounded-md rounded-t-none border-t">
                  No se encontraron pacientes.
                </p>
              )}
            </div>
          )}
        </Transition>
      </CommandList>
    </Command>
  );
};
