import * as React from "react";
import classNames from "classnames";
import { HiDotsVertical } from "react-icons/hi";
import { LuCircleCheck, LuPen, LuTrash2 } from "react-icons/lu";
import { FcCancel } from "react-icons/fc";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from "@/components/atoms";
import { Prescription } from "@/types/patients";

export const Columns = ({
  action,
  prescription_selected,
}: {
  action: (value: any, type: string) => void;
  prescription_selected: Prescription;
}) => {
  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div
                className={classNames(
                  "relative p-2 hover:cursor-pointer flex-none",
                  {
                    "cursor-not-allowed pointer-events-none":
                      prescription_selected,
                  }
                )}
              >
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, "edit")}
                >
                  <LuPen className="mr-2 size-4" />
                  <span>Modificar</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, "delete")}
                >
                  <LuTrash2 type="delete" className="mr-2 size-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Medicamento",
      cell: ({ row }: { row: any }) => {
        const { product_name, presentation, drug_name } = row.original;
        return (
          <div className="text-base font-semibold leading-6 text-slate-900 my-1 hover:cursor-pointer">
            <p>{product_name}</p>
            <p className="text-slate-500">{drug_name}</p>
            <p className="text-slate-500">{presentation}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Cantidad",
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: "diagnosis",
      header: "Diagnostico",
      cell: ({ row }: { row: any }) => {
        const { diagnosis } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {diagnosis.description}
          </p>
        );
      },
    },
    {
      accessorKey: "dosage",
      header: "Tratamiento / Observación",
      cell: ({ row }: { row: any }) => {
        const { dosage, observations } = row.original;

        return (
          <ul className="list-disc">
            <li>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Tx.: {dosage}
              </p>
            </li>
            <li>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Obs.: {observations}
              </p>
            </li>
          </ul>
        );
      },
    },
    {
      accessorKey: "prolonged_treatment",
      header: "Tx. Prolongado",
      cell: ({ row }: { row: any }) => {
        const { prolonged_treatment } = row.original;

        return prolonged_treatment ? (
          <LuCircleCheck className="size-5 mx-auto text-green-500" />
        ) : (
          <FcCancel className="size-6 mx-auto" />
        );
      },
    },
  ];

  return columns;
};
