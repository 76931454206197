"use client";

import React from "react";
import { usePathname, useRouter } from "next/navigation";
import classNames from "classnames";
import { LuPlus } from "react-icons/lu";
import Link from "next/link";
import { Checkbox, Button } from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { searchPatient, searchPatientsList } from "@/actions/patients";
import { Patient } from "@/types/patients";
import { useKeypress, useOnClickOutside, toast } from "@/hooks";
import { SearchPatient } from "@/components/organisms";
import { INITIAL_PATIENT_DATA } from "@/libs/patients";

export function StaticSearchBar({
  className,
  onFocus,
  showTable,
}: {
  className?: string;
  onFocus: any;
  showTable: boolean;
}) {
  const [contained, setContained] = React.useState<boolean>(false);
  const SEARCH_BAR_REF = React.useRef<HTMLInputElement>(null);
  const [query, setQuery] = React.useState<string>("");
  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const REF_WRAPPER = React.useRef<HTMLInputElement>(null);
  const {
    startLoading,
    stopLoading,
    setPatientData,
    resetPatiensList,
    setPatientsList,
    resetPatienData,
    patientDataStartLoading,
    patientDataStopLoading,
    setSearchBarRef,
  } = useGlobalStore();
  const pathname = usePathname();
  const router = useRouter();

  const onSubmit = async () => {
    startLoading();
    try {
      if (query?.trim() !== "" && !hasSearched) {
        const PATIENTS_LIST: Patient[] = await searchPatientsList({
          name: query,
          contained,
        });
        setPatientsList(PATIENTS_LIST);
        setHasSearched(true);
        onFocus(true);
      }
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    } finally {
      stopLoading();
    }
  };

  const searchPatientClickHandler = async (patient: Patient) => {
    onFocus(false);
    patientDataStartLoading();
    const { id_patient } = patient;
    const response = await searchPatient(id_patient);

    if ("error" in response) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      setPatientData(response);      
      if (/(odontogram|benefits)/.test(pathname)) {
        router.replace(
          `${pathname}?id_plan=${response.id_plan}`
        );
      }
      setQuery("");
      resetPatiensList();
    }

    patientDataStopLoading();
  };
  const handleChange = (value: string) => {
    setQuery(value);
    setHasSearched(false);
  };
  useKeypress({
    key: "Enter",
    action: onSubmit,
  });
  useOnClickOutside({
    ref: REF_WRAPPER,
    handler: () => {
      query && resetPatiensList();
      setQuery("");
    },
  });
  React.useEffect(() => {
    setSearchBarRef(SEARCH_BAR_REF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SEARCH_BAR_REF]);

  return (
    <div
      ref={REF_WRAPPER}
      className={classNames(
        "grid grid-cols-12 gap-x-4 rounded-md relative w-full",
        className
      )}
    >
      <SearchPatient
        onSubmit={searchPatientClickHandler}
        className="w-full col-start-3 col-span-4 border rounded-md px-3 focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2"
        classNameTable="col-span-12 w-[1100px] fixed top-1/4 right-1/2 translate-x-1/2 z-50 rounded-md"
        floating={false}
        onChange={handleChange}
        showTable={showTable}
        query={query}
        search={hasSearched}
        focus={SEARCH_BAR_REF}
        contained={contained}
        setContained={setContained}
        onSearch={onSubmit}
      />
      <div className="flex items-center space-x-2 col-span-3 w-full">
        <Checkbox
          id="contained"
          checked={contained}
          onClick={() => setContained(!contained)}
        />
        <p className="text-sm w-full font-medium leading-none text-slate-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Que esté contenido
        </p>
      </div>
      <Link
        href="/dashboard/patients/personal-data"
        className="w-full col-span-3"
      >
        <Button
          className="w-full"
          variant="ghost"
          onClick={() => resetPatienData(INITIAL_PATIENT_DATA)}
        >
          <span className="flex items-center justify-center">
            <LuPlus className="size-4 mr-2" />
            Paciente nuevo
          </span>
        </Button>
      </Link>
    </div>
  );
}
