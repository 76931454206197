"use client";

import classNames from "classnames";
import Link from "next/link";
import { ShorcutCard } from "@/components/molecules";
import { shortcutsDesktop, shortcutsMobile } from "@/libs/shortcuts";
import { useGlobalStore } from "@/providers/global-provider";

type ShortcutsProps = {
  device: string;
};

export function ShortCuts({ device }: ShortcutsProps) {
  const {
    user: { is_supervisor },
  } = useGlobalStore();

  return is_supervisor ? (
    device === "mobile" ? (
      <div
        className={classNames(
          "grid gap-x-5 mt-6",
          shortcutsMobile
            ? `grid-cols-${shortcutsMobile.length}`
            : "grid-cols-4"
        )}
      >
        {shortcutsMobile.map((shortcut, index) => (
          <Link href={shortcut.route} key={index} className="text-center">
            <shortcut.icon className=" text-blue-500 border-8 border-blue-200 rounded-full bg-white w-20 h-20 p-4" />
            <p className="text-sm leading-4 mt-2 text-slate-700">
              {shortcut.title}
            </p>
          </Link>
        ))}
      </div>
    ) : (
      <div className="grid grid-cols-1 gap-y-5 lg:gap-y-0 lg:grid-cols-3 gap-x-5">
        {shortcutsDesktop.map((shortcut, index) => (
          <ShorcutCard
            key={index}
            title={shortcut.title}
            description={shortcut.description}
            text={shortcut.text}
            type={shortcut.type}
            route={shortcut.route}
            icon={shortcut.icon}
          />
        ))}
      </div>
    )
  ) : null;
}
