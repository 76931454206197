import React from "react";
import { formatDate } from "@/utils/helpers";

export const Columns = [
  {
    accessorKey: "date",
    header: "Fecha",
    cell: ({ row }: { row: any }) => {
      const { date } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {formatDate(date)}
        </p>
      );
    },
  },
  {
    accessorKey: "affiliate_number",
    header: "Nro. Afiliado",
    cell: ({ row }: { row: any }) => {
      const { affiliate_number } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {affiliate_number}
        </p>
      );
    },
  },
  {
    accessorKey: "enabled",
    header: "Respuesta",
    cell: ({ row }: { row: any }) => {
      const { enabled } = row.original;
      const ENABLED = enabled === "S" ? "Autorizado" : "No autorizado";

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {ENABLED}
        </p>
      );
    },
  },
  {
    accessorKey: "reason",
    header: "Leyenda",
    cell: ({ row }: { row: any }) => {
      const { reason } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {reason}
        </p>
      );
    },
  },
];
