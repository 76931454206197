"use client";

import React from "react";
import { Combobox } from "@/components/atoms";
import {
  EmptyState,
  Form,
  FormItem,
  FormControl,
} from "@/components/molecules";
import {
  Specialty,
  Representation,
  MedicalInsurance,
  Plan,
  Form as FormProp,
} from "@/types/general";
import { getMedicalInsuranceBenefits } from "@/actions/configs";
import { useGlobalStore } from "@/providers/global-provider";
import { Benefits as Geist } from "@/components/molecules/geist";
import { toast } from "@/hooks";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  medicalInsurance: MedicalInsurance;
  specialties: Specialty[];
  representations: Representation[];
  plan: Plan;
  form: FormProp<any>;
}> = ({ medicalInsurance, specialties, representations, plan, form }) => {
  const {
    loading,
    startLoading,
    stopLoading,
    setBenefits,
    configuration: {
      medicalinsurances: { benefits },
    },
  } = useGlobalStore();

  React.useEffect(() => {
    const fetchMedicalBenefits = async () => {
      try {
        startLoading();
        const planId = form.getValues("id_plan");
        const { benefits } = await getMedicalInsuranceBenefits("OSPRE", planId);

        setBenefits({ data: benefits });
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: error.message,
        });
      } finally {
        stopLoading();
      }
    };

    void fetchMedicalBenefits();
  }, [form.watch("id_plan")]);

  return medicalInsurance?.plans && medicalInsurance?.plans.length > 0 ? (
    <Form {...form}>
      <form>
        <FormItem className="mb-2">
          <FormControl>
            <Combobox
              form={form}
              data={medicalInsurance.plans}
              dataKey="id_plan"
              value="name"
              placeholder="Busca un plan"
              title="Planes"
            />
          </FormControl>
        </FormItem>
        {loading ? (
          <Geist />
        ) : (
          <Data
            columns={Columns({
              representations,
              specialties,
              medicalInsurance,
              plan,
            })}
            data={benefits}
          />
        )}
      </form>
    </Form>
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
