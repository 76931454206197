import React from "react";
import { Laboratory, LaboratoryConcept } from "@/types/general";
import { EmptyState } from "@/components/molecules";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: LaboratoryConcept[];
  laboratory?: Laboratory;
}> = ({ data, laboratory }) => {
  return laboratory && !laboratory?.lab_concepts?.length ? (
    <div className="grid grid-cols-12 gap-x-10">
      <EmptyState
        title="¡Ups!"
        copy="El laboratorio seleccionado no tiene trabajos definidos."
        className="col-span-6 !justify-start flex col-start-4"
      />
    </div>
  ) : (
    <Data data={data} columns={Columns({ laboratory })} />
  );
};
