"use client";

import React from "react";
import Image from "next/image";
import classNames from "classnames";
import { Card, BarOrLineChart } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { colors, namesGraphics, variationIcons } from "@/libs/statistics";
import { numberToCurrency } from "@/utils/currency";
import { useGlobalStore } from "@/providers/global-provider";
import { Finances } from "@/types/statistics";
import { Translations } from "@/types/general";

export const FinancesGraphics = ({ data }: { data: Finances }) => {
  const {
    statistics: {
      finances: { data: values },
    },
    setInitializedDataStatistics,
    general_params: { translations },
  } = useGlobalStore();

  const itemsGraphics =
    values &&
    Object.keys(values)
      .filter(
        (i) =>
          values[i] !== null && values[i] !== 0 && values[i].chart_data !== null
      )
      ?.map((item, index) => ({
        ...values[item],
        total: numberToCurrency(values[item]["total"]),
        color: colors[index],
        title: namesGraphics[item]?.title,
        subtitle: namesGraphics[item]?.subtitle,
        translations: namesGraphics[item]?.translations,
      }));
  const itemsComponents =
    values &&
    Object.keys(values)
      .filter((i) => values[i] !== null && values[i] !== 0)
      .map((item, index) => ({
        ...values[item],
        color: colors[index],
        title: namesGraphics[item]?.title,
        subtitle: namesGraphics[item]?.subtitle,
        translations: namesGraphics[item]?.translations,
      }));

  const nullItems = itemsComponents?.filter(
    (item: any) => item.chart_data === null
  );
  const groupedNullItems = [];
  for (let i = 0; i < nullItems?.length; i += 2) {
    groupedNullItems.push({
      first: {
        ...nullItems[i],
      },
      second: {
        ...nullItems[i + 1],
      },
    });
  }

  for (let i = 0; i < groupedNullItems.length; i++) {
    const nullIndex = itemsComponents.indexOf(nullItems[i * 2]);
    itemsGraphics?.splice(nullIndex, 0, groupedNullItems[i]);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(
    () => setInitializedDataStatistics({ data, section: "finances" }),
    []
  );

  return (
    <div className="mb-10 lg:grid lg:placeholder lg:grid-cols-12 gap-5 flex flex-col">
      {itemsGraphics?.map((item: any, index: number) => {
        const compare = item.chart_data?.length < 0 || item.performance;
        const title =
          item.translations && item.title !== "Facturación total"
            ? `${item.title} ${translations?.medical_insurance}`
            : item.title;
        const subTitle = item.translations
          ? `${item.subtitle} ${translations?.medical_insurance}.`
          : item.subtitle;
        if (item && item.chart_data) {
          return (
            <Card className="col-span-6 flex flex-col p-8 px-6" key={index}>
              <span className="text-xl text-slate-900">{title}</span>
              <span className="text-slate-400 pb-6">{subTitle}</span>
              {item.chart_type === "line" && (
                <span className="text-3xl">{item?.total} Totales</span>
              )}
              {item.chart_type === "line" && item.performance && (
                <span
                  className={variationIcons[item.performance.type]?.className}
                >
                  {`${variationIcons[item.performance.type]?.sumOrRest || ""} ${
                    item.performance.variation_value
                  } (${variationIcons[item.performance.type]?.arrow || ""}%${
                    item.performance.variation_percentage
                  })`}
                </span>
              )}
              {item.chart_data.length > 0 ? (
                <BarOrLineChart
                  data={item}
                  color={item.color}
                  compare={compare}
                  height={250}
                />
              ) : (
                <EmptyState title="Ups!" copy="No hay datos para mostrar" />
              )}
            </Card>
          );
        }

        return (
          <Variations
            key={index}
            data={item}
            variationIcons={variationIcons}
            translations={translations}
          />
        );
      })}
    </div>
  );
};
export const Variations: React.FC<{
  data: any;
  variationIcons: {
    [key: string]: { [key: string]: string };
  };
  translations: Translations;
}> = ({ data, variationIcons, translations }) => {
  return (
    <div className="col-span-6 flex flex-col justify-between">
      {data &&
        Object.keys(data)?.map((item, index) => {
          const value = data[item];
          const totalMoney = numberToCurrency(value?.total);
          const title = value?.translations
            ? `${value?.title} ${translations?.medical_insurance}`
            : value?.title;
          const subTitle = value?.translations
            ? `${value?.subtitle} ${translations?.medical_insurance}.`
            : value?.subtitle;
          const IMAGE_SVG: { [key: string]: string } = {
            increase: "graph_green.svg",
            decrease: "graph_red.svg",
            equals: "graph_blue.svg",
          };
          const imageSvg =
            IMAGE_SVG[value?.performance?.type] || "graph_gray.svg";
          const styleContainer = classNames(
            "flex flex-row gap-5 flex justify-between bg-white",
            {
              "mb-5": index === 0,
            }
          );

          return (
            <Card className={styleContainer} key={index}>
              <div className="flex flex-col">
                <span>{title}</span>
                <span className="text-slate-400 pb-6">{subTitle}</span>
                <span className="text-3xl">{totalMoney}</span>
                {value?.performance && value?.performance.type !== "equals" && (
                  <span
                    className={
                      variationIcons[value.performance?.type]?.className
                    }
                  >
                    {`${variationIcons[value.performance?.type]?.sumOrRest} ${
                      value?.performance?.variation_value
                    } (${variationIcons[value.performance?.type]?.arrow}%${
                      value?.performance?.variation_percentage
                    })`}
                  </span>
                )}
              </div>
              <Image
                key={index}
                src={`/images-graphics/${imageSvg}`}
                className="h-auto hidden lg:block"
                alt="graphic"
                priority={false}
                width={100}
                height={50}
              />
            </Card>
          );
        })}
    </div>
  );
};
