import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_income: z.number(),
  id_office: z.number().nullable(),
  id_bank: z.number().nullable(),
  id_user: z.number(),
  date: z.date(),
  voucher: z.string(),
  voucher_type: z.string(),
  voucher_number: z.string(),
  payment_method: z.string(),
  details: z.string(),
  customer: z.string(),
  tax_number: z.string(),
  concept: z.string(),
  net_taxed: z.coerce.number(),
  iva_percentage_1: z.coerce.number(),
  iva_percentage_2: z.coerce.number(),
  iva_21: z.coerce.number(),
  iva_10: z.coerce.number(),
  exempt: z.coerce.number(),
  others: z.coerce.number(),
  total: z.coerce.number(),
  disabled: z.boolean(),
  is_usd: z.boolean(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
