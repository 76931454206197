"use client";

import React from "react";
import { LuArrowRight as ArrowRight, LuX as X } from "react-icons/lu";
import classNames from "classnames";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";

export const FreemiumBanner = () => {
  const [showBanner, setShowBanner] = React.useState<boolean>(false);
  const {
    user: { user, web_user, plan_version },
  } = useGlobalStore();

  React.useEffect(() => {
    if (plan_version) {
      setShowBanner(plan_version === "freemium");
    }
  }, [plan_version]);

  const alertCss = classNames(
    "border-0 lg:!relative absolute w-full ml-16 mx-8 bottom-0 left-0 w-full z-50 background-animate lg:top-0 lg:py-1 lg:px-4 lg:mt-1 before:!rounded-none before:!rounded-t-lg lg:before:!rounded-lg before:!absolute before:left-0 before:top-0 before:right-0 before:-z-50 before:bg-black transform-gpu before:bg-gradient-to-r from-blue-500 via-purple-500 to-fuchsia-600",
    showBanner
      ? "flex-cols lg:flex lg:items-center lg:justify-between"
      : "hidden"
  );

  const onSuscribe = () => {
    window?.open(
      `https://signup.bilog.com.ar/checkout?email=${user}&webuser=${web_user}`,
      "_blank"
    );
  };

  return (
    <Alert className={alertCss}>
      <AlertTitle className="!mb-4 lg:!mb-0 font-medium text-white text-xl">
        <span className="block lg:hidden">¿Todavía no tenes premium?</span>
        <div className="w-full hidden lg:flex lg:items-center">
          <p>¡Disfrutá de todas las funcionalidades de Bilog Premium!</p>
          <Button
            variant="link"
            onClick={onSuscribe}
            className="hidden w-auto lg:flex !text-white group"
          >
            Contratar ahora
            <ArrowRight className="transition-transform transform group-hover:translate-x-1" />
          </Button>
        </div>
      </AlertTitle>
      <AlertDescription className="flex justify-center lg:hidden">
        <Button
          onClick={onSuscribe}
          className="bg-white !text-blue-500 mt-2 w-full hover:!text-white"
        >
          Contratar ahora
        </Button>
      </AlertDescription>
      <X
        className="right-4 lg:!static !text-white cursor-pointer !justify-self-end"
        onClick={() => setShowBanner(false)}
      />
    </Alert>
  );
};
