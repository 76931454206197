import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  date_from: z.date().optional().nullable(),
  date_to: z.date().optional().nullable(),
};

export const formSchema = z.object(schema).refine(
  (data) => {
    if (!data.date_from || !data.date_to) return true;
    return data.date_to >= data.date_from;
  },
  {
    message: "La fecha final no puede ser anterior a la inicial",
    path: ["date_to"],
  }
);

export const resolver = zodResolver(formSchema);
