import { MedicalHistory } from "@/types/patients";
import { Data } from "./data";
import { Columns } from "./columns";
import { useGlobalStore } from "@/providers/global-provider";
import { Skeleton } from "@/components/atoms";
import { sortByDate } from "@/utils/helpers";

export const Table: React.FC<{
  history: MedicalHistory[];
  action: (hc: { [key: string]: any }, type: string) => void;
}> = ({ history, action }) => {
  const { loading } = useGlobalStore();

  return loading ? (
    <div className="mx-auto">
      <div className="w-full flex justify-between">
        <div className="flex gap-2">
          <Skeleton className="h-8 w-44 rounded-xl" />
          <Skeleton className="h-8 w-44  rounded-xl" />
        </div>
        <Skeleton className="h-8 w-24 rounded-xl" />
      </div>
      <Skeleton className="mt-2 w-full rounded-xl" style={{ height: 580 }} />
    </div>
  ) : (
    <Data columns={Columns({ action })} data={sortByDate(history)} />
  );
};
