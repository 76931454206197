import { LaboratoryPatients } from "@/types/patients";


type State = {
  patient_laboratory: {
    data: {
      [key: string]: any;
    };
  };
};

type Actions = {
  setPatientLaboratory: (payload: { data: LaboratoryPatients[] }) => void;
};

type PatientLaboratoryStore = State & Actions;

export const INIT_STATE: State = {
    patient_laboratory: {
    data: [],
  },
};

export const PatientLaboratorySlice = (
  set: (partial: Partial<PatientLaboratoryStore>) => void,
  get: () => PatientLaboratoryStore
): PatientLaboratoryStore => ({
  ...INIT_STATE,
  setPatientLaboratory: ({ data }: { data: LaboratoryPatients[] }) => {
    const state = get();
    set({
      ...state,
      patient_laboratory: {
        ...state,
        data,
      },
    });
  },
});