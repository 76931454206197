import Image from "next/image";

type GreetingsProps = {
  device: string;
};

export function Greetings({ device }: GreetingsProps) {
  return device === "mobile" ? (
    <div className="flex flex-col items center">
      <Image
        src="/logo-bilog.svg"
        alt="Logo Bilog App"
        width={150}
        height={150}
        className="mx-auto"
      />
    </div>
  ) : (
    <p className="text-4xl font-light text-neutral-500 text-left my-14">
      ¡Hola, que gusto verte de nuevo!
    </p>
  );
}
