"use client";

import * as React from "react";
import classNames from "classnames";
import { SidebarInset, useSidebar } from "@/components/atoms";

export const PermissionsInset = ({
  children,
}: Readonly<{
  children: React.ReactNode;
  section: "general-data" | "users" | "whatsapp-data" | "sms-data";
}>) => {
  const { open } = useSidebar();

  return (
    <SidebarInset
      className={classNames(
        "transition-all duration-300",
        open ? "max-w-[calc(100vw-250px)]" : "max-w-[calc(100vw-50px)]"
      )}
    >
      <div className="h-[500px] overflow-y-auto pr-2">{children}</div>
    </SidebarInset>
  );
};
