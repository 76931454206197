import { Checkbox } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
} from "@/components/molecules";

export const UsdCheckbox = ({ name, form }: { name: string; form: any }) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-center space-x-3 justify-start">
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={(checked) => field.onChange(checked)}
            />
          </FormControl>
          <div className="leading-none mt-0 flex flex-col">
            <FormLabel>Valores en USD</FormLabel>
            <FormDescription className="text-slate-500">
              Las tablas serán realizadas a partir de los datos cargados en USD.
              No se tomará en cuenta los valores en moneda local.
            </FormDescription>
          </div>
        </FormItem>
      )}
    />
  );
};
