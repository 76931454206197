"use client";

import React from "react";
import classNames from "classnames";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Specialty } from "@/types/general";
import { SpecialtyForm, DeleteSpecialty } from "@/components/organisms/forms";

type RowDataT = {
  original: any;
};

const Dropdown = ({ specialty }: { specialty?: Specialty }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              openDialog({
                content: <SpecialtyForm specialty={specialty} />,
              });
            }}
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteSpecialty specialty={specialty} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = ({ specialties }: { specialties?: Specialty[] }) => [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const specialty = specialties?.find(
        (s) => s.specialty_code === row.original.specialty_code
      );

      return <Dropdown specialty={specialty} />;
    },
  },
  {
    accessorKey: "specialty_code",
    header: "Código",
    cell: ({ row }: { row: any }) => {
      const { specialty_code } = row.original;

      return (
        <p className={classNames(`my-1 text-sm leading-6`)}>{specialty_code}</p>
      );
    },
  },
  {
    accessorKey: "description",
    header: "Especialidad",
    cell: ({ row }: { row: any }) => {
      const { description } = row.original;

      return (
        <p className={classNames(`my-1 text-sm leading-6`)}>{description}</p>
      );
    },
  },
];
