import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_med_ins: z.number(),
  code_med_ins: z.string().min(1, {
    message: "El código es requerido",
  }),
  medical_insurance: z.string().min(1, {
    message: "El nombre es requerido",
  }),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  phone: z.string(),
  fax: z.string(),
  email: z.string(),
  tax_number: z.string(),
  observations: z.string(),
  benefits_quantity: z.coerce.number(),
  iva_ob: z.coerce.number(),
  iva_ad: z.coerce.number(),
  load_for_period: z.boolean(),
  client_ftp: z.string(),
  disabled: z.boolean(),
  rowversion: z.string().nullable(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
