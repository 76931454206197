import React from "react";
import {
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  ScrollArea,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Form, Representation } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/configuration/new-medical-insurance/benefit/schema";

export const BenefitDrawing = ({
  form,
  representations,
}: {
  form: Form<typeof formSchema>;
  representations: Representation[];
}) => {
  React.useEffect(() => {
    if (form.getValues("disabled_drawing")) {
      form.setValue("drawing", representations[0].drawing);
    } else {
      form.setValue("drawing", representations[1].drawing);
    }
  }, [form.getValues("disabled_drawing")]);

  return (
    <>
      <FormField
        control={form.control}
        name="drawing"
        render={({ field }) => (
          <FormItem className="col-span-6">
            <FormLabel>Representación en el odontograma</FormLabel>
            <FormControl>
              <Select
                key="drawing"
                disabled={form.getValues("disabled_drawing")}
                value={field.value}
                onValueChange={(value: string) => {
                  form.setValue("drawing", value);
                }}
              >
                <SelectTrigger className="w-full !h-10 !text-sm">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <ScrollArea className="h-[300px]">
                    {representations.map(
                      (representation: { drawing: string; name: string }) => (
                        <SelectItem
                          key={representation.drawing}
                          value={representation.drawing}
                        >
                          {representation.name}
                        </SelectItem>
                      )
                    )}
                  </ScrollArea>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="disabled_drawing"
        render={({ field }) => (
          <FormItem className="col-span-6 gap-x-1 flex items-center">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel>Sin representación en odontograma</FormLabel>
          </FormItem>
        )}
      />
    </>
  );
};
