import React from "react";
import { Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const InitialPayment: React.FC<{ form: Form<typeof formSchema> }> = ({
  form,
}) => {
  const { initial_payment, total_amount_with_discount } = form.watch();

  React.useEffect(() => {
    const value = (initial_payment?.percentage || 0) / 100 || 0;
    form.setValue(
      "initial_payment.amount",
      Math.round((total_amount_with_discount || 0) * value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    total_amount_with_discount,
    initial_payment?.percentage,
    initial_payment?.amount,
  ]);

  return (
    <>
      <FormField
        control={form.control}
        name="initial_payment.percentage"
        render={({ field }) => (
          <FormItem className="col-span-3">
            <FormLabel>% Pago inicial</FormLabel>
            <FormControl>
              <Input type="number" {...field} max={99} placeholder="0.0%" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="initial_payment.amount"
        render={({ field }) => (
          <FormItem className="col-span-3">
            <FormLabel>Valor pago inicial</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                max={99}
                placeholder="$0"
                disabled
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
