import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_bank: z.number(),
  name: z.string(),
  account_number: z.string(),
  description: z.string(),
  initial_balance: z.coerce.number(),
  enabled: z.boolean().nullable(),
  rowversion: z.string().nullable(),
  movements: z.array(z.any()).optional().nullable(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
