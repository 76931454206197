"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { z } from "zod";
import { HiOutlineSearch } from "react-icons/hi";
import { LuLoaderCircle } from "react-icons/lu";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
} from "@/components/molecules";
import {
  Button,
  Combobox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/atoms";
import { toast } from "@/hooks";
import { Appointment, Form as FormT, Professional } from "@/types/general";
import { getAppointments } from "@/actions/schedule";
import { ScheduleList } from "@/types/schedule";
import { addDaysToDate } from "@/utils/helpers";
import { appointmentMapper } from "@/utils/mappers";
import { PATIENT_TYPES } from "@/libs/schedule";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema } from "./schema";
import { SelectByCriteria } from "./select-by-criteria";
import { formSchema as formSchemaSchedule } from "../search/schema";

export function FindNextAvailableAppt({
  form,
  actions,
  setOpenModalForm,
  professionals,
}: {
  form: FormT<typeof formSchemaSchedule>;
  professionals: Professional[];
  actions: ({
    appointment,
    type,
    attendance,
  }: {
    appointment: Appointment;
    type: string;
    attendance: string;
  }) => void;
  setOpenModalForm: (value: boolean) => void;
}) {
  const {
    schedule: { params },
    setScheduleParams,
  } = useGlobalStore();
  const { date, id_specialty, id_office, id_professional } = form.getValues();
  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [openSelectCriteria, setOpenSelectCriteria] =
    React.useState<boolean>(false);
  const [nextDate, setNextDate] = React.useState<Date | string>(date);
  const [selected, setSelected] = React.useState<ScheduleList | null>(null);
  const [availableAppointments, setAvailableAppointments] = React.useState<
    ScheduleList[] | []
  >([]);
  const [loading, setLoading] = React.useState<{
    confirm: boolean;
    next: boolean;
    search: boolean;
    search_criteria: boolean;
  }>({
    confirm: false,
    next: false,
    search: false,
    search_criteria: false,
  });
  const appt_form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      id_patient_type: 1,
      criteria: "professional",
      id_last_prof: -1,
      office: "this_office",
    },
  });
  const { time, id_patient_type, criteria, id_last_prof, office } =
    appt_form.watch();
  const FORMAT_DATE =
    availableAppointments?.[0]?.date &&
    format(availableAppointments?.[0]?.date, "dd-MM-yyyy");
  const PROFESSIONAL = professionals?.find(
    (prof: Professional) => prof.id_professional === id_professional
  )?.name;
  const setLoadings = (type: string) => {
    return type !== "reset"
      ? setLoading(
          (prevState: {
            confirm: boolean;
            next: boolean;
            search: boolean;
            search_criteria: boolean;
          }) => ({
            ...prevState,
            [type]: !prevState[type as keyof typeof prevState],
          })
        )
      : setLoading({
          confirm: false,
          next: false,
          search: false,
          search_criteria: false,
        });
  };
  const setStateForm = (value: boolean) => {
    setOpenForm(value);
    setNextDate(addDaysToDate(format(new Date(), "yyyy/MM/dd"), 1));
  };
  const onSubmit = async ({
    type_loading,
    submit_criteria,
  }: {
    type_loading: string;
    submit_criteria?: boolean;
  }) => {
    if (!submit_criteria && (id_specialty !== -1 || id_office)) {
      return setOpenSelectCriteria(!openSelectCriteria);
    }

    setLoadings(type_loading);
    const ID_VALUES: {
      id_professional: number | null;
      id_specialty: number | null;
      id_office: number | null;
    } = {
      id_professional:
        criteria === "professional" || id_specialty === -1
          ? id_professional
          : -1,
      id_specialty: criteria === "specialty" ? id_specialty : null,
      id_office: office === "this_office" ? id_office : null,
    };

    const response = await getAppointments({
      ...ID_VALUES,
      date: nextDate,
      id_prof_finally: id_last_prof,
      option: "buscardiadisponible",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      submit_criteria && setOpenSelectCriteria(false);
      const availableAppointments = response.filter(
        (appointment: ScheduleList) =>
          appointment.id_schedule === -1 ||
          (!appointment.patient &&
            !appointment.id_patient &&
            !appointment.dont_give)
      );
      setAvailableAppointments(availableAppointments);
      appt_form.setValue("time", availableAppointments[0]?.time);
      criteria === "specialty" &&
        appt_form.setValue(
          "id_last_prof",
          availableAppointments[0]?.id_professional
        );
      setNextDate(
        id_specialty !== -1
          ? response?.[0]?.date
          : addDaysToDate(format(response?.[0]?.date, "yyyy/MM/dd"), 1)
      );
      setOpenForm(true);
      setSelected(availableAppointments[0]);
    }

    setLoadings("reset");
  };

  React.useEffect(() => {
    setNextDate(addDaysToDate(format(new Date(), "yyyy/MM/dd"), 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("date")]);
  React.useEffect(() => {
    const AVAILABLE_APPT = availableAppointments.find(
      (appt: ScheduleList) => appt.time === time
    );
    AVAILABLE_APPT && setSelected(AVAILABLE_APPT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appt_form.watch("time")]);

  return (
    <>
      <Dialog open={openForm} onOpenChange={(value) => setStateForm(value)}>
        <Tooltip>
          <TooltipProvider>
            <TooltipTrigger asChild>
              <Button
                onClick={() => onSubmit({ type_loading: "search" })}
                variant="outline"
                size="icon"
                disabled={loading.search}
                className="flex gap-x-2 w-9 h-9 text-blue-500 !border-blue-500 hover:!bg-blue-500 hover:text-white"
              >
                {loading.search ? (
                  <LuLoaderCircle className="size-4 animate-spin" />
                ) : (
                  <HiOutlineSearch className="size-4" />
                )}
                <span className="sr-only">Turno disponible</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Buscar turno disponible</TooltipContent>
          </TooltipProvider>
        </Tooltip>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Próximo turnos disponibles</DialogTitle>
            {loading.next ? (
              <Skeleton className="h-4 w-72" />
            ) : (
              <DialogDescription className="flex gap-x-2">
                El siguiente turno disponible es el
                <p className="text-slate-700 font-bold">
                  {FORMAT_DATE}
                </p> con{" "}
                <p className="text-slate-700 font-bold">{PROFESSIONAL}</p>
              </DialogDescription>
            )}
          </DialogHeader>
          <Form {...appt_form}>
            <form>
              <div className="grid grid-cols-4 gap-5 w-[500px]">
                {loading.next ? (
                  <>
                    <Skeleton className="col-span-2 h-10" />
                    <Skeleton className="col-span-2" />
                  </>
                ) : (
                  <>
                    <Combobox
                      form={appt_form}
                      data={availableAppointments}
                      dataKey="time"
                      value="time"
                      showInputSearch={false}
                      title="agenda"
                      className="col-span-2 !overflow-hidden"
                      classContent="!overflow-hidden !w-44"
                    />
                    <Combobox
                      form={appt_form}
                      data={PATIENT_TYPES}
                      dataKey="id_patient_type"
                      value="text"
                      showInputSearch={false}
                      title="agenda"
                      className=" col-span-2 !overflow-hidden"
                      classContent="!overflow-hidden"
                    />
                  </>
                )}
              </div>
              <div className="grid grid-cols-4 mt-5 gap-x-5">
                <DialogClose>
                  <Button
                    type="button"
                    className="w-full"
                    onClick={() => {
                      setStateForm(false);
                    }}
                    variant="destructive"
                  >
                    Cancelar
                  </Button>
                </DialogClose>
                {loading.confirm ? (
                  <ButtonLoading className="w-full" />
                ) : (
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormControl>
                        <Button
                          type="button"
                          className="w-full"
                          onClick={() => {
                            if (selected) {
                              field.onChange(new Date(selected.date), {
                                shouldValidate: true,
                              });
                              form.setValue(
                                "id_professional",
                                selected.id_professional,
                                { shouldValidate: true }
                              );
                              setScheduleParams({
                                params: {
                                  ...params,
                                  date: selected.date,
                                },
                              });
                              actions({
                                appointment: appointmentMapper(selected),
                                type:
                                  PATIENT_TYPES.find(
                                    (p: any) =>
                                      p.id_patient_type === id_patient_type
                                  )?.value || "",
                                attendance: selected.attendance || "",
                              });
                              setStateForm(false);
                              setOpenSelectCriteria(false);
                              setOpenModalForm(true);
                            }
                          }}
                        >
                          Confirmar turno
                        </Button>
                      </FormControl>
                    )}
                  />
                )}
                <Button
                  onClick={() =>
                    onSubmit({ type_loading: "next", submit_criteria: true })
                  }
                  className="w-full"
                  variant="ghost"
                  type="button"
                >
                  Buscar siguiente
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <Dialog open={openSelectCriteria} onOpenChange={setOpenSelectCriteria}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Selección de criterios</DialogTitle>
          </DialogHeader>
          <SelectByCriteria
            onSubmit={onSubmit}
            apptForm={appt_form}
            form={form}
            setLoading={setLoadings}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
