import classNames from "classnames";
import { numberToCurrency } from "@/utils/currency";

export const headerSections = ({
  professional_name,
  id_budget,
  total_amount_with_discount,
  budget_balance,
}: {
  total_amount_with_discount: number;
  professional_name: string;
  id_budget: string;
  budget_balance: number;
}) => {
  const MOVEMENTS_TYPE =
    budget_balance < total_amount_with_discount && budget_balance !== 0
      ? "Con movimiento"
      : total_amount_with_discount !== 0 && budget_balance === 0
      ? "Saldado"
      : "Sin movimiento";
  const STYLES_MOVEMENTS = {
    "Sin movimiento": "bg-slate-100 !text-slate-500 px-2 py-px",
    "Con movimiento": "bg-green-100 !text-green-500 px-2 py-px",
    Saldado: "bg-yellow-100 !text-yellow-500 px-2 py-px",
  };
  const SECTIONS_CLASS =
    "font-bold tracking-wide leading-5 text-slate-900 text-xl rounded";
  const RESULT_TABLE = [
    {
      title: "Profesional",
      value: professional_name?.toUpperCase(),
      styles: SECTIONS_CLASS,
    },
    {
      title: "Valor total",
      value: total_amount_with_discount
        ? numberToCurrency(total_amount_with_discount)
        : "-",
      styles: SECTIONS_CLASS,
    },
  ];
  if (!!id_budget) {
    RESULT_TABLE.push(
      {
        title: "Saldo",
        value: numberToCurrency(budget_balance),
        styles: SECTIONS_CLASS,
      },
      {
        title: "Estado",
        value: MOVEMENTS_TYPE,
        styles: classNames(SECTIONS_CLASS, STYLES_MOVEMENTS[MOVEMENTS_TYPE]),
      }
    );
  }

  return RESULT_TABLE;
};
