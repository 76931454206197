"use client";

import React from "react";
import { z } from "zod";
import {
  Button,
  Checkbox,
  Input,
  Separator,
  Combobox,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { toast, useKeypress } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { BENEFIT_STATES, DEFAULT_GROUPS, INITIAL_STATE } from "@/libs/reports";
import { createAuditorReport } from "@/actions/reports";
import { formSchema } from "./schema";
import { Group, MedicalInsurance, Professional } from "@/types/general";

export function ReportsForm({
  form,
  values,
}: {
  form: any;
  values: {
    medical_insurances: MedicalInsurance[];
    professionals: Professional[];
  };
}) {
  const [groups, setGroups] = React.useState(DEFAULT_GROUPS);
  const { medical_insurances, professionals } = values;
  const {
    reports,
    setDataReports,
    startLoading,
    stopLoading,
    loading,
    general_params: { translations },
  } = useGlobalStore();
  const filteredMedicalInsurances = medical_insurances?.filter(
    (item: MedicalInsurance) => !item.disabled
  );
  const filteredProfessionals = professionals?.filter(
    (item: Professional) => item.enabled
  );

  async function onSubmit({ values }: { values: z.infer<typeof formSchema> }) {
    startLoading();
    const response = await createAuditorReport(values);

    if ("error" in response) {
      setDataReports({
        data: INITIAL_STATE.data,
        params: values,
        section: "auditor",
      });
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const { services, total_amount, total_services, total_pages } = response;
      setDataReports({
        data: { services, total_amount, total_services, total_pages },
        params: values,
        section: "auditor",
      });
      toast({
        variant: "successful",
        title: "Reporte generado correctamente",
        description: "Ahora puede ver los datos del reporte generado.",
      });
    }

    stopLoading();
  }

  useKeypress({
    key: "Enter",
    action: form.handleSubmit((values: any) => onSubmit({ values })),
  });

  React.useMemo(() => {
    const { id_med_ins, all_groups } = form.getValues();
    const medInsGroups = medical_insurances?.find(
      (m: MedicalInsurance) => m.id_med_ins === id_med_ins
    )?.groups;
    setGroups(medInsGroups?.length ? medInsGroups : DEFAULT_GROUPS);
    const defaultGroup = all_groups
      ? null
      : medInsGroups?.find(
          (g: Group) => g.id_group === reports?.params?.id_group
        )?.id_group || groups[0].id_group;

    form.setValue("id_group", defaultGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("id_med_ins")]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit((values: any) => onSubmit({ values }))}>
        <div
          className="rounded-md space-y-4 justify-start flex flex-col h-[100vh]"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          <FormField
            control={form.control}
            name="period"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Período</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="id_med_ins"
            render={() => (
              <FormItem className="col-span-4">
                <FormLabel>{translations?.medical_insurance}</FormLabel>
                <FormControl>
                  <Combobox
                    form={form}
                    data={filteredMedicalInsurances}
                    dataKey="id_med_ins"
                    value="medical_insurance"
                    placeholder={`Busca un/a ${translations?.medical_insurance}`}
                    title={translations?.medical_insurance}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="id_benefits_state"
            render={() => (
              <FormItem className="col-span-4">
                <FormLabel>Prestaciones</FormLabel>
                <FormControl>
                  <Combobox
                    form={form}
                    data={BENEFIT_STATES}
                    dataKey="id_benefits_state"
                    value="text"
                    showInputSearch={false}
                    title="Prestacion"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator className="bg-slate-200 h-px" />
          <p className="text-slate-800 text-lg font-bold">Grupos</p>
          <FormField
            control={form.control}
            name="id_group"
            render={() => (
              <FormItem className="col-span-4">
                <FormControl>
                  <Combobox
                    form={form}
                    data={groups}
                    dataKey="id_group"
                    value="description"
                    placeholder="Busca un grupo"
                    title="Grupos"
                    disabled={form.getValues().all_groups}
                    showInputSearch={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="all_groups"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked);
                      if (checked) {
                        return form.setValue("id_group", null);
                      }
                      return form.setValue("id_group", groups?.[0]?.id_group);
                    }}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!mb-0">Todos los grupos</FormLabel>
                  <p className="text-sm font-regular text-gray-400">
                    Se incluirán todos los grupos al reporte.
                  </p>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_rebilling"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!mb-0">Es refacturación</FormLabel>
                  <p className="text-sm font-regular text-gray-400">
                    Indica que ya se realizó una liquidación previa y se
                    realizaron modificaciones.
                  </p>
                </div>
              </FormItem>
            )}
          />
          <Separator className="bg-slate-200 h-px" />
          <p className="text-slate-800 text-lg font-bold">Profesionales</p>
          <FormField
            control={form.control}
            name="id_professional"
            render={() => (
              <FormItem className="col-span-4">
                <FormLabel>Profesionales</FormLabel>
                <FormControl>
                  <Combobox
                    form={form}
                    data={filteredProfessionals}
                    dataKey="id_professional"
                    value="name"
                    placeholder="Busca un profesional"
                    title="Profesional"
                    disabled={form.getValues().all_prof}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="all_prof"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked);
                      if (checked) {
                        return form.setValue("id_professional", null);
                      }
                      return form.setValue(
                        "id_professional",
                        filteredProfessionals?.[0]?.id_professional
                      );
                    }}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!mb-0">
                    Todos los profesionales
                  </FormLabel>
                  <p className="text-sm font-regular text-gray-400">
                    Se incluirán todos los profesionales al reporte.
                  </p>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="include_prof_code"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!mb-0">
                    Usar código de profesional
                  </FormLabel>
                  <p className="text-sm font-regular text-gray-400">
                    Genera el reporte con código de profesionales.
                  </p>
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="w-full lg:w-auto flex flex-row justify-center items-center lg:justify-end gap-x-5 mt-8">
          {loading ? (
            <ButtonLoading className="w-full" />
          ) : (
            <Button
              type="submit"
              className="w-full"
              disabled={!form.getValues().period}
            >
              Generar reporte
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
