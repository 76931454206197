import Image from "next/image";
import Link from "next/link";
import classNames from "classnames";
import { LuChevronRight } from "react-icons/lu";
import { HiCheckCircle } from "react-icons/hi";
import {
  Dialog,
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  buttonVariants,
  CardFooter,
  CardDescription,
} from "@/components/atoms";

type GeistCardProps = {
  data: {
    title: string;
    description: () => React.ReactNode;
    features: string[];
    image: {
      src: string;
      alt: string;
    };
    buttons: {
      primary: {
        text: string;
        href: string;
      };
      secondary: {
        text: string;
        href: string;
      };
    };
  };
};

export const GeistCard = ({ data }: GeistCardProps) => (
  <Dialog defaultOpen>
    <Card className="absolute z-50 w-[880px] h-fit inset-0 mx-auto my-auto grid grid-cols-12 !p-0 !shadow-lg">
      <div className="col-span-6 p-5">
        <CardHeader>
          <CardTitle>{data.title}</CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription className="!text-lg">
            {data.description()}
            <ul className="flex flex-col gap-y-2 mt-5">
              {data.features.map((feature: string, index: number) => (
                <li key={index} className="grid grid-cols-10 items-center">
                  <HiCheckCircle className="mr-2 text-blue-500 size-6 col-span-1" />
                  <p className="col-span-9">{feature}</p>
                </li>
              ))}
            </ul>
          </CardDescription>
        </CardContent>
        <CardFooter className="grid grid-cols-6 gap-x-5 self-end">
          <Link
            href={data.buttons.primary.href}
            className={classNames(
              "col-span-3",
              buttonVariants({ variant: "default" })
            )}
          >
            {data.buttons.primary.text}
          </Link>
          <Link
            href={data.buttons.secondary.href}
            target="_blank"
            className={classNames(
              "col-span-3 flex text-blue-500",
              buttonVariants({ variant: "ghost" })
            )}
          >
            {data.buttons.secondary.text}
            <LuChevronRight className="ml-2" />
          </Link>
        </CardFooter>
      </div>
      <div className="relative col-span-6">
        <Image
          className="rounded-r-lg"
          fill
          alt={data.image.alt}
          src={data.image.src}
        />
      </div>
    </Card>
  </Dialog>
);
