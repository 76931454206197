import { format } from "date-fns";

export const columns = [
  {
    accessorKey: "date",
    header: "Fecha",
    cell: ({ row }: { row: any }) => {
      const { date } = row.original;

      return (
        <p className="text-sm leading-6 text-gray-500 my-1">
          {format(date, "dd/MM/yyyy")}
        </p>
      );
    },
  },
  {
    accessorKey: "hour",
    header: "Hora",
    cell: ({ row }: { row: any }) => {
      const { hour } = row.original;

      return (
        <p className="text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
          {hour}
        </p>
      );
    },
  },
  {
    accessorKey: "patient",
    header: "Paciente",
    cell: ({ row }: { row: any }) => {
      const { patient } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {patient}
        </p>
      );
    },
  },
  {
    accessorKey: "professional",
    header: "Profesional",
    cell: ({ row }: { row: any }) => {
      const { professional } = row.original;

      return <p className="leading-6 text-slate-800 my-1">{professional}</p>;
    },
  },
];
