import * as React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { Row } from "@tanstack/react-table";
import { LuCircleCheck } from "react-icons/lu";
import { FcCancel } from "react-icons/fc";
import { Badge } from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { Translations } from "@/types/general";
import { Permissions } from "@/types/permissions";
import { Alls } from "@/types/patients";

export const columns = [
  {
    accessorKey: "id_budget",
    header: "Nro. Presupuesto",
    cell: ({ row }: { row: any }) => {
      const { id_budget } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{id_budget}</p>
      );
    },
  },
  {
    accessorKey: "date",
    header: "Fecha",
    cell: ({ row }: { row: any }) => {
      const { date } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">
          {format(date, "dd-MM-yyyy")}
        </p>
      );
    },
  },
  {
    accessorKey: "concept",
    header: "Descripción",
    cell: ({ row }: { row: any }) => {
      const { concept } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{concept}</p>
      );
    },
  },
  {
    accessorKey: "professional_navigation",
    header: "Profesional",
    cell: ({ row }: { row: any }) => {
      const { professional_navigation } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">
          {professional_navigation.name}
        </p>
      );
    },
  },
  {
    accessorKey: "receipt",
    header: "Comprobante",
    cell: ({ row }: { row: any }) => {
      const { receipt } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{receipt}</p>
      );
    },
  },
  {
    accessorKey: "type",
    header: "Tipo",
    cell: ({ row }: { row: any }) => {
      const { type } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{type}</p>
      );
    },
  },
  {
    accessorKey: "number",
    header: "Número",
    cell: ({ row }: { row: any }) => {
      const { number } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{number}</p>
      );
    },
  },
  {
    accessorKey: "method",
    header: "Forma de Pago",
    cell: ({ row }: { row: any }) => {
      const { method } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{method}</p>
      );
    },
  },
  {
    accessorKey: "assets",
    header: "Import",
    cell: ({ row }: { row: any }) => {
      const { assets } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">{assets}</p>
      );
    },
  },
  {
    accessorKey: "tax",
    header: "% IVA",
    cell: ({ row }: { row: any }) => {
      const { tax } = row.original;

      return <p className="font-bold text-sm leading-6 text-gray-500">{tax}</p>;
    },
  },
];
