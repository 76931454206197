"use client";

import React from "react";
import classNames from "classnames";
import Image from "next/image";
import { RadioGroup, RadioGroupItem } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";

export const Carousel = ({
  data,
  name,
  form,
}: {
  data: {
    name: string;
    state: string;
    content: string;
    buttons: any;
  }[];
  name: string;
  form: any;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.name}
            >
              <div className="grid grid-cols-3 gap-6 mb-4">
                {data.map((temp, index) => (
                  <FormItem
                    key={index}
                    className="flex justify-center items-center space-x-3 space-y-0"
                  >
                    <FormControl>
                      <RadioGroupItem value={temp.name} className="hidden" />
                    </FormControl>
                    <FormLabel
                      className={classNames(
                        "cursor-pointer border rounded-md overflow-hidden transform transition hover:scale-105",
                        field.value === temp.name
                          ? "border-blue-500 ring-2 ring-blue-400"
                          : "border-gray-300"
                      )}
                    >
                      <div className="overflow-hidden rounded-md">
                        <Image
                          src={`/whatsapp-templates/${temp.name}.png`}
                          alt="template"
                          width={200}
                          height={350}
                          className="w-full object-cover"
                        />
                      </div>
                    </FormLabel>
                  </FormItem>
                ))}
              </div>
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
