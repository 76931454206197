import React from "react";

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window?.innerWidth);
      const handleResize = () => setWindowWidth(window?.innerWidth);
      window?.addEventListener("resize", handleResize);

      return () => window?.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowWidth;
};
