import React from "react";
import classNames from "classnames";
import { FacetedFilter, Visibility } from "./filters";

export function TableToolbar<TData>({
  table,
  options,
  config,
}: any & { config: any }) {
  return (
    <div
      className={classNames(
        "flex items-center mb-2",
        config.params ? "justify-between" : "justify-end"
      )}
    >
      {config.params && (
        <FacetedFilter
          title="Filtrar por moneda"
          name="is_usd"
          table={table}
          options={options}
        />
      )}
      <Visibility table={table} />
    </div>
  );
}
