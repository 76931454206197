"use client";
import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  ButtonLoading,
  DatePicker,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Button,
  SheetFooter,
  Input,
  Checkbox,
  Separator,
} from "@/components/atoms";
import { Offices } from "@/types/general";
import { toast, useDialog } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { updateOfficesConfig } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export const OfficesForm = ({ office }: { office?: Offices }) => {
  const { closeDialog } = useDialog();
  const { startLoading, stopLoading, loading } = useGlobalStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      name: office?.name || "",
      description: office?.description || "",
      enabled_office: office?.enabled || true,
      enabled_billing: !!office?.billing_user || false,
      user: office?.billing_user,
      password: office?.billing_password,
      date_cash_closing: office?.date_cash_closing
        ? new Date(office.date_cash_closing)
        : undefined,
      sales_point: office?.sales_point,
      cash_balance_usd: office?.balance_usd,
      cash_balance: office?.balance,
    },
  });
  const { enabled_billing } = form.watch();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const option = office ? "modificar" : "nuevo";
    const billing = {
      user: null,
      password: null,
      date_cash_closing: null,
      sales_point: undefined,
      cash_balance_usd: null,
      cash_balance: null,
    };

    const response = await updateOfficesConfig({
      ...office,
      ...values,
      ...(values.enabled_billing ? {} : billing),
      option,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: office
          ? "Sucursal actualizada correctamente"
          : "Sucursal creada correctamente",
        description: "Los datos se verán reflejados en las tablas.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <SheetContent className="w-full !max-w-[550px] !text-start flex flex-col justify-between">
      <SheetTitle className="p-2 font-semibold text-xl">
        {office ? "Modificar sucursal" : "Nueva sucursal"}
      </SheetTitle>
      <div className="overflow-y-scroll h-[calc(100vh-173px)]">
        <SheetHeader className="!text-start">
          <SheetDescription className="px-2">
            Datos de sucursal
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full grid gap-4 overflow-hidden bg-white p-2"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nombre de la sucursal</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Descripción</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enabled_office"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Sucursal habilitada</FormLabel>
                    <FormDescription>
                      Habilita o deshabilita la sucursal.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <Separator />
            <SheetDescription>Datos de Facturación</SheetDescription>
            <FormField
              control={form.control}
              name="enabled_billing"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Habilitar facturación</FormLabel>
                    <FormDescription>
                      Define esta sucursal como punto de venta.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="user"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Usuario</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={!enabled_billing} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contraseña</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={!enabled_billing} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-x-4 justify-between">
              <FormField
                control={form.control}
                name="sales_point"
                render={({ field }) => (
                  <FormItem className="!w-full">
                    <FormLabel>Punto de venta</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={!enabled_billing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col !w-full">
                <FormLabel className="!m-1">Fecha último cierre</FormLabel>
                <DatePicker
                  form={form}
                  name="date_cash_closing"
                  disabled={!enabled_billing}
                />
              </div>
            </div>
            <div className="flex gap-x-4 justify-between">
              <FormField
                control={form.control}
                name="cash_balance"
                render={({ field }) => (
                  <FormItem className="!w-full">
                    <FormLabel>Saldo de caja</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={!enabled_billing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cash_balance_usd"
                render={({ field }) => (
                  <FormItem className="!w-full">
                    <FormLabel>Saldo de caja USD</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={!enabled_billing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
      <SheetFooter>
        <SheetClose>
          <Button variant="destructive" type="button">
            Cancelar
          </Button>
        </SheetClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button className="bg-blue-600" onClick={form.handleSubmit(onSubmit)}>
            Guardar
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
};
