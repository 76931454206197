import { Benefit } from "@/types/general";
import { Alls, Navigation } from "@/types/patients";

export const validationToothSection = (
  benefitSelected?: Benefit | Navigation | null
) => {
  return !benefitSelected || !["DIE", "ALG"].includes(benefitSelected?.tooth);
};

export const filterAllByTooth = (patient_benefits: Alls[], tooth: string) => {
  let FILTER_ARRAY: Alls[] = [];
  patient_benefits?.forEach((t: Alls) => {
    if (t.tooth_sector?.trim() === tooth) {
      FILTER_ARRAY.push(t);
    }
  });

  return FILTER_ARRAY;
};

export function ageIsMajorOrMinor(str: string): "major" | "minor" | "none" {
  if (str.includes(">")) return "major";
  if (str.includes("<")) return "minor";
  return "none";
}

export function formatAgeForBenefit({
  age_text,
  age_number,
}: {
  age_text: string;
  age_number: number;
}) {
  if (age_text === "none") return "0";
  if (age_text === "major") return `> ${age_number}`;
  if (age_text === "minor") return `< ${age_number}`;
}
