import React from "react";
import Image from "next/image";
import classNames from "classnames";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";

export const Layout: React.FC<{
  odontogram: any;
  actions: any;
}> = ({ odontogram, actions }) => {
  const [toothSelect, setToothSelect] = React.useState(null);
  function splitOdontogram(odontogram: any) {
    let i,
      j,
      splitted,
      newArr = [],
      chunk = odontogram.length / 4;
    for (i = 0, j = odontogram.length; i < j; i += chunk) {
      splitted = odontogram.slice(i, i + chunk);
      newArr.push(splitted);
    }
    return newArr;
  }
  const splitted = odontogram.length && splitOdontogram(odontogram);

  // Refactor this!
  return splitted && true ? (
    <div className="col-span-12 grid grid-cols-2 p-4">
      {splitted.map((e: any, qi: number) => (
        <div
          key={qi}
          className={`flex p-2 items-center justify-center border-slate-400 ${
            qi % 2 === 0 ? "border-r" : "border-l"
          } ${qi < 2 ? "border-b" : "border-t"}`}
          data-quadrant={qi + 1}
        >
          {e.map((o: any, i: number) => (
            <DropdownMenu key={i}>
              <DropdownMenuTrigger
                className="w-auto h-auto"
                onMouseEnter={() => setToothSelect(o.tooth)}
              >
                <div className="m-2" key={i}>
                  {o.image_sourcer ? (
                    <Image
                      className={classNames("w-12 h-16 rounded-md", {
                        ring: o.tooth === toothSelect,
                      })}
                      key={i}
                      alt="odontograma"
                      src={`data:image/jpeg;base64,${o.image_sourcer}`}
                      width={40}
                      height={40}
                    />
                  ) : (
                    <div className="h-14 w-8 m-2 hover:cursor-pointer" />
                  )}
                  <p className="flex justify-center w-auto">{o.tooth}</p>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => {
                      actions["new_benefit"](o.tooth);
                    }}
                  >
                    <span>Cargar nueva prestación</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => {
                      actions["view_details"](o.tooth);
                    }}
                  >
                    <span>Ver detalles</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          ))}
        </div>
      ))}
    </div>
  ) : //
  null;
};
