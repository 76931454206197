import React from "react";
import Link from "next/link";
import classNames from "classnames";
import { LuArrowRight as ArrowRight } from "react-icons/lu";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  Button,
  buttonVariants,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { INITIAL_PATIENT_DATA } from "@/libs/patients";

export type ShorcutCardProps = {
  title: string;
  description: string;
  text: string;
  type: "route" | "external";
  route: string | "";
  icon: any;
};

export const ShorcutCard: React.FC<ShorcutCardProps> = (shortcut) => {
  const { resetPatienData, patient, setShowfloatingbar } = useGlobalStore();
  return (
    <Card className="shadow-lg shadow-blue-600/20 rounded bg-blue-100 grid grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2 p-4">
      <CardHeader className="justify-between">
        <span className="mb-1">
          <CardTitle className="font-black text-slate-900 text-2xl mb-3 whitespace-nowrap">
            {shortcut.title}
          </CardTitle>
          <CardDescription className="text-base xl:text-sm  ml-0 text-left leading-5 tracking-tight">
            {shortcut.description}
          </CardDescription>
        </span>
        {shortcut.type === "route" ? (
          <Link
            href={shortcut.route}
            className={classNames(
              "h-10 w-full flex items-center",
              buttonVariants({ variant: "secondary" })
            )}
            onClick={() => {
              if (
                ["Nuevo paciente"].includes(shortcut.title) &&
                patient.id_patient
              ) {
                resetPatienData(INITIAL_PATIENT_DATA);
              }
            }}
          >
            <p className="text-blue-500 text-left text-sm font-semibold whitespace-nowrap  group-hover:text-blue-400">
              {shortcut.text}
            </p>
            <ArrowRight className="text-blue-600 h-4 group-hover:text-blue-400" />
          </Link>
        ) : (
          <Button
            variant="secondary"
            onClick={setShowfloatingbar}
            className="h-10 w-full flex items-center"
          >
            <p className="text-blue-500 text-left text-sm font-semibold whitespace-nowrap  group-hover:text-blue-400">
              {shortcut.text}
            </p>
            <ArrowRight className="text-blue-600 h-4 group-hover:text-blue-400" />
          </Button>
        )}
      </CardHeader>
      <div className="justify-center items-center lg:hidden flex 2xl:flex">
        <div className="w-28 h-28 rounded-full bg-gradient-to-b from-blue-300 to-blue-200 flex justify-center items-center">
          <div className="w-24 h-24 rounded-full bg-gradient-to-b from-blue-400 to-blue-200 flex justify-center items-center">
            <shortcut.icon className=" text-blue-500 rounded-full bg-white w-20 h-20 p-6" />
          </div>
        </div>
      </div>
    </Card>
  );
};
