"use client";

import { debounce } from "lodash";
import { useEffect, useCallback, useState } from "react";
import {
  type UseFormWatch,
  type FieldValues,
  type UseFormTrigger,
} from "react-hook-form";

type AutosubmitProps<T extends FieldValues> = {
  trigger: UseFormTrigger<T>;
  watch: UseFormWatch<T>;
  onSubmit: () => void;
  onValidationFailed?: () => void;
  debounceTime?: number;
};

export function useAutoSubmit<T extends FieldValues>({
  trigger,
  watch,
  onSubmit,
  onValidationFailed,
  debounceTime = 500,
}: AutosubmitProps<T>) {
  const [submit, setSubmit] = useState(false);
  const debouncedSubmit = useCallback(
    debounce((submitFn: () => void) => {
      submitFn();
    }, debounceTime),
    []
  );

  useEffect(() => {
    const subscription = watch((_data, info) => {
      if (info?.type !== "change") return;
      setSubmit(true);
      trigger()
        .then((isValid) => {
          if (isValid) {
            debouncedSubmit(onSubmit);
          } else {
            onValidationFailed?.();
          }
        })
        .finally(() => {
          setSubmit(false);
        });
    });

    return () => subscription.unsubscribe();
  }, [watch, onSubmit, onValidationFailed]);

  return { submit };
}
