import React from "react";
import { Form } from "@/types/general";
import { formSchema } from "./schema";
import { Input, RadioGroup, RadioGroupItem } from "@/components/atoms";
import { PAYMENT_OPTIONS } from "@/libs/patients";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";

export const ValuesToPay = ({ form }: { form: Form<typeof formSchema> }) => {
  const [selectedInput, setSelectedInput] = React.useState<{
    [key: string]: string;
  }>({
    patient_input_type: "",
    med_ins_input_type: "",
  });

  return (
    <div className="col-span-full grid grid-cols-12 gap-x-6">
      {PAYMENT_OPTIONS.map((option: any) => (
        <RadioGroup
          key={option.inputType}
          disabled={!form.watch("change_amount_professional")}
          onValueChange={(value) =>
            setSelectedInput((prev) => ({ ...prev, [option.inputType]: value }))
          }
          defaultValue={selectedInput[option.inputType]}
          className="flex flex-col col-span-6 space-y-1"
        >
          {option.fields.map((value: any, index: number) => (
            <FormField
              key={index}
              control={form.control}
              name={value.name}
              disabled={
                selectedInput[option.inputType] !== value.disableCondition ||
                !form.watch("change_amount_professional")
              }
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel className="flex items-center gap-x-2">
                    <RadioGroupItem value={value.disableCondition} />
                    {value.label}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      value={
                        selectedInput[option.inputType] !==
                        value.disableCondition
                          ? ""
                          : field.value || ""
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
        </RadioGroup>
      ))}
    </div>
  );
};
