import React from "react";
import Link from "next/link";
import classNames from "classnames";
import {
  Button,
  buttonVariants,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { useDialog } from "@/hooks";

export const FreemiumForm = () => {
  const {
    user: { web_user },
    general_params: {
      user_params: { email },
    },
  } = useGlobalStore();
  const { closeDialog } = useDialog();

  return (
    <DialogContent>
      <DialogHeader>No puedes realizar esta acción</DialogHeader>
      <DialogDescription className="text-base font-regular text-neutral-500">
        Esta función esta disponible solamente en la versión de Bilog Premium.
        ¿Quieres suscribirte a Bilog Premium?
      </DialogDescription>
      <div className="flex items-center justify-end gap-x-4">
        <Button variant="destructive" onClick={closeDialog}>
          Cancelar
        </Button>
        <Link
          className={classNames(buttonVariants({ variant: "ghost" }))}
          href={`https://signup.bilog.com.ar/checkout?email=${email}&webuser=${web_user}`}
        >
          Contratar ahora
        </Link>
      </div>
    </DialogContent>
  );
};
