import classNames from "classnames";
import { LuTrash, LuPlus } from "react-icons/lu";
import { Input, Button } from "@/components/atoms";
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { CardInstallment } from "@/types/general";

export function InstallmentsInputs({
  installments,
  form,
  remove,
  append,
  disabled,
}: {
  installments: CardInstallment[];
  form: any;
  disabled?: boolean;
  remove?: (index: number) => void;
  append?: (installment: CardInstallment) => void;
}) {
  const { is_credit } = form.getValues();
  const IS_CREDIT_CARD = ["credit"].includes(is_credit);
  const DISABLED = disabled || !IS_CREDIT_CARD;
  const cols = classNames(!!remove ? "col-span-5" : "col-span-6");
  const NEW_INSTALLMENT = {
    id_card: (installments?.[0]?.id_card as number) || 0,
    id_card_installment: 0,
    installment_number: 0,
    interest: 0,
    id_card_navigation: null,
  };

  return (
    <div
      className={classNames(
        "col-span-12 grid grid-cols-12 gap-x-4 gap-y-6 max-h-[300px] overflow-y-auto"
      )}
    >
      <FormLabel className={classNames(cols, { "text-slate-400": DISABLED })}>
        N° de cuotas
      </FormLabel>
      <FormLabel
        className={classNames("col-span-6", { "text-slate-400": DISABLED })}
      >
        % de interés
      </FormLabel>
      {installments.map((_: any, index: number) => (
        <>
          <FormField
            control={form.control}
            name={`card_installments.${index}.installment_number`}
            render={({ field }) => (
              <FormItem className={cols}>
                <FormControl>
                  <Input disabled={DISABLED} type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`card_installments.${index}.interest`}
            render={({ field }) => (
              <FormItem className={"col-span-6"}>
                <FormControl>
                  <Input
                    disabled={DISABLED}
                    min={0}
                    max={100}
                    type="number"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!!remove && (
            <Button
              disabled={DISABLED}
              variant="secondary"
              size="icon"
              className="col-span-1 !text-red-500 hover:cursor-pointer hover:bg-red-100"
              onClick={(e) => {
                e.preventDefault();
                remove(index);
              }}
            >
              <LuTrash className="size-4" />
              <span className="sr-only">Eliminar</span>
            </Button>
          )}
        </>
      ))}
      {!!append && (
        <Button
          disabled={DISABLED}
          variant="ghost"
          className="col-span-12 hover:cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            append(NEW_INSTALLMENT);
          }}
        >
          <LuPlus className="size-4" />
          <p>Agregar cuota</p>
        </Button>
      )}
    </div>
  );
}
