import React from "react";
import classNames from "classnames";
import { LuArrowRight } from "react-icons/lu";
import { format } from "date-fns";
import { INSTALLMENTS, PAYMENT_METHOD, TAXES } from "@/libs/patients";
import { numberToCurrency } from "@/utils/currency";

export const SummarySteps: React.FC<any> = ({
  data,
  setTabIndex,
  canEdit,
  form,
}) => {
  const { medical_insurance, professional_name, description, date, plan } =
    data["summary_steps"];
  const {
    id_tax,
    id_installment,
    affiliate_number,
    estimated_time,
    interest_rate,
    installment_value,
    sessions,
    discount,
    initial_payment,
    observations,
    id_payment_method,
  } = form.watch();
  const TAX = TAXES?.find(
    (t: { [key: string]: any }) => t.id_tax === id_tax
  )?.text;
  const INSTALLMENT = INSTALLMENTS.find(
    (q) => q.id_installment === id_installment
  )?.text;
  const METHOD = PAYMENT_METHOD?.find(
    (q: { [key: string]: any }) => q.id_payment_method === id_payment_method
  );
  const CARDS_DATA = [
    {
      title: "Datos Presupuesto",
      className: "col-span-6 border border-b-slate-300 rounded-md bg-white",
      content: [
        {
          label: "Descripción:",
          value: description,
        },
        {
          label: "Fecha:",
          value: format(date, "dd-MM-yyyy"),
        },
        {
          label: "Profesional:",
          value: professional_name,
        },
      ],
      tabIndex: 0,
    },
    {
      title: "Datos del seguro médico",
      className: "col-span-6 border border-b-slate-300 rounded-md bg-white",
      content: [
        {
          label: "O.S:",
          value: medical_insurance,
        },
        {
          label: "Plan:",
          value: plan,
        },
        {
          label: "Nro. Afiliado:",
          value: affiliate_number,
        },
      ],
      tabIndex: 1,
    },
    {
      title: "Datos de pago",
      className: "col-span-12 border border-b-slate-300 rounded-md bg-white",
      content: [
        {
          label: "Tiempo estimado:",
          value: estimated_time,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Importe descuento:",
          value: numberToCurrency(Number(discount.amount)),
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Cant de sesiones:",
          value: sessions,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "% de interés:",
          value: interest_rate,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Formas de Pago:",
          value: METHOD?.text || "-",
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "IVA:",
          value: TAX,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "% Pago inicial:",
          value: initial_payment.percentage,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Cuotas:",
          value: INSTALLMENT,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Valor inicial:",
          value: numberToCurrency(initial_payment.amount),
          styles: "col-span-3 flex flex-row text-green-500",
        },

        {
          label: "Observaciones:",
          value: observations,
          styles: "col-span-3 flex flex-row truncate",
        },
        {
          label: "% de descuento:",
          value: discount.percentage,
          styles: "col-span-3 flex flex-row",
        },
        {
          label: "Valor de c/ cuota:",
          value: numberToCurrency(installment_value),
          styles: "col-span-3 flex flex-row text-green-500",
        },
      ],
      tabIndex: 2,
    },
  ];

  const renderContent = (
    content: { label: string; value: string; styles?: string }[]
  ) => {
    const HAS_MORE = content.length > 3;

    return (
      <div
        className={classNames(
          "p-4 text-base rounded-md truncate mr-2",
          HAS_MORE ? "grid grid-cols-12" : "flex flex-col"
        )}
      >
        {HAS_MORE
          ? content.map((item, i) => {
              return (
                <div key={i} className={item.styles}>
                  <span className="text-neutral-500">{item.label}</span>
                  <span className="ml-2">{item.value}</span>
                </div>
              );
            })
          : content.map((item, i) => (
              <div key={i} className="flex flex-row">
                <span className="text-neutral-500">{item.label}</span>
                <span className="ml-2 text-slate-900">{item.value}</span>
              </div>
            ))}
      </div>
    );
  };

  return CARDS_DATA.map((item, index) => (
    <div key={index} className={item.className}>
      <div className="flex items-center border-b border-b-slate-300 p-4">
        <span className="h-6 w-6 mr-2 flex items-center justify-center rounded-full border-2 border-slate-800">
          <p className="text-sm font-bold text-slate-800">{index + 1}</p>
        </span>
        <span className="text-2xl">{item.title}</span>
      </div>
      {renderContent(item.content)}
      {canEdit && (
        <p
          className="group flex text-base text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer"
          onClick={() => setTabIndex(item.tabIndex)}
        >
          Modificar
          <LuArrowRight className="h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3" />
        </p>
      )}
    </div>
  ));
};
