import React from "react";
import { z } from "zod";
import classNames from "classnames";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
} from "@/components/atoms";
import { Form, Laboratory, Offices, Professional } from "@/types/general";
import { Patient } from "@/types/patients";
import { useGlobalStore } from "@/providers/global-provider";
import { useStateAcordeon } from "@/hooks";
import { ConceptsTable } from "@/components/organisms/tables";
import { LoadWork } from "../work-load";
import { formSchema } from "../schema";

export const Second: React.FC<{
  data: {
    laboratories: Laboratory[];
    offices: Offices[];
    professionals: Professional[];
    patient: Patient;
  };
  viewLoadOrder: {
    hiddenLoadOrder: boolean;
    setHiddenLoadOrder: React.Dispatch<React.SetStateAction<boolean>>;
  };
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({
  data,
  form,
  setStep,
  viewLoadOrder: { hiddenLoadOrder, setHiddenLoadOrder },
}) => {
  const { ref_header } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const { order_from_list, order_list } = form.watch();
  const TABLE_VALIDATION = order_list?.length > 0;
  const onLoadWork = () => {
    setHiddenLoadOrder(!hiddenLoadOrder);
    form.setValue("id_concept", null);
    form.setValue("order_from_list", {
      id_concept: null,
      code: null,
      description: "",
      is_usd: false,
      id_order_concept: Math.random(),
      amount: 0,
      quantity: 1,
      total_amount: 0,
      color: "",
      tooth_section: "",
      laboratory_items: null,
      laboratory_navigation: null,
      rowversion: null,
    });
  };
  const actions: any = {
    edit: (selectedRow: z.infer<typeof formSchema>["order_from_list"]) => {
      const ORDER_SELECTED = order_list?.find(
        (order: z.infer<typeof formSchema>["order_from_list"]) =>
          order.id_order_concept === selectedRow.id_order_concept
      );
      form.setValue("order_from_list", {
        ...order_from_list,
        ...ORDER_SELECTED,
      });
      form.setValue("is_manual_concept", !selectedRow.code);
      setHiddenLoadOrder(!hiddenLoadOrder);
    },
    delete: (selectedRow: z.infer<typeof formSchema>["order_from_list"]) => {
      const FILTER_ORDERS = order_list?.filter(
        (order: z.infer<typeof formSchema>["order_from_list"]) =>
          order.id_order_concept !== selectedRow.id_order_concept
      );
      form.setValue("order_list", FILTER_ORDERS);
    },
  };
  const action = (
    order: z.infer<typeof formSchema>["order_from_list"],
    action: string
  ) => {
    actions[action](order);
  };
  React.useMemo(() => {
    const total_amount = order_list.reduce(
      (sum: number, order: z.infer<typeof formSchema>["order_from_list"]) =>
        sum + (order.total_amount || 0),
      0
    );
    const total_quantity = order_list.reduce(
      (sum: number, order: z.infer<typeof formSchema>["order_from_list"]) =>
        sum + (Number(order.quantity) || 0),
      0
    );
    form.setValue("total_amount_order_list", total_amount);
    form.setValue("total_quantity_order_list", total_quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_list]);

  return (
    <Card
      className={classNames(
        "!rounded-t-none overflow-y-auto !w-full h-[calc(100vh-245px)]",
        {
          "h-[calc(100vh-310px)]":
            STATE_ACCORDION && STATE_ACCORDION !== "closed",
        }
      )}
    >
      <CardHeader>
        <CardTitle>Lista de trabajos</CardTitle>
        <CardDescription>
          Carga los trabajos en relación al laboratorio seleccionado. Al guardar
          se podrá visualizar la lista de trabajos agregados.
        </CardDescription>
      </CardHeader>
      {!hiddenLoadOrder ? (
        <div className="justify-between flex flex-col">
          <CardContent>
            <>
              {TABLE_VALIDATION && (
                <ConceptsTable data={order_list} action={action} form={form} />
              )}
              <div className="flex justify-end gap-5 mt-5">
                <Button variant="ghost" onClick={onLoadWork}>
                  Cargar trabajo
                </Button>
                <Button
                  type="button"
                  disabled={!TABLE_VALIDATION}
                  onClick={() => setStep(2)}
                >
                  Siguiente
                </Button>
              </div>
            </>
          </CardContent>
          <CardDescription className="flex justify-center">
            {!TABLE_VALIDATION && "Todavía no posee ningún trabajo cargado"}
          </CardDescription>
        </div>
      ) : (
        <LoadWork
          setHiddenLoadMedicine={setHiddenLoadOrder}
          form={form}
          laboratories={data.laboratories}
        />
      )}
    </Card>
  );
};
