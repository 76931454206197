import * as React from "react";
import { ArrowUpDown } from "lucide-react";
import { HiDotsVertical } from "react-icons/hi";
import { LuDownload, LuMail, LuPen } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { format } from "date-fns";
import { Medications } from "@/types/recipes";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { sendRecipeMail } from "@/actions/recipes";
import { toast } from "@/hooks";

type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => "asc" | "desc" | undefined;
};
type ColumnsT = {
  accessorKey: string;
  header?: string | (({ column }: { column: ColumnT }) => JSX.Element) | any;
  cell: ({ row }: { row: any }) => any;
  enableHiding?: boolean;
  id?: string;
};

export const Columns = ({ action, value, offices, permissions }: any) => {
  const { view, to_emit, is_supervisor, send, send_to_mail } = permissions;
  const columns: ColumnsT[] = [
    {
      id: "action",
      accessorKey: "",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                {(is_supervisor || (view && to_emit && send)) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, "view_details")}
                  >
                    <LuPen type="edit" className="mr-2 size-4" />
                    <span>Ver detalles</span>
                  </DropdownMenuItem>
                )}
                {(is_supervisor || send) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, "download_pdf")}
                  >
                    <LuDownload type="edit" className="mr-2 size-4" />
                    <span>Descargar pdf</span>
                  </DropdownMenuItem>
                )}
                {(is_supervisor || send) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, "send")}
                  >
                    <FaWhatsapp className="mr-2 size-4" fill="#35c13e" />
                    <span>Enviar link</span>
                  </DropdownMenuItem>
                )}
                {(is_supervisor || send_to_mail) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={async () => {
                      const response = await sendRecipeMail(
                        row.original.id_prescription
                      );

                      if (response?.error) {
                        toast({
                          variant: "destructive",
                          title: "Ups! Parece que hubo un error",
                          description: response?.error,
                        });
                      } else {
                        toast({
                          variant: "successful",
                          title: "Exito!",
                          description:
                            "La receta fue enviada al email del paciente",
                        });
                      }
                    }}
                  >
                    <LuMail className="mr-2 size-4 text-slate-400" />
                    <span>Enviar receta por mail</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600 w-24"
          >
            Fecha
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {format(date, "yyyy-MM-dd")}
          </p>
        );
      },
    },
    {
      accessorKey: "professional",
      header: "Profesional",
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {professional?.name}
          </p>
        );
      },
    },
    {
      accessorKey: "medications",
      header: "Medicamentos",
      cell: ({ row }: { row: any }) => {
        const { medications } = row.original;

        return value === "expanded" ? (
          <ul className="list-none">
            {medications?.map((m: Medications, index: number) => (
              <li key={index}>
                <div className="flex gap-x-2">
                  <p className="font-bold text-sm leading-6 text-slate-900 uppercase">
                    💊 {m.product_name.split("(")[0]}
                  </p>
                  <p className="font-bold text-sm leading-6 text-gray-500">
                    ({m.product_name.split("(")[1]}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="font-bold text-sm leading-6 text-slate-900 uppercase">
            💊 {medications?.length}
          </p>
        );
      },
    },
    {
      accessorKey: "office",
      header: "Sucursal",
      cell: ({ row }: { row: any }) => {
        const { office } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{office}</p>
        );
      },
    },
    {
      accessorKey: "diagnosis",
      header: "Diagnostico general",
      cell: ({ row }: { row: any }) => {
        const { diagnosis } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {diagnosis}
          </p>
        );
      },
    },
  ];
  !(offices?.length > 0) && columns.splice(4, 1);

  return columns;
};
