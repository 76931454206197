"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  SheetContent,
  SheetTitle,
  SheetHeader,
  SheetClose,
  SheetFooter,
  SheetDescription,
  Button,
  Input,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from "@/components/atoms";
import { toast, useKeypress, useDialog } from "@/hooks";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import {
  Benefit,
  Representation,
  Specialty,
  Plan,
  MedicalInsurance,
} from "@/types/general";
import { ageIsMajorOrMinor } from "@/utils/benefits";
import {
  BenefitsGroups,
  BenefitDrawing,
  BenefitsSpecialies,
} from "@/components/molecules/params";
import { BENEFIT_OPTIONS } from "@/libs/config";
import {
  updateBenefitConfig,
  getMedicalInsuranceBenefits,
} from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function NewMedicalInsuranceBenefitForm({
  selected,
  data: { representations, specialties, medicalInsurance, plan },
}: {
  selected?: Benefit;
  data: {
    representations: Representation[];
    specialties: Specialty[];
    medicalInsurance: MedicalInsurance;
    plan: Plan;
  };
}) {
  const { closeDialog } = useDialog();
  const { loading, startLoading, stopLoading, setBenefits } = useGlobalStore();
  const { groups } = medicalInsurance;
  const UNIT =
    BENEFIT_OPTIONS?.unitField.find((u) => u.text === selected?.unit)?.id ||
    "none";

  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_benefit: selected?.id_benefit || 0,
      id_plan: selected?.id_plan || plan.id_plan,
      id_group: selected?.id_group || null,
      id_benefit_to_be_performed: selected?.id_benefit_to_be_performed || null,
      id_specialty: selected?.id_specialty || null,
      benefit_code: selected?.benefit_code || "",
      code_benefit_med_ins_liq: selected?.code_benefit_med_ins_liq || "",
      description: selected?.description || "",
      amount_mi: selected?.amount_mi || 0,
      amount_patient: selected?.amount_patient || 0,
      drawing: selected?.drawing || "NO_ID",
      tooth: selected?.tooth || "NIN",
      requires_face: selected?.requires_face || false,
      internal: selected?.internal || false,
      disabled: selected?.disabled ? false : true, //TODO:change name checkbox to enabled
      disabled_group_belongs: selected?.id_group ? false : true,
      disabled_drawing: selected?.drawing ? false : true,
      disabled_specialties: selected?.id_specialty ? false : true,
      is_odontogram: selected?.is_odontogram || false,
      unit: UNIT,
      quantity: selected?.quantity || 0,
      age_text: selected?.age ? ageIsMajorOrMinor(selected.age) : "none",
      age_number:
        (selected?.age && parseInt(selected.age.replace(/\D/g, ""), 10)) || 0,
      per_patient: selected?.per_patient || 9999,
      per_piece_sector: selected?.per_piece_sector || 9999,
      rowversion: selected?.rowversion || null,
      is_usd: selected?.is_usd || false,
      id_specialty_navigation: selected?.id_specialty_navigation || null,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();

    const [updateResponse, getResponse] = await Promise.all([
      updateBenefitConfig({
        medicalInsurance,
        benefit: values as unknown as Benefit & {
          age_text: string;
          age_number: number;
        },
        plan,
      }),
      getMedicalInsuranceBenefits("OSPRE", plan.id_plan),
    ]);

    if (updateResponse?.error || getResponse?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: updateResponse?.error || getResponse?.error,
      });
    } else {
      setBenefits({ data: getResponse.benefits });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "La prestación se guardó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <SheetContent className="w-full !max-w-[500px] overflow-y-scroll">
      <SheetHeader>
        <SheetTitle>
          {selected ? "Modificar prestación" : "Nueva prestación"}
        </SheetTitle>
      </SheetHeader>
      <div className="mt-4 space-y-4">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="grid grid-cols-6 gap-4"
          >
            <h3 className="text-slate-800 text-lg font-bold col-span-6">
              Datos de la prestación
            </h3>
            <FormField
              control={form.control}
              name="benefit_code"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Código de prest. O.S</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="code_benefit_med_ins_liq"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Código de prest. O.S Liq.</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Descripción</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} maxLength={50}/>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount_mi"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Importe de O.S ($)</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount_patient"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Importe del paciente ($)</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_usd"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-6">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="leading-none flex flex-col">
                    <FormLabel className="!mb-0">Importe en U$D</FormLabel>
                    <p className="text-xs font-regular text-slate-500">
                      Indica si el importe de la prestación actual es en
                      dólares.
                    </p>
                  </div>
                </FormItem>
              )}
            />
            <Separator className="bg-slate-200 h-px col-span-6" />
            <h3 className="text-slate-800 text-lg font-bold col-span-6">
              Opciones
            </h3>
            <FormField
              control={form.control}
              name="tooth"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Acerca de diente / sector</FormLabel>
                  <FormControl>
                    <Select
                      key="tooth"
                      value={field.value}
                      onValueChange={(value: string) => {
                        form.setValue("tooth", value);
                      }}
                    >
                      <SelectTrigger className="w-full !h-10 !text-sm">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {BENEFIT_OPTIONS.toothField.map(
                          (tooth: { id: string; text: string }) => (
                            <SelectItem key={tooth.id} value={tooth.id}>
                              {tooth.text}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="requires_face"
              render={({ field }) => (
                <FormItem className="col-span-3 gap-x-1 flex items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Pide cara</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_odontogram"
              render={({ field }) => (
                <FormItem className="col-span-3 gap-x-1 flex items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Es catastral</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="internal"
              render={({ field }) => (
                <FormItem className="col-span-3 gap-x-1 flex items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Es interna</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="disabled"
              render={({ field }) => (
                <FormItem className="col-span-3 gap-x-1 flex items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Habilitada</FormLabel>
                </FormItem>
              )}
            />
            <Separator className="bg-slate-200 h-px col-span-6" />
            <div className="col-span-6">
              <h3 className="text-slate-800 text-lg font-bold">Auditoria</h3>
              <SheetDescription>
                Configura las opciones de auditoria de la prestación.
              </SheetDescription>
            </div>
            <BenefitDrawing form={form} representations={representations} />
            <BenefitsGroups form={form} groups={groups} />
            <BenefitsSpecialies form={form} specialties={specialties} />
            <FormField
              control={form.control}
              name="unit"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Unidad de carencia</FormLabel>
                  <FormControl>
                    <Select
                      key="unit"
                      value={field.value}
                      onValueChange={(value: string) => {
                        form.setValue("unit", value);
                        if (["none"].includes(value)) {
                          form.setValue("quantity", 0);
                        }
                      }}
                    >
                      <SelectTrigger className="w-full !h-10 !text-sm">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {BENEFIT_OPTIONS.unitField.map(
                          (unit: { id: string; text: string }) => (
                            <SelectItem key={unit.id} value={unit.id}>
                              {unit.text}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="quantity"
              disabled={["none"].includes(form.watch("unit"))}
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Tiempo de carencia</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="age_text"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Edad válida</FormLabel>
                  <FormControl>
                    <Select
                      key="age_text"
                      value={field.value}
                      onValueChange={(value: string) => {
                        form.setValue("age_text", value);
                        if (["none"].includes(value)) {
                          form.setValue("age_number", 0);
                        }
                      }}
                    >
                      <SelectTrigger className="w-full !h-10 !text-sm">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {BENEFIT_OPTIONS.ageField.map(
                          (age: { id: string; text: string }) => (
                            <SelectItem key={age.id} value={age.id}>
                              {age.text}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="age_number"
              disabled={["none"].includes(form.watch("age_text"))}
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Edad</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="per_patient"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Cantidad máxima por paciente y periodo</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="9999 = Sin límite"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription className="text-slate-500">
                    9999 = Sin límite
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="per_piece_sector"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>
                    Cantidad máxima por paciente y periodo, por diente / sector
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="9999 = Sin límite"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription className="text-slate-500">
                    9999 = Sin límite
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <SheetFooter className="col-span-6">
              <SheetClose asChild>
                <Button
                  type="button"
                  onClick={() => form.reset()}
                  className="w-full"
                  variant="destructive"
                >
                  Cancelar
                </Button>
              </SheetClose>
              {loading ? (
                <ButtonLoading className="w-full" />
              ) : (
                <Button type="submit" className="w-full">
                  Guardar
                </Button>
              )}
            </SheetFooter>
          </form>
        </Form>
      </div>
    </SheetContent>
  );
}
