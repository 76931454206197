import React from "react";
import { Input, RadioGroup, RadioGroupItem } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const SelectDiscountType: React.FC<{
  form: Form<typeof formSchema>;
}> = ({ form }) => {
  const { discount } = form.watch();
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value = (event.target as HTMLButtonElement).value;
    form.setValue("discount.type_discount", value);
  };
  const handleOnChange = () => {
    discount?.type_discount === "percentage"
      ? form.setValue("discount.amount", 0)
      : form.setValue("discount.percentage", 0);
  };
  const DISABLED_INPUT = discount.type_discount === "percentage";

  return (
    <RadioGroup
      className="grid col-span-full grid-cols-2 gap-x-5 items-center"
      onValueChange={handleOnChange}
      value={discount?.type_discount}
    >
      <div className="grid grid-cols-2 items-center gap-x-5">
        <div className="space-x-2 mt-7 flex h-10">
          <RadioGroupItem value="percentage" onClick={onClick} />
          <FormLabel className="!mb-0">Porcentaje de descuento</FormLabel>
        </div>
        <FormField
          control={form.control}
          name="discount.percentage"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel>% de descuento</FormLabel>
              <FormControl>
                <Input type="number" {...field} disabled={!DISABLED_INPUT} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 items-center gap-x-5">
        <div className="space-x-2 mt-7 flex h-10">
          <RadioGroupItem value="amount" onClick={onClick} />
          <FormLabel>Importe de descuento</FormLabel>
        </div>
        <FormField
          control={form.control}
          name="discount.amount"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel>$ de descuento</FormLabel>
              <FormControl>
                <Input type="number" {...field} disabled={DISABLED_INPUT} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </RadioGroup>
  );
};
