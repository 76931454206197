"use client";

import React from "react";
import { numberToCurrency } from "@/utils/currency";

export const columns = [
  {
    accessorKey: "date",
    header: "Fecha",
    cell: ({ row }: { row: any }) => {
      const { date } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap">
          {date}
        </p>
      );
    },
  },
  {
    accessorKey: "affiliate_number",
    header: "N° de Afiliado",
    cell: ({ row }: { row: any }) => {
      const { affiliate_number } = row.original;

      return (
        <p className="lg:text-sm text-slate-600 my-1">{affiliate_number}</p>
      );
    },
  },
  {
    accessorKey: "liquidation_code",
    header: "Cod. de Prestación",
    cell: ({ row }: { row: any }) => {
      const { liquidation_code } = row.original;

      return (
        <p className="lg:text-sm text-slate-600 my-1">{liquidation_code}</p>
      );
    },
  },
  {
    accessorKey: "description",
    header: "Descripción",
    cell: ({ row }: { row: any }) => {
      const { description } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{description}</p>;
    },
  },
  {
    accessorKey: "tooth_sector",
    header: "Diente/s",
    cell: ({ row }: { row: any }) => {
      const { tooth_sector } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{tooth_sector}</p>;
    },
  },
  {
    accessorKey: "faces",
    header: "Caras",
    cell: ({ row }: { row: any }) => {
      const { faces } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{faces}</p>;
    },
  },
  {
    accessorKey: "amount",
    header: "Importe",
    cell: ({ row }: { row: any }) => {
      const { amount } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-green-500 my-1">
          {numberToCurrency(amount)}
        </p>
      );
    },
  },
];
