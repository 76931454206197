import { AppointmentList } from "@/types/patients";

type State = {
  patient_appointments: {
    appointments: AppointmentList[];
    initialized_data: boolean;
  };
};

type Actions = {
  setPatientAppointments: (payload: {
    list: AppointmentList[];
    set_new_data?: boolean;
  }) => void;
};

export const INIT_STATE: State = {
  patient_appointments: {
    appointments: [],
    initialized_data: false,
  },
};

type AppointmentsStore = State & Actions;

export const AppointmentsSlice = (
  set: (partial: Partial<AppointmentsStore>) => void,
  get: () => AppointmentsStore
): AppointmentsStore => ({
  ...INIT_STATE,
  setPatientAppointments: ({
    list,
    set_new_data,
  }: {
    list: AppointmentList[];
    set_new_data?: boolean;
  }) => {
    const state = get();
    (!state.patient_appointments.initialized_data || set_new_data) &&
      set({
        ...state,
        patient_appointments: {
          ...state.patient_appointments,
          appointments: list,
          initialized_data: true,
        },
      });
  },
});
