import React from "react";
import { format } from "date-fns";
import { LuCalendar } from "react-icons/lu";
import classNames from "classnames";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  DateInput,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CardTitle,
  RangeCalendar,
} from "@/components/atoms";
import { dataPreset, dataPresetCompare } from "@/libs/compare-range-picker";
import { transformDateToZoneTime } from "@/utils/date";

export const CompareRangePicker: React.FC<any> = ({
  align,
  showCompare = true,
  name,
  form,
  device,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { date_comparison, badges } = form.getValues();

  const value = {
    date_from: date_comparison.date_from,
    date_to: date_comparison.date_to,
    comparation_date_from: date_comparison.comparation_date_from,
    comparation_date_to: date_comparison.comparation_date_to,
  };

  const badgesValue = {
    principal: badges.principal,
    compare: badges.compare,
  };

  const initialDate = (date: Date | string) =>
    typeof date === "string" ? transformDateToZoneTime(date) : date;
  const [originalRange, setOriginalRange] = React.useState<any>({
    from: initialDate(value.date_from),
    to: initialDate(value.date_to) || initialDate(value.date_from),
  });
  const [rangeCompare, setRangeCompare] = React.useState<any>(
    (value.comparation_date_from && {
      from: initialDate(value.comparation_date_from),
      to:
        initialDate(value.comparation_date_to) ||
        initialDate(value.comparation_date_from),
    }) ||
      null
  );
  const [switchCompare, setSwitchCompare] = React.useState<any>(
    value.comparation_date_from ?? false
  );

  const getPresetNames = (
    preset: { id: string; label: string }[],
    name: string
  ) => {
    return preset?.find((p) => p.label === name)?.id;
  };
  const [selectedPreset, setSelectedPreset] = React.useState(
    getPresetNames(dataPreset, badgesValue.principal)
  );
  const [selectedPresetCompare, setSelectedPresetCompare] = React.useState(
    getPresetNames(dataPresetCompare, badgesValue.compare)
  );
  const calendars = ["Principal", "Comparación"];
  const getPresetRange = (presetName: string | undefined) => {
    const from = new TZDate(new Date().toISOString(), TIMEZONES.AR);
    const to = new TZDate(new Date().toISOString(), TIMEZONES.AR);
    switch (presetName) {
      case "last7":
        from.setDate(from.getDate() - 6);
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "last15":
        from.setDate(from.getDate() - 14);
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "last30":
        from.setDate(from.getDate() - 29);
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "last90":
        from.setDate(from.getDate() - 89);
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "personalized":
        from.setDate(from.getDate());
        to.setDate(to.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "lastperiod":
        from.setFullYear(originalRange.from.getFullYear());
        to.setFullYear(originalRange.to?.getFullYear());
        from.setMonth(originalRange?.from.getMonth() - 1);
        to.setMonth(originalRange.to?.getMonth() - 1);
        from.setDate(originalRange.from.getDate());
        to.setDate(originalRange.to?.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;
      case "sameperiodlastyear":
        from.setFullYear(originalRange.from.getFullYear() - 1);
        to.setFullYear(originalRange.to?.getFullYear() - 1);
        from.setMonth(originalRange.from.getMonth());
        to.setMonth(originalRange.to?.getMonth());
        from.setDate(originalRange.from.getDate());
        to.setDate(originalRange.to?.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        break;

      default:
    }

    return { from, to };
  };
  const setPreset = (preset: string, compare?: boolean) => {
    const range = getPresetRange(preset);

    if (compare) {
      setSelectedPresetCompare(preset);
      form.setValue("badges", {
        ...badgesValue,
        compare: dataPresetCompare.find((d) => d.id === preset)?.label,
      });
      return setRangeCompare(range);
    }

    setSelectedPreset(preset);

    form.setValue("badges", {
      ...badgesValue,
      principal: dataPreset.find((d) => d.id === preset)?.label,
    });
    setOriginalRange(range);
  };
  const handleCalendarSelect = (value: any) => {
    value?.from && setOriginalRange({ from: value.from, to: value?.to });
    setSelectedPreset("personalized");
  };
  const handleCalendarSelectCompare = (value: any) => {
    value?.from && setRangeCompare({ from: value.from, to: value?.to });
    setSelectedPresetCompare("personalized");
  };
  const onDateApply = () => {
    if (originalRange?.to) {
      const objectDate = {
        date_from: originalRange?.from,
        date_to: originalRange?.to,
        comparation_date_from: rangeCompare?.from || null,
        comparation_date_to: rangeCompare?.to || null,
      };
      setIsOpen(false);
      form.setValue(name, objectDate);
      form.setValue("badges", {
        principal: dataPreset.find((d) => d.id === selectedPreset)?.label,
        compare: dataPresetCompare.find((d) => d.id === selectedPresetCompare)
          ?.label,
      });
    }
  };
  const isDesktop = ["desktop"].includes(device);
  React.useEffect(() => {
    switchCompare && setRangeCompare(getPresetRange(selectedPresetCompare));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalRange]);

  return (
    <>
      <Popover
        open={isOpen}
        onOpenChange={(open: boolean) => {
          setIsOpen(open);
        }}
      >
        <PopoverTrigger asChild>
          <div className="py-1 flex flex-col gap-2 hover:cursor-pointer">
            <div className="p-2 text-slate-400 flex justify-between border border-slate-200 rounded-md">
              <div className="text-sm flex gap-x-1">
                <span className="text-slate-600">
                  {format(new Date(originalRange.from), "dd/MM/yyyy")}
                </span>
                {originalRange.to && (
                  <>
                    al
                    <span className="text-slate-600">
                      {format(new Date(originalRange.to), "dd/MM/yyyy")}
                    </span>
                  </>
                )}
              </div>
              <LuCalendar
                width={24}
                className="text-blue-500 hover:scale-110"
              />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          align={align}
          className={classNames("w-full", {
            "min-w-[950px]": showCompare,
          })}
        >
          <div className="w-full flex items-start lg:h-auto gap-5">
            <div
              className={classNames("flex flex-col w-48 gap-y-2 rounded", {
                "justify-center": showCompare,
              })}
            >
              <div>
                <p className="text-sm">Selección de fecha</p>
                <Select
                  key={selectedPreset}
                  defaultValue={selectedPreset}
                  onValueChange={(value: string) => setPreset(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Seleccionar fecha" />
                  </SelectTrigger>
                  <SelectContent>
                    {dataPreset?.map((preset) => (
                      <SelectItem key={preset.id} value={preset.id}>
                        {preset.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <p className="text-sm mb-1">Desde</p>
                <DateInput
                  value={originalRange.from}
                  onChange={(date: Date | string) => {
                    const toDate =
                      originalRange.to == null || date > originalRange.to
                        ? date
                        : originalRange.to;
                    setSelectedPreset("personalized");
                    form.setValue("badges", {
                      ...badgesValue,
                      principal: "personalized",
                    });
                    setOriginalRange(
                      (prevRange: {
                        from: string | Date;
                        to: string | Date;
                      }) => ({
                        ...prevRange,
                        from: date,
                        to: toDate,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <p className="text-sm mb-1">Hasta</p>
                <DateInput
                  value={originalRange.to}
                  onChange={(date: string | Date) => {
                    const fromDate =
                      date < originalRange.from ? date : originalRange.from;
                    setSelectedPresetCompare("personalized");
                    form.setValue("badges", {
                      ...badgesValue,
                      principal: "personalized",
                    });
                    setOriginalRange(
                      (prevRange: {
                        from: string | Date;
                        to: string | Date;
                      }) => ({
                        ...prevRange,
                        from: fromDate,
                        to: date,
                      })
                    );
                  }}
                />
              </div>
              {showCompare && (
                <div className="flex flex-col">
                  <div className="flex justify-between mt-4">
                    <span className="text-sm mb-4">Comparar</span>
                    <Switch
                      defaultChecked={!!switchCompare}
                      onClick={(event: any) => {
                        const checked =
                          (event.target as HTMLInputElement).getAttribute(
                            "aria-checked"
                          ) === "true";
                        if (!checked) {
                          setSwitchCompare(true);
                          if (!originalRange.to) {
                            setOriginalRange({
                              from: originalRange.from,
                              to: originalRange.from,
                            });
                          }
                          setRangeCompare({
                            from: new Date(
                              originalRange.from.getFullYear(),
                              originalRange.from.getMonth(),
                              originalRange.from.getDate() - 366
                            ),
                            to: originalRange.to
                              ? new Date(
                                  originalRange.to.getFullYear() - 1,
                                  originalRange.to.getMonth(),
                                  originalRange.to.getDate()
                                )
                              : new Date(
                                  originalRange.from.getFullYear() - 1,
                                  originalRange.from.getMonth(),
                                  originalRange.from.getDate()
                                ),
                          });
                        } else {
                          setSwitchCompare(false);
                          setRangeCompare(null);
                        }
                      }}
                      id="compare-mode"
                    />
                  </div>
                  <Select
                    key={selectedPresetCompare}
                    defaultValue={selectedPresetCompare}
                    onValueChange={(value: string) => setPreset(value, true)}
                    disabled={!rangeCompare}
                  >
                    <SelectTrigger className="mb-2">
                      <SelectValue placeholder="Seleccionar fecha" />
                    </SelectTrigger>
                    <SelectContent>
                      {dataPresetCompare.map((preset) => (
                        <SelectItem key={preset.id} value={preset.id}>
                          {preset.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <span className="mb-1 text-sm">Desde</span>
                  <DateInput
                    disabled={!rangeCompare}
                    value={rangeCompare?.from}
                    onChange={(date: string | Date) => {
                      if (rangeCompare) {
                        const compareToDate =
                          rangeCompare.to == null || date > rangeCompare.to
                            ? date
                            : rangeCompare.to;
                        setSelectedPresetCompare("personalized");
                        setRangeCompare(
                          (prevRangeCompare: {
                            from: string | Date;
                            to: string | Date;
                          }) => ({
                            ...prevRangeCompare,
                            from: date,
                            to: compareToDate,
                          })
                        );
                      }
                      setRangeCompare({
                        from: date,
                        to: new Date(),
                      });
                    }}
                  />
                  <span className="mt-2 mb-1 text-sm">Hasta</span>
                  <DateInput
                    disabled={!rangeCompare}
                    value={rangeCompare?.to}
                    onChange={(date: string | Date) => {
                      rangeCompare &&
                        rangeCompare.from &&
                        setSelectedPresetCompare("personalized");
                      setRangeCompare({
                        ...rangeCompare,
                        from:
                          date < rangeCompare.from ? date : rangeCompare.from,
                        to: date,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={classNames(
                "h-full flex flex-col justify-between",
                switchCompare
                  ? !isDesktop
                    ? "w-[280px]"
                    : "w-[700px]"
                  : "w-auto"
              )}
            >
              <div className="border border-slate-200 rounded p-4 w-[700px]">
                {rangeCompare && (
                  <Carousel className="w-full">
                    <CarouselContent>
                      {calendars.map((calendarLabel, index) => (
                        <CarouselItem
                          key={index}
                          className="flex flex-col justify-center items-center w-full mx-1"
                        >
                          <CardTitle className="text-center mb-2 text-xl">
                            {calendarLabel}
                          </CardTitle>
                          <RangeCalendar
                            mode="range"
                            focusDate={
                              index === 0 ? originalRange : rangeCompare
                            }
                            type={index === 0 ? "principal" : "compare"}
                            onSelect={
                              index === 0
                                ? handleCalendarSelect
                                : handleCalendarSelectCompare
                            }
                            selected={
                              index === 0 ? originalRange : rangeCompare
                            }
                            numberOfMonths={!isDesktop ? 1 : 2}
                            month={
                              index === 0
                                ? new Date(originalRange.from)
                                : new Date(rangeCompare.from)
                            }
                          />
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <CarouselPrevious className="!bg-blue-100 -left-6 text-blue-500 hover:!text-blue-700 !rounded-md" />
                    <CarouselNext className="!bg-green-100 -right-6 text-green-500 hover:!text-green-700 !rounded-md" />
                  </Carousel>
                )}
                {!rangeCompare && (
                  <div className="w-full flex justify-center lg:block">
                    <RangeCalendar
                      mode="range"
                      focusDate={originalRange}
                      onSelect={handleCalendarSelect}
                      selected={originalRange}
                      numberOfMonths={!isDesktop ? 1 : 2}
                      month={new Date(originalRange.from)}
                    />
                  </div>
                )}
              </div>
              {!originalRange?.to && (
                <span className="text-red-500">
                  Por favor seleccione un rango de fechas para continuar
                </span>
              )}
              <div className="mt-6 flex justify-end space-x-4 pb-1">
                <Button
                  className="rounded shadow w-full lg:!w-28"
                  variant="ghost"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button onClick={onDateApply} className=" w-full lg:!w-28">
                  Aplicar
                </Button>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};
