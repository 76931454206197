"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FiEyeOff, FiEye, FiInfo } from "react-icons/fi";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Input,
  Separator,
  Textarea,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TooltipComponent,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { toast } from "@/hooks";
import { updateSms } from "@/actions/configs";
import { userParamsToDB } from "@/utils/tranformers/params";
import { formSchema, resolver } from "./schema";

export const SmsForm = () => {
  const inputUserRef = React.useRef<HTMLInputElement | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { general_params, startLoading, stopLoading, loading, updateParams } =
    useGlobalStore();
  const { user_params } = general_params;
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      sms_user: user_params?.sms_user,
      sms_password: user_params?.sms_password,
      sms_birthday_text: user_params?.sms_birthday_text,
      sms_appointments_text: user_params?.sms_appointments_text,
    },
  });
  // ME QUEDE ACA!!!
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const params = userParamsToDB(user_params);
    try {
      startLoading();
      const new_params = await updateSms({
        ...params,
        ...values,
      });
      toast({
        variant: "successful",
        title: "Cambios realizados correctamente",
        description: "Los datos han sido actualizados.",
      });
      updateParams("general_params", {
        ...general_params,
        user_params: { ...new_params },
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    } finally {
      stopLoading();
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-end"
      >
        <Card className="w-full !p-0 !border-none !shadow-none">
          <CardHeader>
            <CardDescription>
              Inserta tu usuario y contraseña de SMS sugerida por BILOG, o edita
              y añade la de tu preferencia.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col space-y-4 gap-4 text-slate-800">
              <div className="grid grid-cols-2 gap-6">
                <FormField
                  control={form.control}
                  name="sms_user"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Usuario*</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Ingrese aquí"
                          ref={inputUserRef}
                          maxLength={10}
                          autoFocus
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="sms_password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contraseña*</FormLabel>
                      <FormControl>
                        <div className="relative flex justify-between">
                          <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Escribe tu contraseña aquí"
                            {...field}
                            maxLength={16}
                          />
                          <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-3 cursor-pointer text-slate-600"
                          >
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator className="my-4" />
              <div className="flex flex-col justify-center space-y-2">
                <div className="w-full flex justify-between">
                  <h2 className="text-xl font-semibold text-slate-800">
                    Mensajes
                  </h2>
                  <TooltipComponent
                    contentClass="z-50 mr-4"
                    trigger={<FiInfo className="size-5" />}
                    content={
                      <p className="w-[500px]">{`
    Parámetros para reemplazar valores en el mensaje:
    << PACIENTE >> Nombre del paciente del turno que se visualiza en el listado.
    << DIA >> Fecha del día seleccionado.
    << HORA >> Hora del turno que se visualiza en el listado.
    << CLINICA >> Nombre de su clínica.
    << PROFESIONAL >> Nombre del profesional del turno que se visualiza en el listado.
    `}</p>
                    }
                  />
                </div>
                <h3 className="text-sm text-slate-600">
                  En esta sección vas a poder definir mensajes personalizados de
                  cumpleaños y avisos de turnos.
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <FormField
                  control={form.control}
                  name="sms_birthday_text"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Saludos de cumpleaños</FormLabel>
                      <FormControl>
                        <Textarea
                          className="resize-none"
                          {...field}
                          maxLength={250}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="sms_appointments_text"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Avisos al paciente</FormLabel>
                      <FormControl>
                        <Textarea
                          className="resize-none"
                          {...field}
                          maxLength={250}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            type="submit"
            disabled={form.formState.isSubmitting}
            className="mt-4"
          >
            Guardar
          </Button>
        )}
      </form>
    </Form>
  );
};
