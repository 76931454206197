"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  section: "movements" | "accounts";
}> = ({ section }) => {
  const {
    administration: { accounts, movements, totals },
  } = useGlobalStore();
  const router = useRouter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATA_STATE = React.useMemo(
    () => ({
      accounts,
      movements,
    }),
    [accounts, movements]
  );
  const VALIDATE_SECTION =
    section === "movements" && accounts.data?.data?.length === 0;

  return DATA_STATE[section]?.data?.data?.length > 0 ? (
    <Data
      section={section}
      columns={columns({ column: section })}
      data={{
        data: DATA_STATE[section].data.data,
        totals: {
          total_amount: totals?.total_amount,
          owed: totals?.owed,
          paid: totals?.paid,
        },
      }}
    />
  ) : (
    <div className="grid grid-cols-12">
      <EmptyState
        title={VALIDATE_SECTION ? "Sin bancos Registrados" : "Ups!"}
        className="col-span-6 !justify-start flex col-start-5"
        copy={
          VALIDATE_SECTION
            ? "Por favor, agrega un banco para poder asociar y gestionar sus movimientos."
            : "No se han encontrado registros."
        }
      />
      {VALIDATE_SECTION && (
        <div className="!justify-center flex col-span-3 col-start-6">
          <Button
            className="w-1/2"
            onClick={() =>
              router.push("/dashboard/administration/banks/accounts")
            }
          >
            Dar de alta nuevo banco
          </Button>
        </div>
      )}
    </div>
  );
};
