"use client";

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { numberToCurrency } from "@/utils/currency";
import {
  HiDotsVertical,
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import {
  NewBankForm,
  DeleteBankForm,
  NewMovementForm,
  DeleteMovementForm,
} from "@/components/organisms/forms/administration";
import { MOVEMENT_TYPES } from "@/libs/administration";
import { Bank } from "@/types/general";

type RowDataT = {
  original: any;
};

const MovementsDropdown = ({
  movement,
}: {
  movement: {
    id: number;
    date: string;
    type: "Depósito en efectivo" | "Débitos internos" | "Créditos internos";
    concept: string;
    user: string;
    total: number;
  };
}) => {
  const { openDialog } = useDialog();
  // TODO: Revisar este find porque puede ser que el tipo del mov sea otro que no sea los tres de arriba
  const MOV_TYPE = MOVEMENT_TYPES.find((item) => item.type === movement.type)
    ?.text as "Depósito en efectivo" | "Débitos internos" | "Créditos internos";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            disabled={!MOV_TYPE}
            onSelect={() =>
              openDialog({
                content: <NewMovementForm idMovement={movement.id} />,
              })
            }
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() =>
              openDialog({
                content: <DeleteMovementForm idMovement={movement.id} />,
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// This is the "Movimientos bancarios" columns
const movements = () => [
  {
    id: "actions",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => (
      <MovementsDropdown movement={row.original} />
    ),
  },
  {
    accessorKey: "date",
    header: "Fecha",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { date } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap">
          {format(date, "dd/MM/yyyy")}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "concept",
    header: "Concepto",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { concept } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {concept}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "type",
    header: "Tipo",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { type } = row.original;
      const MOV_TYPE = MOVEMENT_TYPES.find((item) => item.type === type)
        ?.text as
        | "Depósito en efectivo"
        | "Débitos internos"
        | "Créditos internos";

      return <p className="lg:text-sm text-slate-600 my-1">{MOV_TYPE}</p>;
    },
  },
  {
    accessorKey: "total",
    header: "Total",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { total } = row.original;

      return (
        <p
          className={classNames("flex justify-end lg:text-sm my-1", {
            "text-red-500": total < 0,
            "text-green-500": total > 0,
            "text-slate-600": total === 0,
          })}
        >
          {numberToCurrency(total)}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "user",
    header: "Usuario",
    cell: ({ row }: { row: RowDataT }) => {
      const { user } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{user}</p>;
    },
    enableGrouping: false,
  },
];

const AccountsDropdown = ({ bank }: { bank: Bank }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() =>
              openDialog({
                content: <NewBankForm bank={bank} />,
              })
            }
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() =>
              openDialog({
                content: <DeleteBankForm bank={bank} />,
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// This is the "Bancos y cuentas" columns
export const accounts = () => {
  return [
    {
      id: "actions",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => (
        <AccountsDropdown bank={row.original} />
      ),
    },
    {
      accessorKey: "name",
      header: "Concepto",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { name, id_bank } = row.original;

        return (
          <p
            data-id={id_bank}
            className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1"
          >
            {name}
          </p>
        );
      },
    },
    {
      accessorKey: "account_number",
      header: "N° de cuenta",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { account_number } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {account_number}
          </p>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Descripción",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { description } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "initial_balance",
      header: "Saldo inicial",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { initial_balance } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-red-500": initial_balance < 0,
              "text-green-500": initial_balance > 0,
              "text-slate-600": initial_balance === 0,
            })}
          >
            {numberToCurrency(initial_balance)}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "enabled",
      header: "Habilitado",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { enabled } = row.original;

        return (
          <p
            className={classNames(
              "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
              {
                "text-red-500": !enabled,
                "text-green-500": enabled,
              }
            )}
          >
            {enabled ? (
              <HiOutlineCheckCircle className="size-5" />
            ) : (
              <HiOutlineBan className="size-5" />
            )}
          </p>
        );
      },
      enableGrouping: false,
    },
  ];
};

type ColumnKeys = "movements" | "accounts";

export const columns = ({ column }: { column: ColumnKeys }) => {
  const columns: Record<ColumnKeys, typeof movements | typeof accounts> = {
    movements: () => movements(),
    accounts: () => accounts(),
  };

  return columns[column]();
};
