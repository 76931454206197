import React from "react";
import Image from "next/image";
import classNames from "classnames";

export const EmptyState: React.FC<{
  title: string;
  copy: string | null;
  className?: string;
  children?: React.ReactNode;
}> = ({ title, copy, className = "m-auto mt-4", children }) => {
  return (
    <div
      className={classNames(
        "flex justify-center items-center gap-x-4 text-center",
        className
      )}
    >
      <Image
        src="/images-graphics/empty_state.svg"
        alt="Logo Bilog"
        width={50}
        height={50}
        className="size-44"
      />
      <div className="flex items-start flex-col text-start">
        <p className="text-2xl text-slate-900 mr-2">{title}</p>
        <p className="text-lg text-slate-900">{copy}</p>
        {children}
      </div>
    </div>
  );
};
