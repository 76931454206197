import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const schema = {
  sms_user: z.string(),
  sms_password: z.string(),
  sms_birthday_text: z.string(),
  sms_appointments_text: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
