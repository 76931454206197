"use client";

import React from "react";
import { formatISO } from "date-fns";
import { useGlobalStore } from "@/providers/global-provider";
import { EmptyState } from "@/components/molecules";
import { Skeleton } from "@/components/atoms";
import { toast } from "@/hooks";
import { formatMessage, messagesListReducer } from "@/utils/helpers";
import { messageToProcess } from "@/utils/tranformers/reminders";
import { getSentReminders, processWhatsapp } from "@/actions/reminders";
import { ListSentWhatsapp } from "@/types/reminders";
import { columns } from "./columns";
import { Data } from "./data";

export const Table = () => {
  const {
    reminders: { list_sent_reminders },
    setListSentReminders,
    loading,
    startLoading,
    stopLoading,
  } = useGlobalStore();
  const handleSetAssistance = async (
    message: ListSentWhatsapp,
    option: string
  ) => {
    startLoading();
    await processWhatsapp(
      messageToProcess(message),
      option === "ACA" ? "Ausente" : "Confirmo",
      formatISO(new Date(message.date))
    );
    const response = await getSentReminders({
      fecha: message.date,
      opcion: list_sent_reminders.some(
        (reminder: ListSentWhatsapp) => reminder.processed
      )
        ? "buscarwapps"
        : "buscarwappsnoprocesados",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const FORMATTED = (response as ListSentWhatsapp[]).map(
        (m: ListSentWhatsapp) => {
          return { ...m, message: formatMessage(m.message) };
        }
      );

      setListSentReminders(FORMATTED);
    }

    stopLoading();
  };

  // const onClickHandler = (option: any) => setAssistance(message, option, date);
  // React.useEffect(() => {
  //   setReminders({ data: data.listaAgenda });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); //queda comentado para el proximo pr

  return loading ? (
    <>
      <Skeleton className="w-full h-12 mb-1" />
      <Skeleton className="w-full h-[300px]" />
    </>
  ) : list_sent_reminders.length <= 0 ? (
    <EmptyState title="Ups!" copy="No hay mensajes para mostrar." />
  ) : (
    <Data
      columns={columns(handleSetAssistance)}
      data={messagesListReducer(list_sent_reminders)}
    />
  );
};
