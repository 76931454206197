"use client";

import React from "react";
import { GEIST_DATA } from "@/libs/schedule";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC = () => (
  <Data columns={columns} data={GEIST_DATA} />
);
