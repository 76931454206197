import React from "react";
import { create } from "zustand";

interface DialogState {
  open: boolean;
  content: React.ReactNode | null;
}

interface DialogActions {
  openDialog: (data: { content: React.ReactNode | null }) => void;
  closeDialog: () => void;
}

export const useDialog = create<DialogState & DialogActions>((set) => ({
  open: false,
  content: null,
  onAction: () => {},
  onCancel: () => {},
  openDialog: (data) =>
    set(() => ({
      open: true,
      content: data.content,
    })),
  closeDialog: () =>
    set(() => ({
      open: false,
      content: null,
    })),
}));
