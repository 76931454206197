export const PATIENTS_ITEMS = [
  "personal-data",
  "benefits",
  "odontogram",
  "medical-history",
  "prescriptions",
  "payments",
  "budgets",
  "laboratory",
  "appointments",
  "gallery",
].map((item) => `patients/${item}`) as string[];

export const STATISTICS_ITEMS = [
  "finances",
  "control",
  "benefits-made",
  "budgets",
].map((item) => `statistics/${item}`) as string[];

export const ADMINISTRATION_ITEMS = [
  "dailycashflow/patient_payments",
  "dailycashflow/incomes",
  "dailycashflow/expenses",
  "transactions/patient_payments",
  "transactions/incomes",
  "transactions/expenses",
  "banks/movements",
  "banks/accounts",
  "suppliers",
  "cards",
].map((item) => `administration/${item}`) as string[];

export const CONFIGURATION_ITEMS = [
  "tables/professionals",
  "tables/medical-insurances",
  "tables/laboratories",
  "tables/specialties",
  "tables/holydays",
  "tables/offices",
  "sistem/general-data",
  "sistem/users",
  "sistem/whatsapp-data",
  "sistem/sms-data",
].map((item) => `configuration/${item}`) as string[];
