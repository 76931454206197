import { Combobox } from "@/components/atoms";
import { Plan } from "@/types/general";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";

export const PlansSelect = ({
  form,
  plans,
  classname,
}: {
  form: any;
  plans: Plan[];
  classname?: string;
}) => {
  return (
    <FormItem className={classname}>
      <FormLabel>Plan</FormLabel>
      <FormControl>
        <Combobox
          form={form}
          data={plans}
          dataKey="id_plan"
          value="name"
          placeholder="Busca un plan"
          title="Planes"
          className={classname}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};
