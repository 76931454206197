"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  ProfessionalsSelect,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { DatePicker } from "@/components/molecules";
import { Offices, Professional } from "@/types/general";
import { DialogClose, Button, Textarea } from "@/components/atoms";
import { saveHc } from "@/actions/patients";
import { toast, useKeypress } from "@/hooks";
import { enabledOffices } from "@/utils/mappers";
import { professionalsByOffice } from "@/utils/filters";
import { formSchema, resolver } from "../schema";

export function NewMedicalHistoryForm({
  professionals,
  offices,
  closeModal,
  loading,
  setLoading,
  refreshData,
}: {
  professionals: Professional[];
  offices: Offices[];
  closeModal: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  refreshData: () => void;
}) {
  const {
    patient,
    user,
    patient_history: { history_selected },
  } = useGlobalStore();
  const ENABLED_OFFICES = enabledOffices(offices);
  const ENABLED_PROFESSIONALS = professionalsByOffice({
    id_office: ENABLED_OFFICES?.[0]?.id_office || -1,
    professionals,
  });
  const FORM = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      date: history_selected ? new Date(history_selected.date) : new Date(),
      cli_hist: history_selected ? history_selected.cli_hist : "",
      id_professional: history_selected
        ? history_selected.id_prof
        : ENABLED_PROFESSIONALS?.[0]?.id_professional,
      id_office:
        history_selected?.id_office || ENABLED_OFFICES?.[0]?.id_office || null,
      id_patient: history_selected
        ? history_selected.id_patient
        : patient.id_patient,
      id_user: history_selected ? history_selected.id_user : user.id_user,
      id_cli_hist: history_selected ? history_selected.id_cli_hist : 0,
      rowversion: history_selected ? history_selected.rowversion : null,
      option: history_selected ? "modificarhc" : "agregarhc",
    },
  });

  const onSubmit = async (history: z.infer<typeof formSchema>) => {
    setLoading(true);
    const response = await saveHc(history);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      refreshData();
      toast({
        variant: "successful",
        title: history_selected
          ? "Historia clínica actualizada con éxito"
          : "Historia clínica creada con éxito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      closeModal(false);
    }
    setLoading(false);
  };

  useKeypress({ key: "Enter", action: FORM.handleSubmit(onSubmit) });

  return (
    <Form {...FORM}>
      <form onSubmit={FORM.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-5 mt-4 w-[800px] items-center">
          <DatePicker
            form={FORM}
            name="date"
            label="Fecha"
            dateformat="dd/MM/yyyy"
            className="date-picker-registration"
          />
          <ProfessionalsSelect
            offices={ENABLED_OFFICES}
            form={FORM}
            professionals={professionals}
            classname="professionals-select"
          />
          <FormField
            control={FORM.control}
            name="cli_hist"
            render={({ field }) => (
              <FormItem className="pb-4 col-span-5">
                <FormLabel>Historia clínica</FormLabel>
                <FormControl>
                  <Textarea {...field} maxLength={4000} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-12 mt-4 gap-x-5 w-full">
          <DialogClose className="col-start-9 col-span-2">
            <Button type="button" variant="destructive" className="w-full">
              Cancelar
            </Button>
          </DialogClose>
          {loading ? (
            <ButtonLoading className="col-span-2" />
          ) : (
            <Button className="col-span-2" disabled={loading}>
              Guardar
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
