import React from "react";

export const useStateAcordeon = (refHeader: React.RefObject<HTMLElement>) => {
  const [stateAcordeon, setStateAcordeon] = React.useState<
    string | null | undefined
  >(null);

  React.useEffect(() => {
    const handleStateChange = () => {
      setStateAcordeon(refHeader?.current?.getAttribute("data-state"));
    };

    const observer = new MutationObserver(handleStateChange);

    const startObserving = () => {
      if (refHeader?.current) {
        observer.observe(refHeader?.current, {
          attributes: true,
          attributeFilter: ["data-state"],
        });
      }
    };

    const timeout_id = setTimeout(startObserving, 1000);

    return () => {
      clearTimeout(timeout_id);
      observer.disconnect();
    };
  }, [refHeader?.current]);

  return stateAcordeon;
};
