import {
  ListSendWhatsapp,
  ListSendWhatsappDB,
  ListSentWhatsapp,
  ListSentWhatsappDB,
  ProcessToSendWhatsappDB,
} from "@/types/reminders";

export const listWhatsapp = (
  list_whatsapp: ListSentWhatsappDB[]
): ListSentWhatsapp[] =>
  list_whatsapp.map(
    ({
      id,
      id_wapp,
      id_paciente,
      id_agenda,
      id_sucursal,
      nro_tel,
      envio_recep,
      sessionCreationTime,
      fecha,
      mensaje,
      confirmo,
      cancelo,
      procesado,
      pacNombre,
      horaTurno,
      fechaTurno,
      profesional,
      sucursal,
    }) => ({
      id,
      id_whatsapp: id_wapp,
      id_patient: id_paciente,
      id_schedule: id_agenda,
      id_office: id_sucursal,
      phone: nro_tel,
      sending_receiving: envio_recep,
      session_creation_time: sessionCreationTime,
      date: fecha,
      message: mensaje,
      confirm: confirmo,
      cancel: cancelo,
      processed: procesado,
      name_patient: pacNombre,
      appointment_time: horaTurno,
      appointment_date: fechaTurno,
      professional: profesional,
      office: sucursal,
    })
  );

export const listSendWhatsapp = (
  messages: ListSendWhatsappDB[]
): ListSendWhatsapp[] =>
  messages.map(
    ({
      hora,
      idAgenda,
      idPaciente,
      nroTel,
      paciente,
      profesional,
      abreviatura,
      idSucursal,
      sucursal,
      mensajeRespuesta,
    }) => ({
      hour: hora,
      id_schedule: idAgenda,
      id_patient: idPaciente,
      id_office: idSucursal,
      phone: nroTel,
      patient: paciente,
      professional: profesional,
      abbreviation: abreviatura,
      office: sucursal,
      message_response: mensajeRespuesta,
    })
  );

export const messagesFormatter = (
  messages: ListSendWhatsapp[]
): ListSendWhatsappDB[] =>
  messages.map(
    ({
      hour,
      id_schedule,
      id_patient,
      id_office,
      phone,
      patient,
      professional,
      abbreviation,
      office,
      message_response,
    }) => ({
      hora: hour,
      idAgenda: id_schedule,
      idPaciente: id_patient,
      idSucursal: id_office,
      nroTel: phone,
      paciente: patient,
      profesional: professional,
      abreviatura: abbreviation,
      sucursal: office,
      mensajeRespuesta: message_response,
    })
  );

export function messageToProcess(
  message: ListSentWhatsapp
): ProcessToSendWhatsappDB {
  return {
    idagendaprocesar: message.id_schedule,
    listawhatsapps: [
      {
        hora: message.appointment_time,
        idagenda: message.id_schedule,
        idpaciente: message.id_patient,
        idsucursal: message.id_office,
        nrotel: message.phone,
        paciente: message.name_patient,
        profesional: message.professional,
        sucursal: message.office,
      },
    ],
  };
}
