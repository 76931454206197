import React from "react";
import classNames from "classnames";
import { Checkbox } from "@/components/atoms";
import { Benefit, Form } from "@/types/general";
import { TOOTH_FACES } from "@/libs/benefits";
import { Navigation } from "@/types/patients";

export const ToothFaces: React.FC<{
  name: string;
  checksCss?: string;
  form: Form<any>;
  benefit?: Benefit | null | Navigation;
}> = ({ name, checksCss, form, benefit }) => {
  const DISABLED_SELECTION = !benefit?.requires_face;
  const CHECKS_STYLES = classNames("grid grid-cols-4", checksCss);

  return (
    <div className={CHECKS_STYLES}>
      <label htmlFor="input" className="mb-2 block">
        Cara/s
      </label>
      <div className={CHECKS_STYLES}>
        {TOOTH_FACES.map((option: string, i: number) => (
          <div key={i} className="flex items-center gap-x-2">
            <Checkbox
              key={i}
              disabled={DISABLED_SELECTION}
              name={`${name}.${option}`}
              checked={form.watch(name)?.[option]}
              onCheckedChange={(checked) => {
                form.setValue(`${name}.${option}`, checked);
              }}
            />
            <span
              className={classNames("text-slate-900", {
                "!text-gray-500": DISABLED_SELECTION,
              })}
            >
              {option.toUpperCase()}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
