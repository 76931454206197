export const INITIAL_STEPS: {
  id: number;
  name: string;
  status: "complete" | "current" | "upcoming";
  position: string;
  description: string | null;
  key: string | null;
}[] = [
  {
    id: 1,
    name: "Datos del pedido",
    status: "current",
    position: "first",
    description: "Laboratorio: ",
    key: "id_laboratory",
  },
  {
    id: 2,
    name: "Lista de trabajos",
    status: "upcoming",
    position: "second",
    description: null,
    key: null,
  },
  {
    id: 3,
    name: "Resumen del pedido al laboratorio",
    status: "upcoming",
    position: "third",
    description: null,
    key: null,
  },
];
