"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  TimeDatePicker,
} from "@/components/molecules";
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Button,
  SheetFooter,
} from "@/components/atoms";
import { Holyday } from "@/types/general";
import { toast, useDialog } from "@/hooks";
import { updateHolidaysConfig } from "@/actions/configs";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema, resolver } from "./schema";

export const HolydayForm = ({ holyday }: { holyday?: Holyday }) => {
  const { closeDialog } = useDialog();
  const { startLoading, stopLoading, loading } = useGlobalStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      date_from: holyday?.date_from ? new Date(holyday.date_from) : null,
      date_to: holyday?.date_to ? new Date(holyday.date_to) : null,
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const option = holyday ? "modificar" : "nuevo";
    const response = await updateHolidaysConfig({
      ...values,
      rowversion: holyday?.rowversion,
      id_holyday: holyday?.id_holyday,
      id_professional: holyday?.id_professional,
      option,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: holyday
          ? "Feriado actualizado correctamente"
          : "Feriado creada correctamente",
        description: "Los datos se verán reflejados en las tablas.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <SheetContent className="w-96 !text-start flex flex-col justify-between overflow-y-auto">
      <SheetHeader className="!text-start">
        <SheetTitle className="p-2 font-semibold text-xl">
          {holyday ? "Modificar feriado" : "Nuevo feriado"}
        </SheetTitle>
        <SheetDescription className="flex flex-col p-2 font-semibold text-lg">
          Seleccionar fecha
        </SheetDescription>
        <Form {...form}>
          <form className="w-full grid grid-cols-12 gap-4 overflow-hidden bg-white p-2">
            <FormField
              control={form.control}
              name="date_from"
              render={() => (
                <FormItem className="col-span-12">
                  <FormControl>
                    <TimeDatePicker
                      form={form}
                      label="Desde"
                      dateFieldName="date_from"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date_to"
              render={() => (
                <FormItem className="col-span-12">
                  <FormControl>
                    <TimeDatePicker
                      form={form}
                      label="Hasta"
                      dateFieldName="date_to"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </SheetHeader>
      <SheetFooter>
        <SheetClose>
          <Button variant="destructive" type="button">
            Cancelar
          </Button>
        </SheetClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button className="bg-blue-600" onClick={form.handleSubmit(onSubmit)}>
            Guardar
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
};
