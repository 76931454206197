import { PaymentsPatient } from "@/types/patients";

type State = {
  patient_payments: {
    data: {
      [key: string]: any;
    };
    payment_selected: PaymentsPatient | null;
    initialized_data: boolean;
  };
};

type Actions = {
  setPatientPayments: (payload: { data: any[] }) => void;
  setPaymentSelected: (payment: PaymentsPatient | null) => void;
};

type PatientPaymentsStore = State & Actions;

export const INIT_STATE: State = {
  patient_payments: {
    data: [],
    payment_selected: null,
    initialized_data: false,
  },
};

export const PaymentsSlice = (
  set: (partial: Partial<PatientPaymentsStore>) => void,
  get: () => PatientPaymentsStore
): PatientPaymentsStore => ({
  ...INIT_STATE,
  setPatientPayments: ({ data }: { data: any[] }) => {
    const state = get();

    !state.patient_payments.initialized_data &&
      set({
        ...state,
        patient_payments: {
          ...state.patient_payments,
          data,
          initialized_data: true,
        },
      });
  },
  setPaymentSelected: (payment: PaymentsPatient | null) => {
    const state = get();

    set({
      ...state,
      patient_payments: {
        ...state.patient_payments,
        payment_selected: payment,
      },
    });
  },
});
