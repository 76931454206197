import { useCallback } from "react";
import { toast } from "@/hooks/use-toast";
import { useGlobalStore } from "@/providers/global-provider";

export const useRestrictedPatientAction = ({
  callback,
  id,
}: {
  callback: () => void;
  id: number;
}) => {
  const {
    user: { id_professional, is_dentist_data },
  } = useGlobalStore();

  const handleAction = useCallback(() => {
    if (is_dentist_data && id_professional !== id) {
      toast({
        variant: "warning",
        title: "Acción restringida.",
        description:
          "No puedes realizar esta acción en este item. El profesional es distinto.",
      });
    } else {
      callback();
    }
  }, [callback, id, id_professional, is_dentist_data]);

  return handleAction;
};
