import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_patient: z.number(),
  full_name: z
    .string()
    .min(1, {
      message: "Campo requerido",
    })
    .max(40, {
      message: "No puede contener más de 40 caracteres",
    }),
  date_of_birth: z.date(),
  address: z.string().max(40, {
    message: "No puede contener más de 40 caracteres",
  }),
  reasons: z.string().max(50, {
    message: "No puede contener más de 40 caracteres",
  }),
  city: z.string().max(40, {
    message: "No puede contener más de 40 caracteres",
  }),
  state: z.string().max(30, {
    message: "No puede contener más de 30 caracteres",
  }),
  home_phone: z.string().max(30, {
    message: "No puede contener más de 30 caracteres",
  }),
  work_phone: z.string().max(30, {
    message: "No puede contener más de 30 caracteres",
  }),
  mobile_phone: z.string().max(30, {
    message: "No puede contener más de 30 caracteres",
  }),
  email: z.string().max(100, {
    message: "No puede contener más de 100 caracteres",
  }),
  document_number: z.string().max(20, {
    message: "No puede contener más de 20 caracteres",
  }),
  gender: z.string(),
  id_plan: z.number(),
  affiliate_number: z
    .string()
    .max(30, {
      message: "No puede contener más de 30 caracteres",
    })
    .optional(),
  hepatitis: z.boolean().default(false),
  pregnancy: z.boolean().default(false),
  diabetes: z.boolean().default(false),
  allergy: z.boolean().default(false),
  heart_condition: z.boolean().default(false),
  renal_condition: z.boolean().default(false),
  alpha: z.boolean(),
  registration_date: z.date(),
  last_visit_date: z.date(),
  is_holder: z.boolean().default(false),
  clinical_history_number: z.union([z.string(), z.number()]),
  condition: z.string(),
  holder_name: z.string().max(40, {
    message: "No puede contener más de 40 caracteres",
  }),
  holder_company: z.string().max(40, {
    message: "No puede contener más de 40 caracteres",
  }),
  notes: z.string().max(200, {
    message: "No puede contener más de 200 caracteres",
  }),
  photo: z.string().nullable().optional(),
  id_member_holder: z.number(),
  alert: z
    .string()
    .max(250, {
      message: "No puede contener más de 250 caracteres",
    })
    .optional(),
  id_user: z.number().nullable().optional(),
  face: z
    .string()
    .max(50, {
      message: "No puede contener más de 50 caracteres",
    })
    .optional(),
  twitter: z
    .string()
    .max(50, {
      message: "No puede contener más de 50 caracteres",
    })
    .optional(),
  prescription: z.boolean(),
  prescription_date: z.date().nullable().optional(),
  marital_status: z
    .string()
    .max(20, {
      message: "No puede contener más de 20 caracteres",
    })
    .optional(),
  occupation: z
    .string()
    .max(30, {
      message: "No puede contener más de 30 caracteres",
    })
    .optional(),
  cuit: z
    .string()
    .max(11, {
      message: "No puede contener más de 11 caracteres",
    })
    .optional(),
  tax_condition: z.string().nullable().optional(),
  document_type: z.string().nullable().optional(),
  instagram: z
    .string()
    .max(50, {
      message: "No puede contener más de 50 caracteres",
    })
    .optional(),
  rowversion: z.string().nullable().optional(),
  option: z.string().nullable().optional(),
  age: z.string().nullable().optional(),
  balance: z.number(),
  stay_to_search: z.string().nullable().optional(),
  id_med_ins: z.number(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
