"use client";

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Offices } from "@/types/general";
import { OfficesForm, DeleteOffice } from "@/components/organisms/forms";
import { numberToCurrency } from "@/utils/currency";

type RowDataT = {
  original: any;
};

const Dropdown = ({ office }: { office?: Offices }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              openDialog({
                content: <OfficesForm office={office} />,
              });
            }}
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteOffice office={office} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => <Dropdown office={row.original} />,
  },
  {
    accessorKey: "name",
    header: "Sucursal",
    cell: ({ row }: { row: any }) => {
      const { name } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis truncate text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {name}
        </p>
      );
    },
  },
  {
    accessorKey: "enabled",
    header: "Habilitada",
    cell: ({ row }: { row: any }) => {
      const { enabled } = row.original;
      return (
        <p
          className={classNames(
            "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
            {
              "text-red-500": !enabled,
              "text-green-500": enabled,
            }
          )}
        >
          {enabled ? (
            <HiOutlineCheckCircle className="size-5" />
          ) : (
            <HiOutlineBan className="size-5" />
          )}
        </p>
      );
    },
  },
  {
    accessorKey: "description",
    header: "Descripción",
    cell: ({ row }: { row: any }) => {
      const { description } = row.original;
      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {description}
        </p>
      );
    },
  },
  {
    accessorKey: "balance",
    header: "Saldo de caja",
    cell: ({ row }: { row: any }) => {
      const { balance } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {numberToCurrency(balance)}
        </p>
      );
    },
  },
  {
    accessorKey: "date_cash_closing",
    header: "Ult. cierre de caja",
    cell: ({ row }: { row: any }) => {
      const { date_cash_closing } = row.original;
      const DATE_FORMATTED =
        date_cash_closing && format(date_cash_closing, "dd-MM-yyyy");

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {DATE_FORMATTED}
        </p>
      );
    },
  },
  {
    accessorKey: "billing_user",
    header: "Facturación usuario",
    cell: ({ row }: { row: any }) => {
      const { billing_user } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {billing_user}
        </p>
      );
    },
  },
  {
    accessorKey: "billing_company",
    header: "Facturación empresa",
    cell: ({ row }: { row: any }) => {
      const { billing_company } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {billing_company}
        </p>
      );
    },
  },
  {
    accessorKey: "sales_point",
    header: "Punto de venta",
    cell: ({ row }: { row: any }) => {
      const { sales_point } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {sales_point}
        </p>
      );
    },
  },
];
