import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  id_day: z.number().min(1, "Selecciona un día"),
  id_office: z.number().optional(),
  time_from: z.string().min(1, "Hora inicial requerida"),
  time_to: z.string().min(1, "Hora final requerida"),
  interval: z.coerce.number().min(1, "Debe ser mayor a 0").max(90),
  date_from: z.date().optional().nullable(),
  date_to: z.date().optional().nullable(),
  id_specialty: z.number().nullable(),
  all_specialties: z.boolean().optional(),
};

export const formSchema = z
  .object(schema)
  .refine(
    (data: any) => {
      if (!data.time_from || !data.time_to) return true;
      return data.time_to > data.time_from;
    },
    {
      message: "No puede ser menor que la hora inicial",
      path: ["time_to"],
    }
  )
  .refine(
    (data: any) => {
      if (!data.date_from || !data.date_to) return true;
      return data.date_to >= data.date_from;
    },
    {
      message: "La fecha final no puede ser menor a la inicial",
      path: ["date_to"],
    }
  );

export const resolver = zodResolver(formSchema);
