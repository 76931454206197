"use client";

import React from "react";

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number
) => {
  const timeout_ref = React.useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = React.useCallback(
    (...args: any[]) => {
      if (timeout_ref.current) {
        clearTimeout(timeout_ref.current);
      }
      timeout_ref.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
};
