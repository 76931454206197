import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { TAX_CONDITION } from "@/libs/patients";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const MedicalHistory = ({ form }: { form: Form<typeof formSchema> }) => {
  const {
    pregnancy,
    hepatitis,
    diabetes,
    allergy,
    heart_condition,
    renal_condition,
    alpha,
    tax_condition,
  } = form.getValues();
  return (
    <Card className="col-span-12 2xl:col-span-6 p-10">
      <CardHeader>
        <CardTitle>Antecedentes de enfermedades</CardTitle>
        <CardDescription>
          Acá se detallan los antecedentes de enfermedades y condiciones varias
          de tu paciente.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-6">
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-pregnancy"
            checked={pregnancy}
            onCheckedChange={(value: boolean) => {
              form.setValue("pregnancy", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Embarazo</Label>
            <p className="text-sm font-regular text-gray-400">
              Este paciente estuvo o esta embarazada.
            </p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-hepatitis"
            checked={hepatitis}
            onCheckedChange={(value: boolean) => {
              form.setValue("hepatitis", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Hepatitis</Label>
            <p className="text-sm font-regular text-gray-400">
              Tuvo hepatitis.
            </p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-diabetes"
            checked={diabetes}
            onCheckedChange={(value: boolean) => {
              form.setValue("diabetes", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Diabetes</Label>
            <p className="text-sm font-regular text-gray-400">Tuvo diabetes.</p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-allergy"
            checked={allergy}
            onCheckedChange={(value: boolean) => {
              form.setValue("allergy", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Alergia</Label>
            <p className="text-sm font-regular text-gray-400">
              Tuvo o tiene alergias.
            </p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-heart-condition"
            checked={heart_condition}
            onCheckedChange={(value: boolean) => {
              form.setValue("heart_condition", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Cardíaco</Label>
            <p className="text-sm font-regular text-gray-400">Es cardíaco/a.</p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-renal-condition"
            checked={renal_condition}
            onCheckedChange={(value: boolean) => {
              form.setValue("renal_condition", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Renal</Label>
            <p className="text-sm font-regular text-gray-400">
              Tuvo o tiene insuficiencia renal.
            </p>
          </div>
        </div>
        <div className="col-span-3 flex items-center">
          <Checkbox
            className="check-alpha"
            checked={alpha}
            onCheckedChange={(value: boolean) => {
              form.setValue("alpha", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>HIV</Label>
            <p className="text-sm font-regular text-gray-400">
              Tuvo o tiene HIV.
            </p>
          </div>
        </div>
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem className="col-span-12">
              <FormLabel>Observaciones</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={200}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
      <div className="h-px bg-slate-300 my-4" />
      <CardHeader>
        <CardTitle>Datos de facturación</CardTitle>
        <CardDescription>
          Acá vas a encontrar los datos para la facturación de tu paciente junto
          con su condición fiscal.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-6">
        <FormField
          control={form.control}
          name="cuit"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>CUIT</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={11}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-3 self-end">
          <Label>Condición fiscal</Label>
          <Select
            key="tax_condition"
            value={tax_condition}
            onValueChange={(value: string) => {
              form.setValue("tax_condition", value, { shouldDirty: true });
            }}
          >
            <SelectTrigger className="w-full !h-10 !text-sm select-trigger-tax-condition !mb-0">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {TAX_CONDITION.map((tax: { id: string; text: string }) => (
                <SelectItem
                  key={tax.id}
                  value={tax.id}
                  className="select-item-tax-condition"
                >
                  {tax.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <FormField
          control={form.control}
          name="occupation"
          render={({ field }) => (
            <FormItem className="col-span-6">
              <FormLabel>Ocupación</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={30}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
};
