import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_group: z.number(),
  id_med_ins: z.number(),
  code_group: z.string().min(1, {
    message: "El código es requerido",
  }),
  description: z.string().min(1, {
    message: "El nombre es requerido",
  }),
  rowversion: z.string().nullable(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
