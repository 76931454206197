import { IconType } from "react-icons/lib";
import {
  LuHouse as Home,
  LuCalendar as Calendar,
  LuUserCog as UserCog,
  LuCog as Cog,
  LuUsers as Users,
  LuChartColumnBig as BarChartBig,
  LuFileText as FileText,
} from "react-icons/lu";
import { HiOutlineBanknotes as Banknotes } from "react-icons/hi2";
import { Permissions } from "@/types/permissions";
import { getFirstAvailableUrl } from "@/libs/sidebars";
import {
  ADMINISTRATION_ITEMS,
  CONFIGURATION_ITEMS,
  PATIENTS_ITEMS,
  STATISTICS_ITEMS,
} from "@/libs/nav";

type TCreateNavigation = {
  permissions: Permissions;
  plan: string;
  isSupervisor: boolean;
  isOwner: boolean;
};

const hasFullFeaturesVersions = (version: string) =>
  ["premium", "standard", "full"].includes(version);

export function createNavigation({
  permissions,
  plan,
  isSupervisor,
  isOwner,
}: TCreateNavigation) {
  const renderStatistics =
    (hasFullFeaturesVersions(plan) && isSupervisor) ||
    (hasFullFeaturesVersions(plan) &&
      (Object.keys(permissions).includes("Estadísticas") ||
        (Object.keys(permissions).includes("Informes") &&
          (Object.keys(permissions).includes("Indicadores") ||
            Object.keys(permissions).includes("Presupuestos") ||
            Object.keys(permissions).includes("Prestaciones")))));

  const renderLiquidation =
    Object.keys(permissions).includes("Liquidacion Bilog") || isSupervisor;

  const links: {
    title: string;
    new: boolean;
    url: string | null;
    icon: IconType;
    styles: string;
    subItems?: string[];
  }[] = [
    {
      title: "Inicio",
      new: false,
      url: "/dashboard",
      icon: Home,
      styles: "px-4 flex justify-between text-slate-500",
    },
  ];

  (Object.keys(permissions).includes("Agenda Turnos") || isSupervisor) &&
    links.push({
      title: "Agenda de turnos",
      new: false,
      url: "/dashboard/schedule",
      icon: Calendar,
      styles: "px-4 flex justify-between text-slate-500",
    });

  (Object.keys(permissions).includes("Pacientes") || isSupervisor) &&
    links.push({
      title: "Pacientes",
      new: false,
      url: "/dashboard/patients/personal-data",
      icon: Users,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: PATIENTS_ITEMS,
    });

  (Object.keys(permissions).includes("Administracion") || isSupervisor) &&
    links.push({
      title: "Administración",
      new: true,
      url: "/dashboard/administration/dailycashflow/patient_payments",
      icon: Banknotes,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: ADMINISTRATION_ITEMS,
    });

  renderStatistics &&
    links.push({
      title: "Estadísticas",
      new: false,
      url: "/dashboard/statistics/finances",
      icon: BarChartBig,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: STATISTICS_ITEMS,
    });

  renderLiquidation &&
    links.push({
      title: "Liquidaciones",
      new: false,
      url: "/dashboard/reports/auditor",
      icon: FileText,
      styles: "px-4 flex justify-between text-slate-500",
    });
  (Object.keys(permissions).includes("Tablas") || isSupervisor) &&
    links.push({
      title: "Configuración",
      new: false,
      url: isSupervisor
        ? "/dashboard/configuration/tables/professionals"
        : getFirstAvailableUrl(permissions, "tables") ||
          getFirstAvailableUrl(permissions, "system"),
      icon: Cog,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: CONFIGURATION_ITEMS,
    });
  isOwner &&
    ["freemium", "premium"].includes(plan) &&
    links.push({
      title: "Mi cuenta",
      new: false,
      url: "/dashboard/my-account/general",
      icon: UserCog,
      styles: "px-4 flex justify-between text-slate-500",
    });

  return links;
}
