import React from "react";
import { Laboratory } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";
import { EmptyState } from "@/components/molecules";

export const Table: React.FC<{
  data?: Laboratory;
  table: "origin" | "destination";
  form?: any;
}> = ({ data, table, form }) => {
  return data?.lab_concepts && data?.lab_concepts?.length > 0 ? (
    <Data data={data?.lab_concepts} columns={Columns({ table, form })} />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
