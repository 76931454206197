import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_laboratory: z.number(),
  id_concept: z.number(),
  code: z.string().min(1, {
    message: "Código requerido",
  }).max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  amount: z.coerce.number(),
  description: z.string().min(1, {
    message: "Concepto requerido",
  }).max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  enabled: z.boolean(),
  is_usd: z.boolean(),
  rowversion: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
