"use client";

import React from "react";
import classNames from "classnames";
import { useGlobalStore } from "@/providers/global-provider";
import { LuChevronLeft } from "react-icons/lu";
import {
  Button,
  buttonVariants,
  Card,
  CardContent,
  Dialog,
  Skeleton,
  Tabs,
  TabsContent,
} from "@/components/atoms";
import {
  BudgetsTable,
  PaymentBudgetTable,
} from "@/components/organisms/tables";
import { EmptyState } from "@/components/molecules";
import { BudgetsForm } from "@/components/organisms/forms";
import { updateSection } from "@/actions/patients";
import { toast } from "@/hooks";
import { getPayments } from "@/actions/budgets";
import { PaymentBudget } from "@/types/patients";
import { numberToCurrency } from "@/utils/currency";
import { PerformanceIndicator } from "@/components/organisms/forms/patients/budgets/performance-indicator";
import { MedicalInsurance } from "@/types/general";
import { Budget } from "@/types/patients";
import { Delete } from "./delete";

export const BudgetsView = () => {
  const [activeTab, setActiveTab] = React.useState<string>("tables");
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [payments, setPayments] = React.useState<PaymentBudget[]>([]);
  const {
    patient,
    patient_data_loading,
    searchBarRef,
    setBudgetSelected,
    updatePatient,
    budget_selected,
    general_params: { medical_insurances, offices, professionals },
  } = useGlobalStore();
  const refresh = async () => {
    try {
      const { budgets } = await updateSection(["presup"], patient.id_patient);

      updatePatient("budgets", budgets);
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    }
  };
  const CASES_ACTIONS: { [key: string]: (budget: Budget) => void } = {
    edit: (budget) => {
      const selected = patient?.budgets?.find(
        (b: Budget) => b.id_budget === budget.id_budget
      );
      setBudgetSelected(selected);
      setActiveTab("form");
    },
    delete: (budget) => {
      setOpenDeleteModal(true);
      setBudgetSelected(budget);
    },
    view_payments: async (budget) => {
      try {
        setBudgetSelected(budget);
        const payments = await getPayments(budget.id_budget);
        setActiveTab("payments");
        setPayments(payments);
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: error.message,
        });
      }
    },
  };
  const handleNewBudgetOnClick = () => {
    setBudgetSelected(null);
    return setActiveTab("form");
  };
  const actions = (order: Budget, action: string) => {
    CASES_ACTIONS[action](order);
  };
  const BUDGET = React.useMemo(() => {
    return patient?.budgets?.map((budget: Budget) => {
      const MED_INS = medical_insurances?.find((m: MedicalInsurance) =>
        m?.plans?.find((plan) => plan.id_plan === budget?.id_plan)
      );
      return {
        ...budget,
        id_plan_navigation: {
          ...budget.id_plan_navigation,
          medical_insurance_navigation: MED_INS,
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.budgets]);

  return (
    <Tabs
      defaultValue={activeTab}
      value={activeTab}
      className="!w-full hidden lg:flex lg:flex-col"
    >
      <TabsContent value="tables" className="!w-full !mt-0">
        {patient_data_loading ? (
          <div className="col-span-full grid grid-cols-12 gap-4 mx-auto">
            <Skeleton className="h-10 w-40 rounded-xl" />
            <Skeleton className="col-start-12 h-10 rounded-xl" />
            <Skeleton className="col-span-full h-80 rounded-xl" />
          </div>
        ) : patient.id_patient ? (
          <div className="grid grid-cols-12">
            {patient?.budgets?.length ? (
              <BudgetsTable
                loading={false}
                data={{
                  budgets: BUDGET,
                }}
                action={actions}
              />
            ) : (
              <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
                <EmptyState
                  title="No hay presupuestos para mostrar"
                  copy="Puedes cargar un presupuesto"
                  className="col-span-full !justify-center"
                />
                <Button
                  className="col-span-5 col-start-6 w-full"
                  onClick={handleNewBudgetOnClick}
                >
                  Nuevo presupuesto
                </Button>
              </div>
            )}
            {patient?.id_patient && patient?.budgets?.length > 0 && (
              <Button
                onClick={handleNewBudgetOnClick}
                className="col-span-4 col-start-11 mt-5"
              >
                Nuevo presupuesto
              </Button>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-x-10">
            <EmptyState
              title="No existe paciente seleccionado"
              copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
              className="col-span-6 !justify-start flex col-start-4"
            />
            <Button
              className="col-span-3 col-start-6"
              onClick={() => searchBarRef?.current?.focus()}
            >
              Buscar Paciente
            </Button>
          </div>
        )}
      </TabsContent>
      <Dialog open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
        <Delete onChange={refresh} />
      </Dialog>
      <TabsContent value="form" className="!mt-0">
        <BudgetsForm
          onCancel={() => setActiveTab("tables")}
          professionals={professionals}
          offices={offices}
          medicalInsurances={medical_insurances}
          refresh={refresh}
          setActiveTab={setActiveTab}
        />
      </TabsContent>
      {budget_selected && (
        <TabsContent value="payments" className="!mt-0">
          <Card className="col-span-full grid grid-cols-12 shadow-md rounded-md bg-white !p-0 mb-2">
            <div className="col-span-full flex flex-row items-center gap-x-2 p-2">
              <span
                onClick={() => setActiveTab("tables")}
                className={classNames(
                  "cursor-pointer",
                  buttonVariants({ variant: "outline", size: "icon" })
                )}
              >
                <LuChevronLeft className="size-4" />
                <span className="sr-only">Back</span>
              </span>
              <span className="col-span-12 text-2xl font-semibold rounded-t-md bg-white truncate p-4">
                {budget_selected.description}
              </span>
            </div>
            <CardContent
              className="border !p-4
                col-span-6"
            >
              <h3 className="text-slate-500 text-base pb-3">Profesional</h3>
              <span className="text-slate-900 font-semibold">
                {budget_selected.id_professional_navigation?.name}
              </span>
            </CardContent>
            <CardContent
              className="border !p-4
                col-span-6"
            >
              <h3 className="text-slate-500 text-base pb-3">Valor Total</h3>
              <span className="text-green-500 font-semibold">
                {numberToCurrency(budget_selected.total_amount)}
              </span>
            </CardContent>
          </Card>
          <PaymentBudgetTable data={payments} />
        </TabsContent>
      )}
      <TabsContent value="services" className="!mt-0">
        <PerformanceIndicator
          medicalInsurances={medical_insurances}
          setActiveTab={setActiveTab}
        />
      </TabsContent>
    </Tabs>
  );
};
