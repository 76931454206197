export const dataPreset: { id: string; label: string }[] = [
  { id: "last7", label: "Últimos 7 días" },
  { id: "last15", label: "Últimos 15 días" },
  { id: "last30", label: "Últimos 30 días" },
  { id: "last90", label: "Últimos 90 días" },
  { id: "personalized", label: "Personalizado" },
];
export const dataPresetCompare: { id: string; label: string }[] = [
  { id: "lastperiod", label: "Periodo anterior" },
  { id: "sameperiodlastyear", label: "Mismo periodo del año anterior" },
  { id: "personalized", label: "Personalizado" },
];
