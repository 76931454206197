import { UTCDate } from "@date-fns/utc";
import { es } from "date-fns/locale";
import { addHours, format, isEqual, parseISO } from "date-fns";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import { PHONE_CONFIG } from "@/libs/config";
import { Offices as OfficesT, Professional } from "@/types/general";
import { ProfessionalTimetable } from "@/types/schedule";
import { ListSentWhatsapp } from "@/types/reminders";
import { ATTENDANCE_OPTIONS } from "@/libs/schedule";
import { ALIAS_METHOD, PAYMENT_METHOD } from "@/libs/patients";

export const compareDatesRanges = ({
  from,
  to,
  date,
}: {
  from: string;
  to: string;
  date: Date;
}) => {
  if (!from && !to) return true;

  if (!from) {
    return new Date(to).valueOf() > new Date(date).valueOf();
  } else if (!to) {
    return new Date(from).valueOf() < new Date(date).valueOf();
  } else {
    return (
      new Date(from).valueOf() < new Date(date).valueOf() &&
      new Date(to).valueOf() > new Date(date).valueOf()
    );
  }
};

export const getDayOfWeek = (dateString: string | Date): number => {
  const date = new Date(dateString);
  const value = date.getDay();
  const dayOfWeek = value === 0 ? 7 : value;

  return dayOfWeek;
};

export const addDaysToDate = (date: string, days: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const filterEnabledOffices = (offices: OfficesT[]) => {
  return offices?.filter((office) => office.enabled) || [];
};

export const setArrivalHour = (assistance: string | null) => {
  const hour = new TZDate(new Date(), TIMEZONES.AR).getHours();
  const minutes = new TZDate(new Date(), TIMEZONES.AR).getMinutes();
  if (assistance === "PRE")
    return `${hour}:${minutes > 10 ? minutes : `0${minutes}`}`;
  return null;
};

export const checkPermission = (
  tab: string,
  isSupervisor: boolean,
  permissions: Record<string, boolean>
) => {
  return isSupervisor || permissions[tab] || false;
};

export const addDefaultProf = (professionals: Professional[]) => {
  const new_prof = {
    id_professional: -1,
    name: "TODOS",
    enabled: true,
    id_first_specialty: null,
    id_second_specialty: null,
    professional_timetable: [],
  };

  return [new_prof, ...professionals];
};

// Función para transformar una clave de camelCase a snake_case
export const toSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

// Tipos para array de objetos o cualquier dato
type AnyObject = { [key: string]: any };
type Transformable =
  | AnyObject
  | Transformable[]
  | string
  | number
  | boolean
  | null
  | undefined;

// Función recursiva para transformar un objeto a snake_case
export const transformKeysToSnakeCase = (obj: Transformable): Transformable => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToSnakeCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
      const snakeKey = toSnakeCase(key);
      const value = obj[key];
      acc[snakeKey] = transformKeysToSnakeCase(value);

      return acc;
    }, {});
  }
  return obj;
};

export const messagesListReducer = (arr: ListSentWhatsapp[]) => {
  let newArr: any[] = [];
  arr.forEach((e) => {
    if (newArr.find((j) => j.id_schedule === e.id_schedule)) {
      let [obj] = newArr.filter((j) => j.id_schedule === e.id_schedule);
      newArr = newArr.filter((j) => j.id_schedule !== e.id_schedule);
      newArr.push({ ...obj, message: [...obj.message, e.message] });
    } else {
      newArr.push({ ...e, message: [e.message] });
    }
  });
  return newArr;
};

export const formatMessage = (m: string) => {
  let message: string;
  message = m?.trim();
  message = message.replace("Template:", "");
  message = message.replace("!??", "!");
  message = message.replace("\n\n??", ",");
  message = message.replace("\n??", ",");
  message = message.replace("\n?", ",");
  message = message.replace("??", ",");
  message = message.replace(" ??", ".");
  return message;
};

export const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

export const getTabLimit = (width: number, tabCount: number) => {
  if (width < 1280) return 7;
  if (width <= 1512) return 8;
  return tabCount + 1;
};

export const getHeigth = (
  result: any[],
  defaultHeigth: number,
  sizeRow: number,
  sizeAditional: number
) => {
  const calculatedHeight = result.length * sizeRow + sizeAditional;
  if (defaultHeigth > calculatedHeight) {
    defaultHeigth = calculatedHeight;
  }
  return `${defaultHeigth}px`;
};
export const formatPhoneNumberWhatsapp = (
  phoneNumber: string,
  country: string
) => {
  const CLEAN_NUMBER = phoneNumber.replace(/[^0-9]/g, "");
  if (!CLEAN_NUMBER || typeof CLEAN_NUMBER !== "string") {
    return CLEAN_NUMBER;
  }
  let number = CLEAN_NUMBER.trim();
  if (number.startsWith("0")) {
    number = number.slice(1);
  }
  const config =
    PHONE_CONFIG[country?.toLowerCase().trim()] || PHONE_CONFIG.default;

  if (config.adjustments) {
    number = config.adjustments(number);
  }
  if (!number.startsWith(config.prefix)) {
    number = `${config.prefix}${number}`;
  }

  return config.regex.test(number) ? number : CLEAN_NUMBER;
};

export const formatDate = (date: string) => {
  return format(date, "dd 'de' MMMM 'de' yyyy", { locale: es });
};

export function checkFileType(source: any) {
  return source && source.split(".").pop();
}

export function getSourceFromFileType(fileType: any) {
  const fileToLower = fileType?.toLocaleLowerCase();
  if (["jpg", "jpeg", "png", "webp"].includes(fileToLower)) return "IMG";
  if (fileToLower === "pdf") return "PDF";
  if (fileToLower === "txt") return "TXT";
  if (fileToLower === "mp4") return "MP4";
  return null;
}

export const archivesMapper = (
  archives: any[],
  strings: { name: string; base64: string }[],
  patientId: number
) =>
  archives.map((archive) => {
    let fileType = archive.name.split(".");
    const base64String = strings.find(
      (item) => item.name === archive.name
    )?.base64;

    return {
      id_paciente: patientId,
      fecha: new Date().toISOString(),
      descripcion: archive.name,
      filedata: base64String,
      filetype: `.${fileType[fileType.length - 1]}`,
      size: byteToMb(archive.size),
    };
  });

export const checkMapper = (archives: any[]) =>
  archives.map((archive) => {
    return {
      size: Math.round(archive.size),
    };
  });

export const byteToMb = (bytes: number) => {
  const k = 1024;
  const mb = Math.round(bytes / k / k);
  return mb >= 1 ? mb : 1;
};

export const checkUrl = (url: string) => {
  const reg = new RegExp(/(.*https)(.*cloudinary)/gm);
  return reg.test(url);
};
export const getSelectedFaces = (
  facesObject: Record<string, boolean> | null
) => {
  return (
    (facesObject &&
      Object.entries(facesObject)
        .filter(([_, isSelected]) => isSelected)
        .map(([face]) => face.toUpperCase())
        .join("")) ||
    null
  );
};

export const getInitials = (name: string) => {
  const words = name.split(" ");
  return words[0][0] + (words[1] ? words[1][0] : "");
};

export const formatDateToString = (date: string) => {
  const parsedDate = parseISO(date);
  return format(parsedDate, "dd'/'MM'/'yyyy", { locale: es });
};

export const appointmensOptions = (assistance: string) => {
  return ATTENDANCE_OPTIONS.find((a) => a.id === assistance);
};

export const installmentsMapper = (card: { [key: string]: any }) => {
  return card?.card_installments?.map((quote: { [key: string]: any }) => {
    return {
      id_installment: quote.id_installment,
      text: quote.installment_number + "",
      value: quote.interest,
    };
  });
};

export const ccFormatNumber = (str: string) => {
  var v = str.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  return parts.length ? parts.join(" ") : str;
};

export const splitNameFormatter = (
  name: string
): { first_name: string; last_name: string } => {
  let first_name = "";
  let last_name = "";
  const NAME_PARTS = name.split(" ");
  if (NAME_PARTS.length >= 3) {
    last_name = NAME_PARTS.slice(0, -1).join(" ");
    first_name = NAME_PARTS[NAME_PARTS.length - 1];
  } else if (NAME_PARTS.length === 2) {
    last_name = NAME_PARTS[0];
    first_name = NAME_PARTS[1];
  } else {
    last_name = NAME_PARTS[0];
    first_name = ".";
  }

  return { first_name, last_name };
};

export const roundNumber = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};
export function getProfessionalWorkDays({
  id_office,
  currentProfessional,
}: {
  id_office: number | null;
  currentProfessional: Professional | null;
}) {
  let daysCurrentProf: any;
  if (!currentProfessional || !currentProfessional.professional_timetable) {
    return [];
  }
  if (id_office) {
    daysCurrentProf = currentProfessional.professional_timetable
      .filter((s: ProfessionalTimetable) => s.id_office === id_office)
      .map((s: ProfessionalTimetable) => s.day);
  } else {
    daysCurrentProf = currentProfessional.professional_timetable.map(
      (s: ProfessionalTimetable) => s.day
    );
  }

  return daysCurrentProf;
}

export const objectChangeKey = ({
  originalArray,
  oldKey,
  newKey,
}: {
  originalArray: any[];
  newKey: string;
  oldKey: string;
}) => {
  const NEW_ARRAY = originalArray.map((obj) => ({
    ...obj,
    [newKey]: obj[oldKey],
  }));

  NEW_ARRAY.forEach((obj) => delete obj[oldKey]);

  return NEW_ARRAY;
};

export const getPaymentMethod = (paymentMethod: string): number => {
  const resolved_method = ALIAS_METHOD[paymentMethod] || paymentMethod;

  return (
    PAYMENT_METHOD.find((method) => method.value === resolved_method)
      ?.id_payment_method || 1
  );
};

export function sortByDate(data: unknown[]) {
  return data.sort(
    (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
}
