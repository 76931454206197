import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  clinic_history_from: z.coerce.number(),
  schedule_aca: z.boolean(),
  schedule_alert: z.boolean(),
  lender_code_bilog: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
