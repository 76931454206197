import React from "react";
import { BenefitRow } from "./benefit-row";
import { BenefitBudget } from "@/types/patients";

export const CardDetailTooth: React.FC<{
  selectedQuantity: number;
  benefits: BenefitBudget[];
  form: any;
}> = ({ selectedQuantity, benefits, form }) => {
  const BENEFIT_ROWS: JSX.Element[] = [];
  for (let i = 0; i < selectedQuantity; i++) {
    BENEFIT_ROWS.push(
      <BenefitRow position={i} benefits={benefits} form={form} />
    );
  }

  return BENEFIT_ROWS;
};
