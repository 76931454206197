"use client";

import React from "react";
import classNames from "classnames";
import {
  HiDotsVertical,
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import { useDialog } from "@/hooks";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
} from "@/components/atoms";
import {
  NewMedicalInsuranceBenefitForm,
  DeleteBenefitForm,
} from "@/components/organisms/forms";
import {
  Benefit,
  Representation,
  Specialty,
  Plan,
  MedicalInsurance,
} from "@/types/general";

type RowDataT = {
  original: any;
};

const BenefitsDropdown = ({
  selected,
  data: { representations, specialties, medicalInsurance, plan },
}: {
  selected: Benefit;
  data: {
    representations: Representation[];
    specialties: Specialty[];
    medicalInsurance: MedicalInsurance;
    plan: Plan;
  };
}) => {
  const { openDialog } = useDialog();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="relative p-2 hover:cursor-pointer flex-none">
            <span className="sr-only">Abrir menu</span>
            <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="start">
          <DropdownMenuGroup>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() =>
                openDialog({
                  content: (
                    <NewMedicalInsuranceBenefitForm
                      selected={selected}
                      data={{
                        representations,
                        specialties,
                        medicalInsurance,
                        plan,
                      }}
                    />
                  ),
                })
              }
            >
              <HiOutlinePencil className="mr-2 size-4" />
              <span>Modificar</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() =>
                openDialog({
                  content: (
                    <DeleteBenefitForm
                      selected={selected}
                      data={{
                        medicalInsurance,
                        plan,
                      }}
                    />
                  ),
                })
              }
            >
              <HiOutlineTrash className="mr-2 size-4" />
              <span>Eliminar</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export const Columns = ({
  representations,
  specialties,
  medicalInsurance,
  plan,
}: {
  representations: Representation[];
  specialties: Specialty[];
  medicalInsurance: MedicalInsurance;
  plan: Plan;
}) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <BenefitsDropdown
          selected={row.original}
          data={{
            representations,
            specialties,
            medicalInsurance,
            plan,
          }}
        />
      ),
    },
    {
      accessorKey: "description",
      header: "Prestación",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { description, benefit_code, code_benefit_med_ins_liq } = row.original;

        return (
          <>
            <div className="flex gap-x-2">
              <Badge className="w-auto 2xl:ml-3">{`Código OS ${benefit_code}`}</Badge>
              <Badge
                variant="secondary"
                className="w-auto 2xl:ml-3"
              >{`Cód. OS Liq. ${code_benefit_med_ins_liq}`}</Badge>
            </div>
            <p className="font-semibold text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
              {description}
            </p>
          </>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En USD",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_usd } = row.original;

        return (
          <p
            className={classNames(
              "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
              {
                "text-slate-500": !is_usd,
                "text-green-500": is_usd,
              }
            )}
          >
            {is_usd ? (
              <HiOutlineCheckCircle className="size-5" />
            ) : (
              <HiOutlineBan className="size-5" />
            )}
          </p>
        );
      },
    },
    {
      accessorKey: "amount_mi",
      header: "Importe OS",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { amount_mi } = row.original;

        return (
          <p className="font-semibold text-sm text-slate-500/80">
            $ {amount_mi}
          </p>
        );
      },
    },
    {
      accessorKey: "amount_patient",
      header: "Importe paciente",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { amount_patient } = row.original;

        return (
          <p className="font-semibold text-sm text-slate-500/80">
            $ {amount_patient}
          </p>
        );
      },
    },
  ];
};
