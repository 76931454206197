"use client";

import React from "react";
import { HiOutlineBan, HiOutlineCheckCircle } from "react-icons/hi";
import { numberToCurrency } from "@/utils/currency";
import {
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/atoms";
import { LaboratoryConcept } from "@/types/general";

export const Columns = ({
  table,
  form,
}: {
  table: "origin" | "destination";
  form: any;
}) => {
  const columns = [
    {
      id: "select",
      header: ({ table }: { table: any }) => (
        <Tooltip delayDuration={500}>
          <TooltipTrigger asChild>
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) => {
                table.toggleAllPageRowsSelected(!!value);
                const ALL_ROWS = table.getRowModel().rows;
                const ALL_ARTICLES = ALL_ROWS.map((row: any) => row.original.id_concept);
                if (value) {
                  form.setValue("selected_articles", ALL_ARTICLES);
                } else {
                  form.setValue("selected_articles", []);
                }
              }}
              aria-label="Seleccionar todo"
              className="translate-y-[2px]"
            />
          </TooltipTrigger>
          <TooltipContent side="right">
            <p>Seleccionar todos</p>
          </TooltipContent>
        </Tooltip>
      ),
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              const { selected_articles } = form.getValues();
              row.toggleSelected(!!value);

              if (value) {
                form.setValue("selected_articles", [
                  ...selected_articles,
                  row.original.id_concept,
                ]);
              } else {
                const UPDATE_ARTICLE = selected_articles.filter(
                  (l: LaboratoryConcept) =>
                    l.id_concept !== row.original.id_concept
                );
                form.setValue("selected_articles", UPDATE_ARTICLE);
              }
            }}
            aria-label="Seleccionar fila"
            className="translate-y-[2px]"
          />
        );
      },
    },
    {
      accessorKey: "code",
      header: "Codigo",
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {code}
          </p>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Concepto",
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "amount",
      header: "Importe",
      cell: ({ row }: { row: any }) => {
        const { amount, is_usd } = row.original;

        return is_usd ? (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">{`$${amount}`}</p>
        ) : (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {numberToCurrency(amount)}
          </p>
        );
      },
    },
    {
      accessorKey: "enabled",
      header: "Habilitado",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { enabled } = row.original;

        return (
          <p>
            {enabled ? (
              <HiOutlineCheckCircle className="size-5 text-green-500" />
            ) : (
              <HiOutlineBan className="size-5 text-red-500" />
            )}
          </p>
        );
      },
      enableGrouping: false,
    },
  ];
  ["destination"].includes(table) && columns.splice(0, 1);

  return columns;
};
