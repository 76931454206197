"use client";

import React from "react";
import { deleteArchives } from "@/actions/archives";
import {
  Button,
  DialogClose,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { toast } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";

export const DeleteImage = ({
  archive,
  refresh,
  closeModal,
}: {
  archive: any[];
  refresh: () => void;
  closeModal: (value: boolean) => void;
}) => {
  const { startLoading, stopLoading, loading, resetArchiveSelected } =
    useGlobalStore();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteArchives(archive);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Los archivos seleccionados se han eliminado con éxito",
        description: "Los datos de tu cuenta han sido actualizados.",
      });
      refresh();
      resetArchiveSelected();
      closeModal(false);
    }

    stopLoading();
  };

  return (
    <DialogHeader>
      <DialogTitle>Eliminar archivo/s</DialogTitle>
      <p className="text-sm font-regular text-neutral-500 mb-0">
        ¿Desea eliminar el archivo seleccionado?
      </p>
      <p className="text-sm mb-6 text-red-500 font-bold">
        Esta acción es irreversible y los archivos se perderán.
      </p>
      <div className="flex gap-x-4">
        <DialogClose>
          <Button type="button" variant="destructive" className="w-full">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-archive">
            Aceptar
          </Button>
        )}
      </div>
    </DialogHeader>
  );
};
