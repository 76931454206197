import React from "react";
import { HiDotsVertical } from "react-icons/hi";
import { LuPen, LuPencil, LuTrash2 } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { format } from "date-fns";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/atoms";
import { useRestrictedPatientAction } from "@/hooks/use-patient-restricted-action";
import { MedicalHistory } from "@/types/patients";

const Dropdown = ({
  his_cli,
  action,
}: {
  his_cli: MedicalHistory;
  action: (budget: MedicalHistory, type: string) => void;
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(his_cli, type),
          id: his_cli.id_prof,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <Action type="create_edit">
            <LuPen className="mr-2 size-4" />
            <span>Ver detalles</span>
          </Action>
          <Action type="delete">
            <LuTrash2 className="mr-2 size-4" />
            <span>Eliminar</span>
          </Action>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = ({
  action,
}: {
  action: (hc: { [key: string]: any }, type: string) => void;
}) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return <Dropdown his_cli={row.original} action={action} />;
      },
    },
    {
      accessorKey: "date",
      header: "Fecha",
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="lg:text-sm text-slate-600 my-1">
            {format(date, "dd/MM/yyyy")}
          </p>
        );
      },
    },
    {
      accessorKey: "cli_hist",
      header: "Historia clínica",
      cell: ({ row }: { row: any }) => {
        const { cli_hist } = row.original;

        return (
          <Tooltip delayDuration={500}>
            <TooltipTrigger asChild>
              <p className="lg:text-sm text-slate-600 my-1 max-w-60 truncate text-ellipsis">
                {cli_hist}
              </p>
            </TooltipTrigger>
            <TooltipContent
              side="bottom"
              className="max-w-[600px] max-h-44 overflow-y-auto"
            >
              <p className="w-full break-words">{cli_hist}</p>
            </TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Profesional",
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="lg:text-sm text-slate-600 my-1 text-nowrap">
            {`${professional?.abbreviation} ${professional?.name}`}
          </p>
        );
      },
    },
  ];
};
