export const BENEFIT_STATES: {
  id_benefits_state: number;
  value: boolean | null;
  text: string;
}[] = [
  { id_benefits_state: 1, value: null, text: "Todas" },
  { id_benefits_state: 2, value: true, text: "Liquidadas" },
  { id_benefits_state: 3, value: false, text: "No liquidadas" },
];

export const getDefaultStats = ({
  totalAmount,
  totalServices,
}: {
  totalAmount: number;
  totalServices: number;
}) => {
  const formatAmount = () => {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
    }).format(totalAmount);
  };
  formatAmount();
  return [
    {
      name: "Total facturado",
      stat: `${formatAmount()}`,
    },
    {
      name: "Prestaciones realizadas",
      stat: totalServices,
    },
  ];
};

export const INITIAL_STATE = {
  data: {
    services: [],
    total_amount: 0,
    total_services: 0,
    total_pages: 0,
  },
  params: {
    id_med_ins: 0,
    id_professional: 0,
    id_group: 0,
    id_benefits_state: 1,
    period: "",
    all_prof: true,
    all_groups: true,
    is_rebilling: false,
    include_prof_code: false,
    page_number: 1,
  },
};

export const DEFAULT_GROUPS = [
  {
    id_group: 1,
    description: "GENERAL",
  },
];

export const OPTIONS_STATE = [
  { id_state: 1, value: "buscarwapps", text: "Todos" },
  { id_state: 2, value: "buscarwappsnoprocesados", text: "No respondidos" },
  { id_state: 3, value: "buscarwappsprocesados", text: "Respondidos" },
];
