import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  id_office: z.number().nullable().optional(),
  name: z.string(),
  description: z.string(),
  enabled_office: z.boolean(),
  enabled_billing: z.boolean(),
  sales_point: z.string().optional(),
  date_cash_closing: z.date().optional(),
  user: z.string().optional(),
  password: z.string().optional(),
  company: z.string().optional(),
  cash_balance: z.coerce.number().optional(),
  cash_balance_usd: z.coerce.number().optional(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
