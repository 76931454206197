import { Button, RadioGroup, RadioGroupItem } from "@/components/atoms";
import {
  ButtonLoading,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/molecules";
import { CRITERIA_OPTIONS, OFFICES_OPTIONS } from "@/libs/schedule";
import { Form } from "@/types/general";
import { formSchema as apptFormSchema } from "./schema";
import { formSchema as scheduleFormSchema } from "../search/schema";

export const SelectByCriteria = ({
  onSubmit,
  apptForm,
  form,
  setLoading,
  loading,
}: {
  onSubmit: ({
    type_loading,
    submit_criteria,
  }: {
    type_loading: string;
    submit_criteria?: boolean;
  }) => void;
  apptForm: Form<typeof apptFormSchema>;
  form: Form<typeof scheduleFormSchema>;
  setLoading: (value: string) => void;
  loading: { [key: string]: boolean };
}) => {
  const { id_office, id_specialty } = form.getValues();

  return (
    <div className="flex flex-col gap-4">
      {id_specialty !== -1 && (
        <>
          <p className="text-base font-regular text-slate-600 mb-2">
            ¿Querés buscar por profesional o por especialidad?
          </p>
          <div className="mb-2">
            <FormField
              control={apptForm.control}
              name="criteria"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      {CRITERIA_OPTIONS.map((item) => (
                        <div
                          className="flex space-x-3 items-center"
                          key={item.key}
                        >
                          <RadioGroupItem value={item.key} />
                          <FormLabel>{item.label}</FormLabel>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </>
      )}
      {id_office && (
        <div>
          <p className="text-base font-regular text-slate-600 mb-2">
            ¿Querés buscar en esta sucursal o en todas?
          </p>
          <div className="mb-2">
            <FormField
              control={apptForm.control}
              name="office"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      {OFFICES_OPTIONS.map((item) => (
                        <div
                          className="flex space-x-3 items-center"
                          key={item.key}
                        >
                          <RadioGroupItem value={item.key} />
                          <FormLabel>{item.label}</FormLabel>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
      )}
      {loading.search_criteria ? (
        <ButtonLoading className="w-full" />
      ) : (
        <Button
          onClick={() => {
            setLoading("search_criteria");
            onSubmit({ type_loading: "", submit_criteria: true });
          }}
          className="px-10 h-10"
        >
          Buscar turno
        </Button>
      )}
    </div>
  );
};
