import React from "react";

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  original: any;
};

type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => "asc" | "desc" | undefined;
};

type ColumnsT = {
  accessorKey: string;
  header: string | (({ column }: { column: ColumnT }) => JSX.Element);
  cell: ({ row }: { row: RowDataT }) => any;
};

export const Columns = () => {
  const columns: ColumnsT[] = [
    {
      accessorKey: "name_commercial",
      header: "Nombre comercial",
      cell: ({ row }: { row: RowDataT }) => {
        const { product_name, drug_name } = row.original;

        return (
          <div className="text-base font-semibold leading-6 text-slate-900 my-1 hover:cursor-pointer">
            <p>{product_name}</p>
            <p className="text-slate-500">{drug_name}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "active_principle",
      header: "Presentación",
      cell: ({ row }: { row: RowDataT }) => {
        const { presentation } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-500 my-1 hover:cursor-pointer">
            {presentation}
          </p>
        );
      },
    },
  ];
  return columns;
};
