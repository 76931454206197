"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Input,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FreemiumForm,
} from "@/components/molecules";
import { createOrUpdateArticle } from "@/actions/laboratories";
import { Laboratory, LaboratoryConcept } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { toast, useDialog, useKeypress } from "@/hooks";
import { formSchema, resolver } from "./schema";

export const ListPriceFrom = ({
  laboratory,
  labConcept,
}: {
  laboratory?: Laboratory;
  labConcept?: LaboratoryConcept;
}) => {
  const { closeDialog, openDialog } = useDialog();
  const {
    startLoading,
    stopLoading,
    loading,
    user: { plan_version },
  } = useGlobalStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_laboratory: laboratory?.id_laboratory,
      id_concept: labConcept?.id_concept || 0,
      code: labConcept?.code || "",
      amount: labConcept?.amount || 0,
      description: labConcept?.description || "",
      enabled: labConcept?.enabled || true,
      is_usd: labConcept?.is_usd || false,
      rowversion: labConcept?.rowversion || "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (["lender", "freemium"].includes(plan_version)) {
      return openDialog({
        content: <FreemiumForm />,
      });
    }

    startLoading();
    const response = await createOrUpdateArticle(values as LaboratoryConcept);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "El trabajo se ha guardado con éxito.",
        description: "Los datos se veran reflejados en la tabla.",
      });
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <SheetContent className="w-full !max-w-[500px] !text-start flex flex-col justify-between">
      <SheetHeader className="!text-start">
        <SheetTitle className="p-2 !font-semibold !text-2xl">
          {labConcept ? "Modificar trabajo" : "Nuevo trabajo"}
        </SheetTitle>
        <SheetDescription className="flex flex-col p-2">
          <span className="text-sm text-zinc-400">
            Laboratorio seleccionado
          </span>
          <span className="text-lg text-blue-700 uppercase">
            {laboratory?.name}
          </span>
        </SheetDescription>
      </SheetHeader>
      <div className="flex h-[100vh] overflow-y-auto">
        <Form {...form}>
          <form className="w-full flex flex-col gap-4 bg-white  p-2">
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Código</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Importe</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enabled"
              render={({ field }) => (
                <FormItem className="col-span-3 flex flex-row-reverse gap-2 justify-end">
                  <div className="flex flex-col items-start">
                    <FormLabel className="!mb-0 !text-xs !font-bold">
                      Trabajo habilitado
                    </FormLabel>
                    <p className="font-normal text-xs text-slate-500">
                      Habilita o deshabilita el trabajo
                    </p>
                  </div>

                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="!mt-0"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_usd"
              render={({ field }) => (
                <FormItem className="col-span-1 flex flex-row-reverse gap-2 justify-end">
                  <div className="flex flex-col items-start">
                    <FormLabel className="!mb-0 !text-xs !font-bold">
                      Importe en U$D
                    </FormLabel>
                    <p className="font-normal text-xs text-slate-500">
                      Indica si el importe de la prestación actual es en
                      dólares.
                    </p>
                  </div>

                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
      <div className="flex justify-end gap-5 border-t p-5">
        <>
          <SheetClose>
            <Button variant="destructive"> Cancelar</Button>
          </SheetClose>
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button
              className="bg-blue-600"
              onClick={form.handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          )}
        </>
      </div>
    </SheetContent>
  );
};
