import React from "react";
import { Checkbox, Combobox } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Bank } from "@/types/general";

export const Banks = ({
  form,
  banks,
  disabled,
  show = {
    disabled: false,
  },
}: {
  form: any;
  banks: Bank[];
  disabled?: boolean;
  show?: {
    disabled: boolean;
  };
}) => {
  if (!banks || !banks?.length) return;

  const ENABLED_BANKS = banks.filter((bank) => bank.enabled);

  return (
    <>
      <FormItem>
        <FormLabel className="text-base">Bancos</FormLabel>
        <FormControl>
          <Combobox
            form={form}
            data={ENABLED_BANKS}
            dataKey="id_bank"
            value="name"
            placeholder="Selecciona un banco"
            title="Banco"
            disabled={!ENABLED_BANKS.length || disabled}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
      {show.disabled && (
        <FormField
          control={form.control}
          name="disabled_banks"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 justify-start">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="leading-none flex flex-col">
                <FormLabel className="!mb-0">
                  Incluír deshabilitados/as
                </FormLabel>
                <p className="text-sm font-regular text-gray-400">
                  Se incluirán todos los bancos deshabilitados/as
                </p>
              </div>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
