"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import classNames from "classnames";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  Checkbox,
  Input,
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Label,
  Combobox,
} from "@/components/atoms";
import {
  Form,
  FormControl,
  FormField,
  DatePicker,
  FormItem,
  FormLabel,
  FormMessage,
  ProfessionalsSelect,
  ButtonLoading,
} from "@/components/molecules";
import { toast, useStateAcordeon } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { Offices, Professional } from "@/types/general";
import { enabledOffices } from "@/utils/mappers";
import {
  INVOICES_PAYMENTS,
  PAYMENT_METHOD,
  TAXES,
  TYPE_INVOICES_PAYMENTS,
} from "@/libs/patients";
import { createPayment, editPayment } from "@/actions/payments";
import { PaymentMethods } from "./payment-methods";
import { ValuesToPay } from "./values-to-pay";
import { formSchema, resolver } from "./schema";
import { SelectBudget } from "./budgets";

export const PaymentsForm = ({
  onCancel,
  offices,
  professionals,
  bankList,
  onChange,
  cardList,
}: {
  onCancel: (value: string) => void;
  professionals: Professional[];
  offices: Offices[];
  cardList: any[];
  onChange: () => void;
  compact?: boolean;
  bankList: any[];
}) => {
  const {
    general_params: { user_params },
    user,
    patient,
    patient_payments: { payment_selected },
    ref_header,
    updatePatient,
  } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const [installmentsList, setInstallmentsList] = React.useState<any>([]);
  const textField = React.useRef<HTMLInputElement | null>(null);
  const IS_ELECTRONIC_INVOICE = Boolean(
    payment_selected?.id_electronic_invoice
  );
  const ENABLED_OFFICES = enabledOffices(offices);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_payment: payment_selected?.id_payment || 0,
      id_budget: payment_selected?.id_budget || 0,
      date:
        (payment_selected?.date &&
          new TZDate(
            new Date(payment_selected?.date).toISOString(),
            TIMEZONES.AR
          )) ||
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
      observations: payment_selected?.observation || "",
      concept: payment_selected?.concept || "",
      includes_budget: payment_selected?.id_budget ? true : false,
      id_invoice:
        INVOICES_PAYMENTS.find(
          (invoice) => invoice.value === payment_selected?.receipt
        )?.id_invoice || 1,
      id_invoice_type:
        TYPE_INVOICES_PAYMENTS.find(
          (type) => type.value === payment_selected?.type
        )?.id_invoice_type || 1,
      id_payment_method:
        PAYMENT_METHOD.find(
          (method) => method.value === payment_selected?.payment_method
        )?.id_payment_method || 1,
      invoice_number: payment_selected?.number,
      change_amount_professional: false,
      is_usd: payment_selected?.is_usd || false,
      id_tax:
        TAXES.find((tax) => tax.value === payment_selected?.vat)?.id_tax || 1,
      paid: payment_selected?.credit || 0,
      to_pay: payment_selected?.debit || 0,
      id_patient: patient.id_patient,
      id_office: offices?.[0]?.id_office || null,
      id_user: user.id_user,
      id_professional:
        payment_selected?.id_professional ||
        professionals?.[0]?.id_professional,
      interest: payment_selected?.interest || 0,
      interest_percentage: payment_selected?.interest_percentage || 0,
      id_liquidation_professional:
        payment_selected?.id_liquidation_professional || null,
      amount_liquidation_professional:
        payment_selected?.professional_liquidation_amount || null,
      id_electronic_invoice: payment_selected?.id_electronic_invoice || null,
      id_card: payment_selected?.card_movements?.[0]?.id_card,
      card_number: payment_selected?.card_movements?.[0]?.card_number,
      card_cvv: payment_selected?.card_movements?.[0]?.auth_number,
      id_installment: payment_selected?.installment_count,
      with_installments: !!payment_selected?.card_movements,
      id_medical_insurance: payment_selected?.id_health_insurance,
      id_bank: payment_selected?.id_bank,
      save_register: payment_selected?.save_to_cash_register,
      bank_emitter: payment_selected?.checks?.[0]?.bank_emitter,
      paycheck_date:
        (payment_selected?.checks?.[0]?.paycheck_date &&
          new Date(payment_selected?.checks?.[0]?.paycheck_date)) ||
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
      paycheck_number: payment_selected?.checks?.[0]?.paycheck_number,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const TAX = TAXES.find((tax) => tax.id_tax === values.id_tax)?.value;
    const INVOICE = INVOICES_PAYMENTS.find(
      (invoice) => invoice.id_invoice === values.id_invoice
    )?.value;
    const INVOICE_TYPE = TYPE_INVOICES_PAYMENTS.find(
      (type) => type.id_invoice_type === values.id_invoice_type
    )?.value;
    const METHOD = PAYMENT_METHOD?.find(
      (method) => method.id_payment_method === values.id_payment_method
    )?.value;

    setLoadingButton(true);

    const PAYMEN_DATA = {
      ...values,
      iva: TAX,
      invoice: INVOICE,
      invoice_type: INVOICE_TYPE,
      payment_method: METHOD,
      ...(payment_selected && { rowversion: payment_selected.rowversion }),
    };

    const response = payment_selected?.id_payment
      ? await editPayment(PAYMEN_DATA, patient.full_name)
      : await createPayment(PAYMEN_DATA, patient.full_name);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });

      setLoadingButton(false);
      return;
    } else {
      toast({
        variant: "successful",
        title:
          payment_selected?.id_payment !== 0
            ? "Pago actualizado con exito"
            : "Pago realizado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      if (response?.account) {
        updatePatient("balance_usd", response?.account?.balance_usd);
        updatePatient("balance", response?.account?.balance);
      }
      onChange();
      onCancel("tables");
    }

    setLoadingButton(false);
  }

  return (
    <Form {...form}>
      <form
        className={classNames(
          "w-full gap-5 overflow-hidden overflow-y-auto flex flex-col",
          STATE_ACCORDION !== "open"
            ? "h-[calc(100vh-205px)]"
            : "h-[calc(100vh-268px)]"
        )}
      >
        <Card>
          <CardHeader>
            <CardTitle>
              {payment_selected ? "Modificar pago" : "Nueva pago"}
            </CardTitle>
            <CardDescription>Datos generales del pago</CardDescription>
          </CardHeader>
          <CardContent className="col-span-full grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <DatePicker name="date" form={form} label="fecha" />
            </div>
            <ProfessionalsSelect
              offices={ENABLED_OFFICES}
              professionals={professionals}
              form={form}
              classname="col-span-4"
            />
            <SelectBudget
              form={form}
              useUsd={{ params: user_params.use_usd, user: user.use_usd }}
              budgets={patient?.budgets || []}
            />
            <FormField
              control={form.control}
              name="concept"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      ref={textField}
                      disabled={IS_ELECTRONIC_INVOICE}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Combobox
              form={form}
              data={INVOICES_PAYMENTS}
              dataKey="id_invoice"
              value="text"
              showInputSearch={false}
              title="comprobante"
              className="col-span-3"
              classContent="!w-auto"
              label="Comprobante"
              disabled={IS_ELECTRONIC_INVOICE}
            />
            <Combobox
              form={form}
              data={TYPE_INVOICES_PAYMENTS}
              dataKey="id_invoice_type"
              value="text"
              placeholder="Selecciona un tipo de comprobante"
              title="tipo"
              showInputSearch={false}
              classContent="!w-44"
              className="col-span-3"
              label="Tipo"
              disabled={IS_ELECTRONIC_INVOICE}
            />
            <FormField
              control={form.control}
              name="invoice_number"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <Label>Número</Label>
                  <FormControl>
                    <Input
                      type="number"
                      disabled={IS_ELECTRONIC_INVOICE}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <PaymentMethods
              form={form}
              cardList={cardList}
              bankList={bankList}
              modAdmin={user.mod_adm}
              disabled={IS_ELECTRONIC_INVOICE}
              installments={installmentsList}
              setInstallments={setInstallmentsList}
            />
            <FormField
              control={form.control}
              name="paid"
              render={({ field }) => (
                <FormItem className="col-span-3 space-y-0 !pb-0">
                  <Label className="!mt-24 !mb-4">Importe pagado</Label>
                  <FormControl>
                    <Input
                      type="number"
                      disabled={IS_ELECTRONIC_INVOICE}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="to_pay"
              render={({ field }) => (
                <FormItem className="col-span-3 space-y-0 !pb-0">
                  <Label className="!mt-24 !mb-4">Importe adeudado</Label>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      disabled={
                        IS_ELECTRONIC_INVOICE ||
                        form.watch("id_payment_method") !== 6
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="col-span-3 flex">
              <Checkbox
                checked={form.watch("is_usd")}
                onCheckedChange={(checked) => {
                  form.setValue("is_usd", !!checked);
                }}
              />
              <div className="leading-none flex flex-col mx-2 w-56">
                <Label>Es USD</Label>
                <p className="text-sm font-regular text-gray-400">
                  Los importes se cargaran en dolares
                </p>
              </div>
            </div>
            <Combobox
              form={form}
              data={TAXES}
              dataKey="id_tax"
              value="text"
              showInputSearch={false}
              title="iva"
              className="col-span-3"
              label="% I.V.A."
              disabled={IS_ELECTRONIC_INVOICE}
            />
            <FormField
              control={form.control}
              name="observations"
              render={({ field }) => (
                <FormItem className="col-span-full">
                  <FormLabel>Observaciones</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={IS_ELECTRONIC_INVOICE}
                      {...field}
                      ref={textField}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </Card>
        {<ValuesToPay form={form} />}
      </form>
      <div className="flex pt-4 space-x-5 justify-end w-full">
        <Button
          type="button"
          className="px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
          onClick={() => onCancel("tables")}
        >
          Cancelar
        </Button>
        {loadingButton ? (
          <ButtonLoading />
        ) : (
          <Button
            onClick={form.handleSubmit(onSubmit)}
            className="px-10 h-10 hover:bg-blue-600"
            disabled={!form.watch("paid")}
          >
            Guardar pago
          </Button>
        )}
      </div>
    </Form>
  );
};
