import * as React from "react";
import { z } from "zod";
import { HiDotsVertical } from "react-icons/hi";
import { LuCheck, LuPen, LuTrash2 } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { formSchema } from "@/components/organisms/forms/patients/budgets/schema";

export const Columns = ({
  action,
}: {
  action: (
    row: z.infer<typeof formSchema>["selected_benefit"],
    key: string
  ) => void;
}) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { quantity, services_performed } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, "edit")}
                >
                  <LuPen className="mr-2 size-4" />
                  <span>Modificar</span>
                </DropdownMenuItem>
                {row.original.id_budget !== 0 && (
                  <DropdownMenuItem
                    disabled={quantity <= services_performed}
                    className="hover:cursor-pointer text-green-600"
                    onSelect={() => action(row.original, "mark_done")}
                  >
                    <LuCheck className="mr-2 size-4" />
                    <span>Marcar como realizada</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, "delete")}
                >
                  <LuTrash2 className="mr-2 size-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Descripción",
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-base leading-6 text-slate-600">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "tooth_section",
      header: "Diente/Sector",
      cell: ({ row }: { row: any }) => {
        const { tooth_section } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {tooth_section}
          </p>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Cantidad",
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: "services_performed",
      header: "Realizadas",
      cell: ({ row }: { row: any }) => {
        const { services_performed, quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6">
            {services_performed || 0} / {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Importe total",
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-green-500">
            {`$ ${total_amount}`}
          </p>
        );
      },
    },
  ];
};
