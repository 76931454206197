import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { Loading } from "@/components/organisms/reports/loading";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({ action, loading }) => {
  const {
    patient,
    general_params: { translations, user_params },
    permissions,
    user,
  } = useGlobalStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };

  return (
    <div className="w-full col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : (
        <Data
          columns={Columns({
            translations,
            action,
            config: { permissions, USE_USD, is_supervisor: user.is_supervisor },
          })}
          data={patient.alls?.reverse() || []}
          config={USE_USD}
        />
      )}
    </div>
  );
};
