import React from "react";

type Step = {
  id: number;
  name: string;
  status: "complete" | "current" | "upcoming";
  position: string;
};

type UseStepBarReturn = {
  steps: Step[];
  currentPosition: number;
  lastPosition: number;
  setStep: (position: number) => void;
  resetSteps: () => void;
};

export const useStepBar = ({
  initialSteps,
  completeSteps,
}: {
  initialSteps: Step[];
  completeSteps?: boolean;
}): UseStepBarReturn => {
  const [stateBar, setStateBar] = React.useState({
    currentPosition: 0,
    lastPosition: 0,
    steps: initialSteps,
  });

  const setStep = React.useCallback((position: number) => {
    setStateBar((prevState: any) => {
      const lastPosition = prevState.currentPosition;

      return {
        ...prevState,
        lastPosition,
        currentPosition: position,
        steps: prevState.steps.map((step: any) => {
          if (step.id === position + 1) {
            return {
              ...step,
              status: "current",
            };
          }

          if (step.id === position || step.id === lastPosition + 1) {
            return {
              ...step,
              status: "complete",
            };
          }

          return step;
        }),
      };
    });
  }, []);

  const resetSteps = React.useCallback(() => {
    setStateBar({
      currentPosition: 0,
      lastPosition: 0,
      steps: initialSteps.map((step, index) => ({
        ...step,
        status: index === 0 ? "current" : "upcoming",
      })),
    });
  }, [initialSteps]);

  const completeStepBar = React.useCallback(() => {
    setStateBar((prevState) => {
      const lastPosition = prevState.steps.length - 1;
      return {
        ...prevState,
        currentPosition: lastPosition,
        lastPosition: prevState.currentPosition,
        steps: prevState.steps.map((step) => ({
          ...step,
          status: "complete",
        })),
      };
    });
  }, []);

  React.useEffect(() => {
    if (completeSteps) {
      completeStepBar();
    }
  }, []);
  return {
    steps: stateBar.steps,
    currentPosition: stateBar.currentPosition,
    lastPosition: stateBar.lastPosition,
    setStep,
    resetSteps,
  };
};
