"use client";

import React from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { LuWatch } from "react-icons/lu";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Offices, Professional, Specialty } from "@/types/general";
import { TimesTableForm, DeleteTime } from "@/components/organisms/forms";
import { DAYS } from "@/libs/config";
import { Time } from "@/types/timetable";

type RowDataT = {
  original: any;
};

const Dropdown = ({
  time,
  professional,
  specialties,
  offices,
}: {
  time: Time;
  professional?: Professional;
  specialties: Specialty[];
  offices: Offices[];
}) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              openDialog({
                content: (
                  <TimesTableForm
                    time={time}
                    professional={professional}
                    specialties={specialties}
                    offices={offices}
                  />
                ),
              });
            }}
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteTime time={time} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export const columns = ({
  config,
}: {
  config: {
    offices: Offices[];
    professional?: Professional;
    specialties: Specialty[];
  };
}) => {
  const { offices, specialties, professional } = config;
  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <Dropdown
          time={row.original}
          professional={professional}
          specialties={specialties}
          offices={offices}
        />
      ),
    },
    {
      accessorKey: "day",
      header: "Día",
      cell: ({ row }: { row: any }) => {
        const { day } = row.original;
        const DAY = DAYS.find((d: any) => d.id_day === day);

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {DAY?.value || "Día no encontrado"}
          </p>
        );
      },
    },
    {
      accessorKey: "from",
      header: "Desde",
      cell: ({ row }: { row: any }) => {
        const { from } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {from}
          </p>
        );
      },
    },
    {
      accessorKey: "to",
      header: "Hasta",
      cell: ({ row }: { row: any }) => {
        const { to } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {to}
          </p>
        );
      },
    },
    {
      accessorKey: "interval",
      header: "Intervalo",
      cell: ({ row }: { row: any }) => {
        const { interval } = row.original;

        return (
          <div className="flex rounded-full w-fit justify-center gap-x-2 px-4 py-1 bg-amber-50 ring-1 ring-inset ring-amber-600/10">
            <LuWatch className="size-4 text-amber-500" />
            <p className="text-sm font-semibold text-amber-500">
              {interval} min
            </p>
          </div>
        );
      },
    },
    {
      accessorKey: "date_from",
      header: "Fecha desde",
      cell: ({ row }: { row: any }) => {
        const { date_from } = row.original;
        const DATE_FORMATTED = date_from && format(date_from, "dd-MM-yyyy");

        return (
          <p
            className={classNames(
              `my-1 text-sm leading-6 ${
                date_from ? "text-slate-500" : "!text-blue-600"
              } `
            )}
          >
            {DATE_FORMATTED || "Fecha abierta"}
          </p>
        );
      },
    },
    {
      accessorKey: "date_to",
      header: "Fecha hasta",
      cell: ({ row }: { row: any }) => {
        const { date_to } = row.original;
        const DATE_FORMATTED = date_to && format(date_to, "dd-MM-yyyy");

        return (
          <p
            className={classNames(
              `my-1 text-sm leading-6 ${
                date_to ? "text-slate-500" : "!text-blue-600"
              } `
            )}
          >
            {DATE_FORMATTED || "Fecha abierta"}
          </p>
        );
      },
    },
  ];

  if (specialties) {
    columns.push({
      accessorKey: "id_specialty",
      header: "Especialidad",
      cell: ({ row }: { row: any }) => {
        const { id_specialty } = row.original;
        const specialty = specialties?.find(
          (item: any) => item.id_specialty === id_specialty
        );

        return (
          <p className="text-sm leading-6 text-slate-500 my-1">
            {specialty?.description || "TODAS"}
          </p>
        );
      },
    });
  }

  if (offices?.length > 0) {
    columns.push({
      accessorKey: "id_office",
      header: "Sucursal",
      cell: ({ row }: { row: any }) => {
        const { id_office } = row.original;
        const office = offices?.find(
          (item: any) => item.id_office === id_office
        );

        return (
          <p className="text-sm leading-6 text-slate-500 my-1">
            {office?.name || ""}
          </p>
        );
      },
    });
  }

  return columns;
};
