import React from "react";
import { Skeleton } from "@/components/atoms";

export const columns = [
  {
    accessorKey: "action",
    header: " ",
    cell: () => <Skeleton className="h-5 w-[50px] rounded" />,
  },
  {
    accessorKey: "time",
    header: "Hora",
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "presentism",
    header: "Presentismo",
    cell: () => <Skeleton className="h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "patient",
    header: "Paciente",
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "observation",
    header: "Observación",
    cell: () => <Skeleton className="h-5 w-[200px] rounded" />,
  },
  {
    accessorKey: "mod_by",
    header: "Dado/Modificado por",
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
];
