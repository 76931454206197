"use client";

import React from "react";
import { z } from "zod";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { LuChevronLeft } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  buttonVariants,
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  CardFooter,
  Combobox,
  Checkbox,
  Input,
} from "@/components/atoms";
import { toast, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
  DatePicker,
} from "@/components/molecules";
import { Offices, Banks } from "@/components/molecules/params";
import { useGlobalStore } from "@/providers/global-provider";
import { Offices as OfficesProps, Bank } from "@/types/general";
import { Income } from "@/types/administration";
import {
  getIncome,
  setIncome,
  getTransactions,
  getDailycashflow,
} from "@/actions/administration";
import {
  formSchema,
  resolver,
} from "@/components/organisms/forms/administration/new-income";
import {
  VOUCHER_DROPDOWN_INVOICE,
  VOUCHER_TYPE_DROPDOWN,
  PAYMENT_METHOD_DROPDOWN,
} from "@/libs/administration";

export function NewIncomeForm({
  banks,
  offices,
  incomeId,
}: {
  banks: Bank[];
  offices: OfficesProps[];
  incomeId?: string;
}) {
  const {
    loading,
    startLoading,
    stopLoading,
    setDataAdministration,
    administration: {
      transactions,
      dailycashflow: { data: dailycashflowData },
    },
    user: { id_user },
  } = useGlobalStore();
  const ENABLED_BANKS = banks.filter((bank) => bank.enabled) || [];
  const ENABLED_OFFICES = offices.filter((office) => office.enabled);
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_income: 0,
      id_office: ENABLED_OFFICES?.[0]?.id_office ?? null,
      id_bank: ENABLED_BANKS.length > 0 ? ENABLED_BANKS[0].id_bank : null,
      id_user: id_user,
      date: new TZDate(new Date().toISOString(), TIMEZONES.AR),
      voucher: "Factura",
      voucher_type: "A",
      voucher_number: "",
      payment_method: "Efectivo",
      details: "",
      customer: "",
      tax_number: "",
      concept: "",
      net_taxed: 0,
      iva_percentage_1: 0,
      iva_percentage_2: 0,
      iva_21: 0,
      iva_10: 0,
      exempt: 0,
      others: 0,
      total: 0,
      disabled: false,
      is_usd: false,
    },
  });
  const IS_TRANSFERENCE = form.watch("payment_method") === "Transf.";

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setIncome(values as z.infer<typeof formSchema>);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const data = await getTransactions(transactions.params);

      setDataAdministration({
        data,
        params: transactions.params,
        section: "transactions",
      });

      const response = await getDailycashflow();

      if ("error" in response) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response.error,
        });
      } else {
        setDataAdministration({
          data: {
            ...dailycashflowData,
            incomes: response.incomes,
            totals: response.totals,
          },
          section: "dailycashflow",
        });
      }

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El egreso se guardó correctamente.",
      });
      await router.push("/dashboard/administration/transactions/incomes");
    }

    stopLoading();
  };

  React.useEffect(() => {
    async function getData() {
      try {
        startLoading();
        const income: Income = await getIncome(incomeId as string);

        form.reset(income as z.infer<typeof formSchema>);
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Hubo un error al obtener los datos",
          description: error.message,
        });
      } finally {
        stopLoading();
      }
    }

    if (!incomeId) return;

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeId]);

  React.useEffect(() => {
    const subscription = form.watch((values) => {
      const { net_taxed, exempt, others, iva_21, iva_10 } = values;
      const total = Math.round(
        ((Number(net_taxed) +
          Number(exempt) +
          Number(others) +
          Number(iva_21) +
          Number(iva_10) +
          Number.EPSILON) *
          100) /
          100
      );

      if (form.getValues("total") !== total) {
        form.setValue("total", total);
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  React.useEffect(() => {
    const subscription = form.watch(
      ({ iva_percentage_1, iva_percentage_2, net_taxed }) => {
        const iva_21 = Math.round(
          ((Number(net_taxed) * Number(iva_percentage_1) * 0.01 +
            Number.EPSILON) *
            100) /
            100
        );

        const iva_10 = Math.round(
          ((Number(net_taxed) * Number(iva_percentage_2) * 0.01 +
            Number.EPSILON) *
            100) /
            100
        );

        if (form.getValues("iva_21") !== iva_21 && iva_percentage_1) {
          form.setValue("iva_21", iva_21);
        }

        if (form.getValues("iva_10") !== iva_10 && iva_percentage_2) {
          form.setValue("iva_10", iva_10);
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [form]);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-x-2">
          <Link
            href="/dashboard/administration/transactions/incomes"
            className={classNames(
              buttonVariants({ variant: "outline", size: "icon" })
            )}
          >
            <LuChevronLeft className="size-4" />
            <span className="sr-only">Back</span>
          </Link>
          {incomeId ? "Modificar ingreso" : "Nuevo ingreso"}
        </CardTitle>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="gap-4 grid grid-cols-12">
            <div className="col-span-3 self-end">
              <DatePicker label="Seleccionar fecha" name="date" form={form} />
            </div>
            <Offices
              form={form}
              offices={offices}
              show={{
                disabled: false,
                allOffices: false,
              }}
            />
            <FormField
              control={form.control}
              name="customer"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Cliente</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tax_number"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>C.U.I.T.</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="col-span-2 col-start-1">
              <FormLabel>Comprobante</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_DROPDOWN_INVOICE}
                  dataKey="voucher"
                  value="text"
                  placeholder="Elige un comprobante"
                  title="Comprobante"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem className="col-span-1">
              <FormLabel>Tipo</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_TYPE_DROPDOWN}
                  dataKey="voucher_type"
                  value="text"
                  placeholder="Elige un tipo de comprobante"
                  title="Tipo"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="voucher_number"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Número</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="col-span-3">
              <FormLabel>Forma de pago</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={PAYMENT_METHOD_DROPDOWN}
                  dataKey="payment_method"
                  value="text"
                  placeholder="Elige forma de pago"
                  title="Forma de pago"
                  disabled={form.getValues("id_income") !== 0}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <div className="col-span-3 self-end">
              <Banks
                form={form}
                banks={banks}
                disabled={!IS_TRANSFERENCE || form.getValues("id_income") !== 0}
              />
            </div>
            <FormField
              control={form.control}
              name="concept"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="details"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Detalle</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="net_taxed"
              render={({ field }) => (
                <FormItem className="col-span-3 col-start-1">
                  <FormLabel>Neto gravado</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iva_21"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Impo. IVA 1</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      disabled={form.getValues("id_income") !== 0}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iva_10"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Impo. IVA 2</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      disabled={form.getValues("id_income") !== 0}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_usd"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-3 self-start">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                      disabled={form.getValues("id_income") !== 0}
                    />
                  </FormControl>
                  <div className="leading-none mt-0 flex flex-col">
                    <FormLabel>Es USD</FormLabel>
                    <FormDescription className="text-slate-500">
                      Los importes se cargaran en dolares.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="exempt"
              render={({ field }) => (
                <FormItem className="col-span-3 col-start-1">
                  <FormLabel>Exentas</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="col-span-3">
              <FormLabel>% IVA 1</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={[
                    {
                      iva_percentage_1: 0,
                      value: "0",
                    },
                    {
                      iva_percentage_1: 10,
                      value: "10",
                    },
                    {
                      iva_percentage_1: 21,
                      value: "21",
                    },
                  ]}
                  dataKey="iva_percentage_1"
                  value="value"
                  placeholder="% IVA 1"
                  title="% IVA 1"
                  disabled={form.getValues("id_income") !== 0}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem className="col-span-3">
              <FormLabel>% IVA 2</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={[
                    {
                      iva_percentage_2: 0,
                      value: "0",
                    },
                    {
                      iva_percentage_2: 10,
                      value: "10",
                    },
                    {
                      iva_percentage_2: 21,
                      value: "21",
                    },
                  ]}
                  dataKey="iva_percentage_2"
                  value="value"
                  placeholder="% IVA 2"
                  title="% IVA 2"
                  disabled={form.getValues("id_income") !== 0}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="others"
              render={({ field }) => (
                <FormItem className="col-span-3 col-start-1">
                  <FormLabel>Otros</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Total</FormLabel>
                  <FormControl>
                    <Input type="number" disabled {...field} />
                  </FormControl>
                  <FormDescription className="text-slate-500">
                    El total de la factura se calculará automáticamente.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex flex-row justify-center items-center lg:justify-end gap-x-2">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                router.push("/dashboard/administration/transactions/incomes");
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button type="submit">Guardar</Button>
            )}
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
