import { Badge } from "@/components/atoms";
import { ArrowUpDown } from "lucide-react";

type RowDataT = {
  full_name: string;
  id_patient: number;
  mh_number: string;
  medical_insurance: string;
  id_med_ins: number;
  code_plan: string;
  affiliate_number: string;
  document_number: string;
  address: string;
  date_of_birth: string;
  original: any;
};
type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => "asc" | "desc" | undefined;
};
type ColumnsT = {
  id?: string;
  accessorFn?: (row: any) => void;
  accessorKey?: string;
  header: string | (({ column }: { column: ColumnT }) => JSX.Element);
  cell: ({ row }: { row: any }) => any;
};

export const Columns = ({ floating }: { floating: boolean }) => {
  const COLUMNS_ROW: ColumnsT[] = [
    {
      accessorKey: "full_name",
      header: "Nombre y Apellido",
      cell: ({ row }: { row: RowDataT }) => {
        const { full_name } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {full_name}
          </p>
        );
      },
    },
    {
      accessorKey: "clinical_history_number",
      header: "Historia clínica",
      cell: ({ row }: { row: RowDataT }) => {
        const { clinical_history_number } = row.original;

        return <Badge>{clinical_history_number}</Badge>;
      },
    },
    {
      id: "medical_insurance",
      accessorFn: (row: any) =>
        row.plan_navigation?.medical_insurance_navigation?.medical_insurance,
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600"
          >
            Obra social
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: RowDataT }) => {
        const {
          plan_navigation: {
            medical_insurance_navigation: { medical_insurance, id_med_ins },
          },
        } = row.original;

        return (
          medical_insurance && (
            <p
              id={id_med_ins}
              className="font-bold text-sm leading-6 text-gray-500 my-1"
            >
              {medical_insurance}
            </p>
          )
        );
      },
    },
    {
      id: "plan_name",
      accessorFn: (row: any) => row.plan_navigation?.name,
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600"
          >
            Plan
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: RowDataT }) => {
        const {
          plan_navigation: { name },
        } = row.original;

        return (
          name && (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {name}
            </p>
          )
        );
      },
    },
    {
      accessorKey: "date_of_birth",
      header: "Fecha de nacimiento",
      cell: ({ row }: { row: RowDataT }) => {
        const { date_of_birth } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {new Date(date_of_birth).toLocaleDateString()}
          </p>
        );
      },
    },
  ];

  if (!floating) {
    COLUMNS_ROW.push(
      {
        accessorKey: "affiliate_number",
        header: "Nro.Afiliado",
        cell: ({ row }: { row: RowDataT }) => {
          const { affiliate_number } = row.original;

          return (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {affiliate_number}
            </p>
          );
        },
      },
      {
        accessorKey: "document_number",
        header: "DNI",
        cell: ({ row }: { row: RowDataT }) => {
          const { document_number } = row.original;

          return (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {document_number}
            </p>
          );
        },
      },
      {
        accessorKey: "address",
        header: "Domicilio",
        cell: ({ row }: { row: RowDataT }) => {
          const { address } = row.original;

          return (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {address}
            </p>
          );
        },
      }
    );
  }

  return COLUMNS_ROW;
};
