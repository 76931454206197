import { Laboratory } from "@/types/general";

type State = {
  order_selected: Laboratory | null;
};

type Actions = {
  setOrderSelected: (order: Laboratory | null) => void;
};

type PatientOrderStore = State & Actions;

export const INIT_STATE: State = {
  order_selected: null,
};

export const OrdersSlice = (
  set: (partial: Partial<PatientOrderStore>) => void,
  get: () => PatientOrderStore
): PatientOrderStore => ({
  ...INIT_STATE,
  setOrderSelected: (order: Laboratory | null) => {
    const state = get();

    set({
      ...state,
      order_selected: order,
    });
  },
});
