import * as React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { Row } from "@tanstack/react-table";
import { LuCircleCheck } from "react-icons/lu";
import { FcCancel } from "react-icons/fc";
import { Badge } from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { Translations } from "@/types/general";
import { Permissions } from "@/types/permissions";
import { Alls } from "@/types/patients";

export const Columns = ({
  translations,
  config,
}: {
  translations: Translations;
  config: {
    permissions: Permissions;
    USE_USD: { user: boolean; params: boolean };
    is_supervisor: boolean;
  };
}) => {
  const { permissions, USE_USD, is_supervisor } = config;

  const rows = () => {
    const rowsArray: any = [
      {
        accessorKey: "date",
        header: "Fecha",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { date } = row.original;

          return (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {format(date, "dd/MM/yyyy")}
            </p>
          );
        },
      },
      {
        accessorKey: "professional",
        header: "Profesional",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { id_professional_navigation } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1">
              {id_professional_navigation?.name}
            </p>
          );
        },
      },
      {
        accessorKey: "benefit_code",
        header: "Código prestación",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { id_benefit_navigation } = row.original;

          return (
            <div>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Cod. Interno:{" "}
                <span className="font-bold text-sm text-slate-800">
                  {id_benefit_navigation?.benefit_code}
                </span>
              </p>
              <p className="font-bold text-sm leading-6 text-gray-500">
                {translations?.cod_mi + " "}
                <span className="font-bold text-sm text-slate-800">
                  {id_benefit_navigation?.code_benefit_med_ins_liq}
                </span>
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Descripción",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { id_benefit_navigation, color } = row.original;
          const STATUS: { [key: string]: { [key: string]: string } } = {
            ROJO: {
              text: "Prestación anterior",
              style: "bg-red-50 text-red-600 border-red-600/10",
            },
            AZUL: {
              text: "Prestación a realizar",
              style: "bg-blue-50 text-blue-600 border-blue-600/10",
            },
            VERDE: {
              text: "Prestación realizada",
              style: "bg-green-50 text-green-600 border-green-600/10",
            },
          };
          const STATE = STATUS[color];

          return (
            <>
              {color && (
                <Badge className={classNames("w-auto mt-2 mb-1", STATE.style)}>
                  {STATE.text}
                </Badge>
              )}
              <p className="font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
                {id_benefit_navigation?.description}
              </p>
            </>
          );
        },
      },
      {
        accessorKey: "medical_insurance",
        header: translations?.medical_insurances,
        cell: ({ row }: { row: any }) => {
          const { plan_navigation } = row.original;

          return (
            <div>
              <p className="font-bold text-base leading-6 text-slate-800 my-1">
                {
                  plan_navigation?.medical_insurance_navigation
                    .medical_insurance
                }
              </p>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Plan: {plan_navigation?.name}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "thoot",
        header: "Diente",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { tooth_sector, face } = row.original;

          return (
            <div>
              {tooth_sector && (
                <p className="font-bold text-sm leading-6 text-gray-500">
                  Pieza:{" "}
                  <span className="font-bold text-sm text-slate-800">
                    {tooth_sector}
                  </span>
                </p>
              )}
              {face && (
                <p className="font-bold text-sm leading-6 text-gray-500">
                  Cara:{" "}
                  <span className="font-bold text-sm text-slate-800">
                    {face}
                  </span>
                </p>
              )}
            </div>
          );
        },
      },
    ];
    if (permissions.hasOwnProperty("Ver Importes Prest.") || is_supervisor) {
      rowsArray.splice(8, 0, {
        accessorKey: "amouht_medical_insurance",
        header: `Importe ${translations?.medical_insurance_abbreviation}`,
        cell: ({ row }: { row: Row<Alls> }) => {
          const { amount_os } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
              {numberToCurrency(amount_os)}
            </p>
          );
        },
      });
    }
    if (permissions.hasOwnProperty("Ver Importes Prest.") || is_supervisor) {
      rowsArray.splice(9, 0, {
        accessorKey: "amount_patient",
        header: "Importe paciente",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { amount_patient } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
              {numberToCurrency(amount_patient)}
            </p>
          );
        },
      });
    }
    if (USE_USD.params) {
      rowsArray.splice(7, 0, {
        accessorKey: "is_usd",
        header: "En USD",
        cell: ({ row }: { row: Row<Alls> }) => {
          const { is_usd } = row.original;

          return is_usd ? (
            <LuCircleCheck className="size-5 mx-auto text-green-500" />
          ) : (
            <FcCancel className="size-6 mx-auto" />
          );
        },
        filterFn: (row: Row<Alls>, id: string, value: any) =>
          value.includes(row.getValue(id)),
      });
    }
    return rowsArray;
  };
  return rows();
};
