"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  Input,
  Separator,
} from "@/components/atoms";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { userParamsToDB } from "@/utils/tranformers/params";
import { updateVariousData } from "@/actions/configs";
import { toast } from "@/hooks";
import { formSchema, resolver } from "./schema";

export const Various = () => {
  const { general_params, startLoading, stopLoading, updateParams } =
    useGlobalStore();
  const { user_params } = general_params;
  const inputNumberRef = React.useRef<HTMLInputElement | null>(null);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      clinic_history_from: user_params?.clinic_history_from || 0,
      schedule_aca: user_params?.schedule_aca || false,
      schedule_alert: user_params?.schedule_alert || false,
      lender_code_bilog: user_params?.lender_code_bilog || "",
    },
  });

  const onsubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const params = userParamsToDB(user_params);
    const response = await updateVariousData({
      ...params,
      ...values,
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Cambios realizados correctamente",
        description: "Los datos han sido actualizados.",
      });
      updateParams("general_params", {
        ...general_params,
        user_params: { ...response },
      });
    }

    stopLoading();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onsubmit)}
        className="flex flex-col items-end w-full"
      >
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Numeración de Historias Clínicas en Pacientes</CardTitle>
            <CardDescription>
              Las historias clínicas serán numeradas automáticamente por el
              sistema a partir:
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col space-y-6 w-full">
            <div className="grid grid-cols-3">
              <FormField
                control={form.control}
                name="clinic_history_from"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Número</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Número (0 = Automatico)"
                        ref={inputNumberRef}
                        autoFocus
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <h2 className="font-semibold">Agenda de Turnos</h2>
              <h3 className="text-slate-500 text-sm">
                Configuraciones sobre la agenda de turnos.
              </h3>
            </div>
            <div className="grid grid-cols-2">
              <FormField
                control={form.control}
                name="schedule_aca"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        <span className="font-semibold">
                          Al marcar un turno como ACA, moverlo al próximo minuto
                          disponible.
                        </span>
                      </FormLabel>
                      <FormDescription className="text-xs">
                        Cuando se marque un turno como ausente con aviso, se
                        replicará con un minuto adelantado de diferencia.
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="schedule_alert"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        <span className="font-semibold">
                          Al dar un nuevo turno mostrar la alerta del paciente.
                        </span>
                      </FormLabel>
                      <FormDescription className="text-xs">
                        Si el paciente tiene una alerta, se mostrará cuando se
                        agende un nuevo turno.
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className="my-2" />
            <h2 className="font-semibold">Auditor Bilog</h2>
            <div className="grid grid-cols-3">
              <FormField
                control={form.control}
                name="lender_code_bilog"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Código Prestador</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="BIL1234" max={15} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
        </Card>
        <Button className="mt-4" type="submit">
          Guardar
        </Button>
      </form>
    </Form>
  );
};
