import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  date: z.date(),
  id_office: z.number().nullable(),
  id_professional: z.number().nullable(),
  filter_professional_by_day: z.boolean(),
  id_layout: z.number(),
  day_of_week: z.number(),
  id_specialty: z.number().nullable(),
  option: z.string(),
  id_prof_finally: z.number().nullable(),
};
//TODO quedan comentados hasta que terminar toda la agenda.
export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
