import classNames from "classnames";
import { Checkbox } from "@/components/atoms";
import { FormLabel } from "@/components/molecules/form";

export const CheckboxForm = ({
  classname,
  checked,
  onCheckedChange,
  label,
  subLabel,
  disabled,
}: {
  checked: boolean;
  classname?: string;
  onCheckedChange: (value: boolean) => void;
  label: string;
  subLabel?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={classNames("flex space-x-2", classname)}>
      <Checkbox
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
      <div className="leading-none flex flex-col">
        <FormLabel>{label}</FormLabel>
        <p className="text-sm font-regular text-gray-400">{subLabel}</p>
      </div>
    </div>
  );
};
