export { Table as ReportsTable } from "./reports";
export { Table as ScheduleTable } from "./schedule";
export { Table as SearchPatientTable } from "./search-patient";
export { Table as AppointmentsTable } from "./patient/appointments";
export { Table as PatientBenefitsTable } from "./patient/benefits";
export { Table as BenefitsFilter } from "./patient/benefits-filter";
export { Table as MedicalHistoryTable } from "./patient/medical-history";
export { Table as MembershipHistory } from "./patient/membership";
export { Table as PatientLaboratoryTable } from "./patient/laboratory";
export { Table as BudgetsTable } from "./patient/budgets";
export { Table as PaymentsTable } from "./patient/payments";
export { Table as PrescriptionsTable } from "./patient/prescriptions";
export { Table as MedicinesTable } from "./medicines";
export { Table as DiagnosisTable } from "./diagnosis";
export { Table as MedicinesPatientTable } from "./medicines-patient";
export { Table as ConceptsTable } from "./patient/concepts";
export { Table as BenefitsTable } from "./patient/benefits-budgets";
export { Table as BudgetServicesTable } from "./budget-services";
export { Table as PaymentBudgetTable } from "./patient/payments-budget";
export {
  Dailycashflow,
  Transactions,
  Banks,
  Cards,
  Suppliers,
} from "./administration";
export { Table as RemindersTable } from "./reminders";
export { Table as RemindersTabs } from "./reminders";
export { Table as RemindersListTable } from "./reminders-list";
export {
  MedicalInsurances,
  Professionals,
  ProfessionalTimetable,
  ProfessionalAbscences,
  LaboratoriesTable,
  PriceListTable,
  ListCopyTable,
  SpecialtyTable,
  HolydayTable,
  OfficesTable,
  CopyMedicalInsurancesBenefits,
  UsersTable,
  Suppliers as SuppliersConfig,
} from "./configuration";
export { Table as MedicalInsurancesCopy } from "./medical-insurances-copy";
