import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms";
import { useSetHeight } from "@/hooks";

export function Data<TData, TValue>({ columns, data }: any) {
  const [globalFilter, setGlobalFilters] = React.useState("");
  let HEIGHT = useSetHeight(350);
  const getHeigth = () => {
    const calculatedHeight = data.length * 66 + 48;
    if (HEIGHT > calculatedHeight) {
      HEIGHT = calculatedHeight;
    }
    return `${HEIGHT}px`;
  };
  const TABLE = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilters,
    state: {
      globalFilter,
    },
  });

  return (
    <Table
      className="rounded-md border h-full max-h-[600px] w-full bg-white shadow"
      height={getHeigth()}
    >
      <TableHeader>
        {TABLE.getHeaderGroups().map((headerGroup) => (
          <TableRow
            key={headerGroup.id}
            className="shadow sticky top-0 bg-white z-10"
          >
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id} className="">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {TABLE.getRowModel().rows?.length ? (
          TABLE.getRowModel().rows.map((row: any) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
              className="hover:cursor-pointer"
            >
              {row.getVisibleCells().map((cell: any) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              sin resultados
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
