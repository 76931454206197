import {
  LuHouse as Home,
  LuCalendar as Calendar,
  LuCog as Cog,
  LuUsers as Users,
  LuChartBarBig as BarChartBig,
  LuFileText as FileText,
} from "react-icons/lu";
import {
  HiOutlineBanknotes as Banknotes,
  HiOutlineSparkles as Sparkies,
} from "react-icons/hi2";
import { Translations } from "@/types/general";

export const SIDEBAR_STATISTICS = {
  navMain: [
    {
      title: "Finanzas",
      url: "finances",
    },
    {
      title: "Panel de control",
      url: "control",
    },
    {
      title: "Prestaciones realizadas",
      url: "benefits-made",
    },
    {
      title: "Presupuestos emitidos",
      url: "budgets",
    },
  ],
};

export const SIDEBAR_REPORTS = {
  navMain: [
    {
      title: "Auditor Bilog",
      url: "/dashboard/reports/auditor",
    },
  ],
};

export const SIDEBAR_MY_ACCOUNT = {
  navMain: [
    {
      title: "Datos generales",
      url: "/dashboard/my-account/general",
    },
    {
      title: "Suscripción",
      url: "/dashboard/my-account/subscription",
    },
    {
      title: "Productos",
      url: "/dashboard/my-account/products",
    },
  ],
};

export const SIDEBAR_ADMINISTRATION = {
  navMain: [
    {
      title: "Caja diaria",
      url: "/dashboard/administration/dailycashflow/patient_payments",
      items: [
        {
          title: "Pagos pacientes",
          url: "/dashboard/administration/dailycashflow/patient_payments",
          isActive: true,
        },
        {
          title: "Otros ingresos",
          url: "/dashboard/administration/dailycashflow/incomes",
          isActive: false,
        },
        {
          title: "Egresos varios",
          url: "/dashboard/administration/dailycashflow/expenses",
          isActive: false,
        },
      ],
    },
    {
      title: "Movimientos",
      url: "/dashboard/administration/transactions/patient_payments",
      items: [
        {
          title: "Pagos de pacientes",
          url: "/dashboard/administration/transactions/patient_payments",
          isActive: false,
        },
        {
          title: "Otros ingresos",
          url: "/dashboard/administration/transactions/incomes",
          isActive: false,
        },
        {
          title: "Egresos varios",
          url: "/dashboard/administration/transactions/expenses",
          isActive: false,
        },
      ],
    },
    {
      title: "Bancos",
      url: "/dashboard/administration/banks/movements",
      items: [
        {
          title: "Movimientos",
          url: "/dashboard/administration/banks/movements",
          isActive: false,
        },
        {
          title: "Bancos y cuentas",
          url: "/dashboard/administration/banks/accounts",
          isActive: false,
        },
      ],
    },
    {
      title: "Proveedores",
      url: "/dashboard/administration/suppliers",
      items: null,
    },
    {
      title: "Tarjetas",
      url: "/dashboard/administration/cards",
      items: null,
    },
  ],
};

export const getSidebarPatients = (region: string) => {
  const navMain = [
    {
      title: "Datos personales",
      url: "personal-data",
    },
    {
      title: "Prestaciones",
      url: "benefits",
    },
    {
      title: "Odontograma",
      url: "odontogram",
    },
    {
      title: "Historia clínica",
      url: "medical-history",
    },
    {
      title: "Pagos",
      url: "payments",
    },
    {
      title: "Presupuestos",
      url: "budget",
    },
    {
      title: "Laboratorios",
      url: "laboratory",
    },
    {
      title: "Turnos",
      url: "appointments",
    },
    {
      title: "Galería",
      url: "gallery",
    },
  ];

  if (region?.toLowerCase() === "arg") {
    navMain.splice(4, 0, {
      title: "Recetas",
      url: "prescriptions",
    });
  }

  return navMain;
};

export const getFirstAvailableUrl = (
  permissions: Record<string, boolean>,
  type: "tables" | "system"
) => {
  const BASE_URL =
    type === "tables"
      ? "/dashboard/configuration/tables/"
      : "/dashboard/configuration/sistem/";
  const URLS =
    type === "tables"
      ? [
          { permission: "Tabla de Profesionales", url: "professionals" },
          { permission: "Tabla de Obras Sociales", url: "medical-insurances" },
          { permission: "Tabla de Laboratorios", url: "laboratories" },
          { permission: "Tabla de Especialidades", url: "specialties" },
          { permission: "Ver Dias no Laborables", url: "holydays" },
          { permission: "Tabla de Sucursales", url: "offices" },
        ]
      : [
          { permission: "Parametros del Sistema", url: "general-data" },
          { permission: "Usuarios del Sistema", url: "users" },
          { permission: "Datos de whatsapp", url: "whatsapp-data" },
          { permission: "Datos de sms", url: "sms-data" },
        ];
  const AVAILABLE_URL = URLS.find(
    ({ permission }) => permissions[permission]
  )?.url;

  return AVAILABLE_URL
    ? `${BASE_URL}${AVAILABLE_URL}`
    : type === "tables"
    ? null
    : `${BASE_URL}whatsapp-data`;
};

export const sideBarConfig = ({
  permissions,
  is_supervisor,
  translations,
}: {
  permissions: { [key: string]: boolean };
  is_supervisor: boolean;
  translations: Translations;
}) => {
  const NAV_MAIN = [
    (is_supervisor || permissions["Tablas"]) && {
      title: "Tablas",
      url: getFirstAvailableUrl(permissions, "tables"),
      items: [
        (is_supervisor || permissions["Tabla de Profesionales"]) && {
          title: "Profesionales",
          url: "/dashboard/configuration/tables/professionals",
          isActive: true,
        },
        (is_supervisor || permissions["Tabla de Obras Sociales"]) && {
          title: translations?.medical_insurances,
          url: "/dashboard/configuration/tables/medical-insurances",
          isActive: false,
        },
        (is_supervisor || permissions["Tabla de Laboratorios"]) && {
          title: "Laboratorios",
          url: "/dashboard/configuration/tables/laboratories",
          isActive: false,
        },
        (is_supervisor || permissions["Tabla de Especialidades"]) && {
          title: "Especialidades",
          url: "/dashboard/configuration/tables/specialties",
          isActive: false,
        },
        (is_supervisor || permissions["Ver Dias no Laborables"]) && {
          title: "Feriados",
          url: "/dashboard/configuration/tables/holydays",
          isActive: false,
        },
        (is_supervisor || permissions["Tabla de Proveedores"]) && {
          title: "Proveedores",
          url: "/dashboard/configuration/tables/supplier",
          isActive: false,
        },
        (is_supervisor || permissions["Tabla de Sucursales"]) && {
          title: "Sucursales",
          url: "/dashboard/configuration/tables/offices",
          isActive: false,
        },
      ].filter(
        (item): item is { title: string; url: string; isActive: boolean } =>
          !!item
      ),
    },
    (is_supervisor || permissions["Sistema"]) && {
      title: "Sistemas",
      url: getFirstAvailableUrl(permissions, "system"),
      items: [
        (is_supervisor || permissions["Parametros del Sistema"]) && {
          title: "Datos generales",
          url: "/dashboard/configuration/sistem/general-data",
          isActive: false,
        },
        (is_supervisor || permissions["Usuarios del Sistema"]) && {
          title: "Usuarios",
          url: "/dashboard/configuration/sistem/users",
          isActive: false,
        },
        {
          title: "Datos de whatsapp",
          url: "/dashboard/configuration/sistem/whatsapp-data",
          isActive: false,
        },
        {
          title: "Datos de sms",
          url: "/dashboard/configuration/sistem/sms-data",
          isActive: false,
        },
        {
          title: "Datos varios",
          url: "/dashboard/configuration/sistem/various-data",
          isActive: false,
        },
      ].filter(
        (item): item is { title: string; url: string; isActive: boolean } =>
          !!item
      ),
    },
  ].filter(
    (item): item is { title: string; url: string | null; items: any[] } =>
      !!item && typeof item === "object" && "url" in item
  );

  return NAV_MAIN;
};

export const SIDEBAR_PERMISSIONS = {
  navMain: [
    {
      title: "Inicio",
      icon: <Home className="!size-5 text-slate-500" />,
      value: "inicio",
    },
    {
      title: "Administración",
      icon: <Banknotes className="!size-5 text-slate-500" />,
      value: "administracion",
    },
    {
      title: "Agenda",
      icon: <Calendar className="!size-5 text-slate-500" />,
      value: "agenda turnos",
    },
    {
      title: "Informes",
      icon: <FileText className="!size-5 text-slate-500" />,
      value: "informes",
    },
    {
      title: "Pacientes",
      icon: <Users className="!size-5 text-slate-500" />,
      value: "pacientes",
    },
    {
      title: "Sistema",
      icon: <Cog className="!size-5 text-slate-500" />,
      value: "sistema",
    },
    {
      title: "Socios",
      icon: <Sparkies className="!size-5 text-slate-500" />,
      value: "socios",
    },
    {
      title: "Tablas",
      icon: <BarChartBig className="!size-5 text-slate-500" />,
      value: "tablas",
    },
  ],
};
