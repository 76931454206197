import { Separator, Checkbox, Combobox } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Professional } from "@/types/general";

export const Professionals = ({
  form,
  professionals,
}: {
  form: any;
  professionals: Professional[];
}) => {
  const defaultValues = form.getValues();
  const filteredProfessionals = () =>
    defaultValues.disabled_med_ins
      ? professionals
      : professionals?.filter((item) => item.enabled);

  return (
    <>
      <Separator className="bg-slate-200 h-px" />
      <p className="text-slate-800 text-lg font-bold">Profesionales</p>
      <FormField
        control={form.control}
        name="all_prof"
        render={({ field }) => (
          <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                  checked
                    ? form.setValue("id_professional", null)
                    : form.setValue(
                        "id_professional",
                        filteredProfessionals()?.[0]?.id_professional
                      );
                }}
              />
            </FormControl>
            <div className="leading-none flex flex-col">
              <FormLabel className="!m-0">Todos los profesionales</FormLabel>
              <p className="text-sm font-regular text-gray-400">
                Se incluirán todos los profesionales al reporte
              </p>
            </div>
          </FormItem>
        )}
      />
      <FormItem>
        <FormControl>
          <Combobox
            form={form}
            data={filteredProfessionals()}
            dataKey="id_professional"
            value="name"
            placeholder="Busca un profesional"
            title="Profesional"
            disabled={defaultValues.all_prof}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
      <FormField
        control={form.control}
        name="disabled_prof"
        render={({ field }) => (
          <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="leading-none flex flex-col">
              <FormLabel className="!m-0">Incluír deshabilitados/as</FormLabel>
              <p className="text-sm font-regular text-gray-400">
                Se incluirán todos los profesionales deshabilitados/as
              </p>
            </div>
          </FormItem>
        )}
      />
    </>
  );
};
