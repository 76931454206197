"use client";

import * as React from "react";
import { format } from "date-fns";
import { ImWhatsapp as Whatsapp } from "react-icons/im";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  DialogFooter,
  DialogClose,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
} from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { toast } from "@/hooks";
import { sendBirthdayReminders } from "@/actions/reminders";
import { useGlobalStore } from "@/providers/global-provider";

export function Data<TData, TValue>({ columns, data }: any) {
  const { isDemo, startLoading, stopLoading, loading } = useGlobalStore();
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  const selected =
    table
      .getFilteredSelectedRowModel()
      .rows.map((row: any) => {
        return {
          paciente: row.original.patient,
          nroTel: row.original.phone,
        };
      })
      .filter((s) => s.nroTel) || [];
  async function onSubmit(messages: { paciente: string; nroTel: string }[]) {
    const data = {
      fecha: format(
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
        "yyyy-MM-dd"
      ),
      opcion: "recordarcumple",
      listawhatsapps: messages,
    };

    if (isDemo) {
      return toast({
        variant: "destructive",
        title: "Usuario de demostración",
        description:
          "Este es un usuario de demostración y no puede enviar mensajes.",
      });
    }

    try {
      startLoading();
      await sendBirthdayReminders(data);
      toast({
        variant: "successful",
        title: "¡Mensajes enviados correctamente!",
        description:
          "Los mensajes de saludos de cumpleaños se enviaron correctamente",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    } finally {
      stopLoading();
    }
  }

  return (
    <>
      <div className="rounded-md border h-full max-h-[600px] overflow-y-scroll bg-white">
        <Table className="rounded-md">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="hover:cursor-pointer"
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Sin resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DialogFooter className="sm:justify-start">
        {loading ? (
          <ButtonLoading className="bg-green-500 hover:bg-green-600" />
        ) : (
          <Button
            className="bg-green-500 hover:bg-green-600 flex gap-x-2"
            onClick={() => onSubmit(selected)}
          >
            <Whatsapp className="text-white h-5 w-5" />
            <p className="m-0 p-0">Enviar mensajes</p>
          </Button>
        )}
        <DialogClose asChild>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
      </DialogFooter>
    </>
  );
}
