import { Translations } from "@/types/general";

const administration = () => {
  const PAGE: string = "Administración";
  const BASE_MAP: {
    [key: string]:
      | "Caja diaria"
      | "Movimientos"
      | "Bancos"
      | "Proveedores"
      | "Tarjetas";
  } = {
    dailycashflow: "Caja diaria",
    transactions: "Movimientos",
    banks: "Bancos",
    suppliers: "Proveedores",
    cards: "Tarjetas",
  };

  const SECTIONS_MAP: {
    [key: string]:
      | "Pagos de pacientes"
      | "Otros ingresos"
      | "Egresos"
      | "Movimientos bancarios"
      | "Bancos y cuentas"
      | "Egreso de caja"
      | "Pago a proveedor"
      | "Factura de proveedor";
  } = {
    patient_payments: "Pagos de pacientes",
    incomes: "Otros ingresos",
    expenses: "Egresos",
    movements: "Movimientos bancarios",
    accounts: "Bancos y cuentas",
    other: "Egreso de caja",
    payment: "Pago a proveedor",
    invoice: "Factura de proveedor",
  };

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const configuration = (translations?: Translations) => {
  const PAGE: string = "Configuración";
  const BASE_MAP: {
    [key: string]: "Obras sociales" | string;
  } = {
    tables: "Tablas",
  };

  const SECTIONS_MAP: {
    [key: string]: "Obras sociales" | string;
  } = {
    "medical-insurances":
      (translations?.medical_insurances as string) || "Obras sociales",
    professionals: "Profesionales",
    specialties: "Especialidades",
    holydays: "Feriados",
    offices: "Sucursales",
    supplier: "Proveedores",
    laboratories: "Laboratorios",
  };

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const patients = () => {
  const PAGE: string = "Pacientes";
  const BASE_MAP: {
    [key: string]:
      | "Datos personales"
      | "Prestaciones"
      | "Odontograma"
      | "Historia clínica"
      | "Recetas"
      | "Turnos"
      | "Galería"
      | "Pagos"
      | "Laboratorios"
      | "Presupuestos";
  } = {
    "personal-data": "Datos personales",
    benefits: "Prestaciones",
    odontogram: "Odontograma",
    payments: "Pagos",
    "medical-history": "Historia clínica",
    prescriptions: "Recetas",
    appointments: "Turnos",
    laboratory: "Laboratorios",
    gallery: "Galería",
    budget: "Presupuestos",
  };

  const SECTIONS_MAP: {
    [key: string]: any;
  } = {};

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const statistics = () => {
  const PAGE: string = "Estadísticas";
  const BASE_MAP: {
    [key: string]:
      | "Finanzas"
      | "Panel de control"
      | "Prestaciones realizadas"
      | "Presupuestos emitidos";
  } = {
    finances: "Finanzas",
    control: "Panel de control",
    "benefits-made": "Prestaciones realizadas",
    budgets: "Presupuestos emitidos",
  };

  const SECTIONS_MAP: {
    [key: string]: any;
  } = {};

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const sistems = () => {
  const PAGE: string = "Configuración";
  const BASE_MAP: {
    [key: string]: "Sistema" | string;
  } = {
    sistem: "Sistema",
  };

  const SECTIONS_MAP: {
    [key: string]:
      | "Datos generales"
      | "Usuarios"
      | "Whatsapp"
      | "Sms"
      | "Varios";
  } = {
    "general-data": "Datos generales",
    users: "Usuarios",
    "whatsapp-data": "Whatsapp",
    "sms-data": "Sms",
    "various-data": "Varios",
  };

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const reports = () => {
  const PAGE: string = "Liquidaciones";
  const BASE_MAP: {
    [key: string]: "Auditor Bilog";
  } = {
    auditor: "Auditor Bilog",
  };

  const SECTIONS_MAP: {
    [key: string]: any;
  } = {};

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

const account = () => {
  const PAGE: string = "Mi cuenta";
  const BASE_MAP: {
    [key: string]: "Datos generales" | "Suscripción" | "Productos";
  } = {
    general: "Datos generales",
    subscription: "Suscripción",
    products: "Productos",
  };

  const SECTIONS_MAP: {
    [key: string]: any;
  } = {};

  return { BASE_MAP, SECTIONS_MAP, PAGE };
};

export const getBreadcrumb = (
  pathname: string,
  translations?: Translations
) => {
  const paths = pathname.split("/").filter(Boolean);
  const breadcrumbs: { [key: string]: (translations?: Translations) => any } = {
    patients,
    administration,
    statistics,
    sistems,
    reports,
    "my-account": account,
    configuration: () => configuration(translations),
  };

  return Object.keys(breadcrumbs).reduce((breadcrumb, key) => {
    const { BASE_MAP, SECTIONS_MAP, PAGE } = breadcrumbs[key](translations);

    const BASE_KEY = Object.keys(BASE_MAP).find((baseKey) =>
      paths.includes(baseKey)
    );
    if (BASE_KEY) {
      breadcrumb.push(
        { title: PAGE, isPage: false },
        { title: BASE_MAP[BASE_KEY], isPage: false }
      );
    }

    const SECTION_KEY = Object.keys(SECTIONS_MAP).find((sectionKey) =>
      paths.includes(sectionKey)
    );
    if (SECTION_KEY) {
      breadcrumb.push({ title: SECTIONS_MAP[SECTION_KEY], isPage: true });
    }

    return breadcrumb;
  }, [] as { title: string; isPage: boolean }[]);
};
