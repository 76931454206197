import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  date_to: z.date(),
  date_from: z.date(),
  id_supplier: z.number(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
