import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_user: z.number().optional(),
  name: z.string().trim().min(1, "El nombre es requerido"),
  user: z.string().trim().min(1, "El usuario es requerido"),
  enabled: z.boolean(),
  permissions: z.array(z.any()),
  is_dentist: z.boolean(),
  is_dentist_guard: z.boolean(),
  is_dentist_schedule: z.boolean(),
  is_dentist_data: z.boolean(),
  is_supervisor: z.boolean(),
  use_usd: z.boolean(),
  all_offices: z.boolean(),
  id_office: z.number().optional().nullable(),
  id_professional: z.number().optional().nullable(),
  id_user_to_copy: z.number().optional().nullable(),
  id_user_before_copy: z.number().optional().nullable(),
  password: z.string().optional(),
  password_confirm: z.string().optional(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
