import { INITAL_PARAMS } from "@/libs/administration";

type AdministrationData = {
  params?: any;
  data: any;
  initialized_data: boolean;
};

type State = {
  administration: {
    [key: string]: AdministrationData;
  };
};

type Actions = {
  setDataAdministration: (payload: {
    data: any;
    params: any;
    section: string;
  }) => void;
  setInitAdministration: (payload: { data: any; section: string }) => void;
};

type AdministrationStore = State & Actions;

export const initState: State = {
  administration: {
    dailycashflow: {
      params: INITAL_PARAMS.DAILY_CASHFLOW,
      data: {},
      initialized_data: false,
    },
    transactions: {
      params: INITAL_PARAMS.TRANSACTIONS,
      data: {},
      initialized_data: false,
    },
    accounts: {
      data: {},
      initialized_data: false,
    },
    movements: {
      params: INITAL_PARAMS.MOVEMENTS,
      data: {},
      initialized_data: false,
    },
    suppliers: {
      params: INITAL_PARAMS.SUPPLIERS,
      data: {},
      initialized_data: false,
    },
    cards: {
      data: {},
      initialized_data: false,
    },
  },
};

export const AdministrationSlice = (
  set: (partial: Partial<AdministrationStore>) => void,
  get: () => AdministrationStore
): AdministrationStore => ({
  ...initState,
  setInitAdministration: ({
    data,
    section,
  }: {
    data: any;
    section: string;
  }) => {
    if (!get().administration[section]?.initialized_data) {
      set({
        administration: {
          ...get().administration,
          [section]: {
            ...get().administration[section],
            data,
            initialized_data: true,
          },
        },
      });
    }
  },
  setDataAdministration: ({
    data,
    params,
    section,
  }: {
    data: any;
    params: any;
    section: string;
  }) => {
    set({
      administration: {
        ...get().administration,
        [section]: {
          ...get().administration[section],
          params,
          data,
        },
      },
    });
  },
});
