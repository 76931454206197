import React from "react";
import classNames from "classnames";
import { LuChevronsUpDown, LuCheck, LuPlus } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  Input,
  DropdownMenuLabel,
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandSeparator,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Badge,
} from "@/components/atoms";

export const SearchFilter = ({ table }: any) => {
  const firstColumn = React.useMemo(() => {
    return table.getAllColumns().filter((col: any) => col.getCanHide())[0].id;
  }, [table]);
  const [filterColumn, setFilterColumn] = React.useState<string>(firstColumn);
  const [filterInput, setFilterInput] = React.useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterInput(value);
    if (filterColumn) {
      table.getColumn(filterColumn)?.setFilterValue(value);
    }
  };

  const handleSelectColumn = (columnId: string) => {
    if (filterColumn) {
      table.getColumn(filterColumn)?.setFilterValue("");
    }
    setFilterColumn(columnId);
    setFilterInput("");
    table.getColumn(columnId)?.setFilterValue("");
  };

  return (
    <div className="relative w-full max-w-[272px] flex">
      <Input
        placeholder={`Buscando por ${filterColumn}`}
        value={filterInput}
        onChange={handleInputChange}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <p className="absolute right-2 top-1/2 transform -translate-y-1/2 inline-flex items-center justify-center rounded-md text-sm font-semibold text-slate-600 cursor-pointer">
            <LuChevronsUpDown />
          </p>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="!font-bold">
            ¿Que quieres buscar?
          </DropdownMenuLabel>
          {table
            .getAllColumns()
            .filter((column: any) => column.getCanHide())
            .map((column: any) => {
              const IS_CHECKED = filterColumn === column.id;

              return (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="flex justify-between items-center"
                  checked={IS_CHECKED}
                  onCheckedChange={() => handleSelectColumn(column.id)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              );
            })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export const FacetedFilter: React.FC<{
  title: string;
  table: any;
  name: string;
  options: any;
}> = ({ title, table, name, options }) => {
  const column = table.getColumn(name);
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue());

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border border-dashed hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-filter-pres"
        >
          <LuPlus className="size-5" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <span className="w-px h-full bg-gray-200/80" />
              {selectedValues.size > 2 ? (
                <Badge className="bg-slate-100 text-slate-700">
                  {`${selectedValues.size} seleccionados`}
                </Badge>
              ) : (
                options
                  .filter((option: any) => selectedValues.has(option.value))
                  .map((option: any) => (
                    <Badge
                      key={option.value}
                      className="bg-slate-100 text-slate-700"
                    >
                      {option.label}
                    </Badge>
                  ))
              )}
            </>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No se encontraron resultados.</CommandEmpty>
            <CommandGroup>
              {options.map((option: any) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      selectedValues.clear();
                      selectedValues.add(option.value);
                      const filterValues = Array.from(selectedValues);
                      column?.setFilterValue(filterValues);
                    }}
                  >
                    <div
                      className={classNames(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-px",
                        isSelected
                          ? "bg-blue-500 border-blue-500 text-white"
                          : "opacity-50 [&_svg]:invisible border-slate-700"
                      )}
                    >
                      <LuCheck className="size-4" />
                    </div>
                    {option.icon && option.icon}
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className="justify-center text-center"
                  >
                    Limpiar filtros
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
