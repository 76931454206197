import * as React from "react";
import { Combobox } from "@/components/atoms";
import { Form, Offices, Professional } from "@/types/general";
import { professionalsByOffice } from "@/utils/filters";

export function ProfessionalsSelect({
  professionals,
  form,
  classname,
  offices,
}: {
  professionals: Professional[];
  classname?: string;
  form: Form<any>;
  offices: Offices[];
}) {
  const firstMount = React.useRef<boolean>(true);
  const { id_office } = form.watch();
  const PROFESSIONALS = professionalsByOffice({
    id_office: id_office,
    professionals,
  });
  React.useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    } else {
      form.setValue("id_professional", PROFESSIONALS?.[0]?.id_professional);
    }
  }, [id_office]);

  return (
    <>
      {offices?.length > 0 && (
        <Combobox
          form={form}
          data={offices}
          dataKey="id_office"
          value="name"
          placeholder="Busca una sucursal"
          title="sucrusal"
          label="Sucursal"
          className={classname}
        />
      )}
      <Combobox
        form={form}
        data={PROFESSIONALS}
        dataKey="id_professional"
        value="name"
        placeholder="Busca un profesional"
        title="profesional"
        label="Profesional"
        className={classname}
      />
    </>
  );
}
