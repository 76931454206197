"use client";

import React from "react";
import { EmptyState } from "@/components/molecules";
import { MedicalInsurance } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  selected: MedicalInsurance | null | undefined;
}> = ({ selected }) => {
  return selected?.groups && selected?.groups.length > 0 ? (
    <Data columns={Columns({ selected })} data={selected.groups} />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
