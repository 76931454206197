"use client";

import React from "react";
import { LuEye, LuPhone, LuMail, LuPin, LuIdCard } from "react-icons/lu";
import {
  HiDotsVertical,
  HiOutlineBan,
  HiOutlineCheckCircle,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import classNames from "classnames";
import {
  buttonVariants,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/atoms";
import {
  NewSupplierForm,
  DeleteSupplierForm,
} from "@/components/organisms/forms";
import { useDialog } from "@/hooks";
import { numberToCurrency } from "@/utils/currency";
import { Supplier } from "@/types/general";

const Dropdown = ({ supplier }: { supplier: Supplier }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() => {
              openDialog({
                content: <NewSupplierForm supplier={supplier} />,
              });
            }}
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() => {
              openDialog({
                content: <DeleteSupplierForm selected={supplier} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: any }) => {
      return <Dropdown supplier={row.original} />;
    },
  },
  {
    accessorKey: "name",
    header: "Proveedor",
    cell: ({ row }: { row: any }) => {
      const { name } = row.original;

      return <p className="font-bold text-sm text-gray-500">{name}</p>;
    },
  },
  {
    accessorKey: "contact",
    header: "Datos addicionales",
    cell: ({ row }: { row: any }) => {
      const { phone, email, city, tax_number } = row.original;

      return (
        <HoverCard openDelay={300}>
          <HoverCardTrigger>
            <div
              className={classNames(
                "flex gap-x-2 hover:cursor-pointer",
                buttonVariants({ variant: "ghost" })
              )}
            >
              <LuEye className="size-4" />
              <p>Ver datos</p>
            </div>
          </HoverCardTrigger>
          <HoverCardContent className="!w-full" align="start">
            <div className="text-xs text-slate-600">
              <p className="text-sm text-slate-900">Datos adicionales</p>
              {!phone && !email && !city && !tax_number ? (
                <p className="mt-2">No hay datos para mostrar</p>
              ) : (
                <>
                  {phone && (
                    <div className="flex items-end gap-x-2">
                      <LuPhone className="size-4" />
                      <p className="mt-2">{phone}</p>
                    </div>
                  )}
                  {email && (
                    <div className="flex items-end gap-x-2">
                      <LuMail className="size-4" />
                      <p className="mt-2">{email}</p>
                    </div>
                  )}
                  {city && (
                    <div className="flex items-end gap-x-2">
                      <LuPin className="size-4" />
                      <p className="mt-2">{city}</p>
                    </div>
                  )}
                  {tax_number && (
                    <div className="flex items-end gap-x-2">
                      <LuIdCard className="size-4" />
                      <p className="mt-2">{tax_number}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },
  {
    accessorKey: "balance",
    header: "Saldo inicial",
    cell: ({ row }: { row: any }) => {
      const { balance } = row.original;

      return (
        <p
          className={classNames("flex justify-end lg:text-sm my-1", {
            "text-red-500": balance < 0,
            "text-green-500": balance > 0,
            "text-slate-600": balance === 0,
          })}
        >
          {numberToCurrency(balance)}
        </p>
      );
    },
  },
  {
    accessorKey: "observation",
    header: "Observaciones",
    cell: ({ row }: { row: any }) => {
      const { observation } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500">
          {observation}
        </p>
      );
    },
  },
  {
    accessorKey: "enabled",
    header: "Habilitado",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: any }) => {
      const { enabled } = row.original;

      return (
        <p className="">
          {enabled ? (
            <HiOutlineCheckCircle className="size-5 text-green-500" />
          ) : (
            <HiOutlineBan className="size-5 text-red-500" />
          )}
        </p>
      );
    },
  },
];
