import { Card, CardContent, CardHeader, Skeleton } from "@/components/atoms";

export const Loading = () => {
  return (
    <Card>
      <CardHeader className="!flex !flex-row items-center justify-between !space-y-0">
        <Skeleton className="h-12 w-[200px] rounded-md" />
        <Skeleton className="h-12 w-[150px] rounded-md" />
      </CardHeader>
      <CardContent className="grid grid-cols-4 gap-5">
        {Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
          <Skeleton key={i} className="h-10 rounded-md" />
        ))}
        <Skeleton className="h-44 col-span-2 col-start-1" />
        <Skeleton className="h-44 col-span-2" />
      </CardContent>
    </Card>
  );
};
