"use client";

import React from "react";
import classNames from "classnames";
import { useGlobalStore } from "@/providers/global-provider";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import {
  FirstTimeForm,
  NewAppointmentPatientExist,
  DeleteAppointment,
  EditObservation,
  NewOverturn,
  DisabledAppointment,
} from "@/components/organisms";
import { EmptyState } from "@/components/molecules";
import {
  weeklyScheduleMapper,
  scheduleMapper,
  professionalScheduleMapper,
} from "@/utils/mappers";
import { Table as Skeleton } from "@/components/molecules/geist/schedule";
import { checkPermission } from "@/utils/helpers";
import { KEYS_PERMISSIONS } from "@/libs/schedule";
import { ScheduleList } from "@/types/schedule";
import { Appointment, MedicalInsurance } from "@/types/general";
import { Weekly, columns as ColumnsWeekly } from "./weekly";
import { Daily, Columns as ColumnsDaily } from "./daily";

export const Table: React.FC<{
  medicalInsurances: MedicalInsurance[];
  actions: ({
    appointment,
    type,
    attendance,
  }: {
    appointment: Appointment;
    type: string;
    attendance: string;
  }) => void;
  setOpenModalForm: (value: boolean) => void;
  setChangeDataTable: (value: string) => void;
  refresh: () => void;
  changeDataTable: string;
  formState: string;
  openModalForm: boolean;
}> = ({
  medicalInsurances,
  actions,
  setOpenModalForm,
  changeDataTable,
  setChangeDataTable,
  formState,
  openModalForm,
  refresh,
}) => {
  const {
    schedule: {
      data,
      current_appointment,
      config,
      params: { option },
    },
    loading,
    user: { is_supervisor },
    permissions,
    general_params: { what_region, translations },
  } = useGlobalStore();
  const TABLE_TYPE = React.useMemo(() => option, [option]);

  const makeTableData = React.useCallback(
    (type: string) => {
      const res: { [key: string]: any } = {
        buscarpordiatodosprof: professionalScheduleMapper(data),
        buscarsemana: weeklyScheduleMapper(data),
        buscar: scheduleMapper(data),
      };

      return res[type];
    },
    [data]
  );
  const weeklyCss = classNames(
    makeTableData(TABLE_TYPE)?.length === 1 && "flex w-full !ml-0",
    makeTableData(TABLE_TYPE)?.length === 2 && `grid grid-cols-2 gap-x-4 !ml-0`,
    makeTableData(TABLE_TYPE)?.length === 3 && `grid grid-cols-3 gap-x-4`,
    makeTableData(TABLE_TYPE)?.length >= 4 &&
      "flex flex-nowrap gap-x-4 px-6 py-2"
  );
  const weeklyDivCss = classNames(
    "!pl-0",
    makeTableData(TABLE_TYPE)?.length === 1 && "w-full",
    makeTableData(TABLE_TYPE)?.length >= 4 && "!basis-[45%]"
  );

  const FORM_LIST: {
    [key: string]: { header: string; content: React.ReactNode };
  } = {
    new_appointment_new_patient: {
      header: "Datos del turno",
      content: (
        <FirstTimeForm
          refresh={refresh}
          medicalInsurances={medicalInsurances}
          setChangeDataTable={setChangeDataTable}
        />
      ),
    },
    new_appointment_patient_exist: {
      header: "Selecciona un paciente",
      content: (
        <NewAppointmentPatientExist
          refresh={refresh}
          setChangeDataTable={setChangeDataTable}
          medicalInsurances={medicalInsurances}
        />
      ),
    },
    delete_appointment: {
      header: current_appointment?.dont_give
        ? "Habilitar turno"
        : "Liberar turno",
      content: (
        <DeleteAppointment
          setChangeDataTable={setChangeDataTable}
          refresh={refresh}
        />
      ),
    },
    edit_observation: {
      header: "Carga una nueva observación al turno",
      content: (
        <EditObservation
          setChangeDataTable={setChangeDataTable}
          refresh={refresh}
          medicalInsurances={medicalInsurances}
        />
      ),
    },
    disabled_appointment: {
      header: "Anular turno",
      content: (
        <DisabledAppointment
          setChangeDataTable={setChangeDataTable}
          refresh={refresh}
        />
      ),
    },
    new_over_appointment: {
      header: "Nuevo sobreturno",
      content: (
        <NewOverturn
          setChangeDataTable={setChangeDataTable}
          refresh={refresh}
        />
      ),
    },
  };
  const FORM_MODAL = FORM_LIST[formState];
  const SHOW_SCHEDULE = React.useMemo(() => data?.length > 0, [data]);
  const MAPPED_PERMISSIONS = KEYS_PERMISSIONS.reduce((acc, { key, tab }) => {
    acc[key] = checkPermission(tab, is_supervisor, permissions);
    return acc;
  }, {} as Record<string, boolean>);

  const TableEmptyState = () => (
    <EmptyState
      className={classNames({
        "h-[calc(100vh-211px)]": config.extend_schedule,
        "h-[calc(100vh-154px)]": !config.extend_schedule,
      })}
      title="Ups!"
      copy="No hay datos para mostrar, elija otro dia y/o sucursal"
    />
  );

  const Table = React.useCallback(() => {
    const Comp: { [key: string]: any } = {
      buscarpordiatodosprof: (
        <div
          className={classNames("relative", {
            "h-[calc(100vh-211px)]": config.extend_schedule,
            "h-[calc(100vh-154px)]": !config.extend_schedule,
          })}
        >
          {SHOW_SCHEDULE ? (
            <Carousel
              opts={{
                align: "start",
              }}
              className="w-full !static cursor-pointer"
            >
              <CarouselContent className={weeklyCss}>
                {professionalScheduleMapper(data)?.map(
                  (
                    prof: {
                      id: string;
                      name: string;
                      schedule?: ScheduleList[];
                    },
                    i: number
                  ) => (
                    <CarouselItem className={weeklyDivCss} key={i}>
                      <p className="text-center pb-2 text-base">{prof.name}</p>
                      <Weekly
                        key={i}
                        columns={ColumnsWeekly({
                          actions,
                          translations,
                          permissions: MAPPED_PERMISSIONS,
                          config: { region: what_region, countries: [] },
                          changeDataTable,
                        })}
                        data={prof.schedule || []}
                      />
                    </CarouselItem>
                  )
                )}
              </CarouselContent>
              {professionalScheduleMapper(data)?.length >= 4 && (
                <>
                  <CarouselPrevious className="!top-5 !left-0" />
                  <CarouselNext className="!top-5 !right-0" />
                </>
              )}
            </Carousel>
          ) : (
            <TableEmptyState />
          )}
        </div>
      ),
      buscarsemana: (
        <div className="relative">
          {SHOW_SCHEDULE ? (
            <Carousel
              opts={{
                align: "start",
              }}
              className="w-full !static"
            >
              <CarouselContent className={weeklyCss}>
                {(weeklyScheduleMapper(data) as any)?.map(
                  (
                    day: {
                      schedule: Appointment[];
                      date: string;
                      day: string;
                    },
                    i: number
                  ) => (
                    <CarouselItem className={weeklyDivCss} key={i}>
                      <p className="text-center pb-2 text-base">
                        {day.day} <span className="font-bold">{day.date}</span>
                      </p>
                      <Weekly
                        key={i}
                        columns={ColumnsWeekly({
                          actions,
                          translations,
                          permissions: MAPPED_PERMISSIONS,
                          config: { region: what_region, countries: [] },
                          changeDataTable,
                        })}
                        data={day.schedule}
                      />
                    </CarouselItem>
                  )
                )}
              </CarouselContent>
              {weeklyScheduleMapper(data)?.length >= 4 && (
                <>
                  <CarouselPrevious className="!top-5 !left-0" />
                  <CarouselNext className="!top-5 !right-0" />
                </>
              )}
            </Carousel>
          ) : (
            <TableEmptyState />
          )}
        </div>
      ),
      buscar: SHOW_SCHEDULE ? (
        <Daily
          columns={ColumnsDaily({
            config: { region: what_region, countries: [] },
            actions,
            changeDataTable,
            permissions: MAPPED_PERMISSIONS,
            translations,
          })}
          data={scheduleMapper(data)}
        />
      ) : (
        <TableEmptyState />
      ),
    };

    return Comp[TABLE_TYPE];
  }, [TABLE_TYPE, data]);

  return (
    <Dialog open={openModalForm} onOpenChange={setOpenModalForm}>
      <div className="p-4 bg-slate-200/40">
        {loading ? <Skeleton /> : <Table />}
      </div>
      {FORM_MODAL && (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{FORM_MODAL.header}</DialogTitle>
          </DialogHeader>
          {FORM_MODAL.content}
        </DialogContent>
      )}
    </Dialog>
  );
};
