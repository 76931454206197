import React from "react";
import { CardContent, Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  CheckboxForm,
} from "@/components/molecules";
import { Form, Laboratory } from "@/types/general";
import { ButtonsOrder } from "./save";
import { SearchConcept } from "./search-concept";
import { formSchema } from "../schema";

export const LoadWork: React.FC<{
  form: Form<typeof formSchema>;
  setHiddenLoadMedicine: (value: boolean) => void;
  laboratories: Laboratory[];
}> = ({ setHiddenLoadMedicine, form, laboratories }) => {
  const { order_from_list, is_manual_concept } = form.watch();

  return (
    <CardContent className="grid grid-cols-5 gap-5">
      <CheckboxForm
        label="Ingreso manual"
        subLabel="Cargar la información de forma manual."
        onCheckedChange={(check: boolean) => {
          form.setValue("is_manual_concept", check);
          const { order_list } = form.getValues();
          if (order_from_list) {
            const DEFAULT_CONCEPT = order_list?.find(
              (e) => e?.id_order_concept === order_from_list.id_order_concept
            );
            DEFAULT_CONCEPT &&
              form.setValue("order_from_list", DEFAULT_CONCEPT);
          }

          if (check) {
            return form.setValue("order_from_list", {
              ...order_from_list,
              id_concept: null,
              code: null,
              description: "",
              tooth_section: "",
              color: "",
              amount: 0,
              total_amount: 0,
              quantity: 1,
            });
          }
        }}
        checked={is_manual_concept}
      />
      <SearchConcept form={form} laboratories={laboratories} />
      <FormField
        control={form.control}
        name="order_from_list.quantity"
        render={({ field }) => (
          <FormItem className="col-start-1">
            <FormLabel>Cantidad</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                onChange={(e) => {
                  form.setValue(
                    "order_from_list.quantity",
                    Number(e.target.value)
                  );
                  form.setValue(
                    "order_from_list.total_amount",
                    order_from_list.quantity * order_from_list.amount
                  );
                }}
                min={1}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="order_from_list.tooth_section"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Pieza/s</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="order_from_list.color"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Color</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="order_from_list.amount"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Importe unitario</FormLabel>
            <FormControl>
              <Input
                type="number"
                {...field}
                onChange={(e) => {
                  form.setValue(
                    "order_from_list.amount",
                    Number(e.target.value)
                  );
                  form.setValue(
                    "order_from_list.total_amount",
                    order_from_list.quantity * order_from_list.amount
                  );
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="order_from_list.total_amount"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Importe total</FormLabel>
            <FormControl>
              <Input type="number" {...field} disabled />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <ButtonsOrder setHiddenLoadMedicine={setHiddenLoadMedicine} form={form} />
    </CardContent>
  );
};
