"use client";

import React from "react";
import { AppointmentList } from "@/types/patients";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{ data: AppointmentList[] }> = ({ data }) => {
  return <Data columns={Columns} data={data} />;
};
