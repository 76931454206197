import React from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { getMedications } from "@/actions/recipes";
import { Button, Input, Label } from "@/components/atoms";
import { MedicinesTable } from "@/components/organisms/tables";
import { useOnClickOutside, useDebounce, toast } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { Medications, MedicationsInfo } from "@/types/recipes";

export const SearchMedicine: React.FC<{
  form: any;
  placeholder: string;
  label: string;
  name: string;
}> = ({ placeholder, label, form }) => {
  const { selected_medication } = form.watch();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const [hasSearched, setHasSearched] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [sorting, setSorting] = React.useState([]);
  const page_number = React.useRef<number>(1);
  const ref_wrapper = React.useRef<any>(null);
  const ref_list = React.useRef<any>(null);
  const [searchResults, setSearchResults] = React.useState<{
    medications: Medications[];
    medication_info: MedicationsInfo;
  }>({
    medications: [],
    medication_info: {
      current_page: -1,
      total_pages: -1,
      has_more: false,
    },
  });
  const SHOW_RESULT = isOpen && searchResults?.medications?.length >= 1;
  const HEIGTH =
    searchResults && searchResults.medications?.length < 9
      ? `${searchResults.medications?.length * 65 + 80}px`
      : "520px";
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(false);
    setSearchInput(e.target.value);
    form.setValue("selected_medication", {
      ...selected_medication,
      product_name: "",
      presentation: "",
    });
    setHasSearched(false);
  };

  const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingButton(true);
    try {
      if (searchInput?.trim() !== "" && !hasSearched) {
        page_number.current = 1;
        const { medications, medication_info } = (await getMedications(
          searchInput,
          page_number.current
        )) as {
          medications: Medications[];
          medication_info: MedicationsInfo;
        };
        setSearchResults({
          medications,
          medication_info,
        });
        setIsOpen(true);
        setHasSearched(true);
      }
    } catch (error: any) {
      toast({
        variant: error.message.includes("(00001)") ? "warning" : "destructive",
        title: "Ups! No se encontraron coincidencias.",
        description: error.message,
      });
    } finally {
      setLoadingButton(false);
    }
  };

  const onClickHandler = async (selectedMedicine: any) => {
    form.setValue("selected_medication", {
      ...selected_medication,
      ...selectedMedicine,
    });
    setIsOpen(false);
    setSearchInput("");
  };

  const handleScroll = useDebounce(
    React.useCallback(
      async (currentRef?: any) => {
        if (searchResults.medication_info?.has_more) {
          if (currentRef && searchInput) {
            const { scrollTop, scrollHeight, clientHeight } = currentRef;
            if (
              scrollHeight - scrollTop - clientHeight < 500 &&
              page_number.current < 13
            ) {
              page_number.current = page_number.current + 1;
              const { medications, medication_info } = (await getMedications(
                searchInput,
                page_number.current
              )) as any;

              setSearchResults({
                medications: [...searchResults.medications, ...medications],
                medication_info,
              });
            }
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchResults.medication_info, searchInput, page_number]
    ),
    300
  );
  useOnClickOutside({
    ref: ref_wrapper,
    handler: () => {
      setIsOpen(false);
      setSearchInput("");
    },
  });
  React.useEffect(() => {
    handleScroll(ref_list.current);
  }, [handleScroll]);

  return (
    <form
      ref={ref_wrapper}
      className={classNames(
        "rounded-md will-change-[left] transition-all duration-100 ease-out w-full group col-span-6",
        isOpen && "searchbar-container--is_open relative z-10"
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 rounded-md">
        <Label className="mb-2 block !text-sm col-span-full">{label}</Label>
        <div className="col-span-10">
          <Input
            type="search"
            className="text-slate-900 !border-1 shadow-sm !border-gray-300 pl-5 h-10 w-full relative rounded-md text-base col-span-10"
            placeholder={placeholder}
            onChange={(e: any) => onChangeHandler(e)}
            value={searchInput || selected_medication.product_name}
            autoFocus
            maxLength={120}
            autoCapitalize="off"
          />
        </div>
        {loadingButton ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button
            disabled={!searchInput}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onSubmitHandler(e)
            }
            type="submit"
            className="w-full col-span-2 bg-transparent !text-gray-800 hover:bg-slate-200/50 border border-slate-200"
          >
            Buscar
          </Button>
        )}
      </div>
      <Transition
        show={SHOW_RESULT}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MedicinesTable
          data={searchResults.medications}
          sorting={sorting}
          setSorting={setSorting}
          onClick={onClickHandler}
          height={HEIGTH}
          refList={ref_list}
          onScroll={handleScroll}
        />
      </Transition>
    </form>
  );
};
