import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { Offices } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { updateOfficesConfig } from "@/actions/configs";

export const DeleteOffice = ({ office }: { office: Offices | undefined }) => {
  const { loading, startLoading, stopLoading } = useGlobalStore();
  const { closeDialog } = useDialog();

  const onSubmit = async () => {
    startLoading();
    const response = await updateOfficesConfig({
      ...office,
      option: "eliminar",
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "La sucursal se eliminó correctamente.",
      });
      closeDialog();
    }
    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar sucursal</DialogTitle>
      </DialogHeader>
      <span className="text-slate-500">
        Estás por eliminar una sucursal y esta acción es irreversible.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
};
