import React from "react";
import { format } from "date-fns";
import { LuPen } from "react-icons/lu";
import classNames from "classnames";
import { CardTitle } from "@/components/atoms";
import { Prescription } from "@/types/patients";

export const Header = ({
  data,
}: {
  data: {
    [key: string]: {
      professional_name: string;
      date_of_recipe: Date;
      setStep: (value: number) => void;
      prescription_selected: Prescription;
      office_name: string;
      recipe: boolean;
      affiliate_number: string;
      plan: string;
      med_ins_name: string;
    };
  };
}) => {
  const {
    professional_name,
    date_of_recipe,
    setStep,
    prescription_selected,
    office_name,
    affiliate_number,
    plan,
    med_ins_name,
  } = data["header"];
  const sections: { title: string; value: string | number | Date }[] = [
    ...(prescription_selected
      ? []
      : [
          { title: "Obra social:", value: med_ins_name },
          { title: "Plan:", value: plan },
          { title: "Nro de afiliado:", value: affiliate_number },
        ]),
    { title: "Profesional:", value: professional_name },
    { title: "Fecha de receta:", value: format(date_of_recipe, "dd/MM/yyyy") },
    ...(prescription_selected || !office_name
      ? []
      : [{ title: "Sucursal:", value: office_name }]),
  ];

  return (
    <div className="mt-2 rounded-md bg-white">
      <CardTitle className="border-b border-b-slate-300 p-5 text-2xl rounded-t-md bg-white truncate">
        Datos de la receta
      </CardTitle>
      <div className="grid grid-cols-12 p-6 pb-0 border-b border-b-slate-300">
        {sections.map((section: any, index: number) => (
          <div
            className="col-span-4 flex items-center space-x-1 pb-4"
            key={index}
          >
            <h3 className="text-slate-500 text-base">{section?.title}</h3>
            <span>{section?.value}</span>
          </div>
        ))}
      </div>
      <p
        className={classNames(
          "group flex text-base text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer",
          {
            "text-gray-400 cursor-not-allowed pointer-events-none":
              prescription_selected,
          }
        )}
        onClick={() => setStep(0)}
      >
        Modificar
        <LuPen
          type="arrow_right"
          className={classNames(
            "h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3",
            {
              "text-gray-400 cursor-not-allowed": prescription_selected,
            }
          )}
        />
      </p>
    </div>
  );
};
