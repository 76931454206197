import React from "react";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({
  data,
  action,
  prescription_selected,
}) => {
  return (
    <div className="mx-auto w-full">
      <Data columns={Columns({ action, prescription_selected })} data={data} />
    </div>
  );
};
