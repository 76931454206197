import { MedicalHistory } from "@/types/patients";

type State = {
  patient_history: {
    history: MedicalHistory[];
    history_selected: MedicalHistory | null;
  };
};

type Actions = {
  setPatientHistory: (data: { history: MedicalHistory[] }) => void;
  setHistorySelected: (history: MedicalHistory | null) => void;
};

type HistoryStore = State & Actions;

export const INIT_STATE: State = {
  patient_history: {
    history: [],
    history_selected: null,
  },
};

export const HistorySlice = (
  set: (partial: Partial<HistoryStore>) => void,
  get: () => HistoryStore
): HistoryStore => ({
  ...INIT_STATE,
  setPatientHistory: ({ history }: { history: MedicalHistory[] }) => {
    const state = get();
    set({
      ...state,
      patient_history: {
        ...state.patient_history,
        history,
      },
    });
  },
  setHistorySelected: (history: MedicalHistory | null) => {
    const state = get();
    set({
      ...state,
      patient_history: {
        ...state.patient_history,
        history_selected: history,
      },
    });
  },
});
