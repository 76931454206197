import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  ConfigurationSlice,
  UserSlice,
  DashboardSlice,
  ReportsSlice,
  StatisticsSlice,
  AdministrationSlice,
  CommonsSlice,
  ScheduleSlice,
  PatientsSlice,
  GallerySlice,
  AppointmentsSlice,
  BenefitsSlice,
  HistorySlice,
  PatientLaboratorySlice,
  PatientBudgetsSlice,
  PaymentsSlice,
  PrescriptionsSlice,
  OrdersSlice,
  BudgetsSlice,
  RemindersSlice,
} from "@/slices";

export const useBoundStore = create(
  persist(
    (set, get) => ({
      ...ConfigurationSlice(set, get),
      ...ReportsSlice(set, get),
      ...UserSlice(set, get),
      ...DashboardSlice(set),
      ...StatisticsSlice(set, get),
      ...AdministrationSlice(set, get),
      ...CommonsSlice(set),
      ...ScheduleSlice(set, get),
      ...PatientsSlice(set, get),
      ...RemindersSlice(set, get),
      ...GallerySlice(set, get),
      ...AppointmentsSlice(set, get),
      ...BenefitsSlice(set, get),
      ...HistorySlice(set, get),
      ...PatientLaboratorySlice(set, get),
      ...PatientBudgetsSlice(set, get),
      ...PaymentsSlice(set, get),
      ...PrescriptionsSlice(set, get),
      ...OrdersSlice(set, get),
      ...BudgetsSlice(set, get),
    }),
    {
      name: "bound-store",
      partialize: (state: any) => ({
        user: state.user,
        permissions: state.permissions,
        general_params: state.general_params,
        is_demo: state.isDemo,
        shortcuts: state.shortcuts,
        region: state.region,
        countries: state.countries,
        patient: state.patient,
        med_ins_benefits: state.med_ins_benefits,
        schedule_params: state.schedule.params,
      }),
    }
  )
);
