import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  lender_code: z.string().min(4, "El codigo de prestador es requerido").max(10),
  manager_code: z
    .string()
    .min(4, "El codigo de gerenciador es requerido")
    .max(10),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
