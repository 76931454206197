"use client";

import React from "react";
import { SearchForm } from "@/components/organisms/forms/schedule/search";
import { ScheduleTable } from "@/components/organisms/tables";
import { useGlobalStore } from "@/providers/global-provider";
import {
  Appointment,
  MedicalInsurance,
  Offices,
  Professional,
  Specialty,
} from "@/types/general";
import { getAppointments, saveTurn } from "@/actions/schedule";
import { toast, useDialog } from "@/hooks";
import { Button, DialogContent, DialogTitle } from "@/components/atoms";
import { validateAppointmentDateTime } from "@/utils/date";
import { DialogAca } from "./dialog-aca";

export const ScheduleView = ({
  professionals,
  specialties,
  offices,
  medicalInsurances,
}: {
  professionals: Professional[];
  specialties: Specialty[];
  offices: Offices[];
  medicalInsurances: MedicalInsurance[];
}) => {
  const {
    schedule: { params },
    user: { id_user: id_user },
    setDataSchedule,
    setCurrentAppointment,
    general_params: { user_params },
  } = useGlobalStore();
  const [changeDataTable, setChangeDataTable] = React.useState<string>("");
  const [formState, setFormState] = React.useState<string>("");
  const [openModalForm, setOpenModalForm] = React.useState<boolean>(false);
  const { openDialog, closeDialog } = useDialog();
  const refresh = async () => {
    setOpenModalForm(false);
    const response = await getAppointments({
      ...params,
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      setDataSchedule({
        data: response,
      });
    }
  };
  const openAppointmentDialog = (onConfirm: () => void) => {
    setFormState("");
    openDialog({
      content: (
        <DialogContent className="min-w-[550px]">
          <DialogTitle>
            Turno seleccionado con fecha y/o hora anterior a la actual.
          </DialogTitle>
          <p className="text-sm">¿Deseas agendar el turno de todas formas?</p>
          <div className="flex justify-end mt-4 gap-x-4">
            <Button variant="destructive" onClick={closeDialog}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                closeDialog();
                onConfirm();
              }}
            >
              Agendar
            </Button>
          </div>
        </DialogContent>
      ),
    });
  };

  const actions = ({
    appointment,
    type,
    attendance = "DEL",
  }: {
    appointment: Appointment;
    type: string;
    attendance: string;
  }) => {
    const setAppointmentState = () => {
      setFormState(type);
      setCurrentAppointment({ current_appointment: appointment });
    };
    const VALIDATE_TIME = validateAppointmentDateTime(appointment);

    const listActions: { [key: string]: any } = {
      delete_appointment: setAppointmentState,
      edit_observation: setAppointmentState,
      disabled_appointment: setAppointmentState,
      new_appointment_new_patient: () => {
        VALIDATE_TIME
          ? openAppointmentDialog(setAppointmentState)
          : setAppointmentState();
      },
      new_appointment_patient_exist: () => {
        VALIDATE_TIME
          ? openAppointmentDialog(setAppointmentState)
          : setAppointmentState();
      },
      new_over_appointment: setAppointmentState,
      change_attendance: async () => {
        setChangeDataTable(appointment.time);

        if (["ACA"].includes(attendance) && user_params?.schedule_aca) {
          return openDialog({
            content: (
              <DialogAca
                appointment={appointment}
                setChangeDataTable={setChangeDataTable}
                attendance={attendance}
                id_user={id_user}
                refresh={refresh}
              />
            ),
          });
        }
        const response = await saveTurn({
          appointment,
          attendance,
          id_user,
          option: "modificarAsistencia",
        });

        if ("error" in response) {
          toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: response?.error,
          });
        } else {
          await refresh();
        }

        setChangeDataTable("");
      },
    };

    return listActions[type]();
  };

  return (
    <>
      <SearchForm
        data={{
          professionals,
          specialties,
          offices,
        }}
        setOpenModalForm={setOpenModalForm}
        actions={actions}
      />
      <ScheduleTable
        medicalInsurances={medicalInsurances}
        actions={actions}
        setOpenModalForm={setOpenModalForm}
        setChangeDataTable={setChangeDataTable}
        changeDataTable={changeDataTable}
        formState={formState}
        openModalForm={openModalForm}
        refresh={refresh}
      />
    </>
  );
};
