"use client";

import { Specialty } from "@/types/general";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: Specialty[];
}> = ({ data }) => {
  return <Data columns={columns({ specialties: data })} data={data} />;
};
