import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/atoms";

export const TooltipComponent = ({
  trigger,
  content,
  classname,
  contentClass,
}: {
  trigger: React.ReactNode;
  content: React.ReactNode;
  classname?: string;
  contentClass?: string;
}) => {
  return (
    <Tooltip>
      <TooltipProvider>
        <TooltipTrigger type="button" className={classname}>
          {trigger}
        </TooltipTrigger>
        <TooltipContent className={contentClass}>{content}</TooltipContent>
      </TooltipProvider>
    </Tooltip>
  );
};
