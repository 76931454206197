import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { Loading } from "@/components/organisms/reports/loading";
import { EmptyState } from "@/components/molecules";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({ data, loading }) => {
  const {
    general_params: { translations, user_params },
    permissions,
    user,
  } = useGlobalStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };

  return (
    <div className="w-auto col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : data.length ? (
        <Data
          columns={Columns({
            translations,
            config: { permissions, USE_USD, is_supervisor: user.is_supervisor },
          })}
          data={data || []}
          config={USE_USD}
        />
      ) : (
        <EmptyState
          title="No hay prestaciones para mostrar"
          copy="Puedes cargar una prestacion"
          className="col-span-full"
        />
      )}
    </div>
  );
};
