import * as React from "react";
import { Combobox } from "@/components/atoms";
import { Specialty } from "@/types/general";

export function SpecialtiesSelect({
  specialties,
  form,
  className,
}: {
  specialties: Specialty[];
  className?: string;
  form: any;
}) {
  if (!specialties || !specialties?.length) return;

  const SPECIALTIES = [
    {
      id_specialty: -1,
      specialty_code: "-1",
      description: "Todas",
      option: null,
      benefits: [],
      professional_values: [],
    },
    ...specialties,
  ];
  const DISABLED_SPECIALTY = ["buscarpordiatodosprof"].includes(
    form.watch("option")
  );

  return (
    <Combobox
      disabled={DISABLED_SPECIALTY}
      form={form}
      data={SPECIALTIES}
      dataKey="id_specialty"
      value="description"
      placeholder="Busca una especialidad"
      title="Especialidad"
      className={className}
    />
  );
}
