import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_movement: z.number(),
  id_bank: z.number(),
  id_user: z.number().nullable(),
  date: z.date(),
  concept: z.string(),
  amount: z.coerce.number(),
  type: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
