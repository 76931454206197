import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { deleteAbsencesConfig } from "@/actions/professionals";
import { useGlobalStore } from "@/providers/global-provider";
import { Holyday, Professional } from "@/types/general";

export const DeleteAbscence = ({
  abscence,
  professional,
}: {
  abscence: Holyday;
  professional?: Professional;
}) => {
  const { loading, startLoading, stopLoading } = useGlobalStore();
  const { closeDialog } = useDialog();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteAbsencesConfig({
      ...abscence,
      id_professional: professional?.id_professional,
      name: professional?.name,
    });
    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Ausencia eliminada con éxito",
        description: "La ausencia se eliminó correctamente.",
      });
      closeDialog();
    }
    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar ausencia</DialogTitle>
      </DialogHeader>
      <span>
        Estás por eliminar una ausencia y esta acción es irreversible.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
};
