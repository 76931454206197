"use client";

import React from "react";
import classNames from "classnames";
import {
  HiDotsVertical,
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import { useDialog } from "@/hooks";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
} from "@/components/atoms";
import {
  NewMedicalInsurancePlansForm,
  DeletePlanForm,
} from "@/components/organisms/forms";
import { MedicalInsurance, Plan } from "@/types/general";

type RowDataT = {
  original: any;
};

const PlansDropdown = ({
  selected,
  medicalInsurance,
}: {
  selected: Plan;
  medicalInsurance: MedicalInsurance;
}) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() =>
              openDialog({
                content: (
                  <NewMedicalInsurancePlansForm
                    selected={selected}
                    medicalInsurance={medicalInsurance}
                  />
                ),
              })
            }
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() =>
              openDialog({
                content: (
                  <DeletePlanForm
                    selected={selected}
                    medicalInsurance={medicalInsurance}
                  />
                ),
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = ({ selected }: { selected: MedicalInsurance }) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <PlansDropdown selected={row.original} medicalInsurance={selected} />
      ),
    },
    {
      accessorKey: "code_plan",
      header: "Código",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { code_plan } = row.original;

        return <Badge className="w-auto 2xl:ml-3">{code_plan}</Badge>;
      },
    },
    {
      accessorKey: "name",
      header: "Plan",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { name } = row.original;

        return (
          <p className="font-semibold max-w-48 text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
            {name}
          </p>
        );
      },
    },
    {
      accessorKey: "disabled",
      header: "Habilitado",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { disabled } = row.original;

        return (
          <p
            className={classNames(
              "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
              {
                "text-red-500": disabled,
                "text-green-500": !disabled,
              }
            )}
          >
            {!disabled ? (
              <HiOutlineCheckCircle className="size-5" />
            ) : (
              <HiOutlineBan className="size-5" />
            )}
          </p>
        );
      },
    },
  ];
};
