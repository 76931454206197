"use client";

import * as React from "react";
import classNames from "classnames";
import { LuChevronRight as ChevronRight } from "react-icons/lu";
import {
  Card,
  Badge,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  Sheet,
  SheetTrigger,
} from "@/components/atoms";
import { getSheets, getTables } from "@/components/molecules";
import { BirthdayPatient, DailiesDataPatient } from "@/types/statistics";

export function DailiesStatistics({
  device,
  dailies,
}: {
  device: string;
  dailies: {
    title: string;
    data: BirthdayPatient[] | DailiesDataPatient[];
  }[];
}) {
  return device === "mobile" ? (
    <div className="mt-10 mb-5">
      <p className="text-lg font-semibold text-slate-900 text-left mb-2">
        Estadísticas diarias
      </p>
      <Card>
        {dailies?.length > 0 &&
          dailies.map(
            (
              s: {
                data: BirthdayPatient[] | DailiesDataPatient[];
                title: string;
              },
              index: number
            ) => (
              <Sheet key={index}>
                <SheetTrigger asChild className="lg:hidden">
                  <div className="flex justify-between gap-x-4 items-center border-b last:border-0 p-4">
                    <div className="flex truncate items-center gap-x-4">
                      <p
                        className={classNames(
                          "font-bold text-lg",
                          s.data.length > 0 ? "text-blue-500" : "text-slate-400"
                        )}
                      >
                        {s.data.length}
                      </p>
                      <p className="text-xs text-slate-700 text-ellipsis overflow-x-hidden">
                        {s.title}
                      </p>
                    </div>
                    <ChevronRight
                      className={classNames(
                        "w-5 h-5",
                        s.data.length > 0 ? "text-blue-500" : "text-slate-400"
                      )}
                    />
                  </div>
                </SheetTrigger>
                {getSheets({ data: s.data, number: index })}
              </Sheet>
            )
          )}
      </Card>
    </div>
  ) : (
    <div className="my-10 flex flex-col gap-y-4">
      <h3 className="font-semibold text-2xl text-slate-900">
        Estadísticas diarias
      </h3>
      <Card className="grid grid-cols-12">
        {dailies?.length > 0 &&
          dailies.map(
            (
              s: {
                data: BirthdayPatient[] | DailiesDataPatient[];
                title: string;
              },
              index: number
            ) => (
              <div
                key={index}
                className={classNames(
                  "border-r last:border-r-0 col-span-3 flex flex-col items-center p-11",
                  { "pointer-events-none": s.data.length <= 0 }
                )}
              >
                <p
                  className={classNames(
                    "font-semibold text-4xl mb-4",
                    s.data.length > 0 ? "text-blue-500" : "text-slate-400"
                  )}
                >
                  {s.data.length}
                </p>
                <p className="text-center text-slate-700 text-base mb-4 font-normal">
                  {s.title}
                </p>
                <Dialog>
                  <DialogTrigger asChild>
                    <Badge
                      className={classNames(
                        "cursor-pointer py-1 text-base border-1 border-blue-600/10",
                        { "!bg-slate-50 text-slate-500": s.data.length <= 0 }
                      )}
                    >
                      Ver más
                    </Badge>
                  </DialogTrigger>
                  <DialogContent className="!max-w-7xl">
                    <DialogHeader>
                      <DialogTitle>
                        {getTables({ data: s.data, number: index }).title}
                      </DialogTitle>
                    </DialogHeader>
                    {getTables({ data: s.data, number: index }).component()}
                  </DialogContent>
                </Dialog>
              </div>
            )
          )}
      </Card>
    </div>
  );
}
