import {
  LuStar,
  LuArrowBigUpDash,
  LuRocket,
  LuBuilding,
  LuImage,
  LuPill,
} from "react-icons/lu";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Account } from "@/types/my-account";

export const tabs = [
  {
    text: "Datos generales",
    value: "general_data",
  },
  {
    text: "Suscripción",
    value: "suscription",
  },
  {
    text: "Productos",
    value: "products",
  },
];

export const renderParams = ({
  account,
  web_user,
  product,
  plan,
  isSuscribed,
}: {
  account: Account;
  web_user: string;
  product?: string;
  plan?: string;
  isSuscribed: boolean;
}) => {
  const fullFeatures = ["full", "standard", "premium"].includes(
    account?.plan_version
  );
  const params: {
    [key: string]: {
      href: string;
      text: string;
      disabled: boolean;
    };
  } = {
    cloud: {
      href: `https://wa.me/+5491128504255?text=Hola%20,%20quisiera%20contratar%20el%20servicio%20de%20Almacenamiento,%20mi%20email%20es:%20${
        account?.email || "-"
      }%20y%20mi%20usuario%20Bilog%20es:%20${web_user}`,
      text: "Contratar ahora",
      disabled: isSuscribed,
    },
    recepies: {
      href: `https://wa.me/+5491128504255?text=Hola%20,%20quisiera%20contratar%20el%20servicio%20de%20Recetas,%20mi%20email%20es:%20${
        account?.email || "-"
      }%20y%20mi%20usuario%20Bilog%20es:%20${web_user}`,
      text: "Contratar ahora",
      disabled: isSuscribed,
    },
    whatsapp: {
      href: `https://wa.me/+5491128504255?text=Hola%20,%20quisiera%20contratar%20el%20servicio%20de%20Whatsapp,%20mi%20email%20es:%20${
        account?.email || "-"
      }%20y%20mi%20usuario%20Bilog%20es:%20${web_user}`,
      text: "Contratar ahora",
      disabled: isSuscribed,
    },
    premium: {
      href: `https://wa.me/+5491128504255?text=Hola%20quiero%20modificar%20mi%20suscripcion,%20mi%20email%20es:%20${
        account?.email || "-"
      }%20y%20mi%20usuario%20Bilog%20es:%20${web_user}`,
      text: fullFeatures ? "Plan actual" : "Modificar mi suscripción",
      disabled: fullFeatures,
    },
    freemium: {
      href: `https://signup.bilog.com.ar/checkout?email=${
        account?.email || "-"
      }&webuser=${web_user}`,
      text: "Mejorar plan",
      disabled: fullFeatures,
    },
    lender: {
      href: `https://signup.bilog.com.ar/checkout?email=${
        account?.email || "-"
      }&webuser=${web_user}`,
      text: "Activar suscripción",
      disabled: fullFeatures,
    },
  };

  return params[plan && !product ? plan : product ?? "default"];
};

export const products: {
  [key: string]: {
    title: string;
    description: string;
    price: { currency: string; money: number } | null;
    time: string | null;
    btnText: string | null;
    icon: React.ReactNode;
  };
} = {
  freemium: {
    title: "Gratuito",
    description:
      "Disfrutá de la mejor agenda mensual, odontograma digital, historia clínica onlina para un solo usuario, de forma gratuíta.",
    price: { currency: "U$D", money: 0 },
    btnText: null,
    icon: <LuRocket className="size-5 mr-1" />,
    time: " / mes",
  },
  premium: {
    title: "Premium",
    description:
      "Potencia tu negocio con la agenda sin límites, gestión de profesionales y la carga ilimitada de pacientes.",
    price: { currency: "U$D", money: 25 },
    time: " / mes",
    btnText: null,
    icon: <LuStar className="size-5 mr-1" />,
  },
  lender: {
    title: "Soy prestador",
    description:
      "Si eres prestador de una gerenciadora, asociación o circulo odontológico que utiliza el Auditor de Bilog, puedes activar tu suscripción Prestador.",
    price: null,
    time: null,
    btnText: "Activar suscripción ",
    icon: <LuBuilding className="size-5 mr-1" />,
  },
  whatsapp: {
    title: "Whatsapp",
    description:
      "Reducí el ausentismo de tu clínica o consultorio con la nueva función de recordatorio de turnos con Whats App.",
    price: { currency: "U$D", money: 0 },
    time: " / mes",
    btnText: null,
    icon: <AiOutlineWhatsApp className="size-5 mr-1" />,
  },
  cloud: {
    title: "Galeria",
    description:
      "Sube todos los archivos de un paciente a la nube para tenerlo disponible en todo momento y lugar.",
    price: { currency: "U$D", money: 25 },
    time: " / mes",
    btnText: null,
    icon: <LuImage className="size-5 mr-1" />,
  },
  recepies: {
    title: "Recetas",
    description:
      "Emite recetas electrónicas desde la comodidad de tu celular y computadora.",
    price: { currency: "U$D", money: 25 },
    time: " / mes",
    btnText: null,
    icon: <LuPill className="size-5 mr-1" />,
  },
};

export const optionsCard = (planVersion: string = "default") => {
  return {
    premium: {
      description: "¡Eres Premium!",
    },
    freemium: {
      description: "Suscripción gratuita",
      buttonText: "Pasarme a premium",
    },
    default: {
      description: `Suscripción ${planVersion || ""}`,
    },
  }[planVersion];
};

export const getAlertContent = (planVersion: string) => {
  const content: {
    [key: string]: {
      title: string;
      description: string;
      icon: React.ReactNode;
      variant: "premium" | "freemium";
      can_upgrade: boolean;
    };
  } = {
    premium: {
      title: `Sos ${planVersion}!`,
      description: `Tu suscripción actual es ${planVersion}. Disfruta de todas las ventajas y beneficios que tenemos para vos.`,
      icon: <LuStar className="size-4" />,
      variant: "premium",
      can_upgrade: false,
    },
    freemium: {
      title: `Suscripción ${planVersion}`,
      description: `Tu suscripción actual es ${planVersion}. Actualiza tu plan para aprovechar al máximo todas las funciones de Bilog.`,
      icon: <LuArrowBigUpDash className="size-4" />,
      variant: "freemium",
      can_upgrade: true,
    },
  };

  if (!content[planVersion]) {
    return content.freemium;
  }

  if (["premium", "standard", "full"].includes(planVersion)) {
    return content.premium;
  } else {
    return content.freemium;
  }
};
