"use client";

import React from "react";
import { BirthdayPatient } from "@/types/statistics";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{ data: BirthdayPatient[] }> = ({ data }) => (
  <Data columns={columns} data={data} />
);
