import { Benefit } from "@/types/general";
import { Budget } from "@/types/patients";

type State = {
  budget_selected: Budget | null;
  service_performed: Benefit | null;
};

type Actions = {
  setBudgetSelected: (budget: Budget | null) => void;
  setServicePerformed: (benefit: Benefit | null) => void;
};

type PatientBudgetStore = State & Actions;

export const INIT_STATE: State = {
  budget_selected: null,
  service_performed: null,
};

export const BudgetsSlice = (
  set: (partial: Partial<PatientBudgetStore>) => void,
  get: () => PatientBudgetStore
): PatientBudgetStore => ({
  ...INIT_STATE,
  setBudgetSelected: (budget: Budget | null) => {
    const state = get();

    set({
      ...state,
      budget_selected: budget,
    });
  },
  setServicePerformed: (benefit: Benefit | null) => {
    const state = get();

    set({
      ...state,
      service_performed: benefit,
    });
  },
});
