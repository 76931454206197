import * as React from "react";
import { Combobox } from "@/components/atoms";
import { Offices } from "@/types/general";

export function OfficesSelect({
  offices,
  className,
  form,
}: {
  form: any;
  offices: Offices[];
  className?: string;
}) {
  if (!offices || !offices.length) return;

  return (
    <Combobox
      form={form}
      data={offices}
      dataKey="id_office"
      value="name"
      placeholder="Busca una sucursal"
      title="Sucursal"
      className={className}
    />
  );
}
