"use client";

import React from "react";
import { z } from "zod";
import Link from "next/link";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { LuChevronLeft } from "react-icons/lu";
import {
  Button,
  buttonVariants,
  Card,
  CardTitle,
  Checkbox,
  Separator,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FreemiumForm,
  LaboratoriesSelected,
} from "@/components/molecules";
import { ListCopyTable } from "@/components/organisms/tables";
import { Laboratory } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { toast, useKeypress, useDialog } from "@/hooks";
import { copyArticles } from "@/actions/laboratories";
import { formSchema, resolver } from "./schema";

export const ListCopyForm = ({
  id,
  laboratories,
}: {
  id: string | undefined;
  laboratories: Laboratory[];
}) => {
  const {
    user: { plan_version },
  } = useGlobalStore();
  const { openDialog } = useDialog();
  const LABORATORY_DESTINATION = id
    ? laboratories?.find((p: Laboratory) => p.id_laboratory === Number(id))
        ?.id_laboratory
    : laboratories?.[0]?.id_laboratory;
  const { startLoading, stopLoading, loading } = useGlobalStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      laboratory_origin: laboratories?.[0]?.id_laboratory,
      laboratory_destination: LABORATORY_DESTINATION,
      selected_articles: [],
      replace_destination: false,
    },
  });

  const getData = (select: "laboratory_origin" | "laboratory_destination") => {
    const ID_LAB = form.watch(select);
    const FIND_LAB = laboratories?.find(
      (l: Laboratory) => l.id_laboratory === ID_LAB
    );
    return FIND_LAB;
  };

  const onSubmit = async (copy: z.infer<typeof formSchema>) => {
    if (["lender", "freemium"].includes(plan_version)) {
      return openDialog({
        content: <FreemiumForm />,
      });
    }

    startLoading();
    const response = await copyArticles(copy);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "La copia se realizó con éxito.",
        description: `Se copio ${copy.selected_articles.length} trabajos.`,
      });
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="grid grid-cols-12 gap-3 mx-auto max-h-[calc(100vh-90px)]">
          <div className="col-span-full flex flex-row w-full gap-2 items-center">
            <Link
              href="/dashboard/configuration/tables/laboratories"
              className={classNames(
                buttonVariants({ variant: "outline", size: "icon" })
              )}
            >
              <LuChevronLeft className="size-4" />
              <span className="sr-only">Back</span>
            </Link>
            <CardTitle className="!mt-0">Copia de trabajos</CardTitle>
          </div>
          <div className="col-span-full flex flex-row justify-between gap-5">
            <div className="flex flex-col w-full gap-2">
              <div className="grid grid-rows-2">
                <div className="flex flex-row gap-3 items-center">
                  <div className="text-center border-2 border-slate-600 rounded-full col-span-2 size-7 flex items-center justify-center text-slate-600">
                    <span className="font-extrabold">1</span>
                  </div>
                  <p className="font-bold">Datos de obra social de origen</p>
                </div>
                <span className="font-normal text-slate-500">
                  Selecciona el laboratorio de origen.
                </span>
              </div>
              <div className="flex gap-x-4">
                <LaboratoriesSelected
                  label="Laboratorio de origen"
                  form={form}
                  laboratories={laboratories}
                  fieldName="laboratory_origin"
                  className="w-1/2"
                />
              </div>
            </div>
            <Separator orientation="vertical" />
            <div className="flex flex-col w-full gap-2">
              <div className="grid grid-rows-2">
                <div className="flex flex-row gap-3 items-center">
                  <div className="text-center border-2 border-slate-600 rounded-full col-span-2 size-7 flex items-center justify-center text-slate-600">
                    <span className="font-extrabold">2</span>
                  </div>
                  <p className="font-bold">Datos de laboratorio de destino</p>
                </div>
                <span className="font-normal text-slate-500">
                  Selecciona el laboratorio a donde se copiarán los trabajos.
                </span>
              </div>
              <div className="flex justify-between gap-x-4">
                <div className="w-1/2">
                  <LaboratoriesSelected
                    label="Laboratorio de destino"
                    form={form}
                    laboratories={laboratories}
                    fieldName="laboratory_destination"
                  />
                </div>
                <FormField
                  control={form.control}
                  name="replace_destination"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start gap-2 w-1/2 ">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="flex flex-col !mt-0">
                        <FormLabel className="!mb-0 !font-bold">
                          Remplazar trabajos duplicados
                        </FormLabel>
                        <p className="font-normal text-slate-500">
                          Remplazar los datos como códigos de trabajos
                          repetidos.
                        </p>
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-span-full grid grid-cols-2 gap-x-4">
            <ListCopyTable
              data={getData("laboratory_origin")}
              table="origin"
              form={form}
            />
            <ListCopyTable
              data={getData("laboratory_destination")}
              table="destination"
            />
          </div>
          <div className="col-span-full flex justify-end">
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button type="submit">Copiar</Button>
            )}
          </div>
        </Card>
      </form>
    </Form>
  );
};
