import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_patient: z.number(),
  id_payment: z.number(),
  id_professional: z.number(),
  date: z.date(),
  concept: z.string(),
  id_invoice: z.number(),
  id_invoice_type: z.number(),
  id_payment_method: z.number().optional().nullable(),
  invoice_number: z.string().optional(),
  to_pay: z.coerce.number(),
  paid: z.coerce.number(),
  is_usd: z.boolean(),
  change_amount_professional: z.boolean().optional(),
  amount_to_pay_professional: z.coerce.number().optional(),
  amount_to_pay_professional_percentage: z.coerce.number().optional(),
  id_tax: z.number(),
  includes_budget: z.boolean(),
  id_budget: z.number(),
  cancel: z.boolean().optional(),
  id_user: z.number().nullable().optional(),
  id_office: z.number().nullable(),
  id_medical_insurance: z.number().optional().nullable(),
  observations: z.string().optional(),
  interest: z.coerce.number(),
  interest_percentage: z.coerce.number(),
  with_installments: z.boolean().optional(),
  id_bank: z.number().optional().nullable(),
  id_liquidation_professional: z.boolean().nullable(),
  amount_liquidation_professional: z.number().nullable(),
  id_electronic_invoice: z.number().nullable(),
  pdf_url: z.string().optional(),
  invoice_state: z.string().optional(),
  afip_message: z.string().optional(),
  save_register: z.boolean().optional(),
  id_card: z.number().optional().nullable(),
  card_number: z.string().optional(),
  card_cvv: z.string().optional(),
  paycheck_date: z.date().optional(),
  paycheck_number: z.coerce.number().optional(),
  bank_emitter: z.string().optional(),
  id_installment: z.number().optional().nullable(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
