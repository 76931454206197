import classNames from "classnames";
import { format } from "date-fns";
import { FaWhatsapp } from "react-icons/fa";
import { LuCalendar, LuClock, LuContact, LuEye } from "react-icons/lu";
import { BadgeSelect, TooltipComponent } from "@/components/molecules";
import { ATTENDANCE_OPTIONS_REMINDERS } from "@/libs/schedule";
import { ListSentWhatsapp } from "@/types/reminders";

export const columns = (
  handleSetAssistance: (message: ListSentWhatsapp, option: string) => void
) => {
  return [
    {
      accessorKey: "paciente",
      header: "Paciente",
      cell: ({ row }: { row: any }) => {
        const { name_patient } = row.original;

        return (
          <div className="text-base flex items-center gap-1 font-semibold leading-6 text-slate-800 my-1">
            <LuContact className="size-6 text-slate-500" />{" "}
            <p>{name_patient}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "phone",
      header: "Teléfono",
      cell: ({ row }: { row: any }) => {
        const { phone } = row.original;

        return (
          <div className="flex items-center gap-x-2 group">
            <p className="leading-6 text-blue-500 my-1 group-hover:underline">
              {phone}
            </p>
            <TooltipComponent
              trigger={
                <FaWhatsapp
                  className="text-green-500 h-5 w-5"
                  onClick={() => window?.open(`https://wa.me/${phone}`)}
                />
              }
              content={<p>Enviar Whatsapp</p>}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "message",
      header: "Mensaje enviado",
      cell: ({ row }: { row: any }) => {
        const { message } = row.original;

        return (
          <TooltipComponent
            trigger={
              <p className="leading-6 text-slate-800 my-1 flex items-center gap-x-2 hover:underline">
                <LuEye className="text-blue-500 size-6" />
                Ver mensaje
              </p>
            }
            content={
              <div className="w-96">
                <p className="font-bold text-slate-">Mensaje</p>
                <p className="text-slate-500">{message}</p>
              </div>
            }
          />
        );
      },
    },
    {
      accessorKey: "response",
      header: "Respuesta del paciente",
      cell: ({ row }: { row: any }) => {
        const { message, confirm, date, processed } = row.original;
        const NEW_DATE = new Date(date);
        const FORMATTED_DATE = format(NEW_DATE, "dd/MM/yyyy");
        const FORMATTED_TIME = format(NEW_DATE, "HH:mm");

        return (
          message.length > 1 && (
            <>
              <p
                className={classNames(
                  "leading-6 my-1 font-semibold",
                  confirm || message[message.length - 1] === "Confirmo"
                    ? " text-green-500"
                    : "text-red-500"
                )}
              >
                {message.length > 1
                  ? message[message.length - 1]
                  : confirm
                  ? "Confirmo"
                  : "Cancelo"}
              </p>
              <div className="flex text-slate-500 items-center">
                <LuCalendar className="mr-1" />
                {FORMATTED_DATE}
                <LuClock className="ml-2 mr-1" />
                {FORMATTED_TIME}
              </div>
            </>
          )
        );
      },
    },
    {
      accessorKey: "attendance",
      header: "Estado del turno",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { processed, confirm } = row.original;
        const PROCESS_VALUE = processed ? (confirm ? "CON" : "ACA") : null;
        const ATTENDANCE = ATTENDANCE_OPTIONS_REMINDERS.find(
          (att) => PROCESS_VALUE === att.id
        );

        return (
          <BadgeSelect
            text={ATTENDANCE?.text || "Sin confirmar"}
            className={classNames(
              ATTENDANCE?.styles,
              "lg:w-full",
              "md:min-w-[160px]",
              processed ? "cursor-none justify-center" : "md:justify-between"
            )}
            options={ATTENDANCE_OPTIONS_REMINDERS}
            disabled={processed}
            onSelect={(e) => {
              handleSetAssistance(row.original, e.target.id);
            }}
          />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
  ];
};
