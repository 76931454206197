import React from "react";
import classNames from "classnames";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { LuChevronDown } from "react-icons/lu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms";

export function Data<TData, TValue>({
  columns,
  data,
}: {
  columns: any;
  data: any;
}) {
  const table = useReactTable({
    data,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  return (
    <div className="rounded-md mx-auto w-full bg-white border">
      <Table className="rounded-md max-h-[calc(100vh-115px)]">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="sticky top-0 bg-white z-10 shadow"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div className="flex items-center">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row: any) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="hover:cursor-pointer border-slate-200 border-b-px"
              >
                {row.getVisibleCells().map((cell: any) => (
                  <TableCell
                    onClick={row.getToggleExpandedHandler()}
                    key={cell.id}
                    className={classNames(
                      cell.getIsGrouped()
                        ? "bg-gray-50"
                        : cell.getIsAggregated()
                        ? "bg-blue-50"
                        : cell.getIsPlaceholder()
                        ? "bg-gray-50"
                        : "white"
                    )}
                  >
                    {cell.getIsGrouped() ? (
                      <div className="text-left flex items-center text-slate-600 gap-x-1">
                        ({row.subRows.length})
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        <LuChevronDown
                          className={classNames(
                            "size-5 transform transition-transform ease-in-out duration-200",
                            row.getIsExpanded() ? "rotate-180" : "rotate-0"
                          )}
                        />
                      </div>
                    ) : cell.getIsAggregated() ? (
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Sin resultados.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
