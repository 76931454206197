import { INITIAL_STATE } from "@/libs/reports";

type ReportData = any;

type State = {
  reports: {
    [key: string]: ReportData;
  };
};

type Actions = {
  setDataReports: (payload: SetDataReportsPayload) => void;
};

type ReportsStore = State & Actions;

type SetDataReportsPayload = {
  data: ReportData[];
  params: any;
  section: string;
};

export const ReportsSlice = (
  set: (partial: Partial<ReportsStore>) => void,
  get: () => ReportsStore
) => ({
  reports: {
    auditor: INITIAL_STATE,
  },
  setDataReports: ({ data, params, section }: SetDataReportsPayload) => {
    set({
      reports: {
        ...get().reports,
        [section]: {
          ...get().reports[section],
          params,
          data,
        },
      },
    });
  },
});
