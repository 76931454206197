"use client";

import React from "react";
import { z } from "zod";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { Button, Card, CardTitle, Checkbox, Input } from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FreemiumForm,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { toast, useDialog, useKeypress } from "@/hooks";
import { Laboratory } from "@/types/general";
import { createOrUpdateLaboratory } from "@/actions/laboratories";
import { formSchema, resolver } from "./schema";

export const LaboratoriesForm = ({
  laboratory,
}: {
  laboratory?: Laboratory;
}) => {
  const router = useRouter();
  const { openDialog } = useDialog();
  const {
    startLoading,
    stopLoading,
    loading,
    user: { plan_version },
  } = useGlobalStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_laboratory: laboratory?.id_laboratory || 0,
      name: laboratory?.name || "",
      contact: laboratory?.contact || "",
      address: laboratory?.address || "",
      city: laboratory?.city || "",
      state: laboratory?.state || "",
      phone: laboratory?.phone || "",
      email: laboratory?.email || "",
      balance: laboratory?.balance || 0,
      balance_usd: laboratory?.balance_usd || 0,
      tax_number: laboratory?.tax_number || "",
      observations: laboratory?.observations || "",
      enabled: laboratory?.enabled ?? true,
      rowversion: laboratory?.rowversion || "",
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (["lender", "freemium"].includes(plan_version)) {
      return openDialog({
        content: <FreemiumForm />,
      });
    }
    startLoading();
    const response = await createOrUpdateLaboratory(values as Laboratory);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "El laboratorio se ha guardado con éxito.",
        description: "Los datos se veran reflejados en la tabla.",
      });
      await router.replace(
        `/dashboard/configuration/tables/laboratories/new-laboratory?id=${response.id_laboratory}`
      );
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form className="w-full h-[calc(100vh-200px)] overflow-y-auto flex flex-col !justify-between">
        <Card className="w-full grid grid-cols-12 gap-4 p-4">
          <div className="col-span-full h-auto flex justify-between items-start">
            <CardTitle className="flex items-center gap-2">
              Datos de laboratorio
            </CardTitle>
          </div>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Nombre</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Dirección</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Localidad</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Provincia</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Teléfono/s</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="balance"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Saldo actual</FormLabel>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tax_number"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>CUIT</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="observations"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Observaciones</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enabled"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-start justify-center">
                <FormLabel className="!mb-0 !text-base !font-bold">
                  Laboratorio habilitado
                </FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Card>
      </form>
      <div className="col-span-12 w-full pb-4 flex justify-end gap-6">
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            onClick={form.handleSubmit(onSubmit)}
            className="px-10 h-10 bg-blue-600"
          >
            Guardar
          </Button>
        )}
      </div>
    </Form>
  );
};
