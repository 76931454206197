import React from "react";

const checkUserName = (name: string) => ["demo", "demo1"].includes(name);

export const useIsDemo = (userName: string) => {
  const [isDemo, setIsDemo] = React.useState(() => checkUserName(userName));

  React.useEffect(() => {
    setIsDemo(checkUserName(userName?.toLowerCase()));
  }, [userName]);

  return { isDemo };
};
