import React from "react";
import classNames from "classnames";
import {
  GroupingState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { LuGroup, LuChevronDown } from "react-icons/lu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableFooter,
  TableRow,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";

type Totals = {
  owed: number;
  paid: number;
  total_amount: number;
};

export function Data<TData, TValue>({
  section,
  columns,
  data: { data, totals },
}: {
  section: "patient_payments" | "incomes" | "expenses";
  columns: any;
  data: any;
}) {
  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "professional",
      desc: false,
    },
  ]);
  const table = useReactTable({
    data,
    columns,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      grouping,
      sorting,
    },
  });

  return (
    <div className="rounded-md mx-auto w-full bg-white border">
      <Table className="rounded-md max-h-[calc(100vh-155px)]">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="sticky top-0 bg-white z-10 shadow"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div className="flex items-center">
                        {header.column.getCanGroup() ? (
                          <div
                            onClick={header.column.getToggleGroupingHandler()}
                            className="hover:cursor-pointer hover:underline flex gap-x-2"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <LuGroup
                              className={classNames("size-5", {
                                "text-slate-800": header.column.getIsGrouped(),
                                "text-slate-400": !header.column.getIsGrouped(),
                              })}
                            />
                          </div>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row: any) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="hover:cursor-pointer border-slate-200 border-b-px"
              >
                {row.getVisibleCells().map((cell: any) => (
                  <TableCell
                    onClick={row.getToggleExpandedHandler()}
                    key={cell.id}
                    className={classNames(
                      cell.getIsGrouped()
                        ? "bg-gray-50"
                        : cell.getIsAggregated()
                        ? "bg-blue-50"
                        : cell.getIsPlaceholder()
                        ? "bg-gray-50"
                        : "white"
                    )}
                  >
                    {cell.getIsGrouped() ? (
                      <div className="text-left flex items-center text-slate-600 gap-x-1">
                        ({row.subRows.length})
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        <LuChevronDown
                          className={classNames(
                            "size-5 transform transition-transform ease-in-out duration-200",
                            row.getIsExpanded() ? "rotate-180" : "rotate-0"
                          )}
                        />
                      </div>
                    ) : cell.getIsAggregated() ? (
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Sin resultados.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooters
          totals={totals}
          table={table}
          section={section}
          data={data}
        />
      </Table>
    </div>
  );
}

// TODO: Move this footers to a separate file
const TableFooters = ({
  totals,
  section,
  data,
}: {
  totals: Totals;
  section: string;
  table: any;
  data: any;
}) => {
  const IS_PAYMENTS = ["patient_payments"].includes(section);
  const IS_EXPENSES = ["expenses"].includes(section);
  const COL_SPANS: { [key: string]: number } = {
    patient_payments: 6,
    incomes: 5,
    expenses: 6,
  };

  return (
    <TableFooter>
      <TableRow className="bg-slate-100 w-full sticky bottom-0 z-10 text-base">
        <TableCell colSpan={COL_SPANS[section]} className="font-normal">
          <p>Total</p>
          <p className="text-sm text-slate-400">Registros {data.length}</p>
        </TableCell>
        {IS_PAYMENTS && (
          <>
            <TableCell className="font-normal text-right">
              <p>Adeuda</p>
              <p
                className={classNames("text-sm", {
                  "text-red-500": totals.owed > 0,
                  "text-slate-600": totals.owed === 0,
                })}
              >
                {numberToCurrency(totals.owed)}
              </p>
            </TableCell>
            <TableCell className="font-normal text-right">
              <p>Pagado</p>
              <p
                className={classNames("text-sm", {
                  "text-green-500": totals.paid > 0,
                  "text-slate-600": totals.paid === 0,
                })}
              >
                {numberToCurrency(totals.paid)}
              </p>
            </TableCell>
          </>
        )}
        {!IS_PAYMENTS && (
          <TableCell className="font-normal text-right">
            <p
              className={classNames("text-sm", {
                "text-red-500": IS_EXPENSES || totals.total_amount < 0,
                "text-green-500": totals.total_amount > 0,
                "text-slate-600": totals.total_amount === 0,
              })}
            >
              {numberToCurrency(totals.total_amount)}
            </p>
          </TableCell>
        )}
        <TableCell colSpan={13} />
      </TableRow>
    </TableFooter>
  );
};
