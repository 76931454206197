import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  what_template_appointment: z.string(),
  what_template_birthday: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
