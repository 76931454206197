import { Card, Skeleton } from "@/components/atoms";

export const Loading = () => {
  const SPANS = [2, 2, 4, 4, 2, 2, 4, 4, 2, 3, 4, 3, 2, 4, 3, 3, 2];

  return (
    <Card className="w-full grid grid-cols-12 gap-4 p-4 overflow-hidden h-[calc(100vh-190px)]">
      <div className="col-span-full w-full flex justify-between gap-4 pb-12">
        <div>
          <Skeleton className="h-6 w-48 mb-2" />
          <Skeleton className="h-4 w-64" />
        </div>
        <Skeleton className="h-6 w-6 rounded-full" />
      </div>
      {SPANS.map((span) => (
        <Skeleton key={span} className={`h-10 col-span-${SPANS[span]}`} />
      ))}
    </Card>
  );
};
