import { Combobox } from "@/components/atoms";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Supplier } from "@/types/general";

export const Suppliers = ({
  form,
  suppliers,
  className,
}: {
  form: any;
  className?: string;
  suppliers: Supplier[];
}) => {
  // Make the validation inside the component,
  // if there are no suppliers, return null.
  if (!suppliers || !suppliers?.length) return;

  return (
    <div className={className}>
      <FormLabel className="text-base">Proveedores</FormLabel>
      <FormItem>
        <FormControl>
          <Combobox
            form={form}
            data={suppliers}
            dataKey="id_supplier"
            value="name"
            placeholder="Busca un proveedor"
            title="Proveedores"
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    </div>
  );
};
