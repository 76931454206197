"use client";

import React from "react";
import { EmptyState } from "@/components/molecules";
import { MedicalInsurance } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  medicalInsurances: MedicalInsurance[];
}> = ({ medicalInsurances }) => {
  return medicalInsurances.length > 0 ? (
    <Data columns={Columns()} data={medicalInsurances} />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
