import React from "react";
import { z } from "zod";
import { formSchema } from "@/components/organisms/forms/patients/budgets/schema";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table = <TData,>({
  data,
  action,
}: {
  data: z.infer<typeof formSchema>["list_benefits"];
  action: (
    row: z.infer<typeof formSchema>["selected_benefit"],
    key: string
  ) => void;
}) => {
  return (
    <div className="mx-auto w-full">
      <Data columns={Columns({ action })} data={data} />
    </div>
  );
};
