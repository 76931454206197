"use client";

import { LuLoaderCircle } from "react-icons/lu";
import { Button } from "@/components/atoms";

export function ButtonLoading({ className }: { className?: string }) {
  return (
    <Button disabled className={className}>
      <LuLoaderCircle className="mr-2 h-4 w-4 animate-spin" />
      Cargando...
    </Button>
  );
}
