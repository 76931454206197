type State = {
  loading: boolean;
  patient_data_loading: boolean;
  searchBarRef: React.RefObject<HTMLInputElement> | null;
};

type Actions = {
  startLoading: () => void;
  stopLoading: () => void;
  patientDataStartLoading: () => void;
  patientDataStopLoading: () => void;
  setSearchBarRef: (ref: React.RefObject<HTMLInputElement>) => void;
};

type CommonsStore = State & Actions;

export const initState: State = {
  loading: false,
  patient_data_loading: false,
  searchBarRef: null,
};

export const CommonsSlice = (
  set: (partial: Partial<CommonsStore>) => void
) => ({
  ...initState,
  startLoading: () => {
    set({
      loading: true,
    });
  },
  stopLoading: () => {
    set({
      loading: false,
    });
  },
  patientDataStartLoading: () => {
    set({
      patient_data_loading: true,
    });
  },
  patientDataStopLoading: () => {
    set({
      patient_data_loading: false,
    });
  },
  setSearchBarRef: (ref: React.RefObject<HTMLInputElement>) => {
    set({
      searchBarRef: ref,
    });
  },
});
