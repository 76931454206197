import React, { useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Undo } from "lucide-react";
import classNames from "classnames";
import Image from "next/image";
import { LuChevronRight } from "react-icons/lu";
import {
  Button,
  CardDescription,
  CardHeader,
  CardTitle,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Skeleton,
  Textarea,
} from "@/components/atoms";
import { FormLabel } from "@/components/molecules";
import { useDialog, toast } from "@/hooks";
import { getProfessionalSignature } from "@/actions/professionals";

export const Signature = ({ form }: { form: any }) => {
  const { openDialog, closeDialog } = useDialog();
  const sigPad = React.useRef<any>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const clear = () => {
    sigPad.current.clear();
  };
  const undo = () => {
    const data = sigPad.current.toData();
    data && data.pop();
    sigPad.current.fromData(data);
  };
  const save = () => {
    if (!sigPad) return;
    if (sigPad.current?.toData().length <= 0) {
      form.setValue("signature", null);
      sigPad.current.clear();
      document.getElementById("close-dialog-signature")?.click();
      setSignature(null);
      return;
    }

    const signature_data = sigPad.current.toDataURL().split(",")[1];
    form.setValue("signature", signature_data);
    setSignature(signature_data);
    document.getElementById("close-dialog-signature")?.click();
    closeDialog();
  };

  React.useEffect(() => {
    const getSignature = async (id: number) => {
      setLoading(true);
      const response = await getProfessionalSignature(id);

      if ("error" in response) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response.error,
        });
      } else {
        setSignature(response.professionalSignature);
      }
      setLoading(false);
    };

    const id = form.getValues("id_professional");
    if (!id) return;

    void getSignature(id);
  }, []);

  if (loading) return <Skeleton className="h-32 col-span-6" />;

  return (
    <div className="col-span-12">
      <CardHeader className="!p-0">
        <CardTitle className="mt-5">Configuración de sello</CardTitle>
        <CardDescription>
          Utilice el campo de firma para registrar su firma la cual será
          utilizada para recetas y otras funcionalidades.
        </CardDescription>
      </CardHeader>
      <div className="grid grid-cols-12 mt-4 gap-5">
        <div className="col-span-3">
          <FormLabel className="mb-2 block text-sm font-semibold">
            Firma del profesional**
          </FormLabel>
          {signature ? (
            <Image
              alt="signature"
              src={`data:image/png;base64,${signature}`}
              width={140}
              height={140}
              className="h-32 mb-5 w-auto"
            />
          ) : (
            <Textarea
              name="signature"
              placeholder="Realice su firma"
              className="w-full h-24 mt-2 mb-5 shadow-sm px-4 py-2 text-sm ring-1 ring-gray-300 rounded-md cursor-not-allowed"
              disabled
            />
          )}
          <div className="pointer-events-auto">
            <Button
              variant="ghost"
              type="button"
              onClick={() =>
                openDialog({
                  content: (
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Firma del profesional</DialogTitle>
                        <DialogClose />
                      </DialogHeader>
                      <div className="relative top-0 right-0 w-[500px]">
                        <SignaturePad
                          ref={sigPad}
                          options={{
                            minWidth: 1,
                            maxWidth: 3,
                            penColor: "#000",
                          }}
                          canvasProps={{
                            style: {
                              border: "1px solid rgb(209, 213, 219)",
                              borderRadius: "6px",
                            },
                          }}
                        />
                      </div>
                      <DialogFooter>
                        <div
                          onClick={undo}
                          className="text-blue-500 mt-2 flex gap-x-2 cursor-pointer hover:text-blue-600"
                        >
                          Deshacer
                          <Undo />
                        </div>
                        <Button onClick={clear}>Limpiar</Button>
                        <Button onClick={save} className="pointer-events-auto">
                          Guardar
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  ),
                })
              }
            >
              <span>{signature ? "Rehacer firma" : "Crear Firma"}</span>
              <LuChevronRight className="w-5 h-5 min-w-5 min-h-5" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
