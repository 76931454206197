export {
  NewBankDialog,
  NewMovementDialog,
  NewCardDialog,
  NewIncomeDialog,
  NewExpenseDialog,
} from "./dialogs";
export {
  SearchForm,
  FirstTimeForm,
  NewAppointmentPatientExist,
  DeleteAppointment,
  EditObservation,
  NewOverturn,
  DisabledAppointment,
  BenefitsPatientForm,
  PatientForm,
  DeletePatient,
  PaymentsForm,
  PrescriptionsForm,
} from "./forms";
export { BenefitsView } from "./patients/benefits";
export { OdontogramView } from "./patients/odontogram";
export { LoginForm } from "./forms/login/form";
export { Greetings } from "./greetings";
export { DesktopNav } from "./nav/desktop-nav";
export { MobileNav } from "./nav/mobile-nav";
export { GenerateFile } from "./reports/generate-file";
export {
  FinancesGraphics,
  ControlPanelGraphics,
  BenefitsMadeGraphics,
  BudgetsIssuedGraphics,
  DailiesStatistics,
  WeekliesStatistics,
} from "./statistics";
export { ShortCuts } from "./shortcuts";
export { SummaryReports } from "./reports/summary-reports";
export { SearchPatient } from "./search-patient";
export { ScheduleView } from "./schedule";
export * from "./tables";
export {
  MedicalHistoryView,
  PaymentsView,
  PrescriptionsView,
} from "./patients";
export { AppointmentsView } from "./patients/appointments";
export { LaboratoryView } from "./patients/laboratory";
