import React from "react";
import { transformDateToZoneTime } from "@/utils/date";

export const TABS: {
  text: string;
  value: string;
  icon?: React.ReactNode | null;
}[] = [
  { text: "Datos personales", value: "" },
  {
    text: "Prestaciones",
    value: "benefits",
  },
  {
    text: "Odontograma",
    value: "odontogram",
  },

  {
    text: "Historia clínica",
    value: "medical-history",
  },
  {
    text: "Recetas",
    value: "prescriptions",
  },
  {
    text: "Pagos",
    value: "payments",
  },
  {
    text: "Presupuestos",
    value: "budgets",
  },
  {
    text: "Laboratorios",
    value: "laboratory",
  },
  {
    text: "Turnos",
    value: "appointments",
  },
  {
    text: "Galería",
    value: "gallery",
  },
];

export const INITIAL_PATIENT_DATA = {
  acti_chequeos: [],
  alert: "",
  full_name: "",
  cuit: "",
  allergy: false,
  alpha: false,
  heart_condition: false,
  diabetes: false,
  pregnancy: false,
  is_holder: true,
  renal_condition: false,
  hepatitis: false,
  document_number: "",
  address: "",
  age: "",
  reasons: "",
  email: "",
  marital_status: "",
  registration_date: null,
  date_of_birth: null,
  prescription_date: null,
  last_visit_date: null,
  face: "",
  photo: "",
  mh_number: 0,
  medical_history: [],
  id_plan: 0,
  id_member_holder: 0,
  id_user: 0,
  id_patient: null,
  instagram: "",
  city: "",
  affiliate_number: "",
  notes: "",
  occupation: "",
  option: "",
  state: "",
  stay_to_search: "",
  balance: 0,
  mobile_phone: "",
  work_phone: "",
  home_phone: "",
  members: [],
  budgets: [],
  plan_navigation: {},
  patient_payments: [],
  sex: "F",
  holder_company: "",
  holder_name: "",
  twitter: "",
  odontogram: [],
  current_account: [],
  alls: [],
};

export const TAX_CONDITION: { id: string; text: string }[] = [
  { id: "CF", text: "Consumidor Final" },
  { id: "RI", text: "Responsable Inscripto" },
  { id: "MO", text: "Monotributista" },
  { id: "EX", text: "IVA Exento" },
];
export const TAXES = [
  { id_tax: 1, value: 0, text: "0" },
  { id_tax: 2, value: 10.5, text: "10.5" },
  { id_tax: 3, value: 21, text: "21" },
];
export const CONDITION: { id: string; text: string }[] = [
  { id: "NG", text: "No gravado" },
  { id: "GR", text: "Gravado" },
  { id: "PR", text: "Privado" },
];

export const GERDER: { id: string; text: string }[] = [
  { id: "F", text: "Femenino" },
  { id: "M", text: "Masculino" },
];

export const CATEGORIES: { id: string; subtitle: string }[] = [
  {
    id: "images",
    subtitle: "Imágenes",
  },
  {
    id: "videos",
    subtitle: "Videos",
  },
  {
    id: "texts",
    subtitle: "Textos",
  },
  {
    id: "pdfs",
    subtitle: "PDFs",
  },
  {
    id: "others",
    subtitle: "Others",
  },
];
export const PAYMENT_OPTIONS: { [key: string]: any }[] = [
  {
    inputType: "med_ins_input_type",
    fields: [
      {
        name: "amount_to_pay_med_ins",
        label: "Importe a pagar (O.S). ($)",
        disableCondition: "percentage",
      },
      {
        name: "amount_to_pay_med_ins_percentage",
        label: "Importe a pagar (O.S)(%)",
        disableCondition: "integer",
      },
    ],
  },
  {
    inputType: "patient_input_type",
    fields: [
      {
        name: "amount_to_pay_patient",
        label: "Importe a pagar (Pac.)($)",
        disableCondition: "percentage",
      },
      {
        name: "amount_to_pay_patient_percentage",
        label: "Importe a pagar (Pac.)(%)",
        disableCondition: "integer",
      },
    ],
  },
];

export const AMOUNTS_PAYMENTS: { [key: string]: any }[] = [
  {
    name: "amount_to_pay_professional_percentage",
    label: "Porcentaje a pagar (Prof). (%)",
    disableCondition: "percentage",
  },
  {
    name: "amount_to_pay_professional",
    label: "Importe a pagar (Prof). ($)",
    disableCondition: "integer",
  },
];

export const INVOICES_PAYMENTS: { [key: string]: any }[] = [
  { id_invoice: 1, value: "Factura", text: "Factura" },
  { id_invoice: 2, value: "Recibo", text: "Recibo" },
  { id_invoice: 3, value: "Nota Crédito", text: "Nota Crédito" },
  { id_invoice: 4, value: "Nota Débito", text: "Nota Débito" },
  { id_invoice: 5, value: "Otro", text: "Otro" },
];

export const TYPE_INVOICES_PAYMENTS: { [key: string]: any }[] = [
  { id_invoice_type: 1, value: "A", text: "A" },
  { id_invoice_type: 2, value: "B", text: "B" },
  { id_invoice_type: 3, value: "C", text: "C" },
  { id_invoice_type: 4, value: "X", text: "X" },
];

export const PAYMENT_METHOD: { [key: string]: any }[] = [
  { id_payment_method: 1, value: "Efectivo", text: "Efectivo" },
  {
    id_payment_method: 2,
    value: "Tarjeta",
    text: "Tarjeta de credito",
  },
  { id_payment_method: 3, value: "TarjetaD", text: "Tarjeta de debito" },
  { id_payment_method: 4, value: "Transf.", text: "Transferencia" },
  { id_payment_method: 6, value: "Otro", text: "Otro" },
];

export const ALIAS_METHOD: { [key: string]: string } = {
  "TC. VISA": "Tarjeta",
  "TC. Master.": "Tarjeta",
  "TC. Diners": "Tarjeta",
  "TC. Am. Ex.": "Tarjeta",
  "TC. Ame. Expres": "Tarjeta",
  "T. Debito": "TarjetaD",
  "Tar. Débito": "TarjetaD",
};

export const INSTALLMENTS: {
  id_installment: number;
  text: string;
  value: number;
}[] = [
  { id_installment: 1, text: "01", value: 1 },
  { id_installment: 2, text: "03", value: 3 },
  { id_installment: 3, text: "06", value: 6 },
  { id_installment: 4, text: "12", value: 12 },
  { id_installment: 5, text: "24", value: 24 },
];
