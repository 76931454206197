import React from "react";
import { Checkbox } from "@/components/atoms";
import { FormLabel, DatePicker } from "@/components/molecules";

export const TimeDatePicker: React.FC<{
  form?: any;
  label: string;
  dateFieldName: string;
}> = ({ form, label, dateFieldName }) => {
  const [checkedBoxValue, setCheckedBoxValue] = React.useState(
    form.getValues(dateFieldName) ? true : false
  );

  return (
    <div className="col-span-12 flex items-center gap-2">
      <Checkbox
        className="mr-2"
        id={label}
        checked={checkedBoxValue}
        onCheckedChange={(value: boolean) => {
          setCheckedBoxValue(value);
          if (value) {
            return form.setValue(
              dateFieldName,
              form.getValues(dateFieldName) || new Date()
            );
          }
          form.setValue(dateFieldName, null);
        }}
      />
      <div className="flex flex-col gap-1 w-full">
        <FormLabel>{label}</FormLabel>
        <DatePicker
          form={form}
          name={dateFieldName}
          disabled={!checkedBoxValue}
        />
      </div>
    </div>
  );
};
