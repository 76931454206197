import React from "react";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({ data, action, form }) => {
  return (
    <div className="mx-auto w-full">
      <Data columns={Columns({ action })} data={data} form={form} />
    </div>
  );
};
