import classNames from "classnames";
import { TabsTrigger } from "@/components/atoms";

export const renderTabs = (
  tabs: { [key: string]: any },
  className?: string
) => {
  return tabs.map((tab: { [key: string]: any }, i: number) => {
    const Icon = tab.icon;

    return (
      <div
        className="flex flex-col items-center justify-center lg:h-full"
        key={i}
      >
        <TabsTrigger
          key={i}
          className={classNames("h-7", className)}
          value={tab.value}
          disabled={tab.disabled || false}
        >
          <div className="hidden lg:block text-ellipsis overflow-hidden">
            {tab.text}
          </div>
          {Icon && (
            <div className="lg:hidden">
              <Icon />
            </div>
          )}
        </TabsTrigger>
        <div className="flex text-xs justify-center items-center text-center w-20 lg:hidden p-2">
          {tab.text}
        </div>
      </div>
    );
  });
};
