"use client";

import React from "react";
import { z } from "zod";
import { useGlobalStore } from "@/providers/global-provider";
import { EmptyState } from "@/components/molecules";
import {
  Skeleton,
  Button,
  Tabs,
  TabsContent,
  Dialog,
} from "@/components/atoms";
import { PatientLaboratoryTable } from "@/components/organisms";
import { LaboratoryForm } from "@/components/organisms/forms/patients/laboratory";
import { updateSection } from "@/actions/patients";
import { toast } from "@/hooks";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { Delete } from "./delete";

export const LaboratoryView = () => {
  const {
    patient,
    searchBarRef,
    patient_data_loading,
    setOrderSelected,
    updatePatient,
    general_params: { professionals, offices, laboratories },
  } = useGlobalStore();
  const [activeTab, setActiveTab] = React.useState<string>("tables");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const refresh = async () => {
    try {
      const { laboratories } = await updateSection(
        ["labor"],
        patient.id_patient
      );
      updatePatient("patient_labs", laboratories);
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    }
  };
  const CASES_ACTIONS: {
    [key: string]: (
      order: z.infer<typeof formSchema>["order_from_list"]
    ) => void;
  } = {
    edit: (order) => {
      const selected = patient?.patient_labs?.find(
        (r: { [key: string]: any }) => r.id_order === order.id_order
      );
      setOrderSelected(selected);
      setActiveTab("form");
    },
    delete: (order) => {
      setOpenDeleteModal(true);
      setOrderSelected(order);
    },
  };
  const handleNewOrderOnClick = () => {
    setOrderSelected(null);
    return setActiveTab("form");
  };
  const actions = (
    order: z.infer<typeof formSchema>["order_from_list"],
    action: string
  ) => {
    CASES_ACTIONS[action](order);
  };

  return (
    <Tabs
      defaultValue={activeTab}
      value={activeTab}
      className="!w-full hidden lg:flex lg:flex-col"
    >
      <TabsContent value="tables" className="!w-full !mt-0">
        <>
          {patient_data_loading ? (
            <div className="col-span-full grid grid-cols-12 gap-4 mx-auto">
              <Skeleton className="h-10 w-40 rounded-xl" />
              <Skeleton className="col-start-12 h-10 rounded-xl" />
              <Skeleton className="col-span-full h-80 rounded-xl" />
            </div>
          ) : patient.id_patient ? (
            <div className="grid grid-cols-12">
              {patient?.patient_labs?.length > 0 ? (
                <PatientLaboratoryTable
                  data={{
                    laboratories: patient?.patient_labs,
                  }}
                  action={actions}
                  loading={loading}
                />
              ) : (
                <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
                  <EmptyState
                    title="No hay prestaciones para mostrar"
                    copy="Puedes cargar una prestacion"
                    className="col-span-full !justify-start"
                  />
                  <Button
                    onClick={handleNewOrderOnClick}
                    className="col-span-4 col-start-6 mt-4"
                  >
                    Nuevo laboratorio
                  </Button>
                </div>
              )}
              {patient?.id_patient && patient?.patient_labs?.length > 0 && (
                <Button
                  onClick={handleNewOrderOnClick}
                  className="col-span-4 col-start-11 mt-5"
                >
                  Nuevo laboratorio
                </Button>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-12 gap-x-10">
              <EmptyState
                title="No existe paciente seleccionado"
                copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
                className="col-span-6 !justify-start flex col-start-4"
              />
              <Button
                className="col-span-3 col-start-6"
                onClick={() => searchBarRef?.current?.focus()}
              >
                Buscar Paciente
              </Button>
            </div>
          )}
          <Dialog open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
            <Delete
              onChange={refresh}
              loading={loading}
              setLoading={setLoading}
            />
          </Dialog>
        </>
      </TabsContent>
      <TabsContent value="form" className="!mt-0">
        <LaboratoryForm
          onCancel={() => setActiveTab("tables")}
          professionals={professionals}
          offices={offices}
          laboratories={laboratories}
          refresh={refresh}
        />
      </TabsContent>
    </Tabs>
  );
};
