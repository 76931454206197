"use client";

import * as React from "react";
import classNames from "classnames";
import { LuPencil } from "react-icons/lu";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  buttonVariants,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  Button,
  Input,
  Separator,
  Combobox,
} from "@/components/atoms";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  DatePicker,
  Offices,
  ButtonLoading,
} from "@/components/molecules";
import { Offices as OfficesT } from "@/types/general";
import { toast, useKeypress, useDialog } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { getBalances, setCashReconciliation } from "@/actions/administration";
import {
  formSchema,
  resolver,
} from "@/components/organisms/forms/administration/dailybalance";

export const DailybalanceForm = ({
  offices,
  idUser,
  cashCloseDate,
}: {
  offices: OfficesT[];
  idUser: number;
  cashCloseDate: string | null;
}) => {
  const {
    loading,
    startLoading,
    stopLoading,
    user: { is_supervisor, plan_version, mod_adm },
    permissions,
  } = useGlobalStore();
  const { closeDialog } = useDialog();
  const HAS_PARAMS_PERMISSION =
    Object.keys(permissions).includes("Adm. Parametros");
  const HAS_NO_OFFICE = offices?.length === 0;
  const ENABLED_OFFICES = offices?.filter((office) => office.enabled);
  const [edit, setEdit] = React.useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      date_from: new TZDate(new Date().toISOString(), TIMEZONES.AR),
      date_last_close: cashCloseDate ? new Date(cashCloseDate) : null,
      id_office: ENABLED_OFFICES?.[0]?.id_office ?? null,
      id_user: idUser,
      balance_local_currency: 0,
      balance_usd_currency: 0,
      edit_date: cashCloseDate ? new Date(cashCloseDate) : null,
      edit_balance_local_currency: 0,
      edit_balance_usd_currency: 0,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setCashReconciliation({
      edit_date: values.edit_date,
      edit_balance_local_currency: values.edit_balance_local_currency,
      edit_balance_usd_currency: values.edit_balance_usd_currency,
    });
    setEdit(!edit);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Datos actualizados correctamente",
        description: "El cierre de caja se actualizó correctamente.",
      });
    }
    stopLoading();
  };
  const CAN_CHANGE_DATE =
    ["premium"].includes(plan_version) ||
    (["full", "standard"].includes(plan_version) && mod_adm);
  React.useEffect(() => {
    async function getData() {
      const { id_user, date_from, id_office } = form.getValues();
      try {
        startLoading();
        const { balances }: any = await getBalances({
          date_from,
          id_user,
          id_office,
          calculate_in_usd: false,
          ignore_registers: false,
        });

        form.setValue("balance_local_currency", balances?.local_currency);
        form.setValue("balance_usd_currency", balances?.usd_currency);
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Hubo un error al obtener los datos",
          description: error.message,
        });
      } finally {
        closeDialog();
        stopLoading();
      }
    }

    if (!is_supervisor) return;

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("date_from"), form.watch("id_office")]);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Parámetros</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {is_supervisor && (
            <>
              <div className="leading-7 mb-3">
                <h3 className="text-slate-900 font-bold">Saldo al día</h3>
                <p className="text-slate-700 text-sm">
                  El saldo al día indica la acumulación de los Saldos del día en
                  caja, desde la fecha del último cierre de caja en adelante.
                </p>
              </div>
              <div className="grid grid-cols-12 gap-5">
                {ENABLED_OFFICES?.length > 0 && (
                  <FormItem className="col-span-6">
                    <FormLabel>Sucursal</FormLabel>
                    <FormControl>
                      <Combobox
                        form={form}
                        data={ENABLED_OFFICES}
                        dataKey="id_office"
                        value="name"
                        placeholder="Busca una sucursal"
                        title="Sucursal"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                <div className="col-span-6 self-end">
                  <DatePicker
                    label="Fecha último cierre"
                    name="date_last_close"
                    disabled
                    form={form}
                  />
                </div>
                <div className="col-span-6 self-end">
                  <DatePicker
                    label="Fecha saldo al día"
                    name="date_from"
                    form={form}
                    disabled={!CAN_CHANGE_DATE}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="balance_local_currency"
                  render={({ field }) => (
                    <FormItem className="col-start-1 col-span-6">
                      <FormLabel>Saldo al día</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          {...field}
                          className="text-green-500 pointer-events-none"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="balance_usd_currency"
                  render={({ field }) => (
                    <FormItem className="col-span-6">
                      <FormLabel>Saldo al día en USD</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          {...field}
                          className="text-green-500 pointer-events-none"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </>
          )}
          {HAS_NO_OFFICE && (HAS_PARAMS_PERMISSION || is_supervisor) && (
            <>
              <Separator className="mt-8 mb-4" />
              <div className="leading-7 mb-3">
                <div className="flex justify-between items-end">
                  <h3 className="text-slate-900 font-bold">
                    Modificar cierre de caja
                  </h3>
                  <div
                    onClick={() => setEdit(true)}
                    className={classNames(
                      "gap-x-2 hover:cursor-pointer",
                      buttonVariants({ variant: "ghost" })
                    )}
                  >
                    Modificar <LuPencil />
                  </div>
                </div>
                <p className="text-slate-700 text-sm">
                  Esta acción modificará la fecha de cierre en la caja diaria,
                  habilitando la acciones de agregar, modificar o eliminar
                  pagos, ingresos y egresos. Recuerda especificar el &apos;Saldo
                  al día&apos; con el que cuentas en esa fecha.
                </p>
              </div>
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-4 self-end">
                  <DatePicker
                    label="Fecha"
                    name="edit_date"
                    captionLayout="dropdown"
                    disabledDates={
                      cashCloseDate && { after: new Date(cashCloseDate) }
                    }
                    form={form}
                    disabled={!edit}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="edit_balance_local_currency"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Saldo al día en moneda local</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} disabled={!edit} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="edit_balance_usd_currency"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Saldo al día en USD</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} disabled={!edit} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </>
          )}
          <DialogFooter className="mt-4">
            <DialogClose asChild>
              <Button variant="destructive">Cancelar</Button>
            </DialogClose>
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button disabled={!edit} type="submit">
                Guardar
              </Button>
            )}
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};
