"use client";

import React from "react";
import { z } from "zod";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { LuCircleAlert } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import { useGlobalStore } from "@/providers/global-provider";
import { ButtonLoading, Form } from "@/components/molecules";
import { Button, DialogHeader, DialogContent } from "@/components/atoms";
import { saveDataPatient } from "@/actions/patients";
import { CONDITION, TAX_CONDITION } from "@/libs/patients";
import { Patient } from "@/types/patients";
import { toast, useDialog, useStateAcordeon } from "@/hooks";
import { DeletePatient } from "@/components/organisms/forms";
import {
  Personal,
  Affiliation,
  MedicalHistory,
  Aditionals,
  LoadingForm,
} from "./";
import { formSchema, resolver } from "./schema";

export const PatientForm = ({
  preloadPatient,
}: {
  preloadPatient: Patient | undefined;
}) => {
  const {
    patient: {
      id_patient,
      full_name,
      date_of_birth,
      address,
      reasons,
      city,
      state,
      home_phone,
      work_phone,
      mobile_phone,
      email,
      document_number,
      gender,
      id_plan,
      affiliate_number,
      hepatitis,
      pregnancy,
      diabetes,
      allergy,
      heart_condition,
      renal_condition,
      alpha,
      registration_date,
      last_visit_date,
      is_holder,
      clinical_history_number,
      condition,
      holder_name,
      holder_company,
      notes,
      photo,
      id_member_holder,
      alert,
      id_user,
      face,
      twitter,
      prescription_date,
      marital_status,
      occupation,
      cuit,
      tax_condition,
      document_type,
      instagram,
      rowversion,
      age,
      stay_to_search,
      plan_navigation: { id_med_ins },
    },
    user: { id_user: idUser },
    general_params: { translations, user_params, medical_insurances },
    setPatientData,
    patient_data_loading,
    ref_header,
    loading,
  } = useGlobalStore();
  const { openDialog } = useDialog();
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const DEFAULT_DATA = React.useMemo(() => {
    return {
      id_patient: id_patient || 0,
      full_name: full_name,
      date_of_birth: date_of_birth
        ? new Date(date_of_birth)
        : new TZDate(new Date().toISOString(), TIMEZONES.AR),
      address: address,
      reasons: reasons || "",
      city: city,
      state: state || "",
      home_phone: home_phone || "",
      work_phone: work_phone || "",
      mobile_phone: mobile_phone || "",
      email: email,
      document_number: document_number,
      gender: gender || "F",
      id_plan: id_plan || medical_insurances?.[0]?.plans?.[0]?.id_plan,
      affiliate_number: affiliate_number || "",
      hepatitis: hepatitis || false,
      pregnancy: pregnancy || false,
      diabetes: diabetes || false,
      allergy: allergy || false,
      heart_condition: heart_condition || false,
      renal_condition: renal_condition || false,
      alpha: alpha || false,
      registration_date: registration_date
        ? new TZDate(new Date(registration_date).toISOString(), TIMEZONES.AR)
        : new TZDate(new Date().toISOString(), TIMEZONES.AR),
      last_visit_date: last_visit_date
        ? new TZDate(new Date(last_visit_date).toISOString(), TIMEZONES.AR)
        : new TZDate(new Date().toISOString(), TIMEZONES.AR),
      is_holder: is_holder || true,
      clinical_history_number: clinical_history_number || 0,
      condition: condition || CONDITION[0].id,
      holder_name: holder_name,
      holder_company: holder_company,
      notes: notes,
      photo: photo,
      id_member_holder: id_member_holder || 0,
      alert: alert || "",
      id_user: id_user || idUser,
      face: face || "",
      twitter: twitter || "",
      prescription: !!prescription_date || false,
      prescription_date: prescription_date
        ? new TZDate(new Date(prescription_date).toISOString(), TIMEZONES.AR)
        : null,
      marital_status: marital_status ? marital_status : "",
      occupation: occupation || "",
      cuit: cuit || "",
      tax_condition: tax_condition || TAX_CONDITION[0].id,
      document_type: document_type || translations?.doc_types?.[0]?.id,
      instagram: instagram || "",
      rowversion: rowversion || null,
      option: id_patient ? "modificar" : "agregar",
      age: age || null,
      balance: 0,
      stay_to_search: stay_to_search || "",
      id_med_ins: id_med_ins || medical_insurances?.[0]?.id_med_ins,
    };
  }, [id_patient, rowversion]);
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const FORM = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: DEFAULT_DATA,
  });
  const { formState } = FORM;
  const onSubmit = async (patient: z.infer<typeof formSchema>) => {
    setButtonLoading(true);
    const response = await saveDataPatient(patient);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
      return setButtonLoading(false);
    } else {
      setPatientData(response);
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "Los datos del paciente se guardaron correctamente.",
      });
      setButtonLoading(false);
    }
  };

  React.useEffect(() => {
    FORM.reset(DEFAULT_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_patient, medical_insurances, rowversion]);

  React.useEffect(() => {
    const handleNavigation = async () => {
      if (preloadPatient) {
        setPatientData(preloadPatient);
      }
    };
    handleNavigation();
  }, [preloadPatient]);

  React.useEffect(() => {
    id_patient &&
      alert &&
      openDialog({
        content: (
          <DialogContent className="!w-[300px]">
            <DialogHeader className="flex flex-row">
              <div className="flex items-center space-x-2 jus">
                <LuCircleAlert className="size-4 text-red-500" />
                <span className="font-semibold">Alerta</span>
              </div>
            </DialogHeader>
            <p className="text-base text-slate-600">{alert}</p>
          </DialogContent>
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_patient]);

  return patient_data_loading ? (
    <LoadingForm />
  ) : (
    <>
      <Form {...FORM}>
        <form
          onSubmit={FORM.handleSubmit(onSubmit)}
          className={classNames(
            "transition-all ease-in-out 1s overflow-y-scroll h-[calc(100vh-205px)] w-full grid grid-cols-12 gap-5",
            {
              "!h-[calc(100vh-157px)]": !id_patient,
              "!h-[calc(100vh-265px)]":
                id_patient && STATE_ACCORDION && STATE_ACCORDION === "open",
            }
          )}
        >
          <Personal form={FORM} />
          <Affiliation
            form={FORM}
            medicalInsurances={medical_insurances}
            lenderCode={user_params?.lender_code_bilog}
          />
          <Aditionals form={FORM} />
          <MedicalHistory form={FORM} />
        </form>
      </Form>
      <div className="flex justify-end space-x-4 pt-4 border-t border-t-slate-200">
        <Button
          type="button"
          variant="destructive"
          disabled={!id_patient}
          onClick={() =>
            openDialog({
              content: <DeletePatient />,
            })
          }
        >
          Eliminar paciente
        </Button>
        {buttonLoading ? (
          <ButtonLoading />
        ) : (
          <Button
            type="submit"
            disabled={loading || !formState.isDirty}
            onClick={FORM.handleSubmit(onSubmit)}
          >
            Guardar paciente
          </Button>
        )}
      </div>
    </>
  );
};
