import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_benefit: z.number(),
  date: z.date(),
  period: z.string(),
  observations: z.string().optional(),
  tooth_section: z.string().optional(),
  amount_med_ins: z.coerce.number(),
  amount_patient: z.coerce.number(),
  change_amount_professional: z.boolean().optional(),
  amount_to_pay_med_ins: z.coerce.number().optional(),
  amount_to_pay_patient: z.coerce.number().optional(),
  amount_to_pay_med_ins_percentage: z.coerce.number().optional(),
  amount_to_pay_patient_percentage: z.coerce.number().optional(),
  id_professional: z.number(),
  is_usd: z.boolean(),
  id_patient: z.number(),
  id_office: z.number().nullable(),
  id_user: z.number(),
  tooth_face: z.record(z.boolean()).optional(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
