import React from "react";
import classNames from "classnames";
import { ImWhatsapp as Whatsapp } from "react-icons/im";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Button,
} from "@/components/atoms";

export const TurnReminder: React.FC<{
  onClick: (e: any) => void;
  extended: boolean;
}> = ({ onClick, extended }) => {
  return extended ? (
    <Button
      onClick={onClick}
      variant="outline"
      size="default"
      className="flex gap-x-2 text-green-500 !border-green-500 hover:!bg-green-500 hover:text-white"
    >
      <Whatsapp className="size-4" />
      <p>Enviar recordatorios</p>
    </Button>
  ) : (
    <Tooltip>
      <TooltipProvider>
        <TooltipTrigger asChild>
          <Button
            onClick={onClick}
            variant="outline"
            size="icon"
            className="flex gap-x-2 h-9 w-9 text-green-500 !border-green-500 hover:!bg-green-500 hover:text-white"
          >
            <Whatsapp className="size-4" />
            <span className="sr-only">Toggle settings</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Enviar recordatorios</TooltipContent>
      </TooltipProvider>
    </Tooltip>
  );
};
