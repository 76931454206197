import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
  SidebarProvider,
  Switch,
} from "@/components/atoms";
import { PermissionsInset } from "@/components/molecules/sidebars/configuration/permissions/inset";
import { PermissionsSidebar } from "@/components/molecules/sidebars/configuration/permissions/sidebar";
import { Form, Process } from "@/types/general";
import { formSchema } from "../schema";

export const SelectPermissions = ({
  permissions,
  form,
}: {
  permissions: Process[];
  form: Form<typeof formSchema>;
}) => {
  const user_permissions = form.watch("permissions");
  const [selectedPermissions, setSelectedPermissions] =
    React.useState<Process[]>(user_permissions);
  const [section, setSection] = React.useState<string>("inicio");
  const permissions_by_items = permissions?.filter(
    (p: Process) => p.system_item.toLowerCase() === section
  );
  const handleOnChange = (id_permission: number) => {
    const permission = permissions?.find(
      (p: Process) => p.id_process === id_permission
    );
    setSelectedPermissions((prev: any) => {
      const exists_permission = prev?.some(
        (item: Process) => item.id_process === permission?.id_process
      );
      if (exists_permission) {
        return prev.filter(
          (item: Process) => item.id_process !== permission?.id_process
        );
      } else {
        return [...prev, permission];
      }
    });
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Configuración manual</DialogTitle>
        <DialogDescription>
          A continuación, encontrarás las listas de permisos. Activa el permiso
          para cada categoría.
        </DialogDescription>
      </DialogHeader>
      <Separator />
      <div className="relative h-[500px]">
        <SidebarProvider
          style={
            {
              "--sidebar-width": "200px",
            } as React.CSSProperties
          }
        >
          <PermissionsSidebar handlerOnSelect={setSection} section={section} />
          <PermissionsInset section="users">
            {permissions_by_items?.map((p: Process, i: number) => {
              return (
                <div
                  key={i}
                  className="flex justify-between items-center border-b"
                >
                  <header className="p-3">
                    <p className="text-md font-bold text-slate-900">
                      {p.option}
                    </p>
                    <p className="text-sm font-normal text-slate-500">
                      {p.description}
                    </p>
                  </header>
                  <Switch
                    className="switch"
                    checked={selectedPermissions.some(
                      (permission) => permission.id_process === p.id_process
                    )}
                    onCheckedChange={() => handleOnChange(p.id_process)}
                  />
                </div>
              );
            })}
          </PermissionsInset>
        </SidebarProvider>
      </div>
      <DialogClose className="flex justify-end gap-x-4 z-10">
        <Button variant="destructive" type="button">
          Cancelar
        </Button>
        <Button
          type="button"
          onClick={() => form.setValue("permissions", selectedPermissions)}
        >
          Guardar
        </Button>
      </DialogClose>
    </DialogContent>
  );
};
