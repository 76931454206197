"use client";

import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { EmptyState } from "@/components/molecules";
import {
  Skeleton,
  Button,
  Tabs,
  TabsContent,
  Dialog,
} from "@/components/atoms";
import { Regions, User } from "@/types/general";
import { toast } from "@/hooks";
import { PrescriptionsForm, PrescriptionsTable } from "@/components/organisms";
import { Financier } from "@/types/recipes";
import { validatePack } from "@/actions/recipes";
import { HirePackage } from "./hire-package";
import { TermsAndConditions } from "./terms-conditions";

export const PrescriptionsView = ({
  financiers,
  users,
}: {
  financiers: Financier[];
  users: User[];
}) => {
  const {
    patient,
    permissions,
    searchBarRef,
    patient_data_loading,
    user,
    countries,
    region,
    setPrescriptionsSelected,
    general_params: { user_params, offices, professionals, medical_insurances },
  } = useGlobalStore();
  const [activeTab, setActiveTab] = React.useState<string>("tables");
  const [layout, setLayout] = React.useState("expanded");
  const [sorting, setSorting] = React.useState([]);
  const [showHirePack, setShowHirePack] = React.useState(false);
  const [termAndConditions, setTermAndConditions] = React.useState(false);
  const {
    "Descargar / Enviar": send,
    "Ver Recetas": view,
    "Emitir Receta": to_emit,
    "Enviar por email": send_to_mail,
  } = permissions;

  const AREA_NUMBER =
    countries?.find(
      (country: Regions) => country.iso3 === region?.toUpperCase()
    )?.phoneCode || 54;

  const CASES_ACTIONS: { [key: string]: (recipe: any) => any } = {
    view_details: (recipe) => {
      const selected = patient.prescriptions?.find(
        (r: { [key: string]: any }) =>
          r.prescriptionId === recipe.prescriptionId
      );

      setPrescriptionsSelected(selected);
      setActiveTab("form");
    },
    download_pdf: (recipe) => window?.open(recipe.file_link, "_blank"),
    send: (recipe) => {
      const url = "https://wa.me/";
      if (patient.mobile_phone) {
        const phone = patient.mobile_phone
          ?.trim()
          .replace(/\s+/g, "")
          .replace(/-/g, "");

        if (patient.mobile_phone.includes(`+${AREA_NUMBER}`)) {
          return window?.open(`${url}${phone}`, "_blank");
        }
        return window?.open(
          `${url}+${AREA_NUMBER}${phone}?text=Ingresa%20al%20siguiente%20link%20para%20descargar%20tu%20receta:%20${recipe.file_link}`,
          "_blank"
        );
      }
    },
  };
  const handleNewRecipeOnClick = async () => {
    if (patient?.document_number.length < 6) {
      return toast({
        variant: "warning",
        title: "Documento del paciente inválido",
        description: "Debe tener un mínimo de 6 números.",
      });
    }
    if (/[0-9!@#$%^&*(),.?":{}|<>]/.test(patient?.full_name)) {
      return toast({
        variant: "warning",
        title: "Nombre del paciente inválido",
        description: "No puede contener numeros y/o caracteres especiales.",
      });
    }
    if (!user_params.tax_number || !user_params?.address || !user_params.name) {
      return toast({
        variant: "warning",
        title: "La clínica no tiene alguno o todos los parámetros, Falta:",
        description:
          "CUIT, dirección o nombre. Por favor, complete los datos en Configuración/Datos Generales.",
      });
    }

    if (user.is_supervisor || to_emit) {
      if (user.prescriptions_tyc) {
        const response = (await validatePack()) as any;
        if (response?.error) {
          setShowHirePack(true);

          return toast({
            variant: "warning",
            title: "Ups, parece que hubo un error",
            description:
              "Ud. no tiene pack de recetas. Póngase en contacto con su administrador",
          });
        }
        return setActiveTab("form");
      }
      return setTermAndConditions(true);
    }
    return toast({
      variant: "warning",
      title: "Ups, parece que hubo un error",
      description:
        "Ud. no posee permiso para emitir receta. Póngase en contacto con su administrador",
    });
  };

  const actions = (recipe: { [key: string]: string }, action: string) => {
    CASES_ACTIONS[action](recipe);
  };

  return (
    <Tabs
      defaultValue={activeTab}
      value={activeTab}
      className="!w-full hidden lg:flex lg:flex-col"
    >
      <TabsContent value="tables" className="!w-full !mt-0">
        <>
          <Dialog open={showHirePack} onOpenChange={setShowHirePack}>
            <HirePackage />
          </Dialog>
          <Dialog open={termAndConditions} onOpenChange={setTermAndConditions}>
            <TermsAndConditions />
          </Dialog>
          {patient_data_loading ? (
            <div className="col-span-full grid grid-cols-12 gap-4 mx-auto">
              <Skeleton className="h-10 w-40 rounded-xl" />
              <Skeleton className="col-start-12 h-10 rounded-xl" />
              <Skeleton className="col-span-full h-80 rounded-xl" />
            </div>
          ) : patient.id_patient ? (
            <div className="grid grid-cols-12">
              {patient?.prescriptions?.length ? (
                <PrescriptionsTable
                  data={{
                    recipes: patient?.prescriptions,
                    medical_insurances,
                    offices,
                    permissions: {
                      send,
                      view,
                      to_emit,
                      send_to_mail,
                      is_supervisor: user.is_supervisor,
                    },
                  }}
                  action={actions}
                  layout={layout}
                  setLayout={setLayout}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              ) : (
                <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
                  <EmptyState
                    title="No hay prestaciones para mostrar"
                    copy="Puedes cargar una prestacion"
                    className="col-span-full !justify-start"
                  />
                  <Button
                    onClick={handleNewRecipeOnClick}
                    className="col-span-4 col-start-6 mt-4"
                  >
                    Nueva receta
                  </Button>
                </div>
              )}
              {patient?.prescriptions?.length > 0 && patient?.id_patient && (
                <Button
                  onClick={handleNewRecipeOnClick}
                  className="col-span-4 col-start-11 mt-4"
                >
                  Nueva receta
                </Button>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-12 gap-x-10">
              <EmptyState
                title="No existe paciente seleccionado"
                copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
                className="col-span-6 !justify-start flex col-start-4"
              />
              <Button
                className="col-span-3 col-start-6"
                onClick={() => searchBarRef?.current?.focus()}
              >
                Buscar Paciente
              </Button>
            </div>
          )}
        </>
      </TabsContent>
      <TabsContent value="form" className="!mt-0">
        <PrescriptionsForm
          onCancel={() => {
            setActiveTab("tables");
            setPrescriptionsSelected(null);
          }}
          professionals={professionals}
          offices={offices}
          financiers={financiers}
          users={users}
        />
      </TabsContent>
    </Tabs>
  );
};
