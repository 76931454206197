"use client";

import * as React from "react";
import { addDays, format } from "date-fns";
import { es } from "date-fns/locale";
import { LuCalendar as CalendarIcon } from "react-icons/lu";
import { DateRange } from "react-day-picker";
import classNames from "classnames";
import {
  Button,
  RangeCalendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms";

export function DatePickerWithRange({
  className,
  onSelect,
  from = new Date(),
  to = addDays(new Date(), 7),
  max,
  disabledPastDates,
}: React.HTMLAttributes<HTMLDivElement> & {
  onSelect?: (dates: any) => void;
  from?: Date;
  to?: Date;
  max?: number;
  disabledPastDates?: boolean;
}) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from,
    to,
  });

  return (
    <div className={classNames("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={classNames(
              "w-full justify-start text-left font-normal",
              !date && "text-gray-400"
            )}
          >
            <CalendarIcon className="mr-2 size-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y", { locale: es })} -{" "}
                  {format(date.to, "LLL dd, y", { locale: es })}
                </>
              ) : (
                format(date.from, "LLL dd, y", { locale: es })
              )
            ) : (
              <span>Selecciona una fecha</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <RangeCalendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(v: any) => {
              setDate(v);
              onSelect?.(v);
            }}
            max={max}
            numberOfMonths={2}
            month={(date?.from && new Date(date.from)) || new Date()}
            disabledPastDates
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
