import { Combobox } from "@/components/atoms";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { MedicalInsurance, Translations } from "@/types/general";

export const MedInsSelect = ({
  form,
  medicalInsurance,
  translations,
  classname,
  label,
}: {
  form: any;
  medicalInsurance: MedicalInsurance[];
  translations: Translations;
  label?: string;
  classname?: string;
}) => {
  return (
    <FormItem className={classname}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormControl>
        <Combobox
          form={form}
          data={medicalInsurance}
          dataKey="id_med_ins"
          value="medical_insurance"
          placeholder={`Busca un/a ${translations?.medical_insurance}`}
          title={translations?.medical_insurance}
          className={classname}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};
