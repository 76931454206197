"use client";

import React from "react";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import {
  HiDotsVertical,
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { DeleteProfessional } from "@/components/organisms/forms";
import { Professional } from "@/types/general";

type RowDataT = {
  original: any;
};

const Dropdown = ({ professional }: { professional: Professional }) => {
  const { openDialog } = useDialog();
  const router = useRouter();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() =>
              router.push(
                `/dashboard/configuration/tables/professionals/new?id=${professional.id_professional}`
              )
            }
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteProfessional professional={professional} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => (
      <Dropdown professional={row.original} />
    ),
  },
  {
    accessorKey: "professional_code",
    header: "Código",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { professional_code } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {professional_code}
        </p>
      );
    },
  },
  {
    accessorKey: "abbreviation",
    header: "Abreviatura",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { abbreviation } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {abbreviation}
        </p>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Nombre y Apellido",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { name } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {name}
        </p>
      );
    },
  },
  {
    accessorKey: "profession",
    header: "Profesión",
    enableHiding: false,
    enableGrouping: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { profession } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {profession}
        </p>
      );
    },
  },
  {
    accessorKey: "enabled",
    header: "Habilitado",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { enabled } = row.original;

      return (
        <p
          className={classNames(
            "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
            {
              "text-red-500": !enabled,
              "text-green-500": enabled,
            }
          )}
        >
          {enabled ? (
            <HiOutlineCheckCircle className="size-5" />
          ) : (
            <HiOutlineBan className="size-5" />
          )}
        </p>
      );
    },
    enableGrouping: false,
  },
];
