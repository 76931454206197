"use client";

import React from "react";
import { format } from "date-fns";
import classNames from "classnames";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Holyday, Offices, Professional } from "@/types/general";
import { DAYS } from "@/libs/config";
import { AbscencesForm, DeleteAbscence } from "@/components/organisms/forms";

type RowDataT = {
  original: any;
};

const Dropdown = ({
  abscence,
  professional,
  offices,
}: {
  abscence: Holyday;
  professional?: Professional;
  offices: Offices[];
}) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              openDialog({
                content: (
                  <AbscencesForm
                    abscence={abscence}
                    professional={professional}
                    offices={offices}
                  />
                ),
              });
            }}
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: (
                  <DeleteAbscence
                    abscence={abscence}
                    professional={professional}
                  />
                ),
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = ({
  config,
}: {
  config: {
    offices: Offices[];
    professional?: Professional;
  };
}) => {
  const { offices, professional } = config;
  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <Dropdown
          abscence={row.original}
          professional={professional}
          offices={offices}
        />
      ),
    },
    {
      accessorKey: "day",
      header: "Día",
      cell: ({ row }: { row: any }) => {
        const { day } = row.original;
        const DAY = DAYS.find((d: any) => d.id_day === day);

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {DAY?.value || "Todos"}
          </p>
        );
      },
    },
    {
      accessorKey: "date_from",
      header: "Fecha desde",
      cell: ({ row }: { row: any }) => {
        const { date_from } = row.original;
        const DATE_FORMATTED = date_from && format(date_from, "dd-MM-yyyy");

        return (
          <p
            className={classNames(
              `my-1 text-sm leading-6 ${
                date_from ? "text-slate-500" : "!text-blue-600"
              } `
            )}
          >
            {DATE_FORMATTED || "Fecha abierta"}
          </p>
        );
      },
    },
    {
      accessorKey: "date_to",
      header: "Fecha hasta",
      cell: ({ row }: { row: any }) => {
        const { date_to } = row.original;
        const DATE_FORMATTED = date_to && format(date_to, "dd-MM-yyyy");

        return (
          <p
            className={classNames(
              `my-1 text-sm leading-6 ${
                date_to ? "text-slate-500" : "!text-blue-600"
              } `
            )}
          >
            {DATE_FORMATTED || "Fecha abierta"}
          </p>
        );
      },
    },
  ];
  if (offices?.length > 0) {
    columns.push({
      accessorKey: "id_office",
      header: "Sucursal",
      cell: ({ row }: { row: any }) => {
        const { id_office } = row.original;
        const office = offices?.find(
          (item: any) => item.id_office === id_office
        );

        return (
          <p className="text-sm leading-6 text-slate-500 my-1">
            {office?.description || ""}
          </p>
        );
      },
    });
  }

  return columns;
};
