"use client";

import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { Visibility } from "./filters";
import { renderTabs } from "@/utils/tabs";

const tabs = [
  {
    text: "Expandida",
    value: "expanded",
  },
  {
    text: "Compacta",
    value: "compact",
  },
];

export const TableToolbar: React.FC<{ table: any }> = ({ table }) => {
  return (
    <div className="flex flex-1 items-center space-x-2 justify-end mb-2">
      <div id="schedule-daily-layouts">
        <Tabs.List
          className="flex h-10 bg-slate-100 p-1 rounded border border-slate-200"
          aria-label="Schedule layout tabs"
        >
          {renderTabs(tabs, "data-[state=active]:text-slate-800")}
        </Tabs.List>
      </div>
      <div id="schedule-filters-visibility">
        <Visibility table={table} />
      </div>
    </div>
  );
};
