"use client";

import React from "react";
import { getAppointmentPatient } from "@/actions/appointments-patient";
import { Button, Skeleton } from "@/components/atoms";
import { AppointmentsTable } from "@/components/organisms/tables";
import { EmptyState } from "@/components/molecules";
import { toast } from "@/hooks";
import { AppointmentList } from "@/types/patients";
import { useGlobalStore } from "@/providers/global-provider";

export const AppointmentsView = () => {
  const {
    patient,
    patient_data_loading,
    setPatientAppointments,
    patient_appointments: { appointments },
    searchBarRef,
  } = useGlobalStore();
  const [loading, setLoading] = React.useState<boolean>(false);

  const REFRESH = async () => {
    setLoading(true);
    try {
      const PATIENT_APPOINTMENTS: AppointmentList[] =
        await getAppointmentPatient(patient.id_patient);
      setPatientAppointments({
        list: PATIENT_APPOINTMENTS,
        set_new_data: true,
      });
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "¡Ups! Parece que hubo un error",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    patient.id_patient && REFRESH();
  }, [patient.id_patient]);

  return patient_data_loading || loading ? (
    <div className="grid grid-cols-4 gap-4 mx-auto">
      <Skeleton className="col-span-5 h-80 rounded-xl" />
    </div>
  ) : patient.id_patient ? (
    <div className="w-full hidden lg:flex lg:flex-col pb-4">
      {appointments?.length > 0 ? (
        <AppointmentsTable data={appointments} />
      ) : (
        <div className="m-auto col-span-5 col-start-4 grid grid-cols-10 gap-x-10">
          <EmptyState
            title="Ups"
            copy="El paciente no tiene historial de turnos."
            className="col-span-full !justify-center"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="grid grid-cols-12 gap-x-10">
      <EmptyState
        title="No existe paciente seleccionado"
        copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
        className="col-span-6 !justify-start flex col-start-4"
      />
      <Button
        className="col-span-3 col-start-6"
        onClick={() => searchBarRef?.current?.focus()}
      >
        Buscar Paciente
      </Button>
    </div>
  );
};
