"use client";

import { useEffect } from "react";
import { UseKeyPress } from "@/types/hooks";

export function useKeypress({ key, action }: UseKeyPress) {
  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === key) action(e);
    }
    window?.addEventListener("keyup", onKeyup);
    return () => window?.removeEventListener("keyup", onKeyup);
  }, [action, key]);
}
