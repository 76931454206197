import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/molecules";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";

export const GroupDate = ({ form }: any) => {
  const CHECKBOX_LIST = [
    { key: "day", label: "Día", description: "Agrupar por día" },
    { key: "month", label: "Mes", description: "Agrupar por mes" },
    { key: "year", label: "Año", description: "Agrupar por año" },
  ];

  return (
    <FormField
      control={form.control}
      name="group_date"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-base">Agrupar por</FormLabel>
          <FormControl>
            <Select
              key="key"
              value={field.value}
              onValueChange={(value: string) => {
                form.setValue("group_date", value);
              }}
            >
              <SelectTrigger className="w-full !h-10 !text-sm">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {CHECKBOX_LIST.map(
                  (item: {
                    key: string;
                    label: string;
                    description: string;
                  }) => (
                    <SelectItem key={item.key} value={"" + item.key}>
                      <p>{item.label}</p>
                    </SelectItem>
                  )
                )}
              </SelectContent>
            </Select>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
