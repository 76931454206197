import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_laboratory: z.number(),
  name: z
    .string()
    .min(1, {
      message: "Nombre de laboratorio requerido",
    })
    .max(50, {
      message: "No puede contener más de 50 caracteres",
    }),
  contact: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  address: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  city: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  state: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  phone: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  email: z.string().max(50, {
    message: "No puede contener más de 50 caracteres",
  }),
  balance: z.coerce.number(),
  balance_usd: z.coerce.number(),
  tax_number: z.string().max(13, {
    message: "No puede contener más de 13 caracteres",
  }),
  observations: z.string().max(250, {
    message: "No puede contener más de 250 caracteres",
  }),
  enabled: z.boolean(),
  rowversion: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
