import * as React from "react";
import { z } from "zod";
import { HiDotsVertical } from "react-icons/hi";
import { LuPen, LuTrash2 } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";

export const Columns = ({
  action,
}: {
  action: (
    order: z.infer<typeof formSchema>["order_from_list"],
    action: string
  ) => void;
}) => {
  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, "edit")}
                >
                  <LuPen className="mr-2 size-4" />
                  <span>Modificar</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, "delete")}
                >
                  <LuTrash2 className="mr-2 size-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "code",
      header: "Código",
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{code}</p>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Concepto",
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Cantidad",
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: "tooth_section",
      header: "Pieza/s",
      cell: ({ row }: { row: any }) => {
        const { tooth_section } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {tooth_section}
          </p>
        );
      },
    },
    {
      accessorKey: "color",
      header: "Color",
      cell: ({ row }: { row: any }) => {
        const { color } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{color}</p>
        );
      },
    },
    {
      accessorKey: "individual_amount",
      header: "Imp. indiv.",
      cell: ({ row }: { row: any }) => {
        const { amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {numberToCurrency(amount)}
          </p>
        );
      },
    },
    {
      accessorKey: "total_amount",
      header: "Imp. total",
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-green-500">
            {numberToCurrency(total_amount)}
          </p>
        );
      },
    },
  ];
  !action && columns.splice(0, 1);

  return columns;
};
