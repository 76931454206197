"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Checkbox, Input, Card, Combobox } from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { updateDataAccount } from "@/actions/my-account";
import { toast, useKeypress } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { Account } from "@/types/my-account";
import { Regions } from "@/types/general";
import { transformDateToZoneTime } from "@/utils/date";
import { formSchema, resolver } from "./schema";

export function MyAccountForm({
  account,
  regions,
}: {
  account: Account;
  regions: Regions[];
}) {
  const { startLoading, stopLoading, loading } = useGlobalStore();
  const textfieldEl = React.useRef<any>(null);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      first_name: account.first_name,
      last_name: account.last_name,
      country: account.country,
      phone: account.phone,
      id_country:
        regions.find(
          (r: Regions) => r.iso3 === account.country_iso3.toUpperCase()
        )?.id_country || 1,
      birthday: transformDateToZoneTime(account?.birthday),
      is_whatsapp: account.is_whatsapp,
      country_iso3: account.country_iso3,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const response = await updateDataAccount(values, regions);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Datos actualizados correctamente",
        description: "Los datos de tu cuenta han sido actualizados.",
      });
    }

    stopLoading();
  }

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="col-span-6 pt-2 lg:pt-0"
      >
        <Card className="w-full space-y-4 lg:space-y-0 mt-4 !p-0 !border-none !shadow-none lg:grid lg:grid-cols-2 lg:gap-5">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nombres</FormLabel>
                <FormControl>
                  <Input type="text" {...field} ref={textfieldEl} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Apellidos</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="id_country"
            render={() => (
              <FormItem>
                <FormLabel>País</FormLabel>
                <FormControl>
                  <Combobox
                    form={form}
                    data={regions}
                    dataKey="id_country"
                    value="name"
                    placeholder="Busca un país..."
                    title="País"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="birthday"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Fecha de nacimiento</FormLabel>
                <FormControl>
                  <Input className="!block" type="date" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Télefono</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_whatsapp"
            render={({ field }) => (
              <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="leading-none flex flex-col">
                  <FormLabel className="!m-0">Utiliza Whatsapp</FormLabel>
                  <p className="text-sm font-regular text-gray-400">
                    Indica si el teléfono se utiliza como medio de comunicación
                    de WhatsApp
                  </p>
                </div>
              </FormItem>
            )}
          />
        </Card>
        <div className="w-full lg:w-auto flex flex-row justify-center items-center lg:justify-end  gap-x-5 mt-12">
          <Button
            type="button"
            onClick={() => {
              form.reset();
            }}
            variant="destructive"
          >
            Cancelar
          </Button>
          {loading ? <ButtonLoading /> : <Button type="submit">Guardar</Button>}
        </div>
      </form>
    </Form>
  );
}
