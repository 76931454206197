import { DAYS } from "@/libs/schedule";
import { BenefitT } from "@/types/components/reports";
import {
  Appointment,
  Benefit,
  Laboratory,
  Offices,
  Professional,
} from "@/types/general";
import { ScheduleList } from "@/types/schedule";

export const userPermissions = (
  userPermissions: [
    {
      id_permiso: number;
      id_usuario: number;
      id_proceso: number;
      id_procesoNavigation: {
        id_proceso: number;
        item_sistema: string;
        opcion: string;
        orden: number;
        descripcion: string;
        rowversion: string;
      };
      id_usuarioNavigation: null;
    }
  ],
  systemPermissions: [
    {
      id_proceso: number;
      item_sistema: string;
      opcion: string;
      orden: number;
      descripcion: string;
      rowversion: string;
    }
  ]
) => {
  const subPermissions = systemPermissions?.map((i) => i.opcion);
  const mapped = userPermissions.reduce(
    (memo: { [key: string]: boolean }, permission) => {
      if (
        permission.id_procesoNavigation.item_sistema ===
        permission.id_procesoNavigation.opcion
      ) {
        memo[permission.id_procesoNavigation.item_sistema] = true;
      }
      if (subPermissions?.includes(permission.id_procesoNavigation.opcion)) {
        memo[permission.id_procesoNavigation.opcion] = true;
      }
      return memo;
    },
    {}
  );

  return mapped;
};

export const headerReportsMapper = (data: {
  clinic: string;
  address: string;
  phone: string;
  mail: string;
  medicalInsurance: string;
  lender: string;
  period: string;
  rebilling: string;
  group: string;
  services: BenefitT[];
  totalPages: number;
  totalAmount: number;
  totalServices: number;
}): {
  title: string;
  data: string;
}[] => {
  return [
    {
      title: "Dirección",
      data: data.address,
    },
    {
      title: "Teléfono",
      data: data.phone,
    },
    {
      title: "Email",
      data: data.mail,
    },

    {
      title: "Prestador",
      data: data.lender,
    },
  ];
};
export const appointmentMapper = (appointment: ScheduleList): Appointment => {
  const {
    id_schedule,
    id_patient,
    id_professional,
    id_office,
    id_user,
    rowversion,
    date,
    is_overturn,
    date_given,
    time,
    patient,
    notes,
    attendance,
    medical_insurance,
    plan_name,
    affiliate_number,
    arrival_time,
    phone,
    patient_navigation,
    user_navigation,
    prof_navigation,
    dont_give,
  } = appointment;

  const patient_name = patient_navigation?.full_name || patient;

  const medical_insurance_name =
    patient_navigation?.plan_navigation?.medical_insurances_navigation
      ?.medical_insurance || medical_insurance;
  const medical_insurance_plan =
    patient_navigation?.plan_navigation?.name || plan_name;
  const profesional =
    prof_navigation &&
    `${prof_navigation.abbreviation} ${prof_navigation.name}`;

  const turnType = () => {
    if (dont_give && is_overturn)
      return {
        type: "overturn",
        status: "canceled",
      };
    if (dont_give)
      return {
        type: "turn",
        status: "canceled",
      };
    if (is_overturn) {
      if (patient)
        return {
          type: "overturn",
          status: "patient_first_time",
        };
      if (id_patient)
        return {
          type: "overturn",
          status: "patient_exist",
        };
      return {
        type: "overturn",
        status: "free",
      };
    }
    if (id_patient || patient) {
      if (patient)
        return {
          type: "turn",
          status: "patient_first_time",
        };
      return {
        type: "turn",
        status: "patient_exist",
      };
    }
    return {
      type: "turn",
      status: "free",
    };
  };

  return {
    id_appointment: id_schedule,
    id_patient: id_patient,
    id_professional: id_professional,
    id_office: id_office,
    id_user: id_user,
    rowversion,
    is_overturn,
    dont_give,
    time,
    date,
    patient_name: dont_give ? "NO CITAR ESTE TURNO" : patient_name,
    patient_phone: patient_navigation?.cell_phone || phone,
    clinic_history_number: patient_navigation?.hc_number ?? "",
    observation: notes,
    attendance: attendance,
    professional_name: profesional,
    mod_by: user_navigation?.name ?? "",
    eta: arrival_time ?? "",
    medical_insurance: {
      name: medical_insurance_name,
      plan: medical_insurance_plan,
      affiliate_number:
        patient_navigation?.affiliate_number || affiliate_number,
    },
    date_given,
    appointment_type: turnType(),
  };
};
export const scheduleMapper = (schedule: ScheduleList[]) =>
  schedule?.map((appointment) => appointmentMapper(appointment));

export const weeklyScheduleMapper = (schedule: ScheduleList[]) => {
  const separated = groupBy(schedule, "day_number");

  const agreggated = DAYS.map((d, i) => {
    if (separated[i + 1]) {
      return {
        schedule: scheduleMapper(separated[i + 1]),
        day: d,
        date: separated[i + 1][0].date.split("T")[0],
      };
    }
    return null;
  });

  return agreggated.filter((x) => x !== null);
};

function groupBy(arr: any[], property: string) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export const professionalScheduleMapper = (schedule: ScheduleList[]) => {
  let filtered = schedule?.filter((s) => s.prof_navigation.name !== "GUARDIA");
  const separated = groupBy(scheduleMapper(filtered), "id_professional");
  const profsIds = Object.keys(separated);
  let professionals: {
    id: string;
    name: string;
    schedule?: ScheduleList[];
  }[] = profsIds.map((id) => {
    const t = schedule?.find((s) => s.id_professional === parseInt(id));

    return {
      name: t?.prof_navigation?.name || "",
      id,
    };
  });

  professionals = professionals.map((p) => {
    return {
      schedule: separated[p.id],
      name: p.name,
      id: p.id,
    };
  });

  professionals = professionals.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return professionals;
};

export const remindersGridMapper = (
  schedule: ScheduleList[],
  id_prof: number
) => {
  const scheduleFiltered = schedule.filter((obj: ScheduleList) => {
    if (id_prof && id_prof !== -1) {
      return obj.id_schedule !== -1 && obj.id_professional === id_prof;
    }
    return obj.id_schedule !== -1;
  });

  return scheduleFiltered.map((turn: ScheduleList) => {
    const {
      id_schedule,
      id_patient,
      patient_navigation,
      id_office,
      office_navigation,
      patient,
      prof_navigation: { abbreviation, name: professional },
      time,
      phone,
      rowversion,
    } = turn;

    return {
      id_schedule,
      id_patient,
      id_office,
      office: office_navigation?.description || "",
      phone: patient_navigation?.cell_phone || phone,
      patient: patient_navigation?.full_name || patient,
      id_prof,
      professional,
      abbreviation,
      hour: time,
      rowversion,
    };
  });
};

export const enabledProfessionals = (professionals: Professional[]) => {
  return professionals?.filter((p) => p.enabled);
};

export const benefitsMapper = (dataSource: Benefit[]) => {
  return dataSource.map((d) => ({
    ...d,
    description: `${d.benefit_code} - ${d.description}`,
  }));
};

export const enabledOffices = (offices: Offices[]) => {
  return offices?.filter((o) => o.enabled);
};

export const enabledLaboratories = (labs: Laboratory[]) => {
  return labs?.filter((l) => l.enabled);
};
