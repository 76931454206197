"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import classNames from "classnames";
import { LuChevronLeft } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  buttonVariants,
  CardTitle,
  Tabs,
  TabsContent,
} from "@/components/atoms";
import { Form, ProgressBar } from "@/components/molecules";
import { toast, useStepBar } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { Laboratory, Offices, Professional } from "@/types/general";
import {
  enabledLaboratories,
  enabledOffices,
  enabledProfessionals,
} from "@/utils/mappers";
import { INITIAL_STEPS } from "@/libs/laboratory";
import { addOrUpdateOrder } from "@/actions/orders";
import { formSchema, resolver } from "./schema";
import { First, Second, Third } from "./steps";

export const LaboratoryForm = ({
  onCancel,
  offices,
  professionals,
  laboratories,
  refresh,
}: {
  onCancel: () => void;
  professionals: Professional[];
  offices: Offices[];
  laboratories: Laboratory[];
  refresh: () => void;
}) => {
  const { patient, startLoading, stopLoading, order_selected } =
    useGlobalStore();
  const [hiddenLoadOrder, setHiddenLoadOrder] = React.useState(false);
  const { steps, currentPosition, setStep, resetSteps } = useStepBar({
    initialSteps: INITIAL_STEPS,
    completeSteps: !!order_selected,
  });
  const ENABLED_PROFESSIONALS = enabledProfessionals(professionals);
  const ENABLED_OFFICES = offices && enabledOffices(offices);
  const ENABLED_LABORATORIES = enabledLaboratories(laboratories);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_order: order_selected?.id_order,
      amount_laboratory: order_selected?.amount,
      date_received: order_selected?.entry_date
        ? new Date(order_selected?.entry_date)
        : undefined,
      description: order_selected?.description || "",
      id_laboratory:
        order_selected?.id_laboratory ||
        ENABLED_LABORATORIES?.[0]?.id_laboratory ||
        0,
      date_order:
        (order_selected?.date_order && new Date(order_selected?.date_order)) ||
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
      is_manual_concept: false,
      paid: order_selected?.paid ? "si" : "no",
      date_delivered: order_selected?.patient_date
        ? new Date(order_selected?.patient_date)
        : undefined,
      date_payment: order_selected?.payment_date
        ? new Date(order_selected?.payment_date)
        : undefined,
      id_patient: patient.id_patient,
      observation: order_selected?.concept || "",
      date_estimated_received:
        (order_selected?.estimate_date &&
          new Date(order_selected?.estimate_date)) ||
        new TZDate(new Date().toISOString(), TIMEZONES.AR),
      id_office: ENABLED_OFFICES?.[0]?.id_office || null,
      link_budget: false,
      id_budget: null,
      link_office: false,
      is_usd: order_selected?.is_usd || false,
      id_concept: null,
      id_professional: ENABLED_PROFESSIONALS?.[0]?.id_professional,
      total_amount_order_list: order_selected?.total_amount || 0,
      total_quantity_order_list:
        order_selected?.lab_items?.reduce(
          (sum: number, el: any) => sum + el.quantity,
          0
        ) || 0,
      invoice_number: order_selected?.rto_invoice_number || "",
      percentage_discount: order_selected?.commission_percentage || 0,
      modify_percentage_prof: !!order_selected?.commission_percentage,
      order_list:
        order_selected?.lab_items?.map((order: any) => {
          return {
            id_concept: order.id_concept,
            id_order: order.id_order,
            id_item: order.id_item,
            code: order.id_concept_navigation?.code || "",
            description: order.id_concept_navigation?.concept || order.concept,
            is_usd: order.id_concept_navigation?.is_usd || false,
            id_order_concept: Math.random(),
            amount: order.id_concept_navigation?.amount || order.amount,
            quantity: order.quantity,
            total_amount: order.quantity * order.amount,
            color: order.color_conc,
            tooth_section: order.piece,
            laboratory_items: order.lab_items,
            laboratory_navigation: order.lab_navigation,
            rowversion: order.rowversion,
          };
        }) || [],
      order_from_list: {
        id_concept: null,
        code: null,
        description: "",
        is_usd: false,
        id_order_concept: Math.random(),
        amount: 0,
        quantity: 1,
        total_amount: 0,
        color: "",
        tooth_section: "",
        laboratory_items: null,
        laboratory_navigation: null,
        rowversion: null,
      },
    },
  });
  const STEPS = [
    { component: First, position: "first" },
    { component: Second, position: "second" },
    { component: Third, position: "third" },
  ];
  const VALUE = STEPS.find(
    (_step, index) => index === currentPosition
  )?.position;

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const orders = values.order_list?.map(
      (c: {
        id_concept: number | null;
        code?: string | null;
        description: string;
        is_usd: boolean;
        id_order_concept: number;
        id_order?: number;
        color?: string;
        id_item?: number;
        amount: number;
        quantity: number;
        total_amount: number;
        tooth_section: string;
        laboratory_items?: any;
        laboratory_navigation?: any;
        rowversion?: string | null;
      }) => {
        let order: { [key: string]: any } = {
          id_item: c?.id_item,
          id_pedido: c?.id_order,
          concepto: c?.id_concept ? "" : c?.description,
          es_usd: c?.is_usd,
          id_concepto: c?.id_concept || null,
          cant: c?.quantity,
          pieza: c?.tooth_section,
          color_conc: c?.color,
          importe: c?.amount,
          id_conceptoNavigation: null,
          id_pedidoNavigation: null,
        };

        return order;
      }
    );

    const response = await addOrUpdateOrder({
      ...order_selected,
      ...values,
      order_list: orders,
      id_user: patient.id_user,
      id_office: patient.id_office,
      paid: values.paid === "si",
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Orden creada con éxito",
        description: "Los datos se verán reflejados en la tabla.",
      });
      refresh();
      onCancel();
    }

    stopLoading();
  }

  return (
    <Form {...form}>
      <CardTitle className="flex items-center gap-x-2 col-span-4 mb-2">
        <span
          onClick={onCancel}
          className={classNames(
            "cursor-pointer",
            buttonVariants({ variant: "outline", size: "icon" })
          )}
        >
          <LuChevronLeft className="size-4" />
          <span className="sr-only">Back</span>
        </span>
        {order_selected ? "Detalles del pedido" : "Nuevo pedido"}
      </CardTitle>
      <form className="w-full grid grid-cols-12 gap-10 mt-0 overflow-hidden">
        <Tabs
          defaultValue="first"
          value={VALUE}
          className="!w-full hidden lg:flex lg:flex-col col-span-full"
        >
          <ProgressBar
            length={3}
            stepsBar={steps}
            onClick={setStep}
            form={form}
          />

          {STEPS.map((Step: any, index: number) => (
            <TabsContent
              key={index}
              value={Step.position}
              className="!w-full !mt-0"
            >
              <Step.component
                form={form}
                data={{
                  professionals: ENABLED_PROFESSIONALS,
                  offices: ENABLED_OFFICES,
                  laboratories: ENABLED_LABORATORIES,
                }}
                setStep={setStep}
                resetSteps={resetSteps}
                onSubmit={form.handleSubmit(onSubmit)}
                viewLoadOrder={{
                  hiddenLoadOrder,
                  setHiddenLoadOrder,
                }}
                onCancel={onCancel}
              />
            </TabsContent>
          ))}
        </Tabs>
      </form>
    </Form>
  );
};
