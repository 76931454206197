import React from "react";
import { SearchPatient } from "@/components/organisms";
import { useGlobalStore } from "@/providers/global-provider";
import { searchPatientsList } from "@/actions/patients";
import { useOnClickOutside, useDebouncedSearch, toast } from "@/hooks";
import { Checkbox } from "@/components/atoms";

export const FloattingSearchBar: React.FC<{
  onSubmit: any;
}> = ({ onSubmit }) => {
  const { setPatientsList, patient_list, resetPatiensList } = useGlobalStore();
  const [query, setQuery] = React.useState("");
  const [contained, setContained] = React.useState(false);
  const [hasSearched, setHasSearched] = React.useState(false);
  const REF_WRAPPER = React.useRef(null);

  const handleSearchPatient = async (query: string) => {
    try {
      const PATIENTS_LIST = await searchPatientsList({
        name: query,
        contained,
      });
      setPatientsList(PATIENTS_LIST);
      setHasSearched(true);
      return patient_list;
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    }
  };
  const SHOW_TABLE =
    useDebouncedSearch(query, handleSearchPatient) && patient_list && query;

  useOnClickOutside({
    ref: REF_WRAPPER,
    handler: () => {
      setQuery("");
      resetPatiensList();
    },
  });

  React.useEffect(() => {
    if (!query) {
      resetPatiensList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <section ref={REF_WRAPPER} className="flex items-start gap-x-4">
      <SearchPatient
        onSubmit={onSubmit}
        floating
        showTable={!!SHOW_TABLE}
        onChange={(value: string) => {
          setQuery(value);
        }}
        query={query}
        search={hasSearched}
        className="!w-[850px]"
        showInput={false}
        contained={contained}
        setContained={setContained}
      />
      <div className="flex gap-x-4 items-center">
        <Checkbox
          id="contained"
          checked={contained}
          onClick={() => setContained(!contained)}
        />
        <p className="text-sm w-full font-medium leading-none text-slate-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Que esté contenido
        </p>
      </div>
    </section>
  );
};
