import React from "react";
import classNames from "classnames";
import { BadgeSelect } from "@/components/molecules/badge-select";
import { HiDotsVertical } from "react-icons/hi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { ATTENDANCE_OPTIONS } from "@/libs/schedule";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  Badge,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/atoms";
import { formatPhoneNumberWhatsapp } from "@/utils/helpers";
import { Translations } from "@/types/general";
import { ContextMenu } from "../context-menu";

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  getValue: (value: number | string) => any;
  original: any;
};

export const columns = ({
  actions,
  translations,
  permissions,
  config,
}: {
  actions: any;
  translations: Translations;
  changeDataTable?: string;
  permissions: any;
  config: {
    region: string;
    countries: any[];
  };
}) => {
  const { viewContactData } = permissions;
  const { region, countries } = config;

  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <span className="sr-only">Abrir menu</span>
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
            <ContextMenu
              type={appointment_type.status}
              appointment={row.original}
              actions={actions}
              permissions={permissions}
            />
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "time",
      header: "Hora",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time, eta } = row.original;
        const showStatus =
          appointment_type.type === "overturn" ||
          appointment_type.status === "canceled";
        const statusCss = classNames(
          "rounded-full p-1 h-1.5 w-1.5 rounded-full",
          appointment_type.type === "overturn" && "bg-purple-400",
          appointment_type.status === "canceled" && "bg-red-500"
        );
        const appointmentCss = classNames(
          "font-regular text-base text-slate-800",
          showStatus && "mr-2",
          eta && "underline hover:cursor-pointer"
        );

        return (
          <div>
            <div className="flex items-center">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={classNames(
                        "flex items-center hover:underline hover:cursor-pointer"
                      )}
                    >
                      <p className={appointmentCss}>{time}</p>
                    </div>
                  </TooltipTrigger>
                  {eta && (
                    <TooltipContent className="!bg-black text-white">
                      <p>Hora de llegada: {eta}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
              {showStatus && <div className={statusCss} />}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "attendance",
      header: "Presentismo",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { patient_name, time, id_patient, dont_give } = row.original;
        const attendance = ATTENDANCE_OPTIONS.find(
          (att) => att.id === row.getValue("attendance")
        );

        if ((!id_patient && !patient_name) || dont_give) {
          return null;
        }

        const badgeCss = classNames(
          attendance?.styles,
          "lg:w-full md:justify-between"
        );

        return (
          <BadgeSelect
            text={attendance?.id || "-"}
            className={badgeCss}
            options={ATTENDANCE_OPTIONS}
            onSelect={(e) =>
              actions({
                appointment: row.original,
                type: "change_attendance",
                attendance: e.target.id,
              })
            }
          />
        );
      },
    },
    {
      accessorKey: "patient_name",
      header: "Paciente",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          patient_name,
          date,
          professional_name,
          time,
          patient_phone,
          clinic_history_number,
          medical_insurance: { name, plan, affiliate_number },
        } = row.original;

        const messageDate = new Date(date).toLocaleDateString("es", {
          day: "numeric",
          year: "numeric",
          month: "short",
        });
        const message = `Hola, ${patient_name}! Te recordamos tu turno con *${professional_name}* el dia *${messageDate}* a las *${time}hs*. _Para cancelar o modificar el turno no dudes en contactarnos._ Te esperamos!`;
        const areaNumber =
          countries?.find(
            (country: any) => country.iso3 === region?.toUpperCase()
          )?.phoneCode || 54;

        const onClickHandler = () => {
          const baseUrl = "https://wa.me/";
          const VALIDATE_NUMBER = formatPhoneNumberWhatsapp(
            patient_phone,
            areaNumber
          );
          const completeUrl = `${baseUrl}${VALIDATE_NUMBER}?text=${message}`;
          window.open(completeUrl, "_blank");
        };

        return (
          <div className="md:min-w-[200px]">
            <div className="flex flex-col-reverse items-start 2xl:flex-row 2xl:items-center">
              <HoverCard>
                <HoverCardTrigger>
                  <p
                    className={classNames(
                      "font-bold text-sm text-slate-800 my-1 hover:underline hover:cursor-pointer"
                    )}
                  >
                    {patient_name}
                  </p>
                </HoverCardTrigger>
                <HoverCardContent className="!w-full">
                  {clinic_history_number || patient_phone ? (
                    <>
                      <h4 className="text-sm font-bold">Datos del paciente</h4>
                      <div className="grid grid-cols-2 gap-5 mt-2">
                        {clinic_history_number && (
                          <div className="flex items-end">
                            <p className="text-sm text-slate-800 mr-2">
                              Historia clínica:
                            </p>
                            <Badge className="w-auto !inline">
                              {clinic_history_number}
                            </Badge>
                          </div>
                        )}
                        {patient_phone && viewContactData && (
                          <div className="flex items-end">
                            <p className="text-sm text-slate-800 mr-2">
                              Tel:{" "}
                              <span className="text-slate-500">
                                {patient_phone}
                              </span>
                            </p>
                            <span onClick={onClickHandler}>
                              <AiOutlineWhatsApp
                                className="ml-2 w-4 h-4 hover:cursor-pointer"
                                fill="#28D146"
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      {name && (
                        <>
                          <h4 className="text-sm font-bold mt-4">
                            {translations.medical_insurance_data}
                          </h4>
                          <div className="grid grid-cols-2 gap-5 mt-2">
                            {name && (
                              <p className="text-sm text-slate-800">
                                {`${translations.medical_insurance_abbreviation}: `}
                                <span className="text-slate-500">
                                  {name} - {plan}
                                </span>
                              </p>
                            )}
                            {affiliate_number && (
                              <p className="text-sm text-slate-800">
                                Nro. Afiliado:{" "}
                                <span className="text-slate-500">
                                  {affiliate_number}
                                </span>
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <h4 className="text-sm font-bold">
                      No hay datos para mostrar
                    </h4>
                  )}
                </HoverCardContent>
              </HoverCard>
            </div>
          </div>
        );
      },
    },
  ];
};
