"use client";

import React from "react";
import { toast } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { deleteMedicalHistory } from "@/actions/patients";
import { useGlobalStore } from "@/providers/global-provider";

export function DeleteMedicalHistoryForm({
  closeModal,
  loading,
  setLoading,
  refreshData,
}: {
  closeModal: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  refreshData: () => void;
}) {
  const {
    patient_history: { history_selected },
  } = useGlobalStore();
  const onSubmit = async () => {
    setLoading(true);
    try {
      await deleteMedicalHistory(history_selected);
      refreshData();
      closeModal(false);
      toast({
        variant: "successful",
        title: "Historia clínica eliminada corectamente",
        description: "Los datos se veran reflejados en la tabla.",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span className="text-base font-semibold text-slate-500">
        Estás por eliminar una historia clínica y esta acción es irreversible,
        la historia clínica se eliminará definitivamente.
      </span>
      <div className="flex justify-end gap-x-5 mt-2">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-hist-cli">
            Aceptar
          </Button>
        )}
      </div>
    </>
  );
}
