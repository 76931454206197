import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  med_ins_to_copy: z.array(z.number()),
  manager_code: z.string(),
  lender_code: z.string(),
  name_data_base: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
