import React from "react";
import { Combobox, Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Form, Laboratory, LaboratoryConcept } from "@/types/general";
import { formSchema } from "../schema";

export const SearchConcept: React.FC<{
  form: Form<typeof formSchema>;
  laboratories: Laboratory[];
}> = ({ form, laboratories }) => {
  const {
    is_manual_concept,
    id_laboratory,
    is_usd,
    order_from_list,
    id_concept,
  } = form.watch();
  const firstMount = React.useRef<boolean>(true);
  const CONCEPTS =
    laboratories
      ?.find((lab: Laboratory) => lab.id_laboratory === id_laboratory)
      ?.lab_concepts.filter((concept) => !!concept.is_usd === is_usd)
      .map((concept) => ({
        ...concept,
      })) || [];
  React.useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    if (!is_manual_concept) {
      const concept_selected = CONCEPTS.find(
        (concept: LaboratoryConcept) => concept?.id_concept === id_concept
      );

      return form.setValue("order_from_list", {
        ...order_from_list,
        ...concept_selected,
        is_usd: concept_selected?.is_usd ?? false,
        total_amount:
          order_from_list.quantity * (concept_selected?.amount || 0),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_concept]);
  React.useEffect(() => {
    order_from_list.id_concept &&
      form.setValue("id_concept", order_from_list.id_concept);
  }, []);

  return (
    <div className="col-span-2">
      {is_manual_concept ? (
        <FormField
          control={form.control}
          name="order_from_list.description"
          render={({ field }) => (
            <FormItem className="col-start-1">
              <FormLabel>Concepto</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ) : (
        <Combobox
          form={form}
          data={CONCEPTS}
          dataKey="id_concept"
          value="description"
          placeholder="Buscar un concepto"
          title="concepto"
          className="col-span-3"
          classContent="!w-[600px]"
          label="Concepto"
        />
      )}
    </div>
  );
};
