"use client";

import React from "react";
import { toast } from "@/hooks";

export const useDebouncedSearch = <T>(
  query: string,
  searchFunction: (query: string) => Promise<T>,
  delay: number = 500
) => {
  const [results, setResults] = React.useState<T | []>([]);

  React.useEffect(() => {
    const search = setTimeout(async () => {
      try {
        if (query.length >= 3) {
          const result = await searchFunction(query);
          setResults(result);
        }
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: error.message,
        });
      }
    }, delay);

    return () => {
      clearTimeout(search);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, delay]);

  return results;
};
