import React from "react";
import classNames from "classnames";
import {
  GroupingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { LuGroup, LuChevronDown } from "react-icons/lu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { Supplier } from "@/types/general";

export function Data<TData, TValue>({
  columns,
  data: { data, totals },
}: {
  columns: any;
  data: {
    data: Supplier[];
    totals: {
      total_amount: number;
    };
  };
}) {
  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const table = useReactTable({
    data,
    columns,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      grouping,
    },
  });

  return (
    <div className="rounded-md mx-auto w-full bg-white border">
      <Table className="rounded-md max-h-[calc(100vh-155px)]">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              className="sticky top-0 bg-white z-10 shadow"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div className="flex items-center">
                        {header.column.getCanGroup() ? (
                          <div
                            onClick={header.column.getToggleGroupingHandler()}
                            className="hover:cursor-pointer hover:underline flex gap-x-2"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <LuGroup
                              className={classNames("size-5", {
                                "text-slate-800": header.column.getIsGrouped(),
                                "text-slate-400": !header.column.getIsGrouped(),
                              })}
                            />
                          </div>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row: any) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="hover:cursor-pointer border-slate-200 border-b-px"
              >
                {row.getVisibleCells().map((cell: any) => (
                  <TableCell
                    onClick={row.getToggleExpandedHandler()}
                    key={cell.id}
                    className={classNames(
                      cell.getIsGrouped()
                        ? "bg-gray-50"
                        : cell.getIsAggregated()
                        ? "bg-blue-50"
                        : cell.getIsPlaceholder()
                        ? "bg-gray-50"
                        : "white"
                    )}
                  >
                    {cell.getIsGrouped() ? (
                      <div className="text-left flex items-center text-slate-600 gap-x-1">
                        ({row.subRows.length})
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        <LuChevronDown
                          className={classNames(
                            "size-5 transform transition-transform ease-in-out duration-200",
                            row.getIsExpanded() ? "rotate-180" : "rotate-0"
                          )}
                        />
                      </div>
                    ) : cell.getIsAggregated() ? (
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Sin resultados.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow className="bg-slate-100 w-full sticky bottom-0 z-10 text-base">
            <TableCell colSpan={4} className="font-normal">
              <p>Total</p>
              <p className="text-sm text-slate-400">Registros {data.length}</p>
            </TableCell>
            <TableCell className="font-normal text-right">
              <p>Importe total</p>
              <p
                className={classNames("text-sm", {
                  "text-red-500": totals.total_amount < 0,
                  "text-green-500": totals.total_amount > 0,
                  "text-slate-600": totals.total_amount === 0,
                })}
              >
                {numberToCurrency(totals.total_amount)}
              </p>
            </TableCell>
            <TableCell colSpan={13} />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}
