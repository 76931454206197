import React from "react";
import { Budget } from "@/types/patients";
import { Checkbox, Combobox, Label } from "@/components/atoms";
import { Form } from "@/types/general";
import { formSchema } from "./schema";

export const SelectBudget: React.FC<{
  budgets: Budget[];
  form: Form<typeof formSchema>;
  useUsd: { user: boolean; params: boolean };
}> = ({ budgets, form, useUsd }) => {
  const { includes_budget } = form.watch();
  const FILTERED_BUDGETS =
    (useUsd.params &&
      !useUsd.user &&
      budgets?.filter((budget: Budget) => !budget.is_usd)) ||
    budgets;

  return (
    <div className="col-span-full flex">
      <Checkbox
        checked={includes_budget}
        onCheckedChange={() => {
          !includes_budget && form.setValue("id_budget", 0);
          form.setValue("includes_budget", !includes_budget);
        }}
      />
      <div className="leading-none flex flex-col mx-2 w-56">
        <Label>Posee presupuesto</Label>
        <p className="text-sm font-regular text-gray-400">
          Indica si el pago corresponde a un presupuesto emitido
        </p>
      </div>
      <Combobox
        disabled={!includes_budget}
        form={form}
        data={FILTERED_BUDGETS}
        dataKey="id_budget"
        value="description"
        placeholder="Busca un presupuesto"
        title="presupuesto"
        classContent="!w-[300px]"
        label="Presupuestos"
      />
    </div>
  );
};
