"use client";

import React from "react";
import { EmptyState } from "@/components/molecules";
import { columns } from "./columns";
import { Data } from "./data";
import { Supplier } from "@/types/general";

export const Table: React.FC<{
  suppliers: {
    data: Supplier[];
    totals: {
      total_amount: number;
    };
  };
}> = ({ suppliers }) => {
  return suppliers?.data?.length > 0 ? (
    <Data
      columns={columns}
      data={{
        data: suppliers.data,
        totals: {
          total_amount: suppliers?.totals?.total_amount,
        },
      }}
    />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
