import { user } from "@/utils/tranformers";
import { Permissions } from "@/types/permissions";
import { EmptyObj } from "@/types/stores";
import {
  WebUser,
  MedicalInsurance,
  Offices,
  Professional,
  UserParams,
  Translations,
  Regions,
  User,
  Laboratory,
} from "@/types/general";

type GeneralParams = {
  medical_insurances: MedicalInsurance[];
  professionals: Professional[];
  offices: Offices[];
  user_params: UserParams;
  translations: Translations;
  laboratories: Laboratory[];
  [key: string]: any;
};

type State = {
  user: (User & WebUser) | any;
  permissions: Permissions | EmptyObj;
  general_params: GeneralParams | EmptyObj;
  is_demo: boolean;
  region: string;
  countries: Regions[];
};

type Actions = {
  setUser: (user: any, params: any, webuser: string) => void;
  updateUser: (key: string, param: any) => void;
  updateParams: (key: string, params: { [key: string]: any }) => void;
};

type UserStore = State &
  Actions & {
    [key: string]: any;
  };

export const initState: State = {
  user: {},
  permissions: {},
  general_params: {},
  is_demo: false,
  region: "",
  countries: [],
};

export const UserSlice = (
  set: (partial: Partial<UserStore>) => void,
  get: () => UserStore
) => ({
  ...initState,
  setUser: (
    data: {
      user: User;
      permissions: Permissions;
      region: string;
      countries: Regions[];
    },
    params: GeneralParams,
    webuser: string
  ) => {
    const is_demo = !!["demo", "demo1"].includes(webuser);

    set({
      user: { ...data?.user, web_user: webuser } as User & WebUser,
      permissions: data?.permissions,
      general_params: params,
      is_demo,
      region: data.region,
      countries: data.countries,
    });
  },
  updateUser: (key: string, param: any) => {
    const state = get();

    set({
      user: { ...state.user, [key]: param },
    });
  },
  updateParams: (key: string, params: { [key: string]: any }) => {
    const state = get();

    set({
      ...state,
      [key]: {
        ...state[key],
        ...params,
      },
    });
  },
});
