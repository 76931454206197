import React from "react";
import { z } from "zod";
import { toast } from "@/hooks";
import { CardDescription, CardTitle, Dialog } from "@/components/atoms";
import { BudgetServicesTable } from "@/components/organisms/tables";
import { useGlobalStore } from "@/providers/global-provider";
import { ServicePerformed } from "@/components/organisms/forms/patients/budgets/benefits/services-performed";
import { formSchema } from "../schema";

export const Benefits: React.FC<any> = ({
  setStep,
  form,
  viewLoadBenefit,
  setActiveTab,
}) => {
  const { list_benefits, selected_benefit } = form.watch();
  const {
    user: { is_supervisor },
    permissions,
  } = useGlobalStore();
  const [formBenefit, setFormBenefit] = React.useState<boolean>(false);
  const actions = (
    selectedRow: z.infer<typeof formSchema>["selected_benefit"],
    type: string
  ) => {
    const menu: {
      [key: string]: (
        selectedRow: z.infer<typeof formSchema>["selected_benefit"]
      ) => void;
    } = {
      edit: (selectedRow: z.infer<typeof formSchema>["selected_benefit"]) => {
        if (
          !is_supervisor &&
          !permissions.hasOwnProperty("Modificar Presupuesto")
        ) {
          toast({
            variant: "warning",
            title: "Ups! No puede realizar esta acción",
            description: "No posee los permisos para modificar el presupuesto.",
          });
        } else {
          setStep(1);
          const BENEFIT_SELECTED = list_benefits?.find(
            (benefit: { [key: string]: any }) =>
              benefit.id_budget_benefit === selectedRow.id_budget_benefit
          );
          form.setValue("is_manual_benefit", !selectedRow.benefit_code);
          form.setValue("selected_benefit", {
            ...selected_benefit,
            ...BENEFIT_SELECTED,
          });
          viewLoadBenefit.setHiddenLoadBenefit(true);
        }
      },
      delete: (selectedRow: z.infer<typeof formSchema>["selected_benefit"]) => {
        const BENEFIT_SELECTED = list_benefits?.filter(
          (b: { [key: string]: number | string }) =>
            b.id_budget_benefit !== selectedRow.id_budget_benefit ||
            b.id_benefit !== selectedRow.id_benefit
        );
        form.setValue("list_benefits", BENEFIT_SELECTED);
      },
      mark_done: (
        selectedRow: z.infer<typeof formSchema>["selected_benefit"]
      ) => {
        handleMarkAsCompleted(selectedRow);
      },
    };

    return menu[type](selectedRow);
  };
  const handleMarkAsCompleted = (
    selectedRow: z.infer<typeof formSchema>["selected_benefit"]
  ) => {
    const BENEFIT_SELECTED = list_benefits?.find(
      (benefit: { [key: string]: any }) =>
        benefit.id_budget_benefit === selectedRow.id_budget_benefit
    );
    form.setValue("selected_benefit", {
      ...selected_benefit,
      ...BENEFIT_SELECTED,
    });
    if (form.formState.isDirty) {
      return toast({
        variant: "warning",
        title: "No puede realizar esta accion",
        description:
          "La prestacion ha sido modificada, guarde el presupuesto para continuar.",
      });
    }
    setFormBenefit(true);
  };

  return (
    <>
      <div className="mb-4">
        <CardTitle>Prestaciones del presupuesto</CardTitle>
        <CardDescription className="xl:max-w-2xl mt-2">
          Abajo se encuentran la lista de prestaciones incluidas en tu
          presupuesto, puedes editarlas o marcar como realizada las prestaciones
          no manuales, y se incluirán en el odontograma de tu paciente.
        </CardDescription>
      </div>
      <BudgetServicesTable data={list_benefits} action={actions} />
      <Dialog onOpenChange={setFormBenefit} open={formBenefit}>
        <ServicePerformed
          benefit={selected_benefit}
          hiddenView={setFormBenefit}
          setActiveTab={setActiveTab}
        />
      </Dialog>
    </>
  );
};
