"use client";

import React from "react";
import { z } from "zod";
import Image from "next/image";
import { useForm } from "react-hook-form";
import { LuEyeOff } from "react-icons/lu";

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Input,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/molecules";
import { editDescription } from "@/actions/archives";
import { toast, useKeypress } from "@/hooks";
import {
  checkFileType,
  checkUrl,
  getSourceFromFileType,
} from "@/utils/helpers";
import { useGlobalStore } from "@/providers/global-provider";
import { DeleteImage } from "../delete";
import { formSchema, resolver } from "../schema";

export const EditImage = ({
  closeModal,
  refresh,
}: {
  closeModal: (value: boolean) => void;
  refresh: () => void;
}) => {
  const {
    startLoading,
    stopLoading,
    loading,
    patient_gallery: { archive },
  } = useGlobalStore();
  const [editEnabled, setEditEnabled] = React.useState<boolean>(false);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const DELETE_ARCHIVE = [archive];
  const FORM = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_patient: archive.id_patient,
      description: archive.description,
      date: archive.date,
      file_data: archive.file_data,
      file_type: archive.file_type,
      id: archive.id,
      image: archive.image,
      large: archive.large,
      medium: archive.medium,
      small: archive.small,
      rowversion: archive.rowversion,
      rute: archive.rute,
      size: archive.size,
    },
  });
  const { formState } = FORM;
  const RUTE = FORM.getValues("rute");
  const FILE_TYPE = checkFileType(archive.rute);
  const IMG_SOURCE = getSourceFromFileType(FILE_TYPE);
  const closeDeleteModal = (value: boolean) => {
    setDeleteModal(value);
    closeModal(value);
  };
  const onSubmit = async (archives: z.infer<typeof formSchema>) => {
    startLoading();
    const ARCHIVE = {
      ...archives,
      descripcion: archives.description?.trim(),
    };
    const response = await editDescription(ARCHIVE);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "La descripción se ha modificado exitosamente",
        description: "Los datos de tu cuenta han sido actualizados.",
      });
      refresh();
      closeModal(false);
    }

    stopLoading();
  };
  useKeypress({ key: "Enter", action: FORM.handleSubmit(onSubmit) });

  return (
    <>
      <DialogHeader>
        <DialogTitle>Detalles del archivo</DialogTitle>
      </DialogHeader>
      <Form {...FORM}>
        <form onSubmit={FORM.handleSubmit(onSubmit)}>
          {checkUrl(archive.rute) ? (
            IMG_SOURCE === "IMG" ? (
              <Image
                src={archive.large}
                alt="imagen"
                className="max-h-[500px] w-full object-contain"
              />
            ) : (
              <iframe
                id="fileFrame"
                title={FORM.getValues("description")}
                width="800"
                height="590"
                src={RUTE}
              />
            )
          ) : (
            <div className="flex-col px-20">
              <LuEyeOff className="w-8 h-8 mx-auto mb-2 text-blue-600" />
              <p className="text-center text-slate-600">
                Este archivo no se puede previsualizar en este dipositivo
              </p>
            </div>
          )}
          <div className="grid grid-cols-12 gap-x-4 mt-10 mb-2">
            <FormField
              control={FORM.control}
              name="description"
              render={({ field }) => (
                <FormItem className=" col-span-6 h-10 w-full">
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      disabled={!editEnabled}
                      className={`${!editEnabled && "!bg-gray-200"}`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {editEnabled ? (
              <>
                {loading ? (
                  <ButtonLoading className="col-span-3" />
                ) : (
                  <Button
                    type="submit"
                    className="px-4 py-2 col-span-3 save-archive"
                    disabled={loading || !formState.isDirty}
                    onClick={FORM.handleSubmit(onSubmit)}
                  >
                    Guardar
                  </Button>
                )}
              </>
            ) : (
              <Button
                type="button"
                className="px-4 py-2 col-span-3 edit-archive"
                onClick={() => setEditEnabled(!editEnabled)}
              >
                Modificar
              </Button>
            )}
            <Button
              type="button"
              variant="destructive"
              className="px-4 py-2 col-span-3"
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              Eliminar
            </Button>
          </div>
          <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
            <DialogContent>
              <DeleteImage
                closeModal={closeDeleteModal}
                refresh={refresh}
                archive={DELETE_ARCHIVE}
              />
            </DialogContent>
          </Dialog>
        </form>
      </Form>
    </>
  );
};
