import { getDailyStatisticsDefaultPeriod } from "@/utils/date";
import { InitDailies, WeekliesData } from "@/types/statistics";

export const initWeeklies: WeekliesData[] = [
  {
    title: "Pacientes nuevos",
    color: "#007aff",
    data: [],
  },
  {
    title: "Pacientes atendidos",
    color: "#ffb600",
    data: [],
  },
  {
    title: "Turnos agendados",
    color: "#09d242",
    data: [],
  },
];

export const initDailies: InitDailies[] = [
  {
    title: "Turnos con pacientes ya atendidos",
    description: "Mira la lísta de pacientes que ya has antendido previamente",
    data: [],
  },
  {
    title: "Turnos con pacientes nuevos",
    description: "Mira la lísta de pacientes que se atenderán por primera vez",
    data: [],
  },
  {
    title: "Cumpleaños de pacientes que ya conoces",
    description: "Envíales un saludo",
    data: [],
  },
  {
    title: "Pedidos de laboratorios deberían ingresar hoy",
    description: "",
    data: [],
  },
];

export const colors: { original: string; comparison: string }[] = [
  { original: "#3B82F6", comparison: "#F59E0B" },
  { original: "#3B82F6", comparison: "#93C5FD" },
  { original: "#8B5CF6", comparison: "#C084FC" },
  { original: "#4299E1", comparison: "#93C5FD" },
  { original: "#8B5CF6", comparison: "#C084FC" },
  { original: "#F59E0B", comparison: "#FBBF24" },
  { original: "#F59E0B", comparison: "#FBBF24" },
  { original: "#F59E0B", comparison: "#FBBF24" },
  { original: "#F59E0B", comparison: "#FBBF24" },
  { original: "#F59E0B", comparison: "#FBBF24" },
];

export const namesGraphics: {
  [key: string]: {
    title: string;
    subtitle: string;
    translations: boolean;
  };
} = {
  total_billed: {
    title: "Facturación total",
    subtitle: "Sumatoria pagos por paciente, más importes a cargo de cada ",
    translations: true,
  },
  payment_method_billing: {
    title: "Facturación por forma de pago",
    subtitle: "Basado en las formas de pago en caja.",
    translations: false,
  },
  medical_insurance_billing: {
    title: "Facturación por ",
    subtitle: "Incluye los importes de facturación de cada ",
    translations: true,
  },
  most_profitable_patient: {
    title: "Rentabilidad de paciente por pago en caja",
    subtitle: "Incluye pacientes más rentables.",
    translations: false,
  },
  most_performed_practice: {
    title: "Práctica más realizada",
    subtitle: "Incluye las prácticas más realizadas.",
    translations: false,
  },
  most_profitable_practice: {
    title: "Práctica más rentable",
    subtitle: "Incluye las prácticas con mayor rentabilidad.",
    translations: false,
  },
  cash_in_drawer: {
    title: "Dinero en caja",
    subtitle: "Incluye los pagos realizados por pacientes.",
    translations: false,
  },
  billing_to_medical_insurance: {
    title: "Facturación por ",
    subtitle: "Importe de las prestaciones a cargo de cada",
    translations: true,
  },
  average_patient_ticket: {
    title: "Ticket promedio por paciente",
    subtitle: "Ingreso promedio por paciente.",
    translations: false,
  },
  average_practice_value: {
    title: "Valor de práctica promedio",
    subtitle: "Importe promedio por práctica.",
    translations: false,
  },
};

export const variationIcons: {
  [key: string]: { [key: string]: string };
} = {
  increase: {
    sumOrRest: "+",
    arrow: "↑",
    className: "text-green-500",
  },
  decrease: {
    sumOrRest: "-",
    arrow: "↓",
    className: "text-red-500",
  },
};

const { firstday, lastday } = getDailyStatisticsDefaultPeriod();
export const INITAL_PARAMS = {
  id_med_ins: null,
  all_med_ins: true,
  all_prof: true,
  disabled_med_ins: false,
  disabled_prof: false,
  all_offices: true,
  disabled_offices: false,
  id_professional: null,
  id_office: null,
  date_comparison: {
    date_from: firstday,
    date_to: lastday,
    comparation_date_from: null,
    comparation_date_to: null,
  },
  badges: {
    principal: "Últimos 7 días",
    compare: "Mismo periodo del año anterior",
  },
  is_usd: false,
  group_date: "day",
  group_by_med_ins: "medIns",
};
