import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms";

export const GenerateFile = ({ onClick }: { onClick: any }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-full lg:w-36" variant="ghost">
          Generar archivo
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Aviso</DialogTitle>
          <DialogDescription>
            ¿Seguro que desea subir el archivo a la nube?
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-3 gap-x-5">
          <DialogClose>
            <Button className="w-full" variant="destructive">
              Cancelar
            </Button>
          </DialogClose>
          <DialogClose>
            <Button className="w-full" onClick={onClick}>
              Subir archivo
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
