"use client";

import React from "react";
import { z } from "zod";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  CardFooter,
  Checkbox,
  Input,
} from "@/components/atoms";
import { toast, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { MedicalInsurance } from "@/types/general";
import { updateMedicalInsurance } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function NewMedicalInsuranceDataForm({
  selected,
}: {
  selected?: MedicalInsurance;
}) {
  const {
    loading,
    startLoading,
    stopLoading,
    general_params: { translations },
  } = useGlobalStore();
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_med_ins: selected?.id_med_ins || 0,
      code_med_ins: selected?.code_med_ins || "",
      medical_insurance: selected?.medical_insurance || "",
      address: selected?.address || "",
      city: selected?.city || "",
      state: selected?.state || "",
      phone: selected?.phone || "",
      fax: selected?.fax || "",
      email: selected?.email || "",
      tax_number: selected?.tax_number || "",
      client_ftp: selected?.client_ftp || "",
      load_for_period: selected?.load_for_period || false,
      benefits_quantity: selected?.benefits_quantity || 0,
      iva_ob: selected?.iva_ob || 0,
      iva_ad: selected?.iva_ad || 0,
      observations: selected?.observations || "",
      disabled: selected?.disabled ? false : true, //TODO: change name in combobox
      rowversion: selected?.rowversion || null,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await updateMedicalInsurance({
      medicalInsurance: values as MedicalInsurance,
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Éxito!",
        description: `${translations.select_medical_insurance} se guardó correctamente.`,
      });
      await router.replace(
        `/dashboard/configuration/tables/medical-insurances/new?id=${response.id_med_ins}`
      );
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-x-2">
          {selected
            ? translations?.config.edit_med_ins
            : translations?.config.new_med_ins}
        </CardTitle>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="gap-4 grid grid-cols-12 overflow-y-auto max-h-[calc(100vh-330px)]">
            <FormField
              control={form.control}
              name="code_med_ins"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Código</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="medical_insurance"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>{translations?.medical_insurance}</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Dirección</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Localidad</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Provincia</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Teléfono</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tax_number"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>{translations?.taxes}</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="client_ftp"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Cliente FTP</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="load_for_period"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-5 self-start">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                    />
                  </FormControl>
                  <div className="leading-none mt-0 flex flex-col">
                    <FormLabel>Cargar por período</FormLabel>
                    <FormDescription className="text-slate-500">
                      Se cargaran las prestaciones del periodo correspondientes,
                      utilizando el formato AAAAMM.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="benefits_quantity"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Cantidad de prestaciones</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="0 = Sin límites"
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="observations"
              render={({ field }) => (
                <FormItem className="col-span-8">
                  <FormLabel>Observaciones</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <h2 className="text-xl font-semibold leading-none tracking-tight text-slate-900 col-span-12">
              IVA sobre paciente
            </h2>
            <FormField
              control={form.control}
              name="iva_ob"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Obligados / No grabados (%)</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iva_ad"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Adherentes / Grabados (%)</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="disabled"
              render={({ field }) => (
                <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-start justify-end">
                  <FormLabel>
                    {`${translations?.medical_insurance} habilitada`}
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      ref={field.ref}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex flex-row justify-center items-center lg:justify-end gap-x-2">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                router.push(
                  "/dashboard/configuration/tables/medical-insurances"
                );
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button type="submit">Guardar</Button>
            )}
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
