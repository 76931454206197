import React from "react"; /* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, Input, Label, Switch } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/molecules";
import { toast } from "@/hooks";
import { ccFormatNumber, installmentsMapper } from "@/utils/helpers";
import { Form } from "@/types/general";
import { Cards } from "@/types/payments";

const CreditCardMethod: React.FC<{
  form: Form<any>;
  cards: Cards[];
  installments: { [key: string]: any }[];
  setInstallments: (value: { [key: string]: any }) => void;
}> = ({ form, cards, installments, setInstallments }) => {
  const {
    paid,
    interest,
    interest_percentage,
    id_card,
    card_number,
    with_installments,
    id_installment,
  } = form.watch();
  const TOTAL_PAY: number = parseFloat(paid) + parseFloat(interest);

  function setInterest() {
    if (id_installment && paid && paid > 0) {
      const interest = (paid * interest_percentage) / 100;

      return form.setValue("interest", interest);
    } else {
      form.setValue("interest", 0);
    }
  }

  const [card] = React.useMemo(
    () =>
      cards?.filter((c: { [key: string]: any }) => c?.id_card === id_card) ||
      [],

    [cards, id_card]
  );

  React.useEffect(() => {
    if (with_installments) {
      form.setValue(
        "interest_percentage",
        card?.card_installments[0]?.interest || 0
      );
      const INSTALLMENTS = installmentsMapper(card);
      setInstallments(INSTALLMENTS);
      form.setValue(
        "id_installment",
        card?.card_installments?.[0]?.id_installment
      );
    } else if (card && !with_installments) {
      setInstallments([]);
    } else {
      const defaultCard = cards[0];
      form.setValue("id_card", card?.id_card || defaultCard?.id_card || null);
    }
  }, [with_installments, card]);

  React.useEffect(() => {
    if (card) {
      const [installment] = card?.card_installments?.filter(
        (i: { [key: string]: any }) => i.id_installment === id_installment
      );
      form.setValue("interest_percentage", installment?.interest || 0);
    }
  }, [id_installment]);

  React.useEffect(() => {
    setInterest();
  }, [paid, interest_percentage, id_installment]);

  React.useEffect(() => {
    if (card_number) {
      form.setValue("card_number", ccFormatNumber(card_number));
    } else {
      form.setValue("card_number", "");
    }
  }, [card_number]);

  React.useMemo(() => {
    if (!paid) {
      toast({
        variant: "warning",
        title: "Recordatorio para la opcion seleccionada",
        description: "Debe colocar un importe pagado.",
      });

      form.setValue("with_installments", false);
    }
  }, [with_installments]);

  return (
    <>
      <Combobox
        form={form}
        data={cards}
        dataKey="id_card"
        value="card_name"
        showInputSearch={false}
        title="tarjeta de credito"
        className="col-span-3"
        label="Tarjetas de crédito"
      />
      <FormField
        control={form.control}
        name="card_number"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0">
            <Label>Nro. Tarjeta</Label>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="card_cvv"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0">
            <Label className="!mt-24 !mb-4">Nro. Autorización</Label>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="col-span-2">
        <label htmlFor="input" className="mb-3 block text-sm font-medium">
          Con cuotas
        </label>
        <Switch
          checked={with_installments}
          onCheckedChange={(check) => {
            form.setValue("with_installments", check);

            if (!check) {
              form.setValue("id_installment", null);
              return;
            } else {
              form.setValue(
                "id_installment",
                installments?.[0]?.id_installment
              );
            }
          }}
        />
      </div>
      <Combobox
        form={form}
        data={installments}
        dataKey="id_installment"
        value="text"
        placeholder="Selecciona cantidad de cuotas"
        showInputSearch={false}
        title="cuota"
        className="col-span-3"
        label="Cantidad de cuotas"
        disabled={!with_installments}
      />
      <FormField
        control={form.control}
        name="interest_percentage"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0 pointer-events-none">
            <Label className="!mt-24 !mb-4">% Interes</Label>
            <FormControl>
              <Input type="text" {...field} className="bg-slate-50" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="interest"
        render={({ field }) => (
          <FormItem className="col-span-3 space-y-0 !pb-0 pointer-events-none">
            <Label className="!mt-24 !mb-4">Interes</Label>
            <FormControl>
              <Input type="text" {...field} className="bg-slate-50" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex flex-col justify-end col-span-3">
        <p className="text-sm font-regular text-slate-600 mb-2">
          Total a Pagar
        </p>
        <p className="text-2xl font-bold text-slate-600">${TOTAL_PAY}</p>
      </div>
    </>
  );
};

export default CreditCardMethod;
