import React from "react";
import classNames from "classnames";
import { headerSections } from "@/utils/budgets";
import { Card, CardContent } from "@/components/atoms";
import { Form } from "@/types/general";
import { formSchema } from "../schema";
import { Benefits } from "../benefits";

export const Header = ({
  data,
  form,
  setStep,
  setActiveTab,
}: {
  data: {
    header: {
      professional_name: string;
      id_budget: string;
      total_amount_with_discount: number;
      budget_balance: number;
      description: string;
      viewLoadBenefit: {
        hiddenLoadBenefit: boolean;
        setHiddenLoadBenefit: React.Dispatch<React.SetStateAction<boolean>>;
      };
    };
  };
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
  setActiveTab: (value: string) => void;
}) => {
  const {
    professional_name,
    id_budget,
    total_amount_with_discount,
    budget_balance,
    description,
    viewLoadBenefit,
  } = data["header"];
  const sections = headerSections({
    professional_name,
    id_budget,
    total_amount_with_discount,
    budget_balance,
  });

  return (
    <>
      <Card className="col-span-full grid grid-cols-12 shadow-md rounded-md bg-white !p-0 mt-2">
        <span className="col-span-12 text-2xl rounded-t-md bg-white truncate p-4">
          {description}
        </span>
        {sections.map((s, i) => {
          return (
            <CardContent
              key={i}
              className={classNames(
                "border !p-4",
                !!id_budget ? "col-span-3" : "col-span-6"
              )}
            >
              <h3 className="text-slate-500 text-base pb-3">{s.title}</h3>
              <span className={s.styles}>{s.value}</span>
            </CardContent>
          );
        })}
      </Card>
      <Card className="col-span-full mb-4">
        <Benefits
          form={form}
          viewLoadBenefit={viewLoadBenefit}
          setStep={setStep}
          setActiveTab={setActiveTab}
        />
      </Card>
    </>
  );
};
