import classNames from "classnames";
import { Skeleton } from "@/components/atoms";

export function SkeletonCard({ quantity = 1 }) {
  return (
    <div
      className={classNames(
        "grid gap-x-4 my-10",
        quantity && `grid-cols-${quantity}`
      )}
    >
      {Array.from({ length: quantity }).map((_, i) => (
        <div key={i} className="flex flex-col space-y-3">
          <Skeleton className="h-[125px] w-full rounded-xl" />
          <div className="space-y-2">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>
      ))}
    </div>
  );
}
