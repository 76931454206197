"use client";

import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { toast } from "@/hooks";
import {
  deleteMovement,
  getMovements,
  getBankMovement,
} from "@/actions/administration";
import { useGlobalStore } from "@/providers/global-provider";
import { useDialog } from "@/hooks";
import { BanksMovement } from "@/types/general";

export function DeleteMovementForm({ idMovement }: { idMovement: number }) {
  const { closeDialog } = useDialog();
  const {
    startLoading,
    stopLoading,
    loading,
    setDataAdministration,
    administration: { movements },
  } = useGlobalStore();

  const onSubmit = async () => {
    startLoading();
    const response = await getBankMovement(idMovement as number);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      await deleteMovement(response.id_movement as number);
      const { banks: mvnts }: any = await getMovements(movements.params);

      setDataAdministration({
        data: mvnts,
        section: "movements",
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El movimiento bancario se eliminó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar movimiento bancario</DialogTitle>
        <p className="text-slate-500 text-base">
          Está por eliminar un movimiento bancario. Esta acción es irreversible,
          ¿desea continuar?
        </p>
      </DialogHeader>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button className="col-span-2" onClick={onSubmit}>
            Continuar
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
