import React from "react";
import { Visibility, FacetedFilter } from "./filters";

export function TableToolbar<TData>({
  table,
  options,
  config,
}: any & {
  config: {
    is_supervisor: boolean;
    permissions: any;
    useUsd: any;
  };
}) {
  return (
    <div className="flex items-center justify-between mb-2">
      {config.useUsd.params && (
        <FacetedFilter
          title="Filtrar por moneda"
          name="is_usd"
          table={table}
          options={options}
        />
      )}
      <Visibility table={table} />
    </div>
  );
}
