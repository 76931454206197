import React from "react";
import classNames from "classnames";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  Input,
  Button,
  CardContent,
  Combobox,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { numberToCurrency } from "@/utils/currency";
import { roundNumber } from "@/utils/helpers";
import { useGlobalStore } from "@/providers/global-provider";
import { useStateAcordeon } from "@/hooks";
import { PAYMENT_METHOD, TAXES } from "@/libs/patients";
import { InitialPayment } from "./initial-payment";
import { Quota } from "./controller-quota";
import { SelectDiscountType } from "./select-discount-type";

export const Third: React.FC<{
  setStep: (value: number) => void;
  form: any;
}> = ({ setStep, form }) => {
  const { ref_header } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const {
    total_amount_with_discount,
    discount,
    interest_rate,
    id_tax,
    list_benefits,
  } = form.watch();
  React.useEffect(() => {
    const total_amount = list_benefits.reduce(
      (sum: number, benefit: any) => sum + (benefit.total_amount || 0),
      0
    );
    form.setValue("total_amount_benefit_list", total_amount);
    const TAX =
      TAXES.find(
        (tax: { id_tax: number; value: number; text: string }) =>
          tax.id_tax === id_tax
      )?.value || 0;
    const AMOUNT_WHITH_IVA = roundNumber(total_amount * (TAX / 100 + 1));
    const AMOUNT_WHITH_DISCOUNTS =
      discount?.type_discount === "percentage"
        ? AMOUNT_WHITH_IVA - AMOUNT_WHITH_IVA * (discount?.percentage / 100)
        : AMOUNT_WHITH_IVA - discount?.amount;
    const AMOUNT_WHITH_INTEREST = roundNumber(
      AMOUNT_WHITH_DISCOUNTS * (interest_rate / 100 + 1)
    );
    form.setValue("total_amount_with_discount", AMOUNT_WHITH_INTEREST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interest_rate, discount?.percentage, discount?.amount, id_tax]);

  return (
    <>
      <Card
        className={classNames(
          "!rounded-t-none overflow-y-auto !w-full h-[calc(100vh-310px)]",
          {
            "h-[calc(100vh-375px)]":
              STATE_ACCORDION && STATE_ACCORDION !== "closed",
          }
        )}
      >
        <CardHeader>
          <CardTitle>Datos de pago</CardTitle>
          <CardDescription>
            Cargá los datos de tiempo estimado, pagos y observaciones.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid grid-cols-12 gap-5">
          <div className="col-span-3">
            <label className="mb-2 block text-sm font-medium leading-5">
              Valor total
            </label>
            <p className="text-2xl font-bold text-green-700 leading-7">
              {numberToCurrency(total_amount_with_discount)}
            </p>
          </div>
          <FormField
            control={form.control}
            name="estimated_time"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Tiempo estimado</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    {...field}
                    maxLength={20}
                    placeholder="Ej: 3 meses"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="sessions"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Cantidad de sesiones</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    max={99}
                    placeholder="Ej: 3"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Combobox
            form={form}
            data={PAYMENT_METHOD}
            dataKey="id_payment_method"
            value="text"
            title="forma"
            classContent="!w-[300px]"
            className="col-span-3"
            label="Forma de pago"
            showInputSearch={false}
          />
          <SelectDiscountType form={form} />
          <Combobox
            form={form}
            data={TAXES}
            dataKey="id_tax"
            value="text"
            title="iva"
            classContent="!w-[300px]"
            className="col-span-3"
            label="% IVA"
            showInputSearch={false}
          />
          <FormField
            control={form.control}
            name="interest_rate"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>% Interés</FormLabel>
                <FormControl>
                  <Input type="number" {...field} max={99} placeholder="0.0%" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <InitialPayment form={form} />
          <Quota form={form} />
          <FormField
            control={form.control}
            name="observations"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Observaciones</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </CardContent>
      </Card>
      <div className="col-span-full flex justify-end mt-4">
        <Button onClick={() => setStep(3)}>siguiente</Button>
      </div>
    </>
  );
};
