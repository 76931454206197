"use client";

import React from "react";
import classNames from "classnames";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  ColumnFiltersState,
  getFacetedRowModel,
} from "@tanstack/react-table";
import { TableToolbar } from "./toolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms";
import { useStateAcordeon } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { DataTableProps } from "@/types/tables";

export const Data = <TData, TValue>({
  columns,
  data,
  sorting,
  setSorting,
  config,
}: DataTableProps<TData, TValue>) => {
  const { ref_header } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const [globalFilter, setGlobalFilters] = React.useState("");
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    {
      id: "is_usd",
      value: [false],
    },
  ]);
  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilters,
    onSortingChange: setSorting,
    state: {
      globalFilter,
      sorting,
      columnFilters,
    },
  });

  const options = [
    {
      value: true,
      label: "USD",
    },
    {
      value: false,
      label: "Moneda local",
    },
  ];

  return (
    <div>
      <TableToolbar table={table} options={options} config={config} />
      <div className="rounded-md border bg-white" id="patient-result-table">
        <Table
          className={classNames(
            "rounded-md !w-full",
            STATE_ACCORDION !== "open"
              ? "h-[calc(100vh-255px)]"
              : "h-[calc(100vh-320px)]"
          )}
        >
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="sticky top-0 bg-white z-10 shadow-sm"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="hover:cursor-pointer"
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Sin resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
