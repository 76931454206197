import React from "react";
import { SearchFilter,FacetedFilter } from "./filter";

export function TableToolbar({ table, options }: any) {
  return (
    <div className="flex items-center mb-2 w-full gap-3">
      <SearchFilter table={table} />
      <FacetedFilter
        title="Filtrar por moneda"
        name="is_usd"
        table={table}
        options={options}
      />
    </div>
  );
}
