import { Budget } from "@/types/patients";

type State = {
  patient_budget: {
    data: {
      [key: string]: any;
    };
  };
};

type Actions = {
  setPatientBudgets: (payload: { data: Budget[] }) => void;
};

type PatientBudgetsStore = State & Actions;

export const INIT_STATE: State = {
  patient_budget: {
    data: [],
  },
};

export const PatientBudgetsSlice = (
  set: (partial: Partial<PatientBudgetsStore>) => void,
  get: () => PatientBudgetsStore
): PatientBudgetsStore => ({
  ...INIT_STATE,
  setPatientBudgets: ({ data }: { data: Budget[] }) => {
    const state = get();
    set({
      ...state,
      patient_budget: {
        ...state,
        data,
      },
    });
  },
});
