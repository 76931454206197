"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { LuMinimize, LuExpand } from "react-icons/lu";
import { useRouter } from "next/navigation";
import classNames from "classnames";
import {
  CardDescription,
  CardTitle,
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Button,
  Separator,
} from "@/components/atoms";
import {
  DaysOfWeek,
  Form,
  TurnReminder,
  ScheduleLayoutsSelect,
  SpecialtiesSelect,
  OfficesSelect,
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormDescription,
  ProfessionalsSelectSchedule,
  DatePicker,
} from "@/components/molecules";
import { toast, useAutoSubmit, useFilteredProfessionals } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";
import { getAppointments } from "@/actions/schedule";
import { LAYOUTS } from "@/libs/schedule";
import { HeaderFormT } from "@/types/schedule";
import { filterEnabledOffices } from "@/utils/helpers";
import { professionalsByOffice } from "@/utils/filters";
import { Appointment, Offices, Professional, Specialty } from "@/types/general";
import { formSchema, resolver } from "./schema";
import { FindNextAvailableAppt } from "../find-next-available-appointment";

export function SearchForm({
  data: { professionals, specialties, offices },
  actions,
  setOpenModalForm,
}: {
  data: {
    professionals: Professional[];
    specialties: Specialty[];
    offices: Offices[];
  };
  actions: ({
    appointment,
    type,
    attendance,
  }: {
    appointment: Appointment;
    type: string;
    attendance: string;
  }) => void;
  setOpenModalForm: (value: boolean) => void;
}) {
  const router = useRouter();
  const {
    startLoading,
    stopLoading,
    schedule: { params, config },
    setDataSchedule,
    setScheduleParams,
    setScheduleConfig,
  } = useGlobalStore();
  const OFFICES = filterEnabledOffices(offices);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      ...params,
      id_professional: params?.id_professional,
      id_office: params?.id_office || OFFICES?.[0]?.id_office || null,
    },
  });
  const [extendSchedule, setExtendSchedule] = React.useState(
    config?.extend_schedule || false
  );
  const { id_office } = form.watch();
  const FILTER_PROFESSIONALS_BY_OFFICE = id_office
    ? professionalsByOffice({ id_office, professionals })
    : professionals;
  const { PROFESSIONALS } = useFilteredProfessionals({
    professionals: FILTER_PROFESSIONALS_BY_OFFICE,
    form,
    onSubmit: form.handleSubmit(onSubmit),
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const LAYOUT =
      LAYOUTS?.find((l) => l.id_layout === values.id_layout)?.value || "buscar";
    const response = await getAppointments({
      ...values,
      option: LAYOUT,
    });

    if ("error" in response) {
      setDataSchedule({
        data: [],
      });
      toast({
        variant: ["00074"].includes(response?.error)
          ? "warning"
          : "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      setDataSchedule({
        data: response,
      });
    }

    setScheduleParams({
      params: {
        ...values,
        option: LAYOUT,
      },
    });
    stopLoading();
  }
  const setConfiguration = () => {
    setExtendSchedule(!extendSchedule);
    setScheduleConfig({
      config: {
        ...config,
        extend_schedule: !extendSchedule,
      },
    });
  };

  useAutoSubmit({
    trigger: form.trigger,
    watch: form.watch,
    onSubmit: form.handleSubmit(onSubmit),
    debounceTime: 100,
  });

  const LAYOUT =
    (LAYOUTS?.find((l) => l.id_layout === form.getValues("id_layout"))
      ?.value as string) || "buscar";

  return (
    <header className="sticky z-20 top-0 flex shrink-0 items-center justify-between gap-2 border-b bg-white p-3">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="z-50 w-full space-y-8"
        >
          {extendSchedule ? (
            <ExtendHeader
              layout={LAYOUT}
              form={form}
              extendSchedule={extendSchedule}
              professionals={PROFESSIONALS}
              setExtendSchedule={setConfiguration}
              specialties={specialties}
              offices={OFFICES}
              actions={actions}
              router={router}
              setOpenModalForm={setOpenModalForm}
            />
          ) : (
            <CompactHeader
              layout={LAYOUT}
              form={form}
              extendSchedule={extendSchedule}
              professionals={PROFESSIONALS}
              setExtendSchedule={setConfiguration}
              specialties={specialties}
              offices={OFFICES}
              router={router}
              actions={actions}
              setOpenModalForm={setOpenModalForm}
            />
          )}
        </form>
      </Form>
    </header>
  );
}

const ExtendHeader = ({
  layout,
  form,
  extendSchedule,
  professionals,
  setExtendSchedule,
  specialties,
  offices,
  router,
  actions,
  setOpenModalForm,
}: HeaderFormT) => {
  return (
    <div className="flex h-full items-center">
      <div className="w-1/2" id="schedule-view-days">
        <div className="flex !flex-row justify-between items-center">
          <div className="space-y-1">
            <CardTitle className="text-base">Selección de fechas</CardTitle>
            <CardDescription>
              Seleccioná el día que quieres ver y el tipo de agenda.
            </CardDescription>
          </div>
          <TurnReminder
            onClick={(e: any) => {
              e.preventDefault();
              router.push("/dashboard/reminders");
            }}
            extended={extendSchedule}
          />
        </div>
        <div className="mt-4">
          <div className="flex items-center gap-x-4">
            <div className="w-1/2 self-end">
              <DatePicker form={form} name="date" className="h-9" />
            </div>
            <div className="w-1/2 self-end">
              <ScheduleLayoutsSelect
                form={form}
                layouts={LAYOUTS}
                className="col-span-2 flex gap-x-2"
              />
            </div>
          </div>
          <DaysOfWeek
            form={form}
            professionals={professionals}
            className="mt-5"
            extendSchedule={extendSchedule}
          />
        </div>
      </div>
      <Separator orientation="vertical" className="!h-[148px] mx-4" />
      <div className="w-1/2" id="schedule-view-professionals">
        <div className="flex !flex-row justify-between items-center">
          <div className="space-y-1">
            <CardTitle className="text-base">
              Selección de profesional
            </CardTitle>
            <CardDescription>
              Seleccioná el profesional y la especialidad que quieres ver.
            </CardDescription>
          </div>
          <div className="flex items-center gap-x-2">
            <FindNextAvailableAppt
              professionals={professionals}
              form={form}
              actions={actions}
              setOpenModalForm={setOpenModalForm}
            />
            <SwitchSchedule
              extendSchedule={extendSchedule}
              setExtendSchedule={setExtendSchedule}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex items-center gap-x-4">
            <ProfessionalsSelectSchedule
              disabled={["buscarpordiatodosprof"].includes(layout)}
              professionals={professionals}
              form={form}
              className="w-1/2"
            />
            <SpecialtiesSelect
              specialties={specialties}
              form={form}
              className="w-1/2"
            />
          </div>
          <div className="flex items-center gap-x-4 mt-4">
            <FormField
              control={form.control}
              name="filter_professional_by_day"
              render={({ field }) => (
                <FormItem className="w-1/2 gap-x-2 flex flex-row items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <div className="!my-0 flex flex-col">
                    <FormLabel className="!font-bold !mb-0">
                      Filtrar profesional por día
                    </FormLabel>
                    <FormDescription className="truncate">
                      Muestra los habilitados el día elegido.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <OfficesSelect className="w-1/2" offices={offices} form={form} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CompactHeader = ({
  form,
  layout,
  extendSchedule,
  professionals,
  setExtendSchedule,
  specialties,
  offices,
  router,
  actions,
  setOpenModalForm,
}: HeaderFormT) => {
  return (
    <div className="grid grid-cols-12 gap-x-3 w-full">
      <div className="col-span-3">
        <DatePicker className="h-9" form={form} name="date" dateformat="PPPP" />
      </div>
      <ProfessionalsSelectSchedule
        className="col-span-3 w-full h-9"
        professionals={professionals}
        form={form}
        disabled={["buscarpordiatodosprof"].includes(layout)}
      />
      <SpecialtiesSelect
        specialties={specialties}
        form={form}
        className="col-span-3 w-full h-9"
      />
      <div className="flex justify-end gap-x-2 col-span-3">
        <FindNextAvailableAppt
          professionals={professionals}
          form={form}
          actions={actions}
          setOpenModalForm={setOpenModalForm}
        />
        <TurnReminder
          onClick={(e: any) => {
            e.preventDefault();
            router.push("/dashboard/reminders");
          }}
          extended={extendSchedule}
        />
        <SwitchSchedule
          extendSchedule={extendSchedule}
          setExtendSchedule={setExtendSchedule}
        />
      </div>
      <Separator className="col-span-12 my-3" />
      <div className="col-span-12 grid grid-cols-12 gap-x-2">
        <ScheduleLayoutsSelect
          className="col-span-2 flex gap-x-2"
          form={form}
          layouts={LAYOUTS}
        />
        <OfficesSelect
          className="col-span-2 h-9"
          offices={offices}
          form={form}
        />
        <DaysOfWeek
          form={form}
          professionals={professionals}
          className={classNames(
            !offices || !offices.length ? "col-span-6" : "col-span-5"
          )}
          extendSchedule={extendSchedule}
        />
        <div className="col-span-3 h-9">
          <FormField
            control={form.control}
            name="filter_professional_by_day"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 justify-start">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(checked) => field.onChange(checked)}
                  />
                </FormControl>
                <div className="!my-0 flex flex-col">
                  <FormLabel className="!font-bold !mb-0">
                    Filtrar profesional por día
                  </FormLabel>
                  <FormDescription className="truncate">
                    Muestra los habilitados el día elegido.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const SwitchSchedule = ({
  extendSchedule,
  setExtendSchedule,
}: {
  extendSchedule: boolean;
  setExtendSchedule: () => void;
}) => {
  return (
    <Tooltip>
      <TooltipProvider>
        <TooltipTrigger asChild>
          <Button
            onClick={setExtendSchedule}
            variant="outline"
            size="icon"
            className="flex gap-x-2 h-9 w-9"
          >
            {extendSchedule ? (
              <LuMinimize className="size-4" />
            ) : (
              <LuExpand className="size-4" />
            )}
            <span className="sr-only">Toggle settings</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          Agenda {extendSchedule ? "compacta" : "extendida"}
        </TooltipContent>
      </TooltipProvider>
    </Tooltip>
  );
};
