import { Benefit } from "@/types/general";

type State = {
  patient_benefits: {
    data: {
      [key: string]: any;
    };
    benefit_selected: Benefit | null;
    initialized_data: boolean;
  };
};

type Actions = {
  setPatientBenefits: (payload: {
    data: any[];
    set_new_data?: boolean;
  }) => void;
  setBenefitSelected: (benefit: Benefit | null) => void;
};

type PatientBenefitsStore = State & Actions;

export const INIT_STATE: State = {
  patient_benefits: {
    data: [],
    benefit_selected: null,
    initialized_data: false,
  },
};

export const BenefitsSlice = (
  set: (partial: Partial<PatientBenefitsStore>) => void,
  get: () => PatientBenefitsStore
): PatientBenefitsStore => ({
  ...INIT_STATE,
  setPatientBenefits: ({
    data,
    set_new_data,
  }: {
    data: any[];
    set_new_data?: boolean;
  }) => {
    const state = get();
    (!state.patient_benefits.initialized_data || set_new_data) &&
      set({
        ...state,
        patient_benefits: {
          ...state.patient_benefits,
          data,
          initialized_data: true,
        },
      });
  },
  setBenefitSelected: (benefit: Benefit | null) => {
    const state = get();

    set({
      ...state,
      patient_benefits: {
        ...state.patient_benefits,
        benefit_selected: benefit,
      },
    });
  },
});
