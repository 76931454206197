"use client";

import React from "react";
import { z } from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  Input,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  InstallmentsInputs,
} from "@/components/molecules";
import { useDialog, useKeypress } from "@/hooks";
import { CardInstallment } from "@/types/general";
import { resolver, formSchema } from "./schema";

export function InstallmentsForm({ card }: { card: any }) {
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      card: card.card,
      card_installments: card.card_installments,
    },
  });

  const { fields: installments } = useFieldArray({
    control: form.control,
    name: "card_installments",
  });

  useKeypress({ key: "Enter", action: closeDialog });

  return (
    <DialogContent className="w-1/2">
      <DialogHeader>
        <DialogTitle>Cuotas</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form className="grid grid-cols-12 gap-x-4 gap-y-6">
          <FormField
            control={form.control}
            name="card"
            render={({ field }) => (
              <FormItem className="col-span-12">
                <FormLabel>Tarjeta</FormLabel>
                <FormControl>
                  <Input disabled type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <InstallmentsInputs
            installments={installments as CardInstallment[]}
            form={form}
            disabled
          />
        </form>
      </Form>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-11">
          <Button type="button">Cerrar</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
}
