import React from "react";
import { DialogContent, DialogHeader, DialogTitle } from "@/components/atoms";
import { DeleteMedicalHistoryForm } from "./delete-history";
import { NewMedicalHistoryForm } from "./new-history";
import { Offices, Professional } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";

export const Actions = ({
  professionals,
  offices,
  formState,
  closeModal,
  loading,
  setLoading,
  refreshData,
}: {
  professionals: Professional[];
  offices: Offices[];
  formState: string;
  closeModal: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  refreshData: () => void;
}) => {
  const {
    patient_history: { history_selected },
  } = useGlobalStore();
  const FORM_LIST: {
    [key: string]: {
      header: string;
      content: React.ReactNode;
    };
  } = {
    create_edit: {
      header: history_selected
        ? "Modificar historia clínica"
        : "Nueva historia clínica",
      content: (
        <NewMedicalHistoryForm
          professionals={professionals}
          offices={offices}
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          refreshData={refreshData}
        />
      ),
    },
    delete: {
      header: "Eliminar Historia Clínica ",
      content: (
        <DeleteMedicalHistoryForm
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          refreshData={refreshData}
        />
      ),
    },
  };
  const FORM_MODAL = FORM_LIST[formState];

  return (
    <>
      {FORM_MODAL && (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{FORM_MODAL.header}</DialogTitle>
          </DialogHeader>
          {FORM_MODAL.content}
        </DialogContent>
      )}
    </>
  );
};
