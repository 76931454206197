"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { LuInfo } from "react-icons/lu";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Professional, Specialty, Provinces } from "@/types/general";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FreemiumForm,
  TooltipComponent,
} from "@/components/molecules";
import {
  Button,
  Card,
  CardDescription,
  CardTitle,
  Checkbox,
  Combobox,
  Input,
  Separator,
} from "@/components/atoms";
import { createOrUpdateProfessional } from "@/actions/professionals";
import { toast, useDialog, useKeypress } from "@/hooks";
import { objectChangeKey } from "@/utils/helpers";
import { useGlobalStore } from "@/providers/global-provider";
import { PREFIX_DATA, TAXES_CONDITION, TYPES_LICENCES } from "@/libs/config";
import { Loading } from "./loading";
import { createFormSchema } from "./schema";
import { Signature } from "./signature";

export const ProfesionalsForm = ({
  professional,
  specialties,
}: {
  professional?: Professional;
  specialties: Specialty[];
}) => {
  const {
    general_params: { provinces, user_params },
    startLoading,
    stopLoading,
    loading,
  } = useGlobalStore();
  const { openDialog } = useDialog();
  const router = useRouter();
  const textField = React.useRef<HTMLInputElement | null>(null);
  const PREFIX =
    PREFIX_DATA.find((item) => item.description === professional?.abbreviation)
      ?.id_prefix || 1;
  const TAX_CONDITION =
    TAXES_CONDITION.find((item) => item.value === professional?.tax_condition)
      ?.id_tax_condition || 1;
  const TYPE_LICENCE =
    TYPES_LICENCES.find((item) => item.value === professional?.licence_type)
      ?.id_licence_type || 1;
  const JURISDICTION =
    provinces.find(
      (item: Provinces) => item.name === professional?.licence_jurisdiction
    )?.id_province || null;
  const SHOW_INPUTS_REGION = ["arg"].includes(
    user_params?.what_region.toLowerCase()
  );
  const formSchema = createFormSchema(user_params?.what_region?.toLowerCase());
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id_professional: professional?.id_professional || 0,
      name: professional?.name || "",
      id_prefix: PREFIX,
      address: professional?.address || "",
      city: professional?.city || "",
      state: professional?.state || "",
      phone: professional?.phone || "",
      mail: professional?.mail || "",
      licence: professional?.licence || "",
      comission: professional?.comission || 0,
      comission_patient: professional?.comission_patient || 0,
      tax_number: professional?.tax_number || "",
      comission_payments: professional?.comission_payments || 0,
      comission_laboratory: professional?.comission_laboratory || 0,
      max_solp: professional?.max_solp || 0,
      professional_code: professional?.professional_code || "",
      observations: professional?.observations || "",
      enabled: professional ? professional?.enabled : true,
      id_speciality_1: professional?.id_speciality_1 || null,
      id_speciality_2: professional?.id_speciality_2 || null,
      only_specialty: professional?.only || false,
      abbreviation: professional?.abbreviation || "",
      liquidate_all: professional?.liquidate_all || false,
      shcedule_color: professional?.shcedule_color || "",
      liquidate_code: professional?.liquidate_code || "",
      id_tax_condition: TAX_CONDITION,
      has_no_schedule: professional?.has_no_schedule || false,
      is_guard: professional?.is_guard || false,
      rowversion: professional?.rowversion || "",
      profession: professional?.profession || "",
      id_licence_type: TYPE_LICENCE,
      id_province: JURISDICTION,
      signature: null,
      option: professional?.option || "",
      professional_timetable: professional?.professional_timetable || [],
      professional_values: professional?.professional_values || [],
      professional_absences: professional?.professional_absences || [],
      appointments: professional?.appointments || {},
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const PREFIX =
      PREFIX_DATA.find((item) => item.id_prefix === values.id_prefix)?.value ||
      null;
    const TAX_CONDITION =
      TAXES_CONDITION.find(
        (item) => item.id_tax_condition === values.id_tax_condition
      )?.value || null;
    const TYPE_LICENCE =
      TYPES_LICENCES.find(
        (item) => item.id_licence_type === values.id_licence_type
      )?.value || null;
    const option = values.id_professional === 0 ? "nuevo" : "modificar";
    const province =
      provinces.find(
        (item: Provinces) => item.id_province === values.id_province
      )?.name || null;
    const professional = await {
      ...values,
      prefix: PREFIX,
      tax_condition: TAX_CONDITION,
      licence_type: TYPE_LICENCE,
      province,
      option,
    };
    const response = await createOrUpdateProfessional(professional);

    if ("error" in response) {
      response.error.includes("40000")
        ? openDialog({
            content: <FreemiumForm />,
          })
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: response.error,
          });
    } else {
      toast({
        variant: "successful",
        title: professional?.id_professional
          ? "Profesional actualizado con exito"
          : "Profesional creado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });

      router.replace(
        `/dashboard/configuration/tables/professionals/new?id=${response.id_professional}`
      );
    }

    stopLoading();
  }
  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return loading ? (
    <Loading />
  ) : (
    <Form {...form}>
      <form>
        <Card className="w-full grid grid-cols-12 gap-4 p-4 overflow-hidden bg-white h-[calc(100vh-210px)] overflow-y-scroll">
          <div className="col-span-full w-full h-auto flex justify-between items-start">
            <div>
              <CardTitle className="flex items-center gap-2 mb-2">
                Datos del profesional
              </CardTitle>
              <CardDescription>
                Aquí puedes añadir, editar o eliminar la información de los
                profesionales en tu cuenta.
              </CardDescription>
            </div>
            {SHOW_INPUTS_REGION && (
              <TooltipComponent
                trigger={<LuInfo />}
                content={
                  <p>
                    * Campos obligatorios., **Campos obligatorios para recetas.
                  </p>
                }
              />
            )}
          </div>
          <FormField
            control={form.control}
            name="professional_code"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Código*</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} autoFocus />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Combobox
            data={PREFIX_DATA}
            dataKey="id_prefix"
            form={form}
            value="value"
            placeholder="Selecciona un item..."
            title="Selecciona un item..."
            showInputSearch={false}
            className="col-span-2"
            classContent="!w-80"
            label="Prefijo"
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Nombre y Apellido*</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Domicilio</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Localidad</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Provincia</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Teléfono</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="mail"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tax_number"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>CUIT**</FormLabel>
                <FormControl>
                  <Input
                    maxLength={11}
                    ref={textField}
                    value={field.value}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      field.onChange(input);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Combobox
            data={TAXES_CONDITION}
            dataKey="id_tax_condition"
            form={form}
            value="description"
            placeholder="Selecciona un item..."
            title="Selecciona un item..."
            showInputSearch={false}
            className="col-span-3"
            classContent="!w-80"
            label="Condición Fiscal"
          />
          {SHOW_INPUTS_REGION && (
            <>
              <FormField
                control={form.control}
                name="profession"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>Profesión**</FormLabel>
                    <FormControl>
                      <Input {...field} ref={textField} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Combobox
                data={TYPES_LICENCES}
                dataKey="id_licence_type"
                form={form}
                value="description"
                placeholder="Selecciona un item..."
                title="Selecciona un item..."
                showInputSearch={false}
                className="col-span-3 col-start-1"
                classContent="!w-80"
                label="Tipo de Matrícula**"
              />
              <FormField
                control={form.control}
                name="licence"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Matrícula**</FormLabel>
                    <FormControl>
                      <Input {...field} ref={textField} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Combobox
                data={provinces}
                dataKey="id_province"
                form={form}
                value="name"
                placeholder="Añadir jurisdicción"
                title="Selecciona un item..."
                showInputSearch={false}
                className="col-span-4"
                classContent="!w-80"
                label="Jurisdicción**"
                disabled={form.watch("id_licence_type") === 1}
              />
            </>
          )}
          <Combobox
            data={objectChangeKey({
              originalArray: specialties,
              oldKey: "id_specialty",
              newKey: "id_speciality_1",
            })}
            dataKey="id_speciality_1"
            form={form}
            value="description"
            placeholder="Selecciona Especialidad"
            title="Selecciona un item..."
            showInputSearch={false}
            className="col-span-3 col-start-1"
            classContent="!w-80"
            label={"Especialidad"}
          />
          <Combobox
            data={objectChangeKey({
              originalArray: specialties,
              oldKey: "id_specialty",
              newKey: "id_speciality_2",
            })}
            dataKey="id_speciality_2"
            form={form}
            value="description"
            placeholder="Selecciona Especialidad"
            title="Selecciona un item..."
            showInputSearch={false}
            className="col-span-3"
            classContent="!w-80"
            label={"Especialidad 2"}
          />
          <FormField
            control={form.control}
            name="liquidate_code"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Cód. de Liq. para OSDE</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enabled"
            render={({ field }) => (
              <FormItem className="col-span-2 col-start-1 flex flex-row-reverse gap-2 items-center justify-end">
                <FormLabel>Profesional habilitado</FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="only_specialty"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <FormLabel>
                  Solamente podrá cargar prestaciones referidas a sus
                  especialidades
                </FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="liquidate_all"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <FormLabel>
                  Solo un supervisor puede ver la liquidación de este
                  profesional
                </FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_guard"
            render={({ field }) => (
              <FormItem className="col-span-2 col-start-1 flex flex-row-reverse gap-2 items-center justify-end">
                <FormLabel>Es profesional de guardia</FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="observations"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Observaciones</FormLabel>
                <FormControl>
                  <Input {...field} ref={textField} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator className="col-span-12" />
          {SHOW_INPUTS_REGION && <Signature form={form} />}
        </Card>
      </form>
      <div className="col-span-12 w-full py-4 flex justify-end gap-6">
        <Button
          onClick={form.handleSubmit(onSubmit)}
          className="px-10 h-10 bg-blue-600"
        >
          Guardar
        </Button>
      </div>
    </Form>
  );
};
