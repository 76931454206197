"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useRouter } from "next/navigation";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Checkbox,
  Combobox,
  Input,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { Regions } from "@/types/general";
import { TAXES_CONDITION } from "@/libs/config";
import { toast, useBoundStore } from "@/hooks";
import { updateSistem } from "@/actions/configs";
import { userParamsToDB } from "@/utils/tranformers/params";
import { removeLoginCookies } from "@/utils/cookies";
import { formSchema, resolver } from "./schema";
import { UploadLogo } from "./upload_logo";

export const GeneralDataForm = ({ regions }: { regions: Regions[] }) => {
  const router = useRouter();
  const nameInputRef = React.useRef<HTMLInputElement | null>(null);
  const {
    general_params: { user_params },
    startLoading,
    stopLoading,
    loading,
  } = useGlobalStore();
  const TAX_CATEGORY = TAXES_CONDITION.find(
    (tax) => tax.value === user_params?.tax_condition
  );
  const REGION = regions.find(
    (region) =>
      region.iso3.toLowerCase() === user_params?.what_region?.toLowerCase()
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      name: user_params.name,
      address: user_params.address || "",
      phone: user_params.phone || "",
      email: user_params.email || "",
      cuit: user_params.cuit || "",
      id_tax_condition: TAX_CATEGORY?.id_tax_condition || 1,
      id_country: REGION?.id_country || 1,
      use_usd: user_params.use_usd || false,
      tax_number: user_params.tax_number || "",
      recipe_logo: user_params.recipe_logo,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();

    const REGION = regions.find(
      (item) => item.id_country === values.id_country
    );
    const TAX_CONDITION = TAXES_CONDITION.find(
      (item) => item.id_tax_condition === values.id_tax_condition
    );
    const params = userParamsToDB(user_params);
    const response = await updateSistem({
      ...params,
      ...values,
      region: REGION?.iso3.toLowerCase(),
      tax_condition: TAX_CONDITION?.value,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Datos actualizados correctamente",
        description:
          "Los datos del sistema han sido actualizados. por favor vuelva a iniciar sesión.",
      });
      router.push("/?flush=true");
    }

    stopLoading();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-end"
      >
        <Card className="w-full !p-0 !border-none !shadow-none">
          <CardHeader>
            <CardTitle>Datos Generales</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4 grid-cols-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nombre</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Juan Perez"
                      maxLength={40}
                      {...field}
                      ref={nameInputRef}
                      autoFocus
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Dirección</FormLabel>
                  <FormControl>
                    <Input placeholder="Calle 123" maxLength={35} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Teléfono</FormLabel>
                  <FormControl>
                    <Input placeholder="123456789" maxLength={14} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input maxLength={30} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tax_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CUIT</FormLabel>
                  <FormControl>
                    <Input placeholder="1234567890" maxLength={20} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Combobox
              form={form}
              data={TAXES_CONDITION}
              dataKey="id_tax_condition"
              value="description"
              title="Condición fiscal"
              classContent="!w-auto"
              label="Condición fiscal"
              showInputSearch={false}
            />
            <Combobox
              form={form}
              data={regions}
              dataKey="id_country"
              value="name"
              title="País"
              classContent="min-w-[300px] !w-auto"
              label="País"
              showInputSearch={false}
            />
            <div>
              <FormField
                control={form.control}
                name="use_usd"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Usar doble moneda</FormLabel>
                      <FormDescription className="text-xs">
                        Permite a los usuarios cargar prestaciones y pagos en
                        USD.
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <UploadLogo form={form} />
          </CardContent>
        </Card>
        {loading ? (
          <ButtonLoading className="mt-4" />
        ) : (
          <Button
            type="submit"
            className="mt-4"
            disabled={form.formState.isSubmitting}
          >
            Guardar
          </Button>
        )}
      </form>
    </Form>
  );
};
