import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  first_name: z
    .string()
    .min(1, {
      message: "El nombre es requerido",
    })
    .max(225),
  last_name: z
    .string()
    .min(1, {
      message: "El apellido es requerido.",
    })
    .max(225),
  country: z
    .string()
    .min(1, {
      message: "El pais es requerido.",
    })
    .max(225),
  phone: z.string().max(225).min(1, {
    message: "El teléfono es requerido.",
  }),
  id_country: z.number().nullable(),
  birthday: z.string().max(225),
  is_whatsapp: z.boolean().default(false),
  country_iso3: z.string(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
