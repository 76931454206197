import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { GERDER } from "@/libs/patients";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export function Aditionals({ form }: { form: Form<typeof formSchema> }) {
  return (
    <Card className="col-span-12 2xl:col-span-6 p-10">
      <CardHeader>
        <CardTitle>Datos particulares</CardTitle>
        <CardDescription>
          Acá vas a encontrar los datos de contacto, datos de ubicación, redes
          sociales de tu paciente.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-6">
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem className="col-span-6">
              <FormLabel>Domicilio</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={40}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem className="col-span-3 self-end">
              <FormLabel>Localidad</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={40}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="col-span-3 self-end">
              <FormLabel>Provincia</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={30}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mobile_phone"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Teléfono celular</FormLabel>
              <FormControl>
                <Input type="number" {...field} maxLength={30}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="home_phone"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Teléfono particular</FormLabel>
              <FormControl>
                <Input type="number" {...field} maxLength={30}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="work_phone"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Teléfono laboral</FormLabel>
              <FormControl>
                <Input type="number" {...field} maxLength={30}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="col-span-8">
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={100}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="gender"
          render={({ field }) => (
            <FormItem className="col-span-3 col-start-1">
              <FormLabel>Sexo</FormLabel>
              <FormControl>
                <Select
                  key="gender"
                  value={field.value}
                  onValueChange={(value: string) => {
                    form.setValue("gender", value, { shouldDirty: true });
                  }}
                >
                  <SelectTrigger className="w-full !h-10 !text-sm select-trigger-gender">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {GERDER.map((gender: { id: string; text: string }) => (
                      <SelectItem
                        key={gender.id}
                        value={gender.id}
                        className="select-item-gender"
                      >
                        {gender.text}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="marital_status"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Estado civil</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={20}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="face"
          render={({ field }) => (
            <FormItem className="col-start-1 col-span-4">
              <FormLabel>Facebook</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={50}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="twitter"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>X (Twitter)</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={50}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="instagram"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Instagram</FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={50}/>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
