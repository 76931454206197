"use client";

import React from "react";
import { z } from "zod";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Input,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { toast, useDialog } from "@/hooks";
import { setLender } from "@/actions/lenders";
import { formSchema, resolver } from "./schema";

export const Lenders = () => {
  const { general_params, user, startLoading, stopLoading, loading } =
    useGlobalStore();
  const { openDialog, closeDialog } = useDialog();
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      lender_code: general_params.user_params?.lender_code_bilog,
      manager_code: user?.manager_code,
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setLender(values);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Te has suscripto correctamente",
        description: "Los datos se verán reflejados en tu cuenta.",
      });
      openDialog({
        content: (
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Te has suscripto correctamente</DialogTitle>
            </DialogHeader>
            <span>
              {`¿Deseas importar las/los ${general_params.translations?.medical_insurances} de tu gerenciador?`}
            </span>
            <div className="flex gap-x-4 justify-end">
              <Button variant="destructive" onClick={closeDialog}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  router.push("/dashboard/medical-insurances-copy");
                  closeDialog();
                }}
              >
                Importar
              </Button>
            </div>
          </DialogContent>
        ),
      });
    }

    stopLoading();
  };

  React.useEffect(() => {
    form.reset({
      lender_code: general_params.user_params?.lender_code_bilog,
      manager_code: user?.manager_code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="max-w-screen-2xl lg:py-10 md:px-12 xl:mx-auto pb-20">
      <Card className="max-w-7xl w-full my-0 mx-auto flex flex-col px-10">
        <CardTitle className="text-lg mb-5">
          Configuración de prestador
        </CardTitle>
        <Form {...form}>
          <form
            className="flex flex-col items-end"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <CardContent className="w-full grid grid-cols-6 gap-4 overflow-hidden bg-white p-2">
              <FormField
                control={form.control}
                name="lender_code"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Código de prestador</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} placeholder="BIL093" />
                    </FormControl>
                    <FormDescription className="text-slate-400">
                      Acá debes ingresar el código que te ha dado tu
                      gerenciador. Es único e irrepetible.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="manager_code"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Código de gerenciador</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} placeholder="BIL093" />
                    </FormControl>
                    <FormDescription className="text-slate-400">
                      Acá debes ingresar el código de tu gerenciador, círculo o
                      asociación. Si tienes dudas de cual es, puedes consultarle
                      a tu gerenciador.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <div className="flex gap-x-4">
              <Button type="button" variant="destructive" onClick={router.back}>
                Cancelar
              </Button>
              {loading ? (
                <ButtonLoading />
              ) : (
                <Button type="submit">Activar suscripción</Button>
              )}
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
};
