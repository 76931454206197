import React from "react";
import { Checkbox, Combobox, Label } from "@/components/atoms";
import { PAYMENT_METHOD } from "@/libs/patients";
import { toast } from "@/hooks";
import { Banks, Cards } from "@/types/payments";
import { Form } from "@/types/general";
import { useGlobalStore } from "@/providers/global-provider";
import { formSchema } from "../schema";
import DebitCardMethod from "./debit-card";
import CreditCardMethod from "./credit-card";

export const PaymentMethods: React.FC<{
  cardList: Cards[];
  bankList: Banks[];
  eInvoice?: boolean;
  modAdmin: boolean;
  form: Form<typeof formSchema>;
  installments: { [key: string]: any }[];
  setInstallments: (value: any) => void;
  disabled?: boolean;
}> = ({
  cardList,
  bankList,
  eInvoice,
  modAdmin,
  form,
  installments,
  setInstallments,
}) => {
  const firstMount = React.useRef(false);
  const {
    user: { plan_version },
  } = useGlobalStore();

  const { id_payment_method } = form.watch();
  const CREDIT_CARDS = cardList?.filter((c: Cards) => c.is_credit);
  const DEBIT_CARDS = cardList?.filter((c: Cards) => !c.is_credit);

  function renderMethods(value: string) {
    const methodComponent: { [key: string]: () => React.ReactNode } = {
      Tarjeta: () => (
        <CreditCardMethod
          form={form}
          cards={CREDIT_CARDS}
          installments={installments}
          setInstallments={setInstallments}
        />
      ),
      TarjetaD: () => <DebitCardMethod form={form} cards={DEBIT_CARDS} />,
      "Transf.": () => (
        <Combobox
          form={form}
          data={bankList}
          dataKey="id_bank"
          value="bank_name"
          placeholder="Busca una banco"
          showInputSearch={false}
          className="col-span-3"
          label="Banco"
        />
      ),
      Otro: () => (
        <div className="col-span-3 flex">
          <Checkbox
            onCheckedChange={(checked) => {
              if (!checked) {
                form.setValue("amount_to_pay_professional", 0);
                form.setValue("amount_to_pay_professional_percentage", 0);
              }
              form.setValue("save_register", !!checked);
            }}
          />
          <div className="leading-none flex flex-col mx-2 w-full">
            <Label>Guardar caja</Label>
            <p className="text-sm font-regular text-gray-400">
              El pago se guardará con caja
            </p>
          </div>
        </div>
      ),
    };

    return Object.keys(methodComponent).includes(value)
      ? methodComponent[value]()
      : null;
  }

  const METHOD = React.useMemo(
    () =>
      PAYMENT_METHOD?.find(
        (method) => method.id_payment_method === id_payment_method
      ),
    [id_payment_method]
  );
  React.useEffect(() => {
    if (!firstMount.current) {
      firstMount.current = true;
      return;
    } else {
      const IS_VALID =
        (["standard", "full"].includes(plan_version) && modAdmin) ||
        ["premium"].includes(plan_version);

      if (!IS_VALID && id_payment_method !== 1) {
        form.setValue("id_payment_method", 1);
        toast({
          variant: "warning",
          title: "Ups! No puede realizar esta acción",
          description:
            "Necesita el módulo administración para utilizar otra forma de pago.",
        });
      }
    }
  }, [id_payment_method]);

  React.useEffect(() => {
    if (id_payment_method !== 6) {
      form.setValue("to_pay", 0);
    }
  }, [id_payment_method]);

  return (
    <>
      <div className="flex items-center col-span-3">
        <Combobox
          form={form}
          data={PAYMENT_METHOD}
          dataKey="id_payment_method"
          value="text"
          showInputSearch={false}
          title="Metodo de pago"
          className="w-full"
          label="Forma de pago"
          disabled={eInvoice}
        />
      </div>
      {renderMethods(METHOD?.value)}
    </>
  );
};
