import React, { useEffect, useCallback, useRef } from "react";

type UseScrollAutomaticProps = {
  rowRefs: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
  marginMinutes?: number;
};

export const useScrollAutomatic = ({
  rowRefs,
  marginMinutes = 30,
}: UseScrollAutomaticProps) => {
  // Keeps track of whether the scroll has already been performed
  const hasScrolled = useRef(false);
  const scrollToAppointment = useCallback(() => {
    // Prevent multiple executions
    if (hasScrolled.current) return;

    const now = new Date();
    const currentTime = now.getHours() * 100 + now.getMinutes();
    const marginTime = new Date(now.getTime() - marginMinutes * 60000);
    let closestTime = marginTime.getHours() * 100 + marginTime.getMinutes();
    let closestRow: HTMLDivElement | null = null;

    Object.values(rowRefs.current).forEach((ref) => {
      if (ref instanceof HTMLDivElement) {
        const timeText = ref.querySelector(
          '[data-time="schedule-time"]'
        )?.textContent;
        const rowTime = timeText
          ? parseInt(timeText.replace(":", ""), 10)
          : null;

        if (rowTime && rowTime <= currentTime && rowTime > closestTime) {
          closestTime = rowTime;
          closestRow = ref;
        }
      }
    });

    if (closestRow) {
      const parentElement = (closestRow as HTMLDivElement).parentElement;
      const grandparentElement = parentElement?.parentElement;
      const previousRow = grandparentElement?.previousElementSibling;

      (previousRow as HTMLDivElement)?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      (previousRow as HTMLDivElement)?.classList.add(
        "animate-[flash-appointment_0.5s_ease-in-out]",
        "bg-slate-50"
      );
      // Mark that the scroll has already been executed
      hasScrolled.current = true;
    }
  }, [rowRefs, marginMinutes]);

  useEffect(() => {
    scrollToAppointment();
  }, [scrollToAppointment]);
};
