"use client";

import React from "react";
import { z } from "zod";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import {
  Button,
  CardContent,
  CardDescription,
  CardTitle,
} from "@/components/atoms";
import { ButtonLoading, Form } from "@/components/molecules";
import { useGlobalStore } from "@/providers/global-provider";
import { toast } from "@/hooks";
import { sendListMedins } from "@/actions/lenders";
import { MedicalInsurancesCopy } from "@/components/organisms/tables";
import { MedicalInsurance } from "@/types/general";
import { formSchema, resolver } from "./schema";

export const MedInsCopy = ({
  medicalInsurances,
  codes,
  nameDataBase,
}: {
  medicalInsurances: MedicalInsurance[];
  codes: { lender_code: string; manager_code: string };
  nameDataBase: string;
}) => {
  const router = useRouter();
  const { startLoading, stopLoading, loading } = useGlobalStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      lender_code: codes?.lender_code,
      manager_code: codes?.manager_code,
      name_data_base: nameDataBase,
      med_ins_to_copy: [],
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();

    const response = await sendListMedins(values);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Has importado correctamente",
        description: "Los datos se verán reflejados en tu cuenta.",
      });
      await router.push("/dashboard/configuration/tables/medical-insurances");
    }
    stopLoading();
  };

  return (
    <div className="max-w-screen-2xl lg:py-10 md:px-12 xl:mx-auto pb-20">
      <CardTitle className="mb-2">Importación de obras sociales</CardTitle>
      <CardDescription className="mb-2">
        Selecciona las obras sociales que posee tu gerenciadora, y que deseas
        importar. Estas remplazarán a las que ya posea.
      </CardDescription>
      <Form {...form}>
        <form
          className="flex flex-col items-end"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <CardContent className="w-full overflow-hidden bg-white !p-0">
            <div className="shadow-lg rounded-md bg-slate-100 col-span-12"></div>
            <MedicalInsurancesCopy form={form} data={medicalInsurances} />
          </CardContent>
          {loading ? (
            <ButtonLoading className="mt-4" />
          ) : (
            <Button
              type="submit"
              className="mt-4"
              disabled={!medicalInsurances?.length}
            >
              Importar
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};
