"use client";

import React from "react";
import classNames from "classnames";
import {
  HiDotsVertical,
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import {
  NewCardForm,
  DeleteCardForm,
} from "@/components/organisms/forms/administration";
import { InstallmentsDialog } from "@/components/organisms/dialogs";
import { Card } from "@/types/general";

type RowDataT = {
  original: any;
};

const CardsDropdown = ({ card }: { card: Card }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() =>
              openDialog({
                content: <NewCardForm card={card} />,
              })
            }
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() =>
              openDialog({
                content: <DeleteCardForm card={card} />,
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// This is the "Bancos y cuentas" columns
export const columns = () => {
  return [
    {
      id: "actions",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => (
        <CardsDropdown card={row.original} />
      ),
    },
    {
      accessorKey: "card",
      header: "Tarjeta",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { card, id_card } = row.original;

        return (
          <p
            data-id={id_card}
            className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1"
          >
            {card}
          </p>
        );
      },
    },
    {
      accessorKey: "tax_id_number",
      header: "N° de comercio",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { tax_id_number } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {tax_id_number}
          </p>
        );
      },
    },
    {
      accessorKey: "is_credit",
      header: "Tipo",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_credit } = row.original;

        return (
          <Badge
            className={classNames("rounded-md hover:bg-inherit", {
              "text-green-600 bg-green-50": is_credit,
              "text-violet-600 bg-violet-50": !is_credit,
            })}
          >
            {is_credit ? "Crédito" : "Débito"}
          </Badge>
        );
      },
    },
    {
      accessorKey: "card_installments",
      header: "N° de cuotas / interés",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { card_installments, card } = row.original;

        return (
          card_installments?.length > 0 && (
            <InstallmentsDialog card={row.original} />
          )
        );
      },
    },
    {
      accessorKey: "enabled",
      header: "Habilitado",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { enabled } = row.original;

        return (
          <p
            className={classNames(
              "max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm",
              {
                "text-red-500": !enabled,
                "text-green-500": enabled,
              }
            )}
          >
            {enabled ? (
              <HiOutlineCheckCircle className="size-5" />
            ) : (
              <HiOutlineBan className="size-5" />
            )}
          </p>
        );
      },
      enableGrouping: false,
    },
  ];
};
