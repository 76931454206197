import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_plan: z.number(),
  code_plan: z.string().min(1, {
    message: "El código es requerido",
  }),
  name: z.string().min(1, {
    message: "El nombre es requerido",
  }),
  id_med_ins: z.number(),
  disabled: z.boolean(),
  rowversion: z.string().nullable(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
