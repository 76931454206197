export { Table as Professionals } from "./professionals";
export { Table as ProfessionalTimetable } from "./professionals/timetable";
export { Table as ProfessionalAbscences } from "./professionals/abscences";
export { Table as LaboratoriesTable } from "./laboratories";
export { Table as PriceListTable } from "./laboratories/price-list";
export { Table as ListCopyTable } from "./laboratories/list-copy";
export { Table as SpecialtyTable } from "./specialties";
export { Table as HolydayTable } from "./holyday";
export { Table as OfficesTable } from "./offices";
export { Table as UsersTable } from "./users";
export { Table as MedicalInsurances } from "./medical-insurances";
export { Table as MedicalInsurancesPlans } from "./medical-insurances-plans";
export { Table as MedicalInsurancesGroups } from "./medical-insurances-groups";
export { Table as MedicalInsurancesBenefits } from "./medical-insurances-benefits";
export { Table as CopyMedicalInsurancesBenefits } from "./copy-medical-insurances";
export { Table as Suppliers } from "./suppliers";
