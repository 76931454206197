import * as React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { HiDotsVertical } from "react-icons/hi";
import { FcCancel } from "react-icons/fc";
import { LuEye, LuPen, LuTrash2, LuCircleCheck } from "react-icons/lu";
import { CellContext, ColumnDef, RowData } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
} from "@/components/atoms";
import { Translations } from "@/types/general";
import { numberToCurrency } from "@/utils/currency";
import { useRestrictedPatientAction } from "@/hooks/use-patient-restricted-action";
import { Budget } from "@/types/patients";

const Dropdown = ({
  budget,
  action,
  config: { permissions, is_supervisor },
}: {
  budget: Budget;
  action: (budget: Budget, type: string) => void;
  config: { permissions: Record<string, boolean>; is_supervisor: boolean };
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(budget, type),
          id: budget.id_professional,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <Action type="edit">
            <LuPen className="mr-2 size-4" />
            <span>Ver detalles</span>
          </Action>
          {(is_supervisor || permissions.hasOwnProperty("Ver Pagos Ppto.")) && (
            <Action type="view_payments">
              <LuEye className="mr-2 size-4" />
              <span>Ver pagos</span>
            </Action>
          )}
          {(is_supervisor ||
            permissions.hasOwnProperty("Eliminar Presupuesto")) && (
            <Action type="delete">
              <LuTrash2 className="mr-2 size-4" />
              <span>Eliminar presupuesto</span>
            </Action>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = <TData extends RowData>({
  translations,
  config,
  action,
}: {
  translations: Translations;
  config: {
    permissions: any;
    is_supervisor: boolean;
  };
  action: (value: Budget, type: string) => void;
}): ColumnDef<TData, any>[] => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: CellContext<TData, any>) => {
        return (
          <Dropdown
            action={action}
            budget={row.original as Budget}
            config={config}
          />
        );
      },
    },
    {
      accessorKey: "id_budget",
      header: "N° y estado",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { id_budget, balance, total_amount } = budget;
        const validateStatus = (balance: number, total_amount: number) => {
          const STATUS: { [key: string]: { [key: string]: string } } = {
            payed: {
              text: "Saldado",
              style:
                "bg-yellow-50 hover:!bg-yellow-100 text-yellow-600 border-yellow-600/10",
            },
            movement: {
              text: "En movimiento",
              style:
                "bg-green-50 hover:!bg-green-100 text-green-600 border-green-600/10",
            },
            not_movement: {
              text: "Sin movimiento",
              style:
                "bg-slate-50 hover:!bg-slate-200 text-slate-600 border-slate-600/10",
            },
          };

          return balance < total_amount && balance !== 0
            ? STATUS["movement"]
            : total_amount !== 0 && balance === 0
            ? STATUS["payed"]
            : STATUS["not_movement"];
        };
        const STATUS = validateStatus(balance, total_amount);

        return (
          <div className="flex gap-x-2">
            <Badge>N°: {id_budget}</Badge>
            <Badge className={classNames("w-auto", STATUS.style)}>
              {STATUS.text}
            </Badge>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Fecha",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { date } = budget;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {format(date, "dd/MM/yyyy")}
          </p>
        );
      },
    },
    {
      accessorKey: "professional",
      header: "Profesional",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const {
          id_professional_navigation: { name },
        } = budget;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {name}
          </p>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Descripción",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { description } = budget;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "medical_insurance",
      header: translations.medical_insurance,
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const {
          id_plan_navigation: {
            medical_insurance_navigation: { medical_insurance },
          },
        } = budget;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En USD",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { is_usd } = budget;

        return is_usd ? (
          <LuCircleCheck className="size-5 mx-auto text-green-500" />
        ) : (
          <FcCancel className="size-6 mx-auto" />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "total_amount",
      header: "Importe total",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { total_amount } = budget;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {total_amount !== null
              ? total_amount?.toLocaleString("es-AR", {
                  style: "currency",
                  currency: "ARS",
                })
              : "-"}
          </p>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "Saldo",
      cell: ({ row: { original: budget } }: { row: { original: any } }) => {
        const { balance } = budget;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {numberToCurrency(balance)}
          </p>
        );
      },
    },
  ];
};
