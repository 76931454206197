import React from "react";
import classNames from "classnames";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms";
import { useStateAcordeon } from "@/hooks";
import { useGlobalStore } from "@/providers/global-provider";

export function Data<TData, TValue>({ columns, data }: any) {
  const [globalFilter, setGlobalFilters] = React.useState("");
  const { ref_header } = useGlobalStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilters,
    state: {
      globalFilter,
    },
  });
  return (
    <Table
      className={classNames(
        "rounded-md !w-full h-[calc(100vh-160px)] bg-white",
        {
          "!h-[calc(100vh-220px)]":
            STATE_ACCORDION && STATE_ACCORDION !== "closed",
        }
      )}
    >
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow
            key={headerGroup.id}
            className="sticky top-0 bg-white z-10 shadow-sm hover:!bg-white"
          >
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row: any) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
            >
              {row.getVisibleCells().map((cell: any) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No hay historial para mostrar
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
