export { ConfigurationSlice } from "./configuration";
export { CommonsSlice } from "./commons";
export { DashboardSlice } from "./dashboard";
export { ReportsSlice } from "./reports";
export { StatisticsSlice } from "./statistics";
export { AdministrationSlice } from "./administration";
export { UserSlice } from "./login";
export { GallerySlice } from "./gallery";
export { AppointmentsSlice } from "./appointments-patient";
export {
  PatientsSlice,
  BenefitsSlice,
  HistorySlice,
  PaymentsSlice,
  PrescriptionsSlice,
  OrdersSlice,
  BudgetsSlice,
} from "./patient";
export { PatientLaboratorySlice } from "./patient-laboratory";
export { PatientBudgetsSlice } from "./patient-budgets";
export { ScheduleSlice } from "./schedule";
export { RemindersSlice } from "./reminders";