import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_order: z.number().optional().nullable(),
  description: z.string(),
  is_manual_concept: z.boolean(),
  id_patient: z.number(),
  amount_laboratory: z.coerce.number().optional(),
  id_laboratory: z.number(),
  date_order: z.date(),
  date_estimated_received: z.date(),
  observation: z.string(),
  date_received: z.date().optional(),
  id_professional: z.number(),
  id_budget: z.number().nullable(),
  link_budget: z.boolean(),
  link_office: z.boolean(),
  id_office: z.number().nullable(),
  id_concept: z.number().nullable(),
  order_from_list: z.object({
    id_concept: z.number().nullable(),
    color: z.string().optional(),
    code: z.string().nullable().optional(),
    description: z.string(),
    is_usd: z.boolean(),
    id_order_concept: z.number(),
    id_order: z.number().optional(),
    id_item: z.number().optional(),
    amount: z.coerce.number(),
    quantity: z.coerce.number(),
    total_amount: z.coerce.number(),
    tooth_section: z.string(),
    laboratory_items: z.any().optional().nullable(),
    laboratory_navigation: z.any().optional().nullable(),
    rowversion: z.string().nullable().optional(),
  }),
  is_usd: z.boolean(),
  order_list: z.array(
    z.object({
      id_concept: z.number().nullable(),
      code: z.string().nullable().optional(),
      description: z.string(),
      is_usd: z.boolean(),
      id_order_concept: z.number(),
      id_order: z.number().optional(),
      color: z.string().optional(),
      id_item: z.number().optional(),
      amount: z.coerce.number(),
      quantity: z.coerce.number(),
      total_amount: z.coerce.number(),
      tooth_section: z.string(),
      laboratory_items: z.any().optional(),
      laboratory_navigation: z.any().optional().nullable(),
      rowversion: z.string().nullable().optional(),
    })
  ),
  total_amount_order_list: z.number(),
  total_quantity_order_list: z.number(),
  invoice_number: z.string().optional(),
  date_delivered: z.date().optional().nullable(),
  paid: z.string().optional(),
  date_payment: z.date().optional().nullable(),
  modify_percentage_prof: z.boolean(),
  percentage_discount: z.coerce.number().optional(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
