"use client";

import * as React from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { LuCalendar as CalendarIcon } from "react-icons/lu";
import classNames from "classnames";
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms";
import {
  FormField,
  FormLabel,
  FormItem,
  FormControl,
} from "@/components/molecules";

export function DatePicker({
  name,
  form,
  label,
  dateformat = "PPP",
  className,
  disabled,
  disabledDates,
  captionLayout = "label",
  startMonth,
  endMonth,
}: {
  name: string;
  label?: string;
  form: any;
  dateformat?: string;
  className?: string;
  disabled?: boolean;
  disabledDates?: any;
  captionLayout?: "label" | "dropdown" | "dropdown-months" | "dropdown-years";
  startMonth?: Date;
  endMonth?: Date;
}) {
  const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
            <PopoverTrigger asChild className={className}>
              <FormControl>
                <Button
                  disabled={disabled}
                  variant="outline"
                  className={classNames(
                    "w-full justify-between text-left font-normal",
                    !field.value && "text-muted-foreground",
                    className
                  )}
                >
                  {field.value ? (
                    format(field.value, dateformat, { locale: es })
                  ) : (
                    <span>Seleccionar fecha</span>
                  )}
                  <CalendarIcon className="mr-2 size-4" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                disabled={disabledDates}
                selected={field.value}
                onSelect={(date: any) => {
                  date && field.onChange(date);
                  setCalendarOpen(false);
                }}
                startMonth={startMonth}
                endMonth={endMonth}
                captionLayout={captionLayout}
              />
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  );
}
