import { prescription } from "./patients";
import { professional } from "./professionals";
import { office } from "./office";
import { laboratory } from "./laboratories";
import { specialty } from "./specialties";
import { installments } from "./administration";
import {
  Group,
  Plan,
  Process,
  Assistance,
  Holyday,
  UserParams,
  MedicalInsurance,
  MedicalInsuranceDB,
  GroupDB,
  PlanDB,
  ProcessDB,
  AssistanceDB,
  HolydayDB,
  UserParamsDB,
  BenefitDB,
  Benefit,
  BankDB,
  Bank,
  CardDB,
  Card,
  Supplier,
  SupplierDB,
  RepresentationDB,
  Representation,
  FastBenefitDB,
  FastBenefit,
  PermissionsDB,
  Permissions,
  User,
  UserDB,
  ProcessNavigation,
  ProcessNavigationDB,
} from "@/types/general";

export const representations = (
  representation: RepresentationDB
): Representation => {
  return {
    drawing: representation.id === "" ? "NO_ID" : representation.id?.trim(),
    name: representation.nombre,
    thoot: representation.diente,
    imageSourcer: representation.imageSourcer,
  };
};

export const cards = (card: CardDB): Card => {
  return {
    id_card: card.id_tarjeta,
    card: card.tarjeta,
    tax_id_number: card.nro_comercio,
    is_credit: card.es_credito,
    enabled: !!(card.habilitada !== false),
    rowversion: card.rowversion,
    card_installments: card.tarjetas_cuotas.map(installments),
    card_movements: card.tarjetas_mov,
  };
};

export const group = (group: GroupDB): Group => {
  return {
    id_group: group.id_grupo,
    id_med_ins: group.id_os,
    code_group: group.cod_grupo,
    description: group.descripcion,
    rowversion: group.rowversion,
    id_osNavigation: group.id_osNavigation,
  };
};

export const plans = (plan: PlanDB): Plan => {
  return {
    id_plan: plan?.id_plan,
    code_plan: plan?.codigo_plan,
    name: plan?.nombre,
    id_med_ins: plan?.id_os,
    disabled: plan?.inhabilitada,
    rowversion: plan?.rowversion,
    benefits:
      plan?.prestaciones?.length > 0 ? plan?.prestaciones?.map(benefit) : [],
  };
};

export const benefit = (benefit: BenefitDB): Benefit => {
  return {
    id_benefit: benefit.id_prestacion,
    id_plan: benefit.id_plan,
    benefit_code: benefit.codigo_prest_os?.trim(),
    code_benefit_med_ins_liq: benefit.codigo_prest_os_liq?.trim(),
    description: benefit.descripcion?.trim(),
    amount_mi: benefit.importe_os,
    amount_patient: benefit.importe_pac,
    drawing: benefit.dibujo?.trim(),
    tooth: benefit.diente,
    requires_face: benefit.pide_cara,
    internal: benefit.interno,
    disabled: benefit.inhabilitada,
    unit: benefit.unidad,
    quantity: benefit.cantidad || 0,
    is_odontogram: benefit.es_odontograma,
    age: benefit.edad,
    per_patient: benefit.por_paciente,
    per_piece_sector: benefit.por_pieza_sector,
    id_group: benefit.id_grupo,
    id_benefit_to_be_performed: benefit.id_prestacion_a_realizar,
    id_specialty: benefit.id_especialidad,
    rowversion: benefit.rowversion,
    is_usd: benefit.es_usd,
    id_specialty_navigation: benefit.id_especialidadNavigation,
  };
};

export function params({ param, data }: { param: string[]; data: any }) {
  const map: {
    [key: string]: { name: string; key: string; mapper: (arg: any) => any };
  } = {
    USUAR: { name: "users", key: "listaUsuarios", mapper: user },
    PROFE: {
      name: "professionals",
      key: "listaProfesionales",
      mapper: professional,
    },
    SUCUR: { name: "offices", key: "listaSucursales", mapper: office },
    PERMI: { name: "system", key: "listaPermisos", mapper: process },
    BANCOS: { name: "banks", key: "listaBancos", mapper: banks },
    TARJETAS: { name: "cards", key: "listaTarjetas", mapper: cards },
    OSPLA: {
      name: "medical_insurances",
      key: "listaObraSociales",
      mapper: medicalInsurance,
    },
    PARAM: {
      name: "user_params",
      key: "parametros",
      mapper: userparams,
    },
    FERIA: {
      name: "holydays",
      key: "listaFeriados",
      mapper: holyday,
    },
    ASIST: {
      name: "assistance",
      key: "listaAsistencias",
      mapper: assistance,
    },
    ESPEC: {
      name: "specialties",
      key: "listaEspecialidades",
      mapper: specialty,
    },
    LABOR: {
      name: "laboratories",
      key: "laboratories",
      mapper: laboratory,
    },
    PROVEE: {
      name: "suppliers",
      key: "suppliers",
      mapper: suppliers,
    },
  };

  // ############################################# //
  // Return a new object with the mapped data, if
  // the data isn't an array, return the data as is.
  // ############################################# //
  const res = param.reduce((acc, p) => {
    const { name, key, mapper } = map[p];
    acc[name] = Array.isArray(data[key])
      ? data[key].map(mapper)
      : data[key] === null
      ? null
      : mapper(data[key]);
    return acc;
  }, {} as any);

  return res;
}

export const userPermissions = (
  userPermissions: any[],
  systemPermissions: any[]
) => {
  const subPermissions = systemPermissions.map((i) => i.option);
  const mapped = userPermissions.reduce(function (memo, permission) {
    if (
      permission.id_procesoNavigation.item_sistema ===
      permission.id_procesoNavigation.opcion
    ) {
      memo[permission.id_procesoNavigation.item_sistema] = true;
    }
    if (subPermissions.includes(permission.id_procesoNavigation.opcion)) {
      memo[permission.id_procesoNavigation.opcion] = true;
    }
    return memo;
  }, {});

  return mapped;
};

export const banks = (bank: BankDB): Bank => {
  return {
    id_bank: bank.id_banco,
    name: bank.banco,
    account_number: bank.cta_nro,
    description: bank.descripcion,
    initial_balance: bank.saldo_inicial,
    enabled: !!(bank.habilitado !== false),
    movements: bank.bancos_mov,
    rowversion: bank.rowversion,
  };
};

export const process = (process: ProcessDB): Process => {
  return {
    id_process: process.id_proceso,
    system_item: process.item_sistema,
    option: process.opcion,
    order: process.orden,
    description: process.descripcion,
    rowversion: process.rowversion,
  };
};

export const assistance = (assistance: AssistanceDB): Assistance => {
  return {
    code: assistance.codigo,
    description: assistance.descripcion,
  };
};

export const holyday = (holyday: HolydayDB): Holyday => {
  return {
    id_holyday: holyday.id_feriado,
    id_professional: holyday.id_prof,
    date_from: holyday.desde,
    date_to: holyday.hasta,
    day: holyday.dia,
    rowversion: holyday.rowversion,
    option: holyday.opcion,
    professional_data: holyday.id_profNavigation,
  };
};

export const userparams = (params: UserParamsDB): UserParams => {
  const offices: string[] = [];
  [params.suc1, params.suc2].forEach((suc) => {
    if (suc) {
      offices.push(suc);
    }
  });
  const aditional_phones: string[] = [];
  [params.prox_nro1, params.prox_nro2].forEach((prox) => {
    if (prox) {
      aditional_phones.push(prox);
    }
  });
  const taxes: number[] = [];
  [params.iva1, params.iva2].forEach((iva) => {
    if (iva) {
      taxes.push(iva);
    }
  });
  const types: string[] = [];
  [params.tipo1, params.tipo2].forEach((type) => {
    if (type) {
      types.push(type);
    }
  });

  return {
    id: params.id,
    name: params.nombre,
    address: params.direccion,
    phone: params.telefono,
    email: params.email,
    users: params.usuarios,
    types,
    offices,
    aditional_phones,
    taxes,
    colors: {
      before: params.color_anteriores,
      done: params.color_realizadas,
      to_do: params.color_a_realizar,
    },
    balance_cash: params.saldo_caja,
    clinic_history_from: params.h_clinica_desde,
    search: params.buscar,
    id_pas: params.id_pas,
    budget_copy: params.leyenda_presup,
    alert_requested: params.aviso_pedidos,
    alert_tasks: params.aviso_tareas,
    alert_stock: params.aviso_stock,
    logo: params.logo,
    print_appointments: params.imp_turnos,
    print_schedule: params.imp_agenda,
    print_file: params.imp_ficha,
    print_current_account: params.imp_cta_cte,
    print_budgets: params.imp_pptos,
    print_requests: params.imp_ped,
    print_in: params.imprimir_en,
    appointments_in: params.turnos_en,
    margins_tlrb: params.margenes_tlrb,
    appointments_quantity_legend: params.cant_turnos_taco,
    cash_date: params.fecha_caja,
    print_messages: params.imprimir_mensajes,
    lender_code_bilog: params.cod_prest_bilog,
    lender_code_osde: params.cod_prest_osde,
    id_osde: params.id_osde,
    id_partners: params.id_socios,
    edit_patient_data: params.mod_datos_pac,
    system_date: params.fecha_sistema,
    odontogram_default: params.odo_pred,
    odontogram_background: params.odo_fondo,
    current_account: params.cta_cte,
    check_present: params.chk_presente,
    check_print_obs: params.chk_imp_obs,
    sms_user: params.sms_usuario,
    sms_password: params.sms_clave,
    sms_birthday_text: params.sms_texto_cumple,
    sms_appointments_text: params.sms_texto_turnos,
    print_balance: params.imp_saldo,
    pay_version: params.vs_paga,
    current_pay_version: params.vs_paga_act,
    user_bilog: params.usu_bilog,
    user_bilog_password: params.usu_bilog_clave,
    id_swiss: params.id_swiss,
    id_ospoce: params.id_ospoce,
    site_transmitter_traditum: params.sitio_emisor_traditum,
    tax_number_swiss: params.cuit_swiss,
    swiss_user: params.swiss_usu,
    swiss_password: params.swiss_pas,
    ospoce_user: params.ospoce_usu,
    ospoce_password: params.ospoce_pas,
    server_bk_online: params.svr_BKONLINE,
    tax_number: params.cuit,
    tax_condition: params.cond_fiscal,
    sales_point: params.pventa,
    facturante_user: params.fact_usu,
    facturante_password: params.fact_pas,
    facturante_empresa: params.fact_empresa,
    date_sql: params.fecha_SQL,
    id_private: params.id_privado,
    schedule_alert: params.ag_alerta,
    schedule_aca: params.ag_aca,
    facturante_controller: params.control_fac,
    lender_code_pami: params.cod_prest_pami,
    benefit_odo_sincr: params.prest_odo_sincr,
    o_prof_dbf: params.o_prof_dbf,
    email_display: params.email_display,
    email_replyto: params.email_replyto,
    ph_updated: params.ph_actualizado,
    what_template_appointment: params.what_template_turno,
    what_template_birthday: params.what_template_cumple,
    what_region: params.what_region,
    rowversion: params.rowversion,
    use_usd: params.doble_moneda,
    recipe_logo: params.logo_recetas,
  };
};

export const userParamsToDB = (params: UserParams): UserParamsDB => {
  return {
    id: params.id,
    nombre: params.name,
    direccion: params.address,
    telefono: params.phone,
    email: params.email,
    usuarios: params.users,
    tipo1: params.types[0] || null,
    tipo2: params.types[1] || null,
    suc1: params.offices[0] || null,
    suc2: params.offices[1] || null,
    prox_nro1: params.aditional_phones[0] || null,
    prox_nro2: params.aditional_phones[1] || null,
    iva1: params.taxes[0],
    iva2: params.taxes[1],
    color_anteriores: params.colors.before,
    color_realizadas: params.colors.done,
    color_a_realizar: params.colors.to_do,
    saldo_caja: params.balance_cash,
    h_clinica_desde: params.clinic_history_from,
    buscar: params.search,
    id_pas: params.id_pas,
    leyenda_presup: params.budget_copy,
    aviso_pedidos: params.alert_requested,
    aviso_tareas: params.alert_tasks,
    aviso_stock: params.alert_stock,
    logo: params.logo,
    imp_turnos: params.print_appointments,
    imp_agenda: params.print_schedule,
    imp_ficha: params.print_file,
    imp_cta_cte: params.print_current_account,
    imp_pptos: params.print_budgets,
    imp_ped: params.print_requests,
    imprimir_en: params.print_in,
    turnos_en: params.appointments_in,
    margenes_tlrb: params.margins_tlrb,
    cant_turnos_taco: params.appointments_quantity_legend,
    fecha_caja: params.cash_date,
    imprimir_mensajes: params.print_messages,
    cod_prest_bilog: params.lender_code_bilog,
    cod_prest_osde: params.lender_code_osde,
    id_osde: params.id_osde,
    id_socios: params.id_partners,
    mod_datos_pac: params.edit_patient_data,
    fecha_sistema: params.system_date,
    odo_pred: params.odontogram_default,
    odo_fondo: params.odontogram_background,
    cta_cte: params.current_account,
    chk_presente: params.check_present,
    chk_imp_obs: params.check_print_obs,
    sms_usuario: params.sms_user,
    sms_clave: params.sms_password,
    sms_texto_cumple: params.sms_birthday_text,
    sms_texto_turnos: params.sms_appointments_text,
    imp_saldo: params.print_balance,
    vs_paga: params.pay_version,
    vs_paga_act: params.current_pay_version,
    usu_bilog: params.user_bilog,
    usu_bilog_clave: params.user_bilog_password,
    id_swiss: params.id_swiss,
    id_ospoce: params.id_ospoce,
    sitio_emisor_traditum: params.site_transmitter_traditum,
    cuit_swiss: params.tax_number_swiss,
    swiss_usu: params.swiss_user,
    swiss_pas: params.swiss_password,
    ospoce_usu: params.ospoce_user,
    ospoce_pas: params.ospoce_password,
    svr_BKONLINE: params.server_bk_online,
    cuit: params.tax_number,
    cond_fiscal: params.tax_condition,
    pventa: params.sales_point,
    fact_usu: params.facturante_user,
    fact_pas: params.facturante_password,
    fact_empresa: params.facturante_empresa,
    fecha_SQL: params.date_sql,
    id_privado: params.id_private,
    ag_alerta: params.schedule_alert,
    ag_aca: params.schedule_aca,
    control_fac: params.facturante_controller,
    cod_prest_pami: params.lender_code_pami,
    prest_odo_sincr: params.benefit_odo_sincr,
    o_prof_dbf: params.o_prof_dbf,
    email_display: params.email_display,
    email_replyto: params.email_replyto,
    ph_actualizado: params.ph_updated,
    what_template_turno: params.what_template_appointment,
    what_template_cumple: params.what_template_birthday,
    what_region: params.what_region,
    rowversion: params.rowversion,
    doble_moneda: params.use_usd,
    logo_recetas: params.recipe_logo,
  };
};

export const medicalInsurance = (
  medicalInsurance: MedicalInsuranceDB
): MedicalInsurance => {
  return {
    id_med_ins: medicalInsurance.id_os,
    code_med_ins: medicalInsurance.codigo_os,
    medical_insurance: medicalInsurance.obra_social,
    address: medicalInsurance.domicilio,
    city: medicalInsurance.localidad,
    state: medicalInsurance.provincia,
    phone: medicalInsurance.telefono,
    fax: medicalInsurance.fax,
    email: medicalInsurance.e_mail,
    tax_number: medicalInsurance.cuit,
    observations: medicalInsurance.observaciones,
    benefits_quantity: medicalInsurance.cant_prest,
    iva_ob: medicalInsurance.iva_ob,
    iva_ad: medicalInsurance.iva_ad,
    load_for_period: medicalInsurance.cargar_por_periodo,
    client_ftp: medicalInsurance.cliente_ftp || "",
    disabled: medicalInsurance.inhabilitada,
    rowversion: medicalInsurance.rowversion,
    id_financier: medicalInsurance.financierId,
    option: medicalInsurance.opcion,
    groups:
      medicalInsurance.grupos?.length > 0
        ? medicalInsurance.grupos.map(group)
        : [],
    plans: medicalInsurance.planes?.map(plans) || [],
    fast_benefits: medicalInsurance.prest_rapidas.map(fastBenefits),
  };
};

export const fastBenefits = (fast: FastBenefitDB): FastBenefit => {
  return {
    id_benefit_quick: fast.id_prest_rapida,
    id_med_ins: fast.id_os,
    code_benefit1: fast.cod_prest1,
    code_benefit2: fast.cod_prest2,
    code_benefit3: fast.cod_prest3,
    code_benefit4: fast.cod_prest4,
    code_benefit5: fast.cod_prest5,
    code_benefit6: fast.cod_prest6,
    code_benefit7: fast.cod_prest7,
    code_benefit8: fast.cod_prest8,
    code_benefit9: fast.cod_prest9,
    code_benefit10: fast.cod_prest10,
  };
};

export const suppliers = (supplier: SupplierDB): Supplier => {
  return {
    id_supplier: supplier.id_provee,
    name: supplier.nombre,
    address: supplier.direccion,
    city: supplier.loc_ciu,
    phone: supplier.telefono,
    fax: supplier.fax,
    email: supplier.email,
    contact: supplier.contacto,
    balance: supplier.saldo,
    tax_number: supplier.cuit,
    observation: supplier.obs,
    enabled: supplier.habilitado,
    id_professional: supplier.id_prof,
    id_laboratory: supplier.id_labo,
    rowversion: supplier.rowversion,
    expense: supplier.egresos,
    supplier_current_account: supplier.prove_cc,
    stock: supplier.stock_compras,
  };
};

export const user = (user: UserDB): User => {
  return {
    id_user: user.id_usuario,
    user: user.usuario,
    id_professional: user.id_prof,
    name: user.nombre,
    password: user.clave,
    is_supervisor: user.es_supervisor,
    is_dentist: user.es_odontologo,
    enabled: user.habilitado,
    is_dentist_schedule: user.es_odontologo_agenda,
    is_dentist_data: user.es_odontologo_datos,
    is_dentist_guard: user.es_odontologo_guardia,
    id_office: user.id_sucursal,
    rowversion: user.rowversion,
    use_usd: user.doble_moneda,
    id_office_navigation: user.id_sucursalNavigation,
    permission: user.permisos?.map(permission),
    token: user.token,
    option: user.opcion,
    plan_version: user.plan_version,
    mod_adm: user.mod_adm,
    is_owner: user.is_owner,
    manager_code: user.manager_code,
    mod_aud: user.mod_aud,
    prescriptions_tyc: user.recetas_tyc,
    web_user: user.web_user,
  };
};

export const permission = (permission: PermissionsDB): Permissions => {
  return {
    id_permission: permission.id_permiso,
    id_process: permission.id_proceso,
    id_process_navigation:
      (permission.id_procesoNavigation &&
        processNavigation(permission.id_procesoNavigation)) ||
      null,
    id_user: permission.id_usuario,
    id_user_navigation: permission.id_usuarioNavigation,
  };
};

export const processNavigation = (
  process: ProcessNavigationDB
): ProcessNavigation => {
  return {
    description: process.descripcion,
    id_process: process.id_proceso,
    system_item: process.item_sistema,
    option: process.opcion,
    order: process.orden,
    rowversion: process.rowversion,
  };
};
