type State = {
  reminders: {
    data: {
      [key: string]: any;
    };
    list_send_reminders: any;
    list_sent_reminders: any;
  };
};

type Actions = {
  setReminders: (payload: { data: any[] }) => void;
  setListSendReminders: (payload: any[]) => void;
  setListSentReminders: (payload: any[]) => void;
};

type RemindersStore = State & Actions;

export const INIT_STATE: State = {
  reminders: {
    data: [],
    list_send_reminders: [],
    list_sent_reminders: [],
  },
};

export const RemindersSlice = (
  set: (partial: Partial<RemindersStore>) => void,
  get: () => RemindersStore
): RemindersStore => ({
  ...INIT_STATE,
  setReminders: ({ data }: { data: any[] }) => {
    const state = get();

    set({
      reminders: {
        ...state.reminders,
        data,
      },
    });
  },
  setListSendReminders: (list_send_reminders: any[]) => {
    const state = get();

    set({
      reminders: {
        ...state.reminders,
        list_send_reminders,
      },
    });
  },
  setListSentReminders: (list_sent_reminders: any[]) => {
    const state = get();

    set({
      reminders: {
        ...state.reminders,
        list_sent_reminders,
      },
    });
  },
});
