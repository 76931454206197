"use client";

import React from "react";
import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation";
import { LuInfo as Info, LuLogOut as LogOut } from "react-icons/lu";
import { Nav, Avatar } from "@/components/molecules";
import { TooltipProvider, Separator } from "@/components/atoms";
import { useGlobalStore } from "@/providers/global-provider";
import { MainNav } from "@/types/nav";
import { createNavigation } from "@/utils/permissions";

export function DesktopNav({ children }: MainNav) {
  const path = usePathname();
  const router = useRouter();
  const {
    user: { name, plan_version, is_owner, is_supervisor },
    permissions,
  } = useGlobalStore();

  const mainNav = createNavigation({
    permissions,
    plan: plan_version,
    isSupervisor: is_supervisor,
    isOwner: is_owner,
  });

  const secNav = [
    {
      title: "Acerca de",
      new: false,
      url: "/dashboard/about",
      icon: Info,
      styles:
        "!bg-transparent !text-slate-400 hover:!bg-slate-700 hover:!text-white/70",
    },
    {
      title: "Salir",
      new: false,
      url: "/",
      external: true,
      onClick: async () => {
        router.push("/?flush=true");
      },
      icon: LogOut,
      styles:
        "!bg-transparent !text-red-400 hover:!bg-red-700 hover:!text-white/70",
    },
  ];

  const getNavLinks = (nav: any) => {
    return nav.map((link: any) => {
      let IS_ACTIVE =
        (path.startsWith(`${link.url ?? ""}/`) && link.url !== "/dashboard") ||
        link.subItems?.some((item: any) => path.includes(item)) ||
        path === link.url;

      return IS_ACTIVE
        ? { ...link, styles: "bg-slate-700 hover:!bg-slate-700" }
        : {
            ...link,
            styles:
              "!bg-transparent !text-slate-400 hover:!bg-slate-700 hover:!text-white/70",
          };
    });
  };

  return (
    <TooltipProvider delayDuration={0}>
      <nav className="h-dvh items-stretch fixed flex">
        <div
          className={classNames(
            "bg-slate-800 hidden lg:flex lg:flex-col z-50 w-[50px] transition-all duration-300 ease-in-out"
          )}
        >
          <Avatar name={name} planVersion={plan_version} />
          <Separator className="!bg-slate-700" />
          <Nav links={getNavLinks(mainNav)} />
          <Separator className="!bg-slate-700" />
          <Nav links={secNav} />
        </div>
        <div className="h-full overflow-y-auto static w-[calc(100vw-50px)]">
          {children}
        </div>
      </nav>
    </TooltipProvider>
  );
}
