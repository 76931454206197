import React from "react";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { HiUsers, HiDotsVertical } from "react-icons/hi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { LuColumns3, LuCalendar, LuPlaneLanding } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  Badge,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/atoms";
import { BadgeSelect } from "@/components/molecules/badge-select";
import { ATTENDANCE_OPTIONS } from "@/libs/schedule";
import { Translations } from "@/types/general";
import { formatPhoneNumberWhatsapp } from "@/utils/helpers";
import { useGlobalStore } from "@/providers/global-provider";
import { useScrollAutomatic } from "@/hooks/use-scroll-automatic";
import { ContextMenu } from "../context-menu";

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  getValue: (value: number | string) => any;
  original: any;
};

export const Columns = ({
  actions,
  translations,
  permissions,
  config,
}: {
  actions: any;
  changeDataTable?: string;
  translations: Translations;
  permissions: any;
  config: {
    region: string;
    countries: any;
  };
}) => {
  const {
    schedule: {
      config: { type_table },
    },
  } = useGlobalStore();
  const { viewContactData } = permissions;
  const { region, countries } = config;
  const EXPANDED_SCHEDULE = React.useMemo(
    () => ["expanded"].includes(type_table),
    [type_table]
  );
  const rowRefs = React.useRef<{ [key: string]: HTMLDivElement | null }>({});
  useScrollAutomatic({ rowRefs });

  const columns_rows = [
    {
      id: "actions",
      header: ({ table }: { table: any }) => {
        return (
          <DropdownMenu>
            <Tooltip>
              <TooltipProvider>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <div className="relative p-2 hover:cursor-pointer flex-none">
                      <span className="sr-only">Modificar columnas</span>
                      <LuColumns3 className="text-gray-500 hover:text-gray-900 h-4 w-4" />
                    </div>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>Modificar columnas</p>
                </TooltipContent>
              </TooltipProvider>
            </Tooltip>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Modificar columnas</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {table
                .getAllColumns()
                .filter((column: any) => column.getCanHide())
                .map((column: any) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {column.columnDef.header}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div
                className="relative p-2 hover:cursor-pointer flex-none"
                id={`context-menu-trigger-${time}`}
              >
                <span className="sr-only">Abrir menu</span>
                <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
            <ContextMenu
              type={appointment_type.status}
              appointment={row.original}
              actions={actions}
              permissions={permissions}
            />
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "time",
      header: "Hora",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time, attendance, eta } = row.original;

        const showStatus =
          appointment_type.type === "overturn" ||
          appointment_type.status === "canceled";
        const statusCss = classNames(
          "rounded-full p-1 h-1.5 w-1.5 rounded-full",
          appointment_type.type === "overturn" && "bg-purple-400",
          appointment_type.status === "canceled" && "bg-red-500"
        );
        const appointmentCss = classNames(
          "font-regular text-base text-slate-800",
          showStatus && "mr-2"
        );

        return (
          <div
            ref={(el) => {
              rowRefs.current[time] = el;
            }}
          >
            <div className="flex items-center">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={classNames("flex items-center", {
                        "hover:underline hover:cursor-pointer":
                          !EXPANDED_SCHEDULE,
                      })}
                    >
                      <p data-time="schedule-time" className={appointmentCss}>
                        {time}
                      </p>
                    </div>
                  </TooltipTrigger>
                  {eta && !EXPANDED_SCHEDULE && (
                    <TooltipContent
                      className="!bg-black text-white"
                      side="bottom"
                    >
                      <p>Hora de llegada: {eta}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
              {showStatus && <div className={statusCss} />}
            </div>
            {(attendance || eta) && EXPANDED_SCHEDULE && (
              <div className="flex items-center mt-2">
                {eta && (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex items-center hover:underline">
                          <LuPlaneLanding className="w-5 h-5 text-slate-400" />
                          <p className="ml-1 text-sm text-slate-500">{eta}</p>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="!bg-black text-white">
                        <p>Hora de llegada</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "attendance",
      header: "Presentismo",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { patient_name, time, id_patient, dont_give } = row.original;
        const attendance = ATTENDANCE_OPTIONS.find(
          (att) => att.id === row.getValue("attendance")
        );

        if ((!id_patient && !patient_name) || dont_give) {
          return null;
        }

        return (
          <BadgeSelect
            text={
              (EXPANDED_SCHEDULE ? attendance?.text : attendance?.id) || "-"
            }
            className={classNames(
              attendance?.styles,
              "lg:w-full md:justify-between",
              EXPANDED_SCHEDULE ? "md:min-w-[160px]" : "max-w-20"
            )}
            options={ATTENDANCE_OPTIONS}
            onSelect={(e: any) =>
              actions({
                appointment: row.original,
                type: "change_attendance",
                attendance: e.target.id || null,
              })
            }
          />
        );
      },
      filterFn: (row: RowDataT, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "patient_name",
      header: "Paciente",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          patient_name,
          date,
          professional_name,
          time,
          patient_phone,
          clinic_history_number,
          medical_insurance: { name, plan, affiliate_number },
        } = row.original;

        const messageDate = new Date(date).toLocaleDateString("es", {
          day: "numeric",
          year: "numeric",
          month: "short",
        });
        const message = `Hola, ${patient_name}! Te recordamos tu turno con *${professional_name}* el dia *${messageDate}* a las *${time}hs*. _Para cancelar o modificar el turno no dudes en contactarnos._ Te esperamos!`;
        const areaNumber =
          countries?.find(
            (country: any) => country.iso3 === region?.toUpperCase()
          )?.phoneCode || "54";

        const onClickHandler = () => {
          const baseUrl = "https://wa.me/";
          const VALIDATE_NUMBER = formatPhoneNumberWhatsapp(
            patient_phone,
            areaNumber
          );
          const completeUrl = `${baseUrl}${VALIDATE_NUMBER}?text=${message}`;

          window?.open(completeUrl, "_blank");
        };

        return (
          <div className="md:min-w-[200px]">
            <div className="flex flex-col-reverse items-start 2xl:flex-row 2xl:items-center">
              <HoverCard>
                <HoverCardTrigger>
                  <p
                    className={classNames(
                      "font-semibold text-sm text-slate-800",
                      {
                        "hover:underline hover:cursor-pointer":
                          !EXPANDED_SCHEDULE,
                      }
                    )}
                  >
                    {patient_name}
                  </p>
                </HoverCardTrigger>
                {!EXPANDED_SCHEDULE && (
                  <HoverCardContent className="!w-full">
                    {clinic_history_number || patient_phone ? (
                      <>
                        <h4 className="text-sm font-bold text-slate-900">
                          Datos del paciente
                        </h4>
                        <div className="grid grid-cols-2 gap-5 mt-2">
                          {clinic_history_number && (
                            <div className="flex items-center">
                              <p className="text-sm text-slate-800 mr-2">
                                Historia clínica:
                              </p>
                              <Badge className="w-auto !inline pointer-events-none">
                                {clinic_history_number}
                              </Badge>
                            </div>
                          )}
                          {patient_phone && viewContactData && (
                            <div className="flex items-center">
                              <p className="text-sm text-slate-800 mr-2">
                                Tel:{" "}
                                <span className="text-slate-500">
                                  {patient_phone}
                                </span>
                              </p>
                              <span onClick={onClickHandler}>
                                <AiOutlineWhatsApp
                                  className="ml-2 size-4 hover:cursor-pointer"
                                  fill="#28D146"
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {name && (
                          <>
                            <h4 className="text-sm font-bold mt-4 text-slate-900">
                              {translations.medical_insurance_data}
                            </h4>
                            <div className="grid grid-cols-2 gap-5 mt-2">
                              {name && (
                                <p className="text-sm text-slate-800">
                                  {`${translations.medical_insurance_abbreviation}: `}
                                  <span className="text-slate-500">
                                    {name} - {plan}
                                  </span>
                                </p>
                              )}
                              {affiliate_number && (
                                <p className="text-sm text-slate-800">
                                  Nro. Afiliado:{" "}
                                  <span className="text-slate-500">
                                    {affiliate_number}
                                  </span>
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <h4 className="text-sm font-bold">
                        No hay datos para mostrar
                      </h4>
                    )}
                  </HoverCardContent>
                )}
              </HoverCard>
              {clinic_history_number && EXPANDED_SCHEDULE && (
                <Badge className="w-auto 2xl:ml-3">
                  {`H.C: ${clinic_history_number}`}
                </Badge>
              )}
            </div>
            {patient_phone && viewContactData && EXPANDED_SCHEDULE && (
              <span className="flex items-center">
                <p className="text-sm text-slate-500/80">
                  Tel: {patient_phone}
                </p>
                <span onClick={onClickHandler}>
                  <AiOutlineWhatsApp
                    className="ml-2 w-4 h-4 hover:cursor-pointer"
                    fill="#28D146"
                  />
                </span>
              </span>
            )}
          </div>
        );
      },
    },
    EXPANDED_SCHEDULE && {
      accessorKey: "medical_insurance",
      header: translations.medical_insurance,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          medical_insurance: { name, plan, affiliate_number },
          time,
        } = row.original;

        return (
          name && (
            <div>
              <p className="font-semibold text-sm text-slate-800">{name}</p>
              <p className="text-sm text-slate-500/80">
                Plan: {plan} {affiliate_number && `- ${affiliate_number}`}
              </p>
            </div>
          )
        );
      },
    },
    {
      accessorKey: "observation",
      header: "Observación",
      cell: ({ row }: { row: RowDataT }) => {
        const { observation, time } = row.original;

        return (
          observation && (
            <p className="font-semibold text-sm text-slate-800">
              {observation}
            </p>
          )
        );
      },
    },
    {
      accessorKey: "mod_by",
      header: "Dado/Modificado",
      cell: ({ row }: { row: RowDataT }) => {
        const { mod_by, date_given, time } = row.original;

        return (
          mod_by && (
            <div
              className={classNames({
                "flex flex-row space-x-3 divide-x-2": !EXPANDED_SCHEDULE,
              })}
            >
              <div className="flex items-center">
                <HiUsers className="h-4 w-4 mr-2 text-slate-600" />
                <p className="font-semibold text-sm text-slate-800">{mod_by}</p>
              </div>
              <div
                className={classNames("flex items-center", {
                  "pl-2": !EXPANDED_SCHEDULE,
                })}
              >
                <LuCalendar className="h-4 w-4 mr-2 text-slate-600" />
                <p className="text-sm text-slate-500/80">
                  {date_given && format(parseISO(date_given), "dd/MM/yyyy")}
                </p>
              </div>
            </div>
          )
        );
      },
    },
  ].filter(Boolean);

  return columns_rows;
};
