import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  hour: z.coerce.number(),
  minutes: z.coerce.number(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
