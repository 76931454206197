export type DashboardState = {
  shortcuts: {
    showfloatingbar: boolean;
  };
};

export type DashboardActions = {
  setShowfloatingbar: () => void;
  setHiddefloatingbar: () => void;
};

export type DashboardStore = DashboardState & DashboardActions;

export const initState: DashboardState = {
  shortcuts: {
    showfloatingbar: false,
  },
};

export const DashboardSlice = (
  set: (partial: Partial<DashboardStore>) => void
) => ({
  ...initState,
  setShowfloatingbar: () => {
    set({
      shortcuts: {
        showfloatingbar: true,
      },
    });
  },
  setHiddefloatingbar: () => {
    set({
      shortcuts: {
        showfloatingbar: false,
      },
    });
  },
});
