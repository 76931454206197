"use client";

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { numberToCurrency } from "@/utils/currency";

type RowDataT = {
  original: any;
};

export const columns = [
  {
    accessorKey: "date",
    header: "Fecha",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { date } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap">
          {format(date, "dd/MM/yyyy")}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "concept",
    header: "Concepto",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { concept } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {concept}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "voucher",
    header: "Comprobante",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { voucher } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {voucher}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "type_number",
    header: "Tipo - N°",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { type_number } = row.original;

      return (
        <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
          {type_number}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "total",
    header: "Importe",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => {
      const { total } = row.original;

      return (
        <p
          className={classNames("flex justify-end lg:text-sm my-1", {
            "text-red-500": total < 0,
            "text-green-500": total > 0,
            "text-slate-600": total === 0,
          })}
        >
          {numberToCurrency(total)}
        </p>
      );
    },
    enableGrouping: false,
  },
  {
    accessorKey: "user",
    header: "Usuario",
    cell: ({ row }: { row: RowDataT }) => {
      const { user } = row.original;

      return <p className="lg:text-sm text-slate-600 my-1">{user}</p>;
    },
    enableGrouping: false,
  },
];
