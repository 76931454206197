import React from "react";
import classNames from "classnames";
import { LuCircleSlash } from "react-icons/lu";
import {
  Badge,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { PAYMENT_METHODS } from "@/utils/translate";
import { Offices } from "@/types/general";

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  getValue: (value: number | string) => any;
  original: any;
};

// This is the "Pagos de pacientes" columns
const payments = ({ offices }: { offices: Offices[] }) => {
  const columns = [
    {
      accessorKey: "patient",
      header: "Paciente",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { patientId, patient } = row.original;

        return (
          <p
            data-id={patientId}
            className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap"
          >
            {patient}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "concept",
      header: "Concepto",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { concept } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {concept}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "voucher",
      header: "Comprobante",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { voucher } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{voucher}</p>;
      },
    },
    {
      accessorKey: "type_number",
      header: "Tipo - N°",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { type_number } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-600 my-1">
            {type_number}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "method",
      header: "Forma",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { method } = row.original;

        return (
          <p className="lg:min-w-28 lg:text-sm text-slate-600 my-1">
            {PAYMENT_METHODS[method]}
          </p>
        );
      },
      filterFn: (row: RowDataT, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "professional",
      header: "Profesional",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { professional } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap font-bold lg:text-sm text-slate-600 my-1">
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: "owed",
      header: "Adeuda",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { owed } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-red-500": owed > 0,
              "text-slate-600": owed === 0,
            })}
          >
            {numberToCurrency(owed)}
          </p>
        );
      },
      aggregatedCell: ({ getValue }: { getValue: () => number }) => (
        <p
          className={classNames("flex justify-end lg:text-sm my-1", {
            "text-red-500": getValue() > 0,
            "text-slate-600": getValue() === 0,
          })}
        >
          {numberToCurrency(getValue())}
        </p>
      ),
      aggregationFn: "sum",
      enableGrouping: false,
    },
    {
      accessorKey: "paid",
      header: "Pagado",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { paid, voucher } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-green-500": paid > 0,
              "text-slate-600": paid === 0,
              "text-red-600": ["Nota Crédito"].includes(voucher),
            })}
          >
            {numberToCurrency(paid)}
          </p>
        );
      },
      aggregatedCell: ({ getValue }: { getValue: () => number }) => (
        <p
          className={classNames("flex justify-end lg:text-sm my-1", {
            "text-green-500": getValue() > 0,
            "text-slate-600": getValue() === 0,
          })}
        >
          {numberToCurrency(getValue())}
        </p>
      ),
      aggregationFn: "sum",
      enableGrouping: false,
    },
    {
      accessorKey: "obervation",
      header: "Observaciones",
      cell: ({ row }: { row: RowDataT }) => {
        const { obervation } = row.original;

        return (
          <Tooltip delayDuration={500}>
            <TooltipTrigger asChild>
              <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
                {obervation}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <p>{obervation}</p>
            </TooltipContent>
          </Tooltip>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "method_detail",
      header: "Detalles de forma",
      cell: ({ row }: { row: RowDataT }) => {
        const { method_detail } = row.original;

        return (
          <p className="lg:min-w-60 lg:text-sm text-slate-600 my-1">
            {method_detail}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "office",
      header: "Sucursal",
      cell: ({ row }: { row: RowDataT }) => {
        const { office } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap">
            {office}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "user",
      header: "Usuario",
      cell: ({ row }: { row: RowDataT }) => {
        const { user } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis font-bold lg:text-sm text-slate-600 my-1 text-nowrap 2xl:text-wrap">
            {user}
          </p>
        );
      },
      enableGrouping: false,
    },
    {
      accessorKey: "is_canceled",
      header: "Anulado",
      cell: ({ row }: { row: RowDataT }) => {
        const { is_canceled } = row.original;

        return (
          is_canceled && <LuCircleSlash className="size-6 text-slate-500" />
        );
      },
      enableGrouping: false,
    },
  ];

  !(offices?.length > 0) && columns.splice(10, 1);

  return columns;
};

// This is the "Otros ingresos" columns
export const incomes = ({ offices }: { offices: Offices[] }) => {
  const columns = [
    {
      accessorKey: "customer",
      header: "Cliente",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { customer } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap font-bold lg:text-sm text-slate-500 my-1">
            {customer}
          </p>
        );
      },
    },
    {
      accessorKey: "concept",
      header: "Concepto",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { concept } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {concept}
          </p>
        );
      },
    },
    {
      accessorKey: "voucher",
      header: "Comprobante",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { voucher } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{voucher}</p>;
      },
    },
    {
      accessorKey: "type_number",
      header: "Tipo - N°",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { type_number } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-600 my-1">
            {type_number}
          </p>
        );
      },
    },
    {
      accessorKey: "method",
      header: "Forma",
      cell: ({ row }: { row: RowDataT }) => {
        const { method } = row.original;

        return (
          <p className="lg:min-w-28 lg:text-sm text-slate-600 my-1">
            {PAYMENT_METHODS[method]}
          </p>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Total",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { total } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-red-500": total < 0,
              "text-green-500": total > 0,
              "text-slate-600": total === 0,
            })}
          >
            {numberToCurrency(total)}
          </p>
        );
      },
    },
    {
      accessorKey: "cuit",
      header: "CUIT",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { cuit } = row.original;

        return (
          <p className="lg:min-w-60 lg:text-sm text-slate-600 my-1">{cuit}</p>
        );
      },
    },
    {
      accessorKey: "detail",
      header: "Detalle",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { detail } = row.original;

        return (
          <p className="lg:min-w-60 lg:text-sm text-slate-600 my-1">{detail}</p>
        );
      },
    },
    {
      accessorKey: "method_detail",
      header: "Detalle de forma",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { method_detail } = row.original;

        return (
          <p className="lg:min-w-60 lg:text-sm text-slate-600 my-1">
            {method_detail}
          </p>
        );
      },
    },
    {
      accessorKey: "office",
      header: "Sucursal",
      cell: ({ row }: { row: RowDataT }) => {
        const { office } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{office}</p>;
      },
    },
    {
      accessorKey: "user",
      header: "Usuario",
      cell: ({ row }: { row: RowDataT }) => {
        const { user } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{user}</p>;
      },
    },
    {
      accessorKey: "is_canceled",
      header: "Anulado",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_canceled } = row.original;

        return (
          is_canceled && <LuCircleSlash className="size-6 text-slate-500" />
        );
      },
    },
  ];

  !(offices?.length > 0) && columns.splice(9, 1);

  return columns;
};

// This is the "Egresos varios" columns
export const expenses = ({ offices }: { offices: Offices[] }) => {
  const columns = [
    {
      accessorKey: "concept",
      header: "Concepto",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { concept } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-500 my-1">
            {concept}
          </p>
        );
      },
    },
    {
      accessorKey: "voucher",
      header: "Comprobante",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { voucher } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{voucher}</p>;
      },
    },
    {
      accessorKey: "is_payment",
      header: "Egreso por",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_payment } = row.original;

        return (
          <Badge
            className={classNames("rounded-md hover:bg-inherit", {
              "text-amber-600 bg-amber-50": is_payment,
              "text-pink-600 bg-pink-50": !is_payment,
            })}
          >
            {is_payment ? "Pago a proveedor" : "Otros egresos"}
          </Badge>
        );
      },
    },
    {
      accessorKey: "type_number",
      header: "Tipo - N°",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { type_number } = row.original;

        return (
          <p className="max-w-40 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm text-slate-600 my-1">
            {type_number}
          </p>
        );
      },
    },
    {
      accessorKey: "method",
      header: "Forma",
      cell: ({ row }: { row: RowDataT }) => {
        const { method } = row.original;

        return (
          <p className="lg:min-w-28 lg:text-sm text-slate-600 my-1">
            {PAYMENT_METHODS[method]}
          </p>
        );
      },
    },
    {
      accessorKey: "supplier",
      header: "Proveedor",
      cell: ({ row }: { row: RowDataT }) => {
        const { supplier } = row.original;

        return (
          <p className="lg:min-w-28 lg:text-sm font-bold text-slate-600 my-1">
            {supplier}
          </p>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Total",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { total } = row.original;

        return (
          <p
            className={classNames("flex justify-end lg:text-sm my-1", {
              "text-red-500": total > 0,
              "text-slate-600": total === 0,
            })}
          >
            {numberToCurrency(total)}
          </p>
        );
      },
    },
    {
      accessorKey: "method_detail",
      header: "Detalles de forma",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { method_detail } = row.original;

        return (
          <p className="lg:min-w-60 lg:text-sm text-slate-600 my-1">
            {method_detail}
          </p>
        );
      },
    },
    {
      accessorKey: "office",
      header: "Sucursal",
      cell: ({ row }: { row: RowDataT }) => {
        const { office } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{office}</p>;
      },
    },
    {
      accessorKey: "user",
      header: "Usuario",
      cell: ({ row }: { row: RowDataT }) => {
        const { user } = row.original;

        return <p className="lg:text-sm text-slate-600 my-1">{user}</p>;
      },
    },
    {
      accessorKey: "is_canceled",
      header: "Anulado",
      enableGrouping: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { is_canceled } = row.original;

        return (
          is_canceled && <LuCircleSlash className="size-6 text-slate-500" />
        );
      },
    },
  ];

  !(offices?.length > 0) && columns.splice(8, 1);

  return columns;
};

type ColumnKeys = "patient_payments" | "incomes" | "expenses";

export const columns = ({
  column,
  config: { offices },
}: {
  column: ColumnKeys;
  config: { offices: Offices[] };
}) => {
  const columns: Record<
    ColumnKeys,
    typeof payments | typeof incomes | typeof expenses
  > = {
    patient_payments: () => payments({ offices }),
    incomes: () => incomes({ offices }),
    expenses: () => expenses({ offices }),
  };

  return columns[column]({ offices });
};
