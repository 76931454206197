"use client";

import * as React from "react";
import classNames from "classnames";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/atoms";
import { SIDEBAR_PERMISSIONS } from "@/libs/sidebars";

export function PermissionsSidebar({
  handlerOnSelect,
  section,
  ...props
}: React.ComponentProps<typeof Sidebar> & {
  handlerOnSelect: (value: string) => void;
  section: string;
}) {
  const isActive = (value: string) => section === value;

  return (
    <Sidebar {...props} className="absolute !h-auto">
      <SidebarContent className="!bg-white">
        <SidebarGroup className="!inline">
          <SidebarMenu className="gap-2">
            {SIDEBAR_PERMISSIONS.navMain.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild>
                  <span
                    onClick={() => handlerOnSelect(item.value)}
                    className={classNames("font-medium cursor-pointer", {
                      "bg-slate-100": isActive(item.value),
                    })}
                  >
                    {item.icon}
                    <p className="font-bold text-base"> {item.title}</p>
                  </span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
