export const TOOTH_FACES = ["v", "p", "o", "d", "m", "l", "i", "g"];

export const ODONTOGRAM_OPTIONS = [
  { id_option: 1, name: "Primario", value: "odontogramapri" },
  { id_option: 2, name: "Permanente", value: "odontogramaper" },
];

export const STATUS_OPTIONS: { [key: string]: { [key: string]: string } } = {
  has_benefit: {
    text: "Prestación",
    style: "bg-green-50 text-green-600 border-green-600/10",
  },
  payment: {
    text: "Pago",
    style: "bg-slate-50 text-slate-600 border-slate-600/10",
  },
  canceled: {
    text: "Pago anulado",
    style: "bg-red-50 text-red-600 border-red-600/10",
  },
};
