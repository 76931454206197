"use client";

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/atoms";
import { useDialog } from "@/hooks";
import { Holyday } from "@/types/general";
import { HolydayForm, DeleteHolyday } from "@/components/organisms/forms";

type RowDataT = {
  original: any;
};

const Dropdown = ({ holyday }: { holyday?: Holyday }) => {
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              openDialog({
                content: <HolydayForm holyday={holyday} />,
              });
            }}
            className="hover:cursor-pointer"
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500 hover:bg-red-50"
            onSelect={() => {
              openDialog({
                content: <DeleteHolyday holyday={holyday} />,
              });
            }}
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = [
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }: { row: RowDataT }) => <Dropdown holyday={row.original} />,
  },
  {
    accessorKey: "date_from",
    header: "Fecha desde",
    cell: ({ row }: { row: any }) => {
      const { date_from } = row.original;
      const DATE_FORMATTED = date_from && format(date_from, "dd-MM-yyyy");

      return (
        <p
          className={classNames(
            `my-1 text-sm leading-6 ${
              date_from ? "text-slate-500" : "!text-blue-600"
            } `
          )}
        >
          {DATE_FORMATTED || "Fecha abierta"}
        </p>
      );
    },
  },
  {
    accessorKey: "date_to",
    header: "Fecha hasta",
    cell: ({ row }: { row: any }) => {
      const { date_to } = row.original;
      const DATE_FORMATTED = date_to && format(date_to, "dd-MM-yyyy");

      return (
        <p
          className={classNames(
            `my-1 text-sm leading-6 ${
              date_to ? "text-slate-500" : "!text-blue-600"
            } `
          )}
        >
          {DATE_FORMATTED || "Fecha abierta"}
        </p>
      );
    },
  },
];
