"use client";

import { LuStar as Star } from "react-icons/lu";

type AvatarProps = {
  name: string;
  planVersion: string;
};

export function Avatar({ name, planVersion }: AvatarProps) {
  return (
    <div className="flex flex-col items-center px-4 py-3 whitespace-pre-wrap text-xl bg-slate-900">
      {["premium", "full", "standard", "small", "smallprem"].includes(
        planVersion
      ) && (
        <Star
          className="relative h-4 w-4 text-yellow-500"
          fill="rgb(234,179,8)"
        />
      )}
      {name && (
        <p className="text-white text-4xl font-semibold">{name.charAt(0)}</p>
      )}
    </div>
  );
}
