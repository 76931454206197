import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  id_patient: z.number(),
  description: z.string().min(1, {
    message: "Campo requerido",
  }),
  date: z.string(),
  file_data: z.string().nullable(),
  file_type: z.string().nullable(),
  id: z.number(),
  image: z.string().nullable(),
  large: z.string().nullable(),
  medium: z.string().nullable(),
  small: z.string().nullable(),
  rowversion: z.string(),
  rute: z.string(),
  size: z.number(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
