import React from "react";
import { Button } from "@/components/atoms";
import { toast } from "@/hooks";

export const ButtonsMedicines: React.FC<{
  setHiddenLoadMedicine: (value: boolean) => void;
  form: any;
}> = ({ setHiddenLoadMedicine, form }) => {
  const {
    list_medications,
    selected_medication,
    is_manual_diagnosis,
    add_diagnosis,
  } = form.watch();

  const updateAndSaveMedicine = () => {
    let list_medications_filter = list_medications || [];
    const index_medicine = list_medications_filter.findIndex(
      (b: { [key: string]: string }) =>
        b.id_medicine_recipe === selected_medication.id_medicine_recipe
    );

    if (
      list_medications_filter[0] &&
      list_medications_filter[0].is_for_hiv !== selected_medication?.is_for_hiv
    ) {
      const message = list_medications_filter[0].is_for_hiv
        ? "El primer medicamento es para HIV; no se podrán incluir otros tipos de medicamentos."
        : "El primer medicamento NO es para HIV; no se podrán incluir otros tipos de medicamentos.";

      return toast({
        variant: "warning",
        title: "Ups! No se puede cargar este medicamento",
        description: message,
      });
    }

    if (
      add_diagnosis &&
      !is_manual_diagnosis &&
      !selected_medication.diagnosis.code
    ) {
      return toast({
        variant: "warning",
        title: "Ups! El diagnostico no es valido",
        description:
          "Por favor busca un diagnostico o escríbelo de forma manual.",
      });
    }
    index_medicine !== -1
      ? (list_medications_filter[index_medicine] = selected_medication)
      : list_medications_filter.push(selected_medication);
    form.setValue("list_medications", list_medications_filter);
    setHiddenLoadMedicine(false);
  };

  const cancelButton = () => {
    form.setValue("add_diagnosis", false);
    form.setValue("is_manual_diagnosis", false);
    setHiddenLoadMedicine(false);
  };

  return (
    <div className="flex w-full justify-end my-4 space-x-4 col-span-full">
      <Button variant="destructive" type="button" onClick={cancelButton}>
        Cancelar
      </Button>
      <Button
        onClick={updateAndSaveMedicine}
        type="button"
        disabled={!selected_medication.product_name}
      >
        Guardar medicamento
      </Button>
    </div>
  );
};
