import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  date: z.date(),
  cli_hist: z
    .string()
    .min(1, {
      message: "Campo requerido",
    })
    .max(4000, {
      message: "No puede contener más de 4000 caracteres",
    }),
  id_professional: z.number(),
  id_office: z.number().nullable(),
  id_patient: z.number(),
  id_user: z.number(),
  id_cli_hist: z.number(),
  rowversion: z.string().nullable(),
  option: z.string(),
};

export const formSchema = z.object(schema);
export const resolver = zodResolver(formSchema);
