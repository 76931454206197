import React from "react";
import { useGlobalStore } from "@/providers/global-provider";
import { Skeleton } from "@/components/atoms";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({ action }) => {
  const {
    patient: { account },
    general_params: { user_params },
    permissions,
    user,
    loading,
  } = useGlobalStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };
  const CONFIG = {
    permissions,
    is_supervisor: user.is_supervisor,
    useUsd: USE_USD,
  };

  return (
    <div className="w-full col-span-full mb-4">
      {loading ? (
        <div className="grid grid-cols-4 gap-4 mx-auto mb-4 mt-5">
          <Skeleton className="col-start-1 h-10 w-40 rounded-xl" />
          <Skeleton className="col-start-5 h-10 w-40 rounded-xl" />
          <Skeleton className="col-span-5 h-80 rounded-xl" />
        </div>
      ) : (
        <Data
          config={CONFIG}
          columns={Columns({
            action,
            config: CONFIG,
          })}
          data={account?.reverse() || []}
        />
      )}
    </div>
  );
};
