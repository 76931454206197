import { LuCalendar, LuUser } from "react-icons/lu";
import { Card, CardContent, CardTitle } from "@/components/atoms/card";
import { BenefitT } from "@/types/components/reports";
import { numberToCurrency } from "@/utils/currency";

export const ReportList = ({ data }: { data: BenefitT[] }) => {
  return (
    <Card className="mt-6 !bg-transparent !border-none">
      <CardTitle>Lista de prestaciones</CardTitle>
      <CardContent className="!p-0 mt-4 h-96 overflow-y-auto overflow-x-hidden pb-40">
        {data?.map((benefit: any) => (
          <div
            className="border rounded-md mb-4 p-2 bg-white"
            key={benefit.code}
          >
            <div className="flex justify-between mb-1">
              <div>
                <span>Código: </span>
                <span className="font-bold text-slate-500 text-base mx-2">
                  {benefit.liquidationCode}
                </span>
              </div>
              <span className="text-green-500">
                {numberToCurrency(benefit.amount)}
              </span>
            </div>
            <span className="text-blue-500">{benefit.description}</span>
            <div className="flex items-center space-x-4 mt-2">
              <LuCalendar />
              <span>{new Date(benefit.date).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center space-x-4">
              <LuUser />
              <span>{benefit.patient}</span>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
