import React from "react";
import { Button } from "@/components/atoms";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const ButtonsOrder: React.FC<{
  setHiddenLoadMedicine: (value: boolean) => void;
  form: Form<typeof formSchema>;
}> = ({ setHiddenLoadMedicine, form }) => {
  const { order_from_list, order_list } = form.watch();
  const updateAndSaveOrder = () => {
    let list_concept_filter = order_list || [];
    const index_concept = list_concept_filter.findIndex(
      (b: { [key: string]: any }) =>
        b.id_order_concept === order_from_list.id_order_concept
    );
    index_concept !== -1
      ? (list_concept_filter[index_concept] = order_from_list)
      : list_concept_filter.push(order_from_list);
    form.setValue("order_list", list_concept_filter);
    setHiddenLoadMedicine(false);
  };

  return (
    <div className="flex w-full justify-end space-x-4 col-span-full">
      <Button
        variant="destructive"
        type="button"
        onClick={() => {
          form.setValue("is_manual_concept", false);
          setHiddenLoadMedicine(false);
        }}
      >
        Cancelar
      </Button>
      <Button
        onClick={updateAndSaveOrder}
        type="button"
        disabled={!order_from_list.description}
      >
        Guardar trabajo
      </Button>
    </div>
  );
};
